/**
 * Create string for multienum from array
 *
 * @param {[]} items
 * @returns {string}
 */
const encodeMultienum = (items) => {
    if (items.length === 0) {
        return '';
    }

    return `^${items.join('^,^')}^`;
};
export default encodeMultienum;
