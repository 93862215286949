/**
 *
 * @param restData
 * @param paramData
 */
export default function checkDeletedRecord(restData, paramData) {
    if (restData.message) {
        if (restData.message.text === 'deleted') {
            paramData.changes = this.dataGet('view/changes/fields');

            this.dsClear();
            this.dsAdd('set', 'conf/load', false);
            this.dsAdd('set', 'view/changes/fields', {});
            this.dsProcess();

            const popupData = {
                color: 'red',
                header: this.translate('LBL_ERROR'),

                buttons: [
                    {
                        label: this.translate('LBL_CANCEL_ACTION'),
                        callback: () => {
                            const url = `#list/${paramData.module}`;
                            window.location.replace(url);
                        },
                    },
                    {
                        label: this.translate('LBL_CONTINUE'),
                        callback: () => {
                            this.saveAsNewRecord(paramData);
                            this.popupHide();
                        },
                    },
                ],
            };

            this.popup(this.translate(restData.message.data), popupData);

            return false;
        }
    }

    return true;
}
