/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Users
 */
export default function loginAsRegUser(data) {
    this.popup('loginAsRegUser', {
        params: data,
        header: this.translate('LBL_LOGIN_AS', 'Users'),
    });
}
