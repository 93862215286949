/**
 * @param {object} attrs
 * @this sAction
 * @button DetailView/Quotes
 */
export default function quoteNewVersion(attrs) {
    this.load();
    const params = {action: 'quote_new_version', record: attrs.id, type: attrs.type};

    this.rest.post('customAction', params, (returnData) => {
        if (returnData.status) {
            this.href('#detail/Quotes/' + returnData.message.data.record);
        } else {
            this.unLoad();
            this.alert(returnData.errorMessage.text);
        }
    });
}
