/**
 *
 * @param file
 * @param callback
 */
export default function uploadImagesFile(file, callback) {
    const formData = {
        files: file,
    };
    this.popup('fileUpload', {percent: 0, loading: true, onClose: () => {

    }});
    const id = this.getPopupId();
    this.filesXhr({
        action: 'uploadImageFile',
        data: formData,
        success: (data) => {
            if (data.state == true) {
                this.dataSet('conf/popup'+id+'/data/loading', false);
                callback(data);
            }
            if (data.state === false) {
                this.dataSet('conf/popup' + id + '/data/loading', false);
                this.dataSet('conf/popup' + id + '/show', false);
                this.error(this.translate(data.err), 'Upload');
            }
        },
        progress: (data) => {
            this.dataSet('conf/popup'+id+'/data/percent', data.percent);
        },
    });
}
