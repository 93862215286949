/**
 * unlockDetail
 * @param {object} recordData
 */
export default function unlockDetail(recordData) {
    this.dsClear();
    this.dsAdd('set', recordData.prefix + '/readonly', false);
    this.dsAdd('set', recordData.prefix + '/fields/locked/value', 0);
    this.dsAdd('set', recordData.prefix + '/changes/fields/locked', 0);
    this.dsProcess();
}
