import detailDefault from '../../detailDefault';

export default class detailacm_machines extends detailDefault {
    load(sAction, data) {

    }

    update(sAction, data) {

    }
}
