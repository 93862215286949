/**
 *
 * @param a
 */
export default function dateToMysql(a) {
    if (a == null) return '';
    a = a.trim().replace(/[^0-9:]+/g, '-');
    a = a.replace(/([0-9]{4})-/g, '$1 ');
    a = a.replace(/^[^0-9]+/g, '');

    let b;
    a = a.split(' ');
    b = a[0];
    b = b.split('-');

    if (b.length == 3) {
        if (b[2].length && b[2].length < 4) b[2] += '%';
        if (b[1].length == 1) b[1] = '0' + b[1];
        if (b[0].length == 1) b[0] = '0' + b[0];

        b = b[2] + '-' + b[1] + '-' + b[0];
    } else if (b.length == 2) {
        if (b[1].length == 1) b[1] = '0' + b[1];
        if (b[0].length == 1) b[0] = '0' + b[0];

        b = b[1] + '-' + b[0];
    } else {
        b = b[0];
    }

    if (a[1]) {
        a[1] = a[1].replace(/[^0-9]+/g, ':');
        b += ' ' + a[1];
    }

    return b;
}
