/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Quotes
 * @render src/components/popup/detailApi/QuoteCreateTemplatePopup.js
 */
export default function quoteCreateTemplate(data) {
    this.popup('quoteCreateTemplate', {
        params: {id: data.id, name: data.name, prefix: data.prefix},
        header: this.translate('LBL_QUOTE_CREATE_TEMPLATE', 'Quotes'),
    });
}
