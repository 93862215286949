/**
 * přidá výraz do textového vyhledávání pro daný modul
 *
 * @param {string} val      vyhledáváný výraze
 * @param {string} module   modul, ve kterém má být vyhledáváno
 * @param {string} prefix   view, pro který je výraz přidán
 */
export default function addSearchContext(val, module, prefix) {
    const view = prefix.split('/').slice(-1)[0];
    let searchContext = this.getStorage('searchContext');

    if (!searchContext) searchContext = {};
    if (!searchContext[`${module}_${view}`]) {
        searchContext[`${module}_${view}`] = {
            searchData: [{
                key: 0,
                val: val,
            }],
        };
    } else {
        if (searchContext[`${module}_${view}`].searchData.find((item) => item.val === val)) return;
        searchContext[`${module}_${view}`].searchData.push({
            key: searchContext[`${module}_${view}`].searchData.length,
            val: val,
        });
    }
    this.setStorage('searchContext', searchContext);
}
