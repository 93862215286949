/**
 *
 * @param name
 */
export default function getStorage(name) {
    let value = null;
    if (window.localStorage) {
        value = localStorage.getItem(name);
    } else {
        value = localStorage.getItem(name);
    }
    try {
        value = JSON.parse(value);
        return value;
    } catch (e) {
        return value;
    }
}
