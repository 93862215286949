/**
 *
 * @param e
 */
export default function rowResizeMove(e) {
    if (this.activeResizeRow) {
        const actX = e.clientX;
        let vysledekX = actX - this.resizeStart;
        vysledekX = vysledekX;
        this.resizeRows.forEach((element) => {
            element.style.maxWidth = this.resizeWidthStart+vysledekX+'px';
            // Pro filtr slozeny ze selektu a inputu width na 150
            if (element.firstChild !== null && element.firstChild.childNodes.length > 1 && element.firstChild.childNodes[0].classList.contains('acmSelectContainer') && element.firstChild.childNodes[1].classList.contains('acmInputContainer')) {
                element.style.minWidth = this.resizeWidthStart+vysledekX < 150 ? '150px' : this.resizeWidthStart+vysledekX+'px';
            } else {
                element.style.minWidth = this.resizeWidthStart+vysledekX < 100 ? '100px' : this.resizeWidthStart+vysledekX+'px';
            }
        });
    }
}
