import React from 'react';
import JSONFormatter from 'json-formatter-js';

import sAction from 'sAction';

export default class ErrorController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            way: null,
        };
        this.jsonTableProps = React.createRef();
        this.jsonTableState = React.createRef();
    }
    componentDidUpdate() {
        if (this.state.hasError == true) {
            const props = window.reactAppErrorComponent.props;

            const newProps = {};
            props.forEachObject((child, key) => {
                try {
                    const data = child.toJS();
                    newProps[key] = data;
                } catch (e) {
                    newProps[key] = child;
                }
            });
            let formatter = new JSONFormatter(newProps);
            this.jsonTableProps.current.appendChild(formatter.render());

            const state = window.reactAppErrorComponent.state;

            const newState = {};
            state.forEachObject((child, key) => {
                try {
                    const data = child.toJS();
                    newState[key] = data;
                } catch (e) {
                    newState[key] = child;
                }
            });
            formatter = new JSONFormatter(newState);
            this.jsonTableState.current.appendChild(formatter.render());
        }
    }
    /**
     *
     * @param error
     * @param info
     */
    componentDidCatch(error, info) {
    // Display fallback UI
        let message = info.componentStack;
        message = message.split('\n').reverse();

        const wayToError = [];
        message.forEach((element) => {
            element = element.trim().replace('in ', '');
            element = element.split(' ')[0];
            if (element != '') {
                const first = element.charAt(0);
                if (first == first.toUpperCase()) {
                    wayToError.push('<' + element + '>');
                }
            }
        });
        this.setState({
            hasError: true,
            error: error.toString(),
            way: wayToError,
        });
    // You can also log the error to an error reporting service
    // sentry removed
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            const way = this.state.way;
            const wayRender = [];
            let lastComponentName = null;
            const length = way.length;
            way.forEach((component, key) => {
                if (key > 3) {
                    let extraClass = '';
                    if (key == (length-1)) {
                        extraClass = ' last';
                    }
                    wayRender.push(<div className={'errorContainerComponent'+extraClass} key={key}>{component}</div>);
                    wayRender.push(<div key={key+'arrow'} className="errorContainerArrow">=></div>);
                    lastComponentName = component;
                }
            });
            const message = this.state.error;
            const componentName = window.reactAppErrorComponent.constructor.name;

            return (
                <div className="errorControllerContainer">
                    <h1>{'Error in '}<b>{lastComponentName}</b>{' component' }</h1>
                    <div className="errorControllerErrorMessage">{message}</div>
                    <div className="errorWayContainer">{wayRender}</div>
                    <div className="errorPropsContainer">
                        <div className="errorPropsHeader">{componentName} - props</div>
                        <div className="errorPropsContent" ref={this.jsonTableProps} />
                    </div>
                    <div className="errorPropsContainer">
                        <div className="errorPropsHeader">{componentName} - state</div>
                        <div className="errorPropsContent" ref={this.jsonTableState} />
                    </div>
          more in console
                </div>
            );
        }
        return this.props.children;
    }
}
