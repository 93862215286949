import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Relate from '../Relate/Relate';
import Select from '../../formElements/Select';
import Input from '../../formElements/InputText';
import sAction from 'sAction';
import Autocomplete from '../../formElements/Autocomplete';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class Parent extends Relate {
    constructor(props) {
        super(props);
        this.state = {
            state: 'fine',
            value: props.value,
            id: '',
            resultOpen: false,
            selectModule: null,
            options: [{value: '', label: ''}],
            defaultValue: '',
            keyCode: null,
            relateSelected: !!props.value,
            focused: 0,
        };
        this.input = React.createRef();
        this.autocomplete = React.createRef();
        this.selectedModuleRef = React.createRef();
    }

    /**
     * clear the current value
     * @param {boolean} force clear value completely
     */
    deleteValue = (force = false) => {
        this.setState({
            id: '',
            value: '',
            relateSelected: false,
        });

        this.selectItem({id: '', name: ''}, true, force);

        if (!force && this.input?.current?.value) {
            this.input.current.value = '';
        }
    };

    /**
     * handle selection of related record
     * @param {string|object} item selected record id
     * @param {boolean} doUpdate force update of the field
     * @param {boolean} cancelEdit
     */
    selectItem(item, doUpdate = true, cancelEdit = true) {
        const data = {
            way: this.props.way,
            name: this.props.def?.get('id_name'),
            fieldName: this.props.name,
            type: 'relate',
            value: item,
            cancelEdit,
        };
        this.saveField(data, doUpdate);
    }

    /**
     * opens related record selection popup
     */
    openPopupList() {
        const self = this;
        const data = {
            module: this.getParentModule(),
            selectedActive: false,
        };
        sAction.openRelatePopup(data, (returnData) => {
            self.selectItem(returnData);
            sAction.popupHide();
        });
    }

    /**
     * create a new record for related module
     */
    newRecord() {
        const module = this.state.module;
        if (!module) {
            return;
        }

        sAction.popupDetail({
            module,
            record: '',
            saveCallback: (ret) => {
                const item = {
                    id: ret.record,
                    name: ret.name,
                };
                const data = {
                    way: this.props.way,
                    name: this.props.def?.get('id_name'),
                    fieldName: this.props.name,
                    type: 'relate',
                    value: item,
                    cancelEdit: true,
                };
                this.setState({
                    id: item.id,
                    name: item.name,
                });
                this.saveField(data, true);
                sAction.unLoad();
            },
            exitCallback: () => {},
        });
    }

    /**
     * handle change of selected module
     * @param {string} val related module name
     */
    moduleChange(val) {
        if (val !== this.getParentModule()) {
            const data = {
                way: this.props.way,
                name: this.props.def?.get('id_name'),
                fieldName: this.props.name,
                type: 'parent',
                value: {id: null, name: null},
                parent_type: val,
                cancelEdit: false,
            };
            this.setState({
                id: '',
                value: '',
            });
            if (this.input?.current?.value) {
                this.input.current.value = '';
            }
            this.saveField(data);
            this.setState({relateSelected: false});
        }
    }

    /**
     * get options for module select
     */
    getOptions() {
        const data = this.props;
        const storedOptions = data.def;
        if (storedOptions?.get('customOptions')) {
            this.setState({
                options: storedOptions?.get('customOptions').toJS(),
                defaultValue: this.getParentModule(),
            });
        } else {
            sAction.load();
            sAction.getParentFieldOptions(this.props.module, (returnData) => {
                sAction.unLoad();
                const options = [];
                returnData.forEach((option) => {
                    options.push({
                        value: option,
                        label: sAction.app_strings['moduleList'][option],
                    });
                });
                this.setState({
                    options: options,
                    defaultValue: this.getParentModule(),
                });
                sAction.dataSet(this.props.way + '/def/customOptions', options);
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.searchInterval);
    }

    // React 18 update componentWillMount -> componentDidMount
    componentDidMount() {
        this.getOptions();
    }

    /**
     * handle key press events
     * @param {KeyboardEvent} e key press event
     * @param {string} type event type
     * @param {bool} save save field value
     */
    onKeyDown(e, type = null, save = true) {
        if (e.keyCode === 9 && this.state.focused === 0) {
            e.preventDefault();
            this.input.current.focus();
            this.setState({focused: 1});
            return;
        }
        super.onKeyDown(e, type, save);
    }

    render() {
        const data = this.props;
        const newRecord = this.props.newRecord;
        const ok = this.state.ok;
        let inputClass = 'form-control-sm';
        const containerClass = 'inputEditContainer';
        if (ok === false) {
            inputClass += ' wrongInput';
        }
        let error = false;
        const keyCode = this.state.keyCode;
        if ((keyCode === 13 || keyCode === 9) && this.input.current.value !== this.state.value) {
            error = sAction.translate('LBL_SELECT_ITEM');
        }

        const containerClassField = 'inputContainer relateField';

        let value = this.state.value || data.value;
        if (newRecord && (value === '' || value === undefined)) {
            value = '';
        }
        this.state.defaultValue = this.getParentModule();

        const isModuleSelected = this.selectedModuleRef?.current?.value || !sAction.isMobile();
        const selectedRecordRender = <>
            <div className={`relatedIcon icon-${this.state.defaultValue}`}/>
            <span>
                {this.state.value}
            </span>
        </>;

        return (
            <div className={containerClass} data-fieldname={data.name}>
                <div className="detailViewParentModuleSelect">
                    <Select
                        onKeyDown={(e) => this.onKeyDown(e, 'first')}
                        options={this.state.options}
                        onChange={(e) => this.moduleChange(e.target.value)}
                        defaultValue={this.state.defaultValue}
                        autoFocus={true}
                        onFocus={() => this.setState({focused: 0})}
                        myRef={this.selectedModuleRef}
                    />
                </div>

                {isModuleSelected && (<div className={containerClassField}>
                    {this.state.relateSelected && (<div className='relatedRecordContainer'>
                        <a
                            href={`#detail/${this.state.defaultValue}/${this.props.def?.get('id_value')}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {selectedRecordRender}
                        </a>

                        {sAction.isMobile() && (
                            <div
                                className={'selectedRecordRender'}
                                onClick={() => sAction.isMobile() && this.openPopupList()}
                            >
                                {selectedRecordRender}
                            </div>
                        )}

                        <TooltipWrapper label={'LBL_CANCEL_ACTION'}>
                            <div onClick={() => this.deleteValue()} className='inputEditButton'>
                                <div className={'icon-detailCancel'}/>
                            </div>
                        </TooltipWrapper>
                    </div>)}
                    <Input
                        onKeyDown={(e) => this.onKeyDown(e, 'last')}
                        onKeyUp={(event) => this.keyUp(event)}
                        onFocus={() => this.setState({focused: 1})}
                        onBlur={(e) => this.onBlur(e)}
                        onClick={() => sAction.isMobile() && this.openPopupList()}
                        myRef={this.input}
                        className={inputClass}
                        type="text"
                        id={data.name}
                        defaultValue={value}
                        autoComplete="off"
                    />
                    {this.state.resultOpen ? <Autocomplete
                        data={data}
                        resultOpen={this.state.resultOpen}
                        searchText={this.state.searchText}
                        searchMethod={'parent'}
                        autocompleteReturnItem={this.storeRelate}
                        addButton={true}
                        module={this.state.defaultValue}
                        newRecord={this.newRecord}
                        ref={this.autocomplete}
                    /> : null}
                </div>)}

                {isModuleSelected && (<div className="buttonContainer">
                    {!!error && (
                        <div
                            tabIndex="-1"
                            className="warningMessage inputEditButton"
                        >
                            <TooltipWrapper title={error} show={!!error}>
                                <div className="icon-warning" />
                            </TooltipWrapper>
                        </div>
                    )}
                    <div
                        tabIndex="2"
                        onClick={() => this.deleteValue(true)}
                        className=" inputEditButton"
                    >
                        <TooltipWrapper label={'LBL_DELETE'}>
                            <div className={'icon-detailCancel'} />
                        </TooltipWrapper>
                    </div>
                    <div
                        tabIndex="1"
                        onClick={() => this.openPopupList()}
                        className="inputEditButton"
                    >
                        <TooltipWrapper label={'LBL_LIST_SEARCH_GLOBAL'}>
                            <div className={'icon-detailSearch'} />
                        </TooltipWrapper>
                    </div>
                </div>)}
            </div>
        );
    }
}

Parent.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        def: ImmutablePropTypes.mapContains({
            id_name: PropTypes.string,
            type: PropTypes.string,
            customOptions: ImmutablePropTypes.listOf(
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }).isRequired,
            ),
            parent_type: PropTypes.string,
        }).isRequired,
    }),
    module: PropTypes.string,
    way: PropTypes.string.isRequired,
    newRecord: PropTypes.bool,
    saveField: PropTypes.func.isRequired,
};
