import React, {useState, createContext} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

/** @type {React.Context<{}>} */
export const ShortcutManagerContext = createContext({});

/**
 * @constructor
 * @param children.children
 * @param children
 * @return {JSX.Element}
 */
export default function ShortcutManagerContextProvider({children}) {
    const [namespace, setNamespace] = useState({
        'main': null,
        'rightPanel': null,
        'popup': null,
        'popup2': null,
        'popup3': null,
        'popup4': null,
        'popup5': null,
    });
    const scopeOrder = ['popup5', 'popup4', 'popup3', 'popup2', 'popup', 'rightPanel', 'main'];
    let activeScope = null;
    let module = null;
    let way = null;
    scopeOrder.every((scope) => {
        if (namespace[scope] !== null) {
            activeScope = scope;
            return false;
        }
        return true;
    });
    const mode = namespace.popup5 || namespace.popup4 || namespace.popup3 || namespace.popup2 ||
        namespace.popup || namespace.rightPanel || namespace.main || 'Default';
    switch (activeScope) {
        case 'main':
            module = sAction.getModuleName();
            way = 'view';
            break;
        case 'rightPanel':
            way = `rightPanel/data/${mode === 'DetailView' ? 'detail' : 'listView'}`;
            module = sAction.dataGet(`${way}/module`);
            break;
        default:
            way = `conf/${activeScope}/data/${mode.charAt(0).toLowerCase() + mode.slice(1)}`;
            module = sAction.dataGet(`${way}/module`);
            break;
    }

    /**
     *
     * @param level
     * @param handler
     */
    const setNewNamespace = (level, handler) => {
        const tempNamespace = namespace;
        tempNamespace[level] = handler;
        setNamespace({...tempNamespace});
    };

    /**
     *
     * @param level
     */
    const clearNewNamespace = (level) => {
        const tempNamespace = namespace;
        tempNamespace[level] = null;
        setNamespace({...tempNamespace});
    };

    /**
     *
     */
    const getHandler = () => {
        const importedHandler = require('../components/Shortcuts/Handlers/' + mode + '.js').default;
        return importedHandler;
    };

    const ShortcutManagerContextData = {
        setNewNamespace,
        clearNewNamespace,
        namespace,
        callHandler: (action, event) => {
            const fce = getHandler();
            const params = {
                scope: activeScope,
                module: module,
                way: way,
            };
            return fce(action, event, params);
        },
    };

    return (
        <ShortcutManagerContext.Provider value={ShortcutManagerContextData}>
            {children}
        </ShortcutManagerContext.Provider>
    );
}

ShortcutManagerContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
