/**
 *
 * @param file
 * @param callback
 */
export default function uploadCacheFile(file, callback) {
    const formData = {
        files: file,
    };
    this.popup('fileUpload', {percent: 0, loading: true, onClose: () => {

    }});
    const id = this.getPopupId();
    this.filesXhr({
        action: 'uploadCacheFile',
        data: formData,
        success: (data) => {
            if (data.state == true) {
                this.dataSet('conf/popup'+id+'/data/loading', false);
                callback(data);
            }
        },
        progress: (data) => {
            this.dataSet('conf/popup'+id+'/data/percent', data.percent);
        },
    });
}
