import moment from 'moment';
import detailDefault from '../../detailDefault';

export default class detailTasks extends detailDefault {
    load(sAction, data) {
        this.checkAllDay(sAction, data);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'all_day':
                this.checkAllDay(sAction, data);
                break;
            case 'date_start':
            case 'date_due':
                this.checkSwitchToAllDay(sAction, data);
                break;
        }
        const dateDue = sAction.dataGet(data.prefix + '/fields/date_due/value');
        if (data.field === 'date_start' && !dateDue) {
            const dateDue = new Date(data.value);
            dateDue.setHours(dateDue.getHours() + 1);
            const formattedDateDue = moment(dateDue).format('YYYY-MM-DD HH:mm:ss');
            sAction.dataSet(data.prefix + '/fields/date_due/value', formattedDateDue);
            sAction.dataSet(data.prefix + '/changes/fields/date_due', formattedDateDue);
        }
    }

    /**
     *
     * @param {class} sAction
     * @param {object} data
     */
    checkAllDay(sAction, data) {
        if (parseInt(sAction.dataGet(data.prefix + '/fields/all_day/value')) === 1) {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
            sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'date');
            sAction.dsProcess();
        } else {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD HH:mm:ss');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD HH:mm:ss');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'datetimecombo');
            sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'datetimecombo');
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {class} sAction
     * @param {object} data
     */
    checkSwitchToAllDay(sAction, data) {
        // when date diff is more than one day, switch to all day format
        const allDay = sAction.dataGet(data.prefix + '/fields/all_day/value');
        let startDate = sAction.dataGet(data.prefix + '/fields/date_start/value');
        let endDate = sAction.dataGet(data.prefix + '/fields/date_due/value');

        if ((parseInt(allDay) === 0 || allDay === null) && startDate !== null && endDate !== null) {
            startDate = moment(startDate).format('YYYY-MM-DD');
            endDate = moment(endDate).format('YYYY-MM-DD');

            // eslint-disable-next-line eqeqeq
            if (startDate !== endDate && startDate !== 'Invalid date' && endDate !== 'Invalid date') {
                // popup switch to allday format?
                sAction.confirm(sAction.translate('LBL_SWITCH_TO_ALLDAY', 'Meetings'), () => {
                    sAction.popupHide();
                    sAction.dsClear();
                    let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
                    let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_due/value');
                    if (valDateStart) {
                        valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
                    }
                    if (valDateEnd) {
                        valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
                    }

                    sAction.dsAdd('set', data.prefix + '/fields/all_day/value', '1');
                    sAction.dsAdd('set', data.prefix + '/changes/fields/all_day', '1');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/actEdit', false);
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/value', valDateEnd);
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_due/actEdit', false);
                    sAction.dsProcess();
                });
            }
        }
    }
}
