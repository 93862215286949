import React from 'react';
import PureComponent from '../../pure';

import InputText from '../../formElements/InputText';

import sAction from 'sAction';
import {EmailPopupButtons} from '../email/shared/EmailPopupButtons';
import ErrorMessage from '../../default/ErrorMessage';

export default class ListviewAddNewProspectList extends PureComponent {
    constructor(props) {
        super(props);

        this.nameRef = React.createRef();
        this.descriptionRef = React.createRef();

        this.state = {
            errors: [],
            error_msg: [],
        };
    }

    savePopup = () => {
        const errors = [];
        const error_msg = [];
        const name = this.nameRef.current.value;
        const description = this.descriptionRef.current.value;

        if (!name) {
            errors.push('name');
            error_msg.push(sAction.translate('ERR_FILL_REQUIRED_ITEMS', 'Emails'));
        }

        this.setState({errors, error_msg});

        if (errors.length) {
            return;
        }

        sAction.popupHide();

        this.props.saveCallback(name, description);
    };

    render() {
        const {errors, error_msg} = this.state;

        return (
            <div>
                <div className="acmPopupHeader">{sAction.translate('LBL_NEW_FORM_TITLE', 'ProspectLists')}</div>
                <div className="acmPopupContent">
                    <div className="w100">
                        {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}
                        <table className="w100">
                            <tbody>
                                <tr>
                                    <th>{sAction.translate('LBL_LIST_PROSPECTLIST_NAME', 'ProspectLists') /* Název*/}</th>
                                    <td style={{padding: '10px 0'}}>
                                        <InputText
                                            autoFocus
                                            inputRef={this.nameRef}
                                            id="name"
                                            type="text"
                                            defaultValue={this.props.name}
                                            fullWidth
                                            className="withBorder"
                                            error={errors.includes('name')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{sAction.translate('LBL_LIST_DESCRIPTION', 'ProspectLists')}:</th>
                                    <td>
                                        <textarea
                                            className="form-control form-control-sm"
                                            id="textarea"
                                            ref={this.descriptionRef}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <EmailPopupButtons onSave={this.savePopup} />
            </div>
        );
    }
}
