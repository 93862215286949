import sAction from 'sAction';
import DefaultAcmDynamicEnum from '../../defaultFields/AcmDynamicEnum/AcmDynamicEnum';

export default class AcmDynamicEnum extends DefaultAcmDynamicEnum {
    /**
     *
     * @param data
     */
    saveField(data) {
        sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);
    }
}
