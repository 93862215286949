import React from 'react';
import PropTypes from 'prop-types';
import AcmDate from '../../formElements/AcmDate.js';
import FormField from './../formField.js';

export default class Date2 extends FormField {
    /**
     * @param {string} date
     */
    save(date) {
        if (this.check(date)) {
            if (this.props.way === 'report' && this.props.onBlur) {
                this.props.onBlur(date);

                return;
            }
            const data = {
                way: this.props.way,
                name: this.props.name,
                value: date,
                cancelEdit: false,
                type: 'date',
            };
            this.saveField(data);
        }
    }
    /**
     * @param {string} date
     * @return {boolean}
     */
    check(date) {
        const required = this.props.def.get('required') || false;
        const isOk = date || !required;

        this.setState({dateOk: isOk});

        return isOk;
    }
    render() {
        return (
            <AcmDate
                autoOpen={true}
                autoFocus={true}
                onChange={(value) => this.save(value)}
                onKeyDown={(e, type, save) => this.onKeyDown(e, type, save)}
                value={this.props.value}
                {...this.props}/>
        );
    }
}

Date2.propTypes = {
    way: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
};
