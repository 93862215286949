/**
 *
 * @param way
 * @param index
 */
export default function reportWindowDeleteLine(way, index) {
    this.dsClear();
    this.dsAdd('delete', way, index);
    this.dsAdd('set', 'reportWindow/filterAlert/alert', false);
    this.dsProcess();
    this.reportWindowFindAllRelate();
}
