import PropTypes from 'prop-types';

/**
 *
 * @param blurActive
 */
export default function load(blurActive = false) {
    this.dataSet('conf/load', true);
    if (blurActive) {
        document.activeElement.blur();
    }
}
load.propTypes = {
    blurActive: PropTypes.bool,
};
