/**
 * getModuleName - get module name from url
 * @return {string} module name
 */
export default function getModuleName() {
    let url = window.location.hash;
    url = url.replace('#', '').split('?')[0];
    url = url.split('/');
    return url[1] || url[0] || 'home';
}
