import React, {PureComponent, Suspense} from 'react';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';

/**
 * Lazy load tutorial
 * https://benestudio.co/how-to-lazy-load-your-react-components/
 */
export default class ReactReportContainer extends PureComponent {
    componentDidMount() {
        // It has to be done like this, beacause in breadcrumb.js for report set on {} and title is set in iframe
        // so react reports has to be set here
        document.title = this.props.label;
    }

    render() {
        const Report = React.lazy(() => import(`../../customReports/${this.props.name}/${this.props.name}.js`));

        return (<Suspense fallback={<Loader/>}>
            <Report title={this.props.label} isDashboard={this.props.isDashboard}/>
        </Suspense>);
    }
}

ReactReportContainer.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    way: PropTypes.string,
    isDashboard: PropTypes.bool,
};
