/**
 *
 * @param {*} module
 * @param {*} id
 */
export default function reportWindowLoadSearch(module, id) {
    if (id !== null && id !== '') {
        const self = this;
        this.rest.get('search/' + module + '/' + id, (data) => {
            data = data.data;
            self.dsClear();
            self.dsAdd('set', 'reportWindow/group', null);
            self.dsAdd('set', 'reportWindow/selectedSavedSearchId', id);
            self.dsProcess();
            self.dsClear();
            self.dsAdd(
                'set',
                'reportWindow/metadata',
                data.metadata,
                'reportWindowMetadata',
            );

            // _____ Nastavení sloupců
            self.dsAdd('set', 'reportWindow/columns/active', []);
            data.columns.active.forEach((activeColumn) => {
                self.dsAdd(
                    'add',
                    'reportWindow/columns/active',
                    activeColumn,
                    'reportWindowColumn',
                );
            });
            self.dsAdd('set', 'reportWindow/columns/hidden', []);
            data.columns.hidden.forEach((hiddenColumn) => {
                if (this.checkValidColumn(hiddenColumn)) {
                    self.dsAdd(
                        'add',
                        'reportWindow/columns/hidden',
                        hiddenColumn,
                        'reportWindowColumn',
                    );
                }
            });

            // _____ Nastavení sloupců END

            // _____ nastaveni filtru
            if (data.group != null) {
                setGroup(data.group, 'reportWindow/group', self);
            }
            // _____ nastaveni filtru END

            // _____ nastaveni orderBy
            data.orderBy.forEachObject((element, key) => {
                self.dsAdd(
                    'set',
                    'reportWindow/orderBy/' + key,
                    element,
                    'reportWindowOrderBy',
                );
            });
            // _____ nastaveni orderBy END

            // _____ nastaveni groupBy
            data.groupBy.forEachObject((element, key) => {
                self.dsAdd(
                    'set',
                    'reportWindow/groupBy/' + key,
                    element,
                    'reportWindowGroupBy',
                );
            });
            // _____ nastaveni groupBy END

            // _____ nastaveni funkcí
            data.fieldFunction.forEachObject((element, key) => {
                self.dsAdd(
                    'set',
                    'reportWindow/fieldFunction/' + key,
                    element,
                    'reportWindowFieldFunction',
                );
            });
            // _____ nastaveni funkcí END

            // _____ nastaveni alter name do storu
            if (data.alterName !== undefined) {
                data.alterName.forEachObject((element, key) => {
                    self.dsAdd(
                        'set',
                        'reportWindow/alterName/' + key,
                        element,
                        'reportWindowAlterName',
                    );
                });
            }
            // ____ nastaveni seskupovani podle data
            if (data.dateGroupFunction) {
                data.dateGroupFunction.forEachObject((element, key) => {
                    self.dsAdd(
                        'set',
                        'reportWindow/dateGroupFunction/' + key,
                        element,
                        'reportWindowGroupByDate',
                    );
                });
            }
            // _____ nastaveni alter name
            self.dsProcess();
            self.reportWindowFindAllRelate();
            this.unLoad();
        });
    } else {
        const module = this.dataGet('reportWindow/module');
        this.reportSelectMainModule(module);
    }
}
/**
 *
 * @param {*} group
 * @param {*} way
 * @param {*} self
 */
function setGroup(group, way, self) {
    self.dsAdd(
        'set',
        way,
        {type: group.type, operator: group.operator},
        'reportWindowGroup',
    );
    group.children.forEach((child, index) => {
        if (child.type === 'field') {
            self.dsAdd('set', way + '/children/' + index, child, 'selectField');
        } else if (child.type === 'group') {
            setGroup(child, way + '/children/' + index, self);
        } else if (child.type === 'relate') {
            self.dsAdd(
                'set',
                way + '/children/' + index,
                {
                    module: child.module,
                    def: child.def,
                    select: child.select,
                    fields: child.fields,
                    relate: child.relate,
                },
                'reportRelateField',
            );
            setGroup(child.group, way + '/children/' + index + '/group', self);
        }
    });
}
