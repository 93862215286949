/**
 *
 * @param way
 */
export default function detailEditDefaultLayout(way) {
    const hidden = [];
    const fields = this.dataGet(way + '/fields');

    fields.entrySeq().forEach((e) => {
        if (e[0] != 'empty_row') {
            const def = e[1].get('def');
            if (def.get('customField') == true && e[1].get('name') !== 'id_c') {
                hidden.push(e[1].get('name'));
            }
        }
    });
    const params = {
        hidden,
        module: this.dataGet(way + '/module'),
    };
    this.load();
    this.rest.post('setDefaultLayout', params, (returnData) => {
        if (returnData.status === true) {
            this.routeDetailView();
        } else {
            this.unLoad();
            this.alert(returnData.errorMessage?.text);
        }
    });
}
