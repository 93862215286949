import detailDefault from '../../detailDefault';

export default class detailAccounts extends detailDefault {
    load(sAction, data) {
        this.updateDetailApiVisibility(sAction, data);
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/type', 'HTMLText');
        sAction.dsAdd('set', data.prefix+'/fields/seeky_activity/def/readonly', true);
        sAction.dsProcess();
    }

    update(sAction, data) {
        switch (data.field) {
            case 'primary_address_country':
                this.updateDetailApiVisibility(sAction, data);
                break;
        }
    }

    // fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
    /**
     *
     * @param sAction
     * @param data
     */
    updateDetailApiVisibility(sAction, data) {
        let address;
        if (data.value) {
            address = data.value;
        } else {
            address = sAction.dataGet(data.prefix + '/fields/primary_address_country/value');
        }

        /**
         *
         * @param fieldName
         * @param fieldValue
         */
        const updateFieldApiValue = (fieldName, fieldValue) => {
            if (sAction.dataGet(data.prefix + '/fields/'+fieldName) !== undefined) {
                sAction.dsAdd('set', data.prefix + '/fields/'+fieldName+'/def/showDetailApi', fieldValue);
            }
        };

        sAction.dsClear();
        if (!address || address === 'CZECH REPUBLIC') {
            // zobrazi ikony
            updateFieldApiValue('account_name', 'ApiName');
            updateFieldApiValue('ticker_symbol', 'ApiICO');
            updateFieldApiValue('primary_address_street', 'ApiStreet');
            updateFieldApiValue('alt_address_street', 'ApiStreet');
            updateFieldApiValue('primary_address_postalcode', 'ApiPSC');
            updateFieldApiValue('alt_address_postalcode', 'ApiPSC');
        } else if (address === 'SLOVAKIA') {
            // zobrazi ikony
            updateFieldApiValue('name', 'ApiNameSK');
            updateFieldApiValue('account_name', 'ApiNameSK');
            updateFieldApiValue('ticker_symbol', 'ApiICOSlovakia');
            updateFieldApiValue('sic_code', '');
            updateFieldApiValue('billing_address_street', 'ApiStreet');
            updateFieldApiValue('shipping_address_street', 'ApiStreet');
            updateFieldApiValue('billing_address_postalcode', 'ApiPSC');
            updateFieldApiValue('shipping_address_postalcode', 'ApiPSC');
        } else {
            // skryje ikony
            updateFieldApiValue('account_name', '');
            updateFieldApiValue('ticker_symbol', '');
            updateFieldApiValue('primary_address_street', '');
            updateFieldApiValue('alt_address_street', '');
            updateFieldApiValue('primary_address_postalcode', '');
            updateFieldApiValue('alt_address_postalcode', '');
        }

        // Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
        const tabs = sAction.dataGet(data.prefix + '/tabs');
        if (tabs) {
            const finstatIndex = tabs.findIndex((i) => i.name === 'LBL_DETAILVIEW_PANEL_FINSTAT');
            if (finstatIndex !== -1) {
                if (address !== 'SLOVAKIA') {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', true);
                } else {
                    sAction.dsAdd('set', data.prefix + '/tabs/' + finstatIndex + '/hidden', false);
                }
            }
        }

        sAction.dsProcess();
    }
}
