import React from 'react';
import FilterBasic from './filterBasic';

import MultiSelect from '../../formElements/MultiSelect';

import sAction from 'sAction';

export default class FilterActivity extends FilterBasic {
    componentDidUpdate() {
        const actFilter = this.props.actFilter;
        if (actFilter == null || actFilter.actCustom == false) {
            this.props.parent.normal();
        } else {
            this.props.parent.advanced();
        }
    }
    /**
     *
     * @param values
     */
    multiEnumValue(values) {
        this.addFilter('module', 'contArray', values);
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter;
        let defaultValue = null;
        if (actFilter != null) {
            defaultValue = actFilter.filters.get(0).value;
        }
        const optionsToSelect = [
            {label: 'Schůzky', value: 'Meetings'},
            {label: 'Hovory', value: 'Calls'},
            {label: 'Úkoly', value: 'Tasks'},
            {label: 'Emaily', value: 'Emails'},
        ];

        const custom = [
            <div className="filterEnumSelectContainer" key="select">
                <MultiSelect
                    ref="input"
                    id={data.get('name')}
                    options={optionsToSelect}
                    defaultValue={defaultValue}
                    open={false}
                    valueFromProps={true}
                    onBlur={(values) => this.multiEnumValue(values)}
                />
            </div>,
        ];

        let removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get('name'))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate('LBL_DELETE_FILTER')}
                </div>
            );
        }
        return (
            <div className="filterBox">
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
