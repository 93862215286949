import React from 'react';
import PureComponent from '../../pure';

import Select from '../../formElements/Select';

import sAction from 'sAction';

export default class BoolField extends PureComponent {
    render() {
        const label = this.props.label;
        const options = [
            {value: '1', label: sAction.translate('LBL_STATUS_ACTIVE_LIST')},
            {value: '0', label: sAction.translate('LBL_STATUS_INACTIVE_LIST')},
        ];
        return (
            <Select
                label = {label}
                options = {options}
                value={this.props.defaultValue}
                myRef={this.headerInput}
                onChange={(e) => this.props.onChange(e.target.value)}
            />
        );
    }
}
