import React from 'react';

import MaterialDefault from './MaterialDefault';
import MaterialRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialRadio from '@mui/material/Radio';

export default class RadioGroup extends MaterialDefault {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
        };
        this.lastKeyCode = null;
    }
    /**
   *
   * @param {Event} e
   */
    onChange(e) {
        this.setState({value: e.target.value});
        if (this.props.onChange != null) {
            if (this.lastKeyCode === 38 || this.lastKeyCode === 39 || this.lastKeyCode === 40 || this.lastKeyCode === 37) {
                this.lastKeyCode = null;
            } else {
                this.props.onChange(e);
            }
        }
    }
    /**
     *
     * @param {Event} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.props.onChange(e);
        } else if (e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40 || e.keyCode === 37) {
            this.lastKeyCode = e.keyCode;
        } else {
            if (this.props.onKeyDown !== undefined) {
                this.props.onKeyDown(e);
            }
        }
    }
    render() {
        const attr = this.createAttr('acmRadioGroup');
        const optionsRender = [];
        let number = 1;
        this.props.options.forEachObject((label, value) => {
            let focus = false;
            if (this.props.autoFocus && value === this.props.value) {
                focus = true;
            }

            optionsRender.push(
                <FormControlLabel
                    tabIndex={number}
                    key={value}
                    value={value}
                    control={<MaterialRadio autoFocus={focus} onKeyDown={(e) => this.onKeyDown(e, number)} />}
                    label={label}
                />,
            );
            number += 1;
        });

        let value = this.state.value;
        if (this.props.value != null) {
            value = this.props.value;
        }
        attr.autoFocus = false;
        attr.onKeyDown = undefined;
        return (
            <MaterialRadioGroup value={value} onChange={(e) => this.onChange(e)} {...attr}>
                {optionsRender}
            </MaterialRadioGroup>
        );
    }
}
