/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Opportunities
 * @render src/components/popup/detailApi/OppCreateQuoteFromTemplate.js
 */
export default function oppCreateQuoteFromTemplate(data) {
    this.popup('oppCreateQuoteFromTemplate', {
        params: data,
        header: this.translate('LBL_SELECT_TEMPLATE', 'Opportunities'),
    });
}
