import DefaultFloat from '../../defaultFields/Float/Float';
import sAction from 'sAction';

export default class Float extends DefaultFloat {
    /**
	 *
	 * @param data
	 */
    saveField(data) {
        sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);
    }
}
