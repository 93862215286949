/**
 *
 */
export default function reportWindowCreateFilter() {
    const data = this.dataGet('reportWindow/group');
    if (data != null && data.children.size != 0) {
        const filter = createFilterFromGroup(data, '');
        return filter;
    } else {
        return {operator: 'AND', operands: []};
    }
}
/**
 *
 * @param data
 * @param relPole
 */
function createOperand(data, relPole) {
    let operand = null;
    if (data.type == 'group') {
        operand = createFilterFromGroup(data, relPole);
    } else if (data.type == 'field') {
        operand = createFilterFromField(data);
    } else if (data.type == 'relate') {
        operand = createFilterFromRelate(data, relPole);
    } else {
        return false;
    }
    return operand;
}
/**
 *
 * @param data
 */
function createFilterFromField(data) {
    if (data.name != null && data.operator != null) {
        console.log(typeof data.value);
        let value = data.value;
        if (typeof data.value === 'object') {
            try {
                value = data.value.toJS();
            } catch (e) {

            }
        }
        const ret = {field: data.name, type: data.operator, value: value};
        if (data.get('parent_type') != undefined) {
            ret['parent_type'] = data.get('parent_type');
        }

        return ret;
    } else {
        return false;
    }
}
/**
 *
 * @param data
 * @param relPole
 */
function createFilterFromGroup(data, relPole) {
    const children = [];
    if (data.children.size != 0) {
        data.children.forEach((child) => {
            const field = createOperand(child, relPole);
            if (field) {
                children.push(field);
            }
        });

        return {operator: data.operator, operands: children};
    } else {
        return false;
    }
}
/**
 *
 * @param data
 * @param relPole
 */
function createFilterFromRelate(data, relPole) {
    const relName = data.def.get('link');
    if (relName != undefined && relName != '') {
        if (relPole == '') {
            relPole = relName;
        } else {
            relPole += '/*'+relName;
        }
    }

    if (data.group != null) {
        const returnData = {
            module: data.module,
            type: 'relate',
            name: data.def.get('name'),
            relationship: relPole.split('/*'),
            filter: createFilterFromGroup(data.group, relPole),
        };
        return returnData;
    } else {
        return false;
    }
}
