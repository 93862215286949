/**
 * @param {object} params
 * @this sAction
 * @button Calendar/DetailView (asi)
 */
export default function saveRecordPopup(params) {
    this.saveRecordBase(params, (data) => {
        if (data.state === true) {
            this.unLoad();
            this.popupHide();
            data.data.popupHidden = true;
            params?.saveCallback?.(data.data);
        }
    });
}
