import React from 'react';
import PureComponent from '../pure';
import PropTypes from 'prop-types';

import sAction from 'sAction';
import InputText from '../formElements/InputText';
import GlobalSearchListContainer from './GlobalSearchListContainer';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

class GlobalSearch extends PureComponent {
    constructor() {
        super();
        this.searchInput = React.createRef();
    }

    /**
     *
     */
    handleSearch() {
        const val = this.searchInput.current.value;

        sAction.href('#globalSearch/' + val);
        sAction.route();
    }

    /**
     *
     * @param {KeyboardEvent} ev
     */
    keyUp(ev) {
        if (ev.keyCode === 13) {
            this.handleSearch();
        }
    }

    render() {
        const way = this.props.way;
        const lists = this.props.data.results;
        const searchString = this.props.data.searchString;

        const renderLists = [];

        lists.forEach((list, ind) => {
            if (list.get('records').size) {
                renderLists.push(
                    <GlobalSearchListContainer
                        data={list}
                        key={ind}
                        ind={ind}
                        way={way}
                    />,
                );
            } else {
                renderLists.push(
                    <div
                        key={ind}
                        className="globalSearchListContainer emptyListContainer"
                    >
                        <div className="moduleTitle">
                            {sAction.translate('LBL_MODULE_NAME', list.modul)}
                        </div>
                    </div>,
                );
            }
        });

        return (
            <div className="globalSearchContainer">
                <div className="globalSearchHeaderContainer">
                    <InputText
                        containerClassName="globalSearchInput "
                        className="withBorder"
                        type="text"
                        key={searchString}
                        myRef={this.searchInput}
                        onKeyUp={(ev) => this.keyUp(ev)}
                        defaultValue={searchString}
                        placeholder={sAction.translate('LBL_GLOBAL_SEARCH_HEADER_TEXT')}
                    />
                    <TooltipWrapper label={'LBL_SEARCHED'}>
                        <div
                            className="icon-search userHeaderButton"
                            onClick={() => this.handleSearch()}
                        />
                    </TooltipWrapper>
                </div>
                <div className="globalSearchResultContainer">{renderLists}</div>
            </div>
        );
    }
}

export default GlobalSearch;

GlobalSearch.propTypes = {
    way: PropTypes.string,
    data: PropTypes.object,
};
