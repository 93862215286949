/**
 * @param {object} params
 * @this sAction
 * @button DetailView/Opportunities
 * @render src/components/popup/detailApi/OppDuplicateQuotePopup.js
 */
export default function oppDuplicateQuote(params) {
    this.popup('oppDuplicateQuote', {
        params: params,
        header: this.translate('LBL_OPP_DUPLICATE_QUOTE', 'Opportunities'),
    });
}
