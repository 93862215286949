// import {calPlanReload} from '../../exportConfig';

/**
 *
 * @param {class} state
 * @param {function} callBack
 * @param {bool} sharedView
 */
export default function saveCalSettings(state, callBack, sharedView = false) {
    const self = this;

    this.load();
    this.rest.post('saveCalSettings', state, function(ret) {
        if (ret.status) {
            callBack();
            self.calPlanReload('calendar', sharedView);
            // this.unLoad(); soucasti calPlanReload()
        } else {
            this.unLoad();
            alert(ret.errorMessage.text);
        }
    });
}
