import DefaultFile from '../../defaultFields/File/File';

export default class File extends DefaultFile {
    /**
   *
   */
    newFile() {

    }

    /**
     *
     */
    deleteFile() {

    }
}
