import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

export default function RightPanelWord(props) {
    useEffect(() => {
        import('docx-preview').then((docx) => {
            fetch(props.data.get('url')).then((res) => {
                const template = res.arrayBuffer();
                docx.renderAsync(template, document.getElementById('panel-section'));
            });
        });
    }, []);

    return (
        <div className="RightPanelWord">
            <div id="panel-section" className='rightPanelWordPreview'/>
        </div>
    );
}

RightPanelWord.propTypes = {
    data: PropTypes.any,
};
