/**
 *
 * @param way
 * @param data
 */
export default function saveMerge(way, data) {
    const oldRecords = [];
    const module = data?.module;
    if (!data || data.records.size < 1) {
        const popupData = {
            color: 'red',
            header: this.translate('LBL_ERROR'),
            buttons: [{
                label: this.translate('LBL_CLOSE_POPUP'),
                callback: () => {
                    this.popupHide();
                    window.location.hash = module ? `#list/${module}` : '#home';
                },
            }],
        };
        this.popup(this.translate('LBL_MERGE_MISSING_OLD_RECORDS'), popupData);
        return;
    }
    data.records.forEach((record) => {
        oldRecords.push(record.get('id'));
    });

    const newRecord = data.newRecord.toJS();
    delete newRecord.id;

    const paramData = {
        record: newRecord,
        oldRecords,
        module: data.module,
    };
    this.load();
    this.rest.post('mergeSave', paramData, (returnData) => {
        if (returnData.record != undefined) {
            window.location.hash = '#detail/'+data.module+'/'+returnData.record;
        }
    });
}
