import {Map, List, fromJS} from 'immutable';
import {DataConstructors} from '../../../DataConstructors';
import processSubpanel from './processSubpanel';
import processCustomData from './processCustomData';

/**
 * @param {*} state
 * @param {*} action
 * @return {*}
 * @TODO fix param types
 */
export default function detailCustom(state, action) {
    let customData;
    const allState = state;

    let prefix = ['detailView'];
    if (action.prefix != null) {
        prefix = action.prefix.split('/');
    }
    state = state.getIn(prefix);
    //
    let type = action.type;
    if (type === 'PAGELOADDETAIL') {
        const data = action.content;
        const module = action.content.module;

        const tabs = []; // přijde do DetailView.tabs jako List(tabs)

        data.tabs.forEach((tab) => {
            const tabPanels = [];
            tab.panels?.forEach((panel) => {
                const tabRows = []; // přijde do Tab.rows jako List(tabRows)
                panel.rows.forEach((row) => {
                    const listRow = [];
                    row.fields.forEach((field) => {
                        if (field.name === 'empty_row' || !field.name) {
                            listRow.push(new DataConstructors['Cell']({
                                name: '',
                                columns: 1,
                            }));

                            return;
                        }

                        if (field.name) {
                            listRow.push(new DataConstructors['Cell']({
                                name: field.name,
                                columns: field.columns,
                            }));
                        }

                        // __________________________________ ze sugaruz chodí špatný currency id type
                        if (field.name === 'currency_id') {
                            if (field.def?.type) {
                                field.def.type = 'currency_id';
                            } else {
                                field.def = {type: 'currency_id'};
                            }
                        }
                    });

                    // if(listRow.length < 2){
                    //     listRow.push(null);
                    // }
                    //   console.log(listRow);
                    tabRows.push(new DataConstructors['DetailViewRow']({fields: new List(listRow)}));
                });
                tabPanels.push(new Map({
                    name: panel.name,
                    rows: new List(tabRows),
                    columns: panel.columns || 2,
                }));
            });

            let active = false;
            if (tabs.length === 0) {
                active = true;
            }
            const newTab = new DataConstructors['Tab']({
                id: tabs.length + 1,
                name: tab.name,
                type: tab.type ?? 'standard',
                removable: tab.removable ?? 'true',
                newRecord: tab.newRecord ?? 'true',
                active: active,
                columns: tab.columns || 2,
                panels: new List(tabPanels),
            });
            tabs.push(newTab);
        });

        // _______________________________________________ naplnění fields
        const fields = {};
        data.fields.forEachObject((field, name) => {
            //  console.log(data.fields);
            let def = fromJS(field.def);
            if (def == null) {
                def = new Map();
            }

            if (field.def) {
                if (field.def.name === 'currency_id') {
                    field.def.type = 'currency_id';
                    if (!field.value) {
                        field.value = allState.getIn(['conf', 'user', 'currency']);
                        if (!action.content.changes) {
                            action.content.changes = {};
                        }
                        // action.content.changes['currency_id'] = "-99"; // resi se az na backendu, pokud si user tu menu nezmeni
                    }
                }
            }

            fields[name] = new DataConstructors['Field']({
                name: name,
                value: field.value,
                def: def,
            });
        });
        // _______________________________________________ naplnění fields END

        // ________________________________________________________ACTION BUTTONS
        const buttons = action.content.actionButtons;
        const dButtons = [];
        buttons.forEach((button) => {
            if (button.params != null) {
                const paramsList = [];
                button.params.forEach((param) => {
                    paramsList.push(new Map(param));
                });
                button.params = new List(paramsList);
            }

            const b = {
                id: button.id,
                def: new Map(button),
            };
            dButtons.push(new DataConstructors['DetailButton'](b));
        });
        // ________________________________________________________ACTION BUTTONS

        // _________________________________________ SUBPANELS
        const subpanels = action.content.subpanels;
        const subpanelsPole = [];
        subpanels.forEachObject((subpanel) => {
            const subData = {
                module: module,
                subpanel: subpanel,
            };
            subData.parentId = data.id;

            const subpanelData = processSubpanel(subData);

            subpanelsPole.push(subpanelData);
        });
        // _________________________________________ SUBPANELS END

        // _________________________________________ TIMELINE
        const timeline = action.content.timeline;
        let timeLineData = null;
        if (timeline != null) {
            let listData = null;
            try {
                timeline.list = timeline.list.toJS();
            } catch (e) {
                // Empty as we dont care
            }
            if (timeline.list.type === 'list') {
                listData = new Map(timeline.list);
            } else if (timeline.list.type === 'custom') {
                const value = {};
                timeline.list.value.forEachObject((data, key) => {
                    value[key] = {
                        label: data.label,
                        values: new List(data.values),
                    };
                });
                listData = new Map({
                    type: timeline.list.type,
                    value: new Map(value),
                    name: timeline.list.name,
                });
            }

            timeLineData = DataConstructors.timeline({
                field: timeline.field,
                list: listData,
                clickable: timeline.clickable,
                onClick: new Map(timeline.onClick),
                colors: new Map(timeline.colors),
                titles: new Map(timeline.titles),
                servisColors: timeline?.servisColors ? new Map(timeline.servisColors) : null,
            });
        }
        // _________________________________________ TIMELINE END

        // _________________________________________ DASHBOARD END

        // _________________________________________ ACTIVITIES
        let activities = null;
        if (action.content.activities != null) {
            activities = fromJS(action.content.activities);
        }
        // _________________________________________ ACTIVITIES END

        // _________________________________________ CUSTOM DATA

        if (data.type === 'popup' && (module === 'Meetings' || module === 'Calls')) {
            // pridani tabu
            const invTab = new DataConstructors['Tab']({
                id: tabs.length + 1,
                name: 'LBL_PARTICIPANTS_TAB',
                active: false,
                type: 'invitees',
            });
            tabs.push(invTab);
        } else if (module === 'Users' && data.id && +allState.conf.user.is_admin === 1) {
            // pridani tabu
            const aclTab = new DataConstructors['Tab']({
                id: tabs.length + 1,
                name: 'LBL_USER_ACCESS',
                active: false,
                type: 'acl_access',
            });
            tabs.push(aclTab);
        }

        customData = processCustomData(data);

        type = action.content.type;
        if (state != null) {
            type = state.type;
        }
        let changes = null;

        let custDataChanges = new Map();

        if (customData && customData.customLines) {
            custDataChanges = {customLines: new List()};
        }

        if (action.content.changes === undefined) {
            changes = new Map({
                fields: new Map(),
                files: new List(),
                customData: custDataChanges,
                forceChange: false,
            });
        } else {
            changes = new Map({
                fields: new Map(action.content.changes),
                files: new List(),
                customData: custDataChanges,
                forceChange: false,
            });
        }
        const newDetailView = new DataConstructors['DetailView']({
            id: data.id,
            name: data.recordName,
            module: module,
            fields: new Map(fields),
            tabs: new List(tabs),
            buttons: new List(dButtons),
            subpanels: new List(subpanelsPole),
            customData: customData,
            timeline: timeLineData,
            activities: activities,
            readonly: data.readonly,
            tableName: data.tableName,
            changes: changes,
            mode: data.mode ?? '',
            type,
            acl: new Map(data.acl),
        });

        return allState.setIn(prefix, newDetailView);
    }

    if (type === 'LOADSUBPANEL') {
        const subpanel = action.subpanel;
        const module = action.parent;
        const subData = {
            module: module,
            subpanel,
        };
        subData.parentId = action.parentId;
        const subpanelData = processSubpanel(subData);

        const way = action.way.replace(prefix.join('/') + '/', '').split('/');
        state = state.setIn(way, subpanelData);

        return allState.setIn(prefix, state);
    } else if (type === 'QUOTERPDATAINIT') {
        const data = action.content;

        const favList = [];
        data.records.favorites.forEach(function(val) {
            // data.favorites[ind] .def = {show :true};
            // val.def = {show:true};
            favList.push(new Map(val));
        });

        const topList = [];
        data.records.top.forEach(function(val) {
            topList.push(new Map(val));
        });

        const recentList = [];
        data.records.recent.forEach(function(val) {
            recentList.push(new Map(val));
        });

        const rpData = {
            favorites: new List(favList),
            recent: new List(recentList),
            top: new List(topList),
        };

        return allState.setIn(
            prefix,
            // eslint-disable-next-line new-cap
            new DataConstructors['quoteProductSelector'](rpData),
        );
    } else if (type === 'LEADCONVERTALLOCATION') {
        const prefix = action.prefix.split('/');
        const lists = [];
        for (let i = 0; i < action.content.length; i++) {
            lists.push(new DataConstructors['DetailView']());
        }

        return allState.setIn(
            prefix,
            // eslint-disable-next-line new-cap
            new DataConstructors['leadConvert']({
                detailViews: new List(lists),
                action: 'convertion',
                record: action.content.record,
                name: action.content.name,
            }),
        );
    } else if (type === 'LEADCONVERTHANDLERESULTS') {
        const prefix = action.prefix.split('/');

        return allState.setIn(
            prefix,
            // eslint-disable-next-line new-cap
            new DataConstructors['leadConvert']({
                newBeans: new List(action.content.data.newBeans),
                relatedBeans: new List(action.content.data.relatedBeans),
                activities: action.content.data.activities,
                action: 'displayResults',
                name: action.content.name,
            }),
        );
    } else if (type === 'DETAILLOCALSTORAGERESTORE') {
        customData = processCustomData(action.content);

        return allState.setIn(['view', 'customData'], customData);
    }

    return allState;
}

/**
 *
 * @param {*} field
 * @param {*} event
 * @returns {*}
 * @TODO fix types in params and return
 */
export function addEvent(field, event) {
    const list = field.get('events').push(event);

    return field.set('events', list);
}
