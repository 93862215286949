import React from 'react';
import sAction from 'sAction';
import PureComponent from '../pure';
import OldRecordContainer from './OldRecordContainer';
import NewRecordContainer from './NewRecordContainer';
import Button from '../formElements/Button';

export default class Merge extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
        };
    }

    render() {
        const data = this.props.data;
        const way = this.props.way;
        const oldRecordsRender = [];
        console.log('MERGE PROPS', this.props);
        if (data) {
            data.records.forEach((record, key) => {
                oldRecordsRender.push(
                    <OldRecordContainer
                        way={way}
                        index={key}
                        data={record}
                        key={key}
                        def={data.filteredDef}
                        sameValues={data.sameValues}
                        module={data.module}
                        isChecked={this.state.isChecked}
                    />,
                );
            });
        }
        return (
            <div className="mergeRecordsContainer">
                <div className="viewActionPanel">
                    <div className="viewActionPanelButtons">
                        <Button onClick={() => sAction.saveMerge(way, data)}>
                            <div className="actionPanelButtonIcon icon-saveIcon"/>
                            {sAction.translate('LBL_SAVE_RECORD')}
                        </Button>
                        <Button onClick={() => window.location.hash = 'list/' + data.module}>
                            <div className="actionPanelButtonIcon icon-detailCancel"/>
                            {sAction.translate('LBL_CANCEL_ACTION')}
                        </Button>
                    </div>
                </div>
                <div className="mergeRecordsContent">
                    <div className="mergeRecordsheader header">
                        <div className="title">
                            {sAction.translate('LBL_MERGE_RECORDS_HEADER')}
                            <label htmlFor="mergeDifferent">
                                <input id="mergeDifferent" type="checkbox" onChange={(e) => this.setState({
                                    isChecked: e.target.checked,
                                })} /> {sAction.translate('LBL_DIFFERENT_ITEMS')}</label>
                        </div>
                    </div>
                    <div className="mergeRecordsBody">
                        <div className="oldRecordsContainer">{oldRecordsRender}</div>
                        <div className="newRecordContainer">
                            {data != null && (
                                <NewRecordContainer
                                    way={way}
                                    def={data.def}
                                    module={data.module}
                                    record={data.newRecord}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
