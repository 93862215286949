/**
 * Mass clears the notifications
 * @param {Object} data
 */
export default function notificationClear(data) {
    this.rest.fetchData('clearNotifications', 'DELETE', {})
        .then((res)=>{
            sAction.openNotBase(data.limit, data.offset);
        },
        )
        .catch((res) => {
            console.warn(res);
        });
}
