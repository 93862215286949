/**
 *
 * @param data
 * @param prefix
 * @param view
 * @param setLayout
 */
export default function emailGetDetail(data, prefix, view = 'email', setLayout = false) {
    const way = 'conf/popup/data';

    if (view != 'email') {
        this.popup('load');
    } else {
        this.load();
    }

    const url = data.mbox.indexOf('sugar::') === -1 ? 'email/getSingleMessage' : 'email/getSingleMessageFromSugar';

    this.rest.post(url, data, (activeEmail) => {
        this.dsClear();

        // rozpesany email - presmeruj na psani emailu
        if (activeEmail.type === 'draft') {
            this.dsAdd('set', 'conf/popup/show', true);
            this.dsAdd('set', 'conf/popup/content', 'emailCompose');

            this.dsAdd('set', way, {}, 'EmailCompose');
            this.dsAdd('set', 'conf/popup/data/header', this.translate('LBL_COMPOSEEMAIL', 'Emails'));

            const newData = {
                to_addrs: activeEmail.to,
                cc_addrs: activeEmail.cc,
                bcc_addrs: activeEmail.bcc,
                name: activeEmail.name || '',
                parent_type: activeEmail.parent_type,
                parent_name: activeEmail.parent_name,
                parent_id: activeEmail.parent_id,
                ccExpanded: !!activeEmail.cc,
                bccExpanded: !!activeEmail.bcc,
                description_html: activeEmail.description,
                emailTemplate: '',
                uid: activeEmail.uid,
                way: '',
            };

            if (activeEmail.fromAccounts.data) {
                activeEmail.fromAccounts.data.forEach((email) => {
                    if (email.selected) {
                        newData.account = email.value;
                    }
                });
            }

            if (activeEmail.attachments) {
                this.dsAdd('set', way + '/attachments', {});
                activeEmail.attachments.forEachObject((a) => {
                    const data = {
                        id: a.id,
                        name: a.filename,
                        source: 'sugartemplate',
                    };
                    this.dsAdd('set', way + '/attachments/' + data.id, data);
                });
            } else {
                newData.attachments = {};
            }

            // this.dsAdd("set", way, newData, "EmailCompose");
            newData.forEachObject((value, key) => {
                this.dsAdd('set', way + '/' + key, value);
            });

            // normalni email - presmeruje na detail
        } else {
            if (view != 'email') {
                this.dsAdd('set', 'conf/popup/show', true);
                this.dsAdd('set', 'conf/popup/content', 'emailCompose');
                this.dsAdd('set', way, {replyMode: view}, 'EmailCompose');
                this.dsAdd('set', 'conf/popup/data/header', this.translate('LBL_COMPOSEEMAIL', 'Emails'));
            }

            const email_way = data.way || prefix + '/emails/0';

            this.dsAdd('set', prefix + '/activeEmail', {...activeEmail, seen: data.seen, way: email_way}, 'EmailDetail');
        }

        this.dsAdd('set', prefix + '/selectedEmail', data.uid);

        this.dsAdd('set', 'conf/load', false);

        if (setLayout) {
            this.dsAdd('set', prefix + '/layout', 1);
            this.dsAdd('set', prefix + '/view1', 'detail');
        }

        this.dsProcess();
    });
}
