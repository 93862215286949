import sAction from 'sAction';

/**
 *
 * @param {String} action
 * @param {Event} event
 * @param {Object} params
 */
const handler = (action, event, params) => {
    switch (action) {
        case 'newRecord':
            document.getElementById('new_record')?.click();
            break;
        case 'searchInList':
            event.preventDefault();
            document.getElementById(params.scope + '-listSearchInput').focus();
            break;
        case 'lookSelect':
            document.getElementById(params.scope + '-listViewLookSelect').focus();
            break;
        case 'previous':
            document.getElementById(params.scope + '-listPrevPage')?.click();
            break;
        case 'next':
            document.getElementById(params.scope + '-listNextPage')?.click();
            break;
        case 'listFirst':
            document.getElementById(params.scope + '-listFirstPage')?.click();
            break;
        case 'listLast':
            if (sAction.dataGet(`${params.way}/rowTotalCount`) !== null) {
                document.getElementById(params.scope + '-listLastPage')?.click();
            } else {
                sAction.listViewCount(params.way);
                // Wait 0,3s for listViewCount, then move to last page
                setTimeout(() => document.getElementById(params.scope + '-listLastPage')?.click(), 300);
            }
            break;
        case 'listSelectAll':
            if (params.scope.indexOf('popup') === -1) {
                sAction.dsClear();
                sAction.dsAdd('set', params.way + '/selected', 'all');
                sAction.dsAdd('set', params.way + '/selectPage', true);
                sAction.dsProcess();
            }
            break;
        case 'listDeselectAll':
            if (params.scope.indexOf('popup') === -1) {
                sAction.dsClear();
                sAction.dsAdd('set', params.way + '/selected', []);
                sAction.dsAdd('set', params.way + '/selectPage', false);
                sAction.dsProcess();
            }
            break;
        case 'exportList':
            if (sAction.dataGet(`${params.way}/selected`).size !== 0) {
                sAction.exportList({prefix: params.way});
            }
            break;
        default:
            break;
    }
};

export default handler;
