/**
 * @param {object} params
 * @param {bool} leave
 * @this sAction
 * @button DetailView
 */
export default function saveRecordIframe(params, leave = false) {
    // funkce prochazi vsechny iframy na detailu a ulozi formulare v nich kliknutim na tlacitko "#saveButton" - pokud existuje
    this.load();
    /**
     *
     */
    const saveFunction = () => {
    // localStorage.setItem('view/changes', '')
        this.unLoad();
        if (leave) {
            this.saveRecordLeave(params);
        } else {
            this.saveRecord(params);
        }
    };

    const intervals = [];
    const iframes = document.getElementsByTagName('iframe');

    if (!iframes.length) {
        saveFunction();

        return;
    }

    iframes.forEachObject((iframe, k) => {
        const saveButton = iframe.contentWindow.document.getElementById('saveButton');
        if (saveButton) {
            iframe.contentWindow.status = 0;
            saveButton.click();
        } else {
            iframe.contentWindow.status = '1';
        }

        intervals[k] = setInterval(() => {
            if (iframe.contentWindow.status && iframe.contentWindow.status !== '0') {
                clearInterval(intervals[k]);
                intervals.splice(k, 1);

                // vsechny formulare byly ulozeny, muze se zavolat dalsi akce
                if (!intervals.length) {
                    saveFunction();
                }
            }
        }, 1000);
    });
}
