/**
 *
 * @param module
 * @param action
 */
export default function hasAccess(module, action) {
    if (this.userPreferences.is_admin == 1) {
        return true;
    }

    const moduleAcl = this.getAccess(module);
    if (moduleAcl != undefined) {
        return moduleAcl[action];
    } else {
        return false;
    }
}
