/**
 *
 * @param data
 * @param callback
 */
export default function checkDuplicityPost(data, callback = null) {
    if (data.isEmail) { // doplneni definic pro groupy
        data.def = this.dataGet(`${data.prefix}/fields/${data.field}/def`);
    }
    const checking = this.dataGet(`${data.prefix}/fields/${data.field}/def/checking`);
    if (checking) {
        return;
    }
    this.load();
    this.dataSet(`${data.prefix}/fields/${data.field}/def/checking`, true);
    if (data?.def.get('checkDuplicityGroup')) {
        const fields = this.dataGet(`${data.prefix}/fields`);

        // potrebujeme na BE poslat aktualni hodnoty poli
        data.groupedFields = fields.filter((field) =>
            field.def.get('checkDuplicityGroup') == data.def.get('checkDuplicityGroup'),
        );
    }

    data.id = this.dataGet(data.prefix + '/id'); // abychom nehledali ve stejnem zaznamu ... pro kontrolu na jiz existujicich zaznamech
    this.rest.post('checkDuplicity', data, (response) => {
        this.dataSet(`${data.prefix}/fields/${data.field}/def/checking`, false);
        if (response.status) {
            const dataPopup = {
                header: this.translate('LBL_CONFIRM'),
                buttons: [
                    // Zrušit
                    {
                        label: this.translate('LBL_CANCEL', 'Home'), callback: () => {
                            this.popupHide();
                            if (callback) {
                                callback();
                            }
                        },
                    },
                    // Pokračovat
                    {
                        label: this.translate('LBL_CONTINUE', 'Home'), callback: () => {
                            this.popupHide();
                            this.href('#detail/' + data.module + '/' + response.message.data.duplicateID);
                        },
                    },
                ],
                preventClose: true,
            };

            this.popup(response.message.text, dataPopup);
        }

        this.unLoad();
    });
}
