import React from 'react';
import PureComponent from '../pure';

import Subpanel from './subpanel';
import DragAndDropCoripo from '../dragAndDrop/DragAndDropCoripo';
import propTypes from 'prop-types';
import sAction from 'sAction';

export default class Subpanels extends PureComponent {
    /**
     *
     * @param {*} data
     */
    moveStart(data) {
        const way = data.element.dataset.way;
        sAction.dataSet(way + '/def/show', false);
    }
    /**
     *
     */
    moveEnd() {
        sAction.saveSubpanelOrder(this.props.way);
    }

    render() {
        const data = this.props.data;
        const way = this.props.way + '/subpanels';
        let colorIndex = 0;
        const colorTemplateLength = sAction.colorTemplate.length;
        const renderData = [];
        data.forEach((subpanel, key) => {
            if (colorIndex >= colorTemplateLength) {
                colorIndex = 0;
            }
            const color = sAction.colorTemplate[colorIndex];
            colorIndex ++;
            renderData.push(
                <Subpanel key={key} data={subpanel} prefix={this.props.way} iconColor={color} way={way + '/' + key} acl={this.props.acl} />,
            );
        });

        return (

            <DragAndDropCoripo
                containersClass=".subpanelsContainer"
                elementsClass=".subpanelContainer"
                handleElementClass=".moveHolder"
                wayPrefix=""
                moveStart={(data) => this.moveStart(data)}
                afterEnd={(data) => this.moveEnd(data)}
                moveObjectClass="subpanelMove"
                minHeight={false}
            >
                <div id="subpanels" className="subpanelsContainer" data-way={way}>

                    {renderData}
                </div>
            </DragAndDropCoripo>

        );
    }
}

Subpanels.propTypes = {
    data: propTypes.any,
    acl: propTypes.any,
    way: propTypes.string,
};
