/**
 *
 * @param params
 */
export default function importFileMapping(params) {
    const module = this.getModuleName();
    const prefix = params.prefix;
    const data = this.dataGet(prefix).toJS();
    const mapping = data.mapping?.fileMapping;
    delete data.mapping?.duplicates;
    const delimiters = data.delimiters;
    const fileEncoding = data.fileEncoding;
    const fileId = params.fileId;
    const ignoreFirst = data.ignoreFirstRow === 1;
    const mapFromFirst = data.useFirstAsMapping === 1;

    let additionalMapping;
    if (data.mapping?.additionalData) {
        additionalMapping = this.formatRelatesMapping(data.mapping.additionalData);
    }
    mapping.forEachObject((value, key) => {
        mapping[key] = {value: value.value, type: value.type};
        if (value.type === 'relate') {
            mapping[key].relate = value.relatedField?.value;
        }
    });

    const mappingData = {
        mapping: mapping,
        ignoreFirst: ignoreFirst,
        mapFromFirst: mapFromFirst,
        additionalData: additionalMapping,
        delimiters: delimiters,
        fileEncoding: fileEncoding,
        file: fileId,
        duplicatesBy: data.duplicates.field,
        duplicatesHandle: data.duplicates.action,
    };

    this.dsClear();
    this.dsAdd('set', 'conf/load', true);
    this.dsProcess();
    this.rest.post('import/' + module, mappingData, (returnData) => {
        if (returnData.status === true) {
            this.loadImportedList(returnData, module, prefix);
        } else {
            this.unLoad();
            this.error(
                returnData.errorMessage?.data?.map?.((x) => this.translate(x)).join('<br>') ||
                this.translate(returnData?.errorMessage?.text) ||
                this.translate('LBL_IMPORT_UNKNOWN_ERROR'),
            );
        }
    });
}
