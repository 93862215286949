/**
 *
 * @param pass
 * @param token
 * @param _callback
 */
export default function resetPassword(pass, token, _callback) {
    this.load();
    this.rest.post('resetPassword', {password: pass, token: token}, (data) => {
        this.unLoad();
        _callback(data);
    });
}
