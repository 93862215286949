/**
 *
 * @param tags
 * @param way
 */
export default function searchTagRelatives(tags, way) {
    this.load();
    this.dsClear();

    this.rest.post('globalSearch/', {str: tags, byTag: true}, (data) => {
        data.lists.forEach((list, index) => {
            const akce = {
                type: 'PAGELOADLIST',
                content: list,
                listViewPrefix: way + '/data/lists/' + index,
                page: list.module,
            };
            this.dsAdd('set', way + '/data/lists/' + index, {});
            this.dsAddCustom(akce);
        });
        this.dsProcess();

        this.unLoad();
    });
}
