import sAction from 'sAction';

/**
 *
 * @param callback
 */
export default function homeLayoutSave(callback) {
    const data = this.dataGet('view').toJS();
    // console.log(data);

    this.setStorage('homeActiveTab', data.activeId);

    data.tabs.forEach((t, ti) => {
        t.cols.forEach((c, ci) => {
            c.rows.forEach((r, ri) => {
                if (!r.def) {
                    return;
                }
                if (r.def.type == 'view' || r.def.type == 'calendar') {
                    const path = data.tabs[ti].cols[ci].rows[ri].data;
                    path.orderBy = path.viewData?.orderBy ?? '';
                    path.asc = path.viewData?.asc ?? 0;
                    path.viewData = '';
                    path.dashletId = ti+'_'+ci+'_'+ri;
                    const viewData = sAction.dataGet('view/tabs/'+ti+'/cols/'+ci+'/rows/'+ri+'/data/viewData');
                    if (viewData) {
                        this.dataSet('view/tabs/'+ti+'/cols/'+ci+'/rows/'+ri+'/data/viewData/dashletId', ti+'_'+ci+'_'+ri);
                    }
                }
            });
        });
    });

    this.rest.post('home/savedashboard', data, function(data) {
        if (callback) {
            callback();
        }
    }, false);

    // uprava sirky sloupcu v listview kvuli asynchroncite...
    const times = [100, 1100];
    for (const time of times) {
        setTimeout(() => {
            if (this.dataGet('conf/page') === 'home') {
                this.afterLoadListView('view', undefined, 'widget');
            }
        }, time);
    }
}
