import detailDefault from '../../detailDefault';

export default class detailDocuments extends detailDefault {
    load(sAction, data) {
        const customData = sAction.dataGet('view/customData').toJS();
        sAction.dataSet('view/customData', customData, 'EmailDetail');
    }

    update(sAction, data) {

    }
}
