import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';

import InputText from '../formElements/InputText';
import Select from '../formElements/Select';
import Relate from '../formElements/Relate';
import MultiSelect from '../formElements/MultiSelect';
import Bool from '../formElements/CheckBox';

export default class MergeEditField extends PureComponent {
    constructor() {
        super();
    }
    componentDidMount() {}
    componentDidUpdate() {}
    /**
     *
     * @param e
     */
    onKeyUp(e) {
        if (e.keyCode == 13) {
            this.save(e);
        }
    }
    /**
     *
     * @param e
     * @param save
     */
    save(e, save) {
        const way = this.props.way;
        const field = this.props.field;

        const value = e.target.value;
        console.log(value);
        if (save == true) {
            sAction.dataSet(way + '/newRecord/' + field, value);
        }
        this.props.onBlur();
    }
    /**
     *
     * @param data
     */
    multiEnumSave(data) {
        let values = '';
        if (data != null) {
            values = data.join('^,^');
            values = '^' + values + '^';
        }
        this.save({target: {value: values}}, true);
    }

    /**
     *
     * @param value
     * @param save
     */
    boolSave(value, save) {
        value = value ? 1 : 0;
        this.save({target: {value}}, save);
    }

    render() {
        const value = this.props.value;
        const field = this.props.field;
        const way = this.props.way;
        const def = this.props.def;

        const type = def.get('type');
        let content = null;
        console.log(def.toJS());
        if (type == 'enum') {
            const options = sAction.getEnumForSelect(def.get('options'));
            content = (
                <Select
                    options={options}
                    onBlur={(e) => this.save(e, false)}
                    onChange={(e) => this.save(e, true)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    defaultValue={value}
                    autoFocus={true}
                />
            );
        } else if (type == 'multienum') {
            const options = sAction.getEnumForSelect(def.get('options'));
            content = (
                <MultiSelect
                    ref="input"
                    id={field}
                    options={options}
                    defaultValue={value}
                    valueFromProps={true}
                    open={true}
                    //   onChange={values => this.multiEnumSave(values)}
                    onBlur={(values) => this.multiEnumSave(values)}
                    onKeyDown={(e, value) => this.multiOnKeyDown(e, value)}
                    className="detailViewMulti"
                />
            );
        } else if (type == 'bool') {
            content = (
                <Bool
                    ref="input"
                    id={field}
                    defaultValue={value ? 1 : 0}
                    // onBlur={e => this.boolSave(e, false)}
                    // onChange={e => this.boolSave(e, true)}
                    readonly={true}
                />
            );
        } else if (type == 'relate' && def.get('source') == 'non-db') {
            content = (
                <Relate
                    module={def.get('module')}
                    data={{value: value, name: field}}
                    autoFocus={true}
                    callback={(data) => {
                        sAction.dsClear();
                        sAction.dsAdd('set', way + '/newRecord/' + field, data.name);
                        sAction.dsAdd(
                            'set',
                            way + '/newRecord/' + def.get('id_name'),
                            data.id,
                        );
                        sAction.dsProcess();
                    }}
                />
            );
        } else {
            content = (
                <InputText
                    onBlur={(e) => this.save(e, true)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    defaultValue={value}
                    autoFocus={true}
                />
            );
        }

        return content;
    }
}
