import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import composeEmail from '../detailView/DetailViewFields/InputFields/Email/composeEmail';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class NewActivityButton extends PureComponent {
    constructor() {
        super();
        this.state = {
            open: false,
        };
        this.button = React.createRef();
    }

    showEmailPopup = (e) => {
        this.setState({open: false});
        composeEmail(this.props.prefix)(e);
    };

    render() {
        const prefix = this.props.prefix;
        const optionsRender = [];
        this.props.activities.forEach((activity, index) => {
            if (activity.module === 'Emails') {
                optionsRender.push(
                    <TooltipWrapper key={activity.module} label={'LBL_COMPOSE_EMAIL_BUTTON_LABEL'}>
                        <div key={index} onClick={this.showEmailPopup} className={'newActivityOption'}>
                            <div className={activity.icon} /* for color: 'moreOptionIcon '*/
                            />
                        </div>
                    </TooltipWrapper>,
                );
            } else {
                if (sAction.hasAccess(activity.module, 'newRecord')) {
                    const parent = sAction.dataGet(prefix);
                    const params = {
                        module: activity.module,
                        parentId: parent.id,
                        parentName: parent.name,
                        parentModule: parent.module,
                        prefix: prefix,
                    };
                    optionsRender.push(
                        <TooltipWrapper key={activity.module} label={'LBL_NEW_' + activity.module.toUpperCase()}>
                            <div key={index} className={'newActivityOption'} onClick={() => {
                                this.setState({open: false});
                                if (this.props.parent) {
                                    this.props.parent.closeMaxSize();
                                }
                                sAction.newSubRecord(params);
                            }}>
                                <div
                                    className={'icon-' + activity.module} /* for color: 'moreOptionIcon '*/
                                />
                            </div>
                        </TooltipWrapper>,
                    );
                }
            }
        });

        return (
            <div className={'newActivityButtonList'}>
                {optionsRender}
            </div>
        );
    }
}

NewActivityButton.propTypes = {
    activities: PropTypes.array,
    data: PropTypes.any,
    parent: PropTypes.any,
    prefix: PropTypes.string,
    way: PropTypes.string,
};
