import detailDefault from '../../detailDefault';

// eslint-disable-next-line camelcase
export default class detailacm_contracts_file_templates extends detailDefault {
    load(sAction, data) {
        const defaultText = sAction.translate('LBL_DEFAULT_TEXT', 'acm_contracts_file_templates');

        sAction.dsClear();
        sAction.dsAdd('set', data.prefix+'/fields/description/value', defaultText);
        sAction.dsProcess();
    }

    // eslint-disable-next-line no-unused-vars
    update(sAction, data) {

    }
}
