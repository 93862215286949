import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

export default function NetworkAlert({type}) {
    let content = '';
    let buttons = '';

    switch (type) {
        case 'offline':
            content = sAction.translate('LBL_OFFLINE_MESSAGE');
            // buttons = (
            //     <button className='alertButton' onClick={() => {
            //         window.location.reload();
            //         sAction.dataSet('conf/offline', false);
            //     }}>
            //         {sAction.translate('LBL_OFFLINE_BTN')}
            //     </button>
            // );
            break;
        case 'loggedOut':
            content = sAction.translate('LBL_LOGGED_OUT_MESSAGE');
            buttons = (
                // navigate to login page  #login
                <button className='alertButton' onClick={() => {
                    window.location.href = '#login';
                    sAction.dataSet('conf/loggedOut', false);
                }}>
                    {sAction.translate('LBL_LOGGED_OUT_BTN')}
                </button>
            );
            break;
    }

    return (
        <div className={'networkAlert ' + type}>
            <div className="networkAlertIcon">
                <span className={'iconfa-' + type}></span>
            </div>
            <div className="networkAlertText">
                {content}
            </div>
            <div className='networkAlertButtons'>
                {buttons}
            </div>
        </div>
    );
}

NetworkAlert.propTypes = {
    type: PropTypes.string,
};
