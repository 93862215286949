import sAction from 'sAction';

/**
 *
 * @param {string} module
 * @returns {string}
 */
export default function getSubpanelIcon(module) {
    let icon;
    const menuGroups = sAction.dataGet('menu/groups');
    for (const group of menuGroups) {
        icon = group.icon;
        if (icon !== module) {
            icon = group.buttons.find((button) => button.name === module);
            icon = icon === undefined ? 'object-connection' : icon.name;
        }

        if (icon === module) break;
    }

    if (module === 'History') {
        icon = 'history';
    }

    if (module && module.includes('mail')) {
        icon = 'emails';
    }

    if (!icon) {
        icon = 'listview';
    }

    return icon;
}
