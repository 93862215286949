/**
 *
 * @param files
 * @param {string} way
 * @param {bool} redirect
 */
export default function uploadFiles(files, way, redirect = false) {
    const formData = {
        files: files,
    };
    const list = this.dataGet(way);
    if (list.listViewTree !== undefined) {
        if (list.listViewTree !== null) {
            const activeCategory = list.listViewTree.get('activeCategory');
            const fieldName = list.listViewTree.get('field');
            formData['fieldData'] = {};
            formData['fieldData'][fieldName] = activeCategory;
        }
        // ______________________________________ if is subpanel
        if (list.type === 'subpanel') {
            const sRelData = this.dataGet(way + '/../def/rel');
            const parentId = this.dataGet(way + '/../../../id');

            const relData = {
                module: sRelData.get('module'),
                relname: sRelData.get('relName'),
                id: parentId,
            };
            formData['relData'] = relData;
        }

        // ______________________________________ if is subpanel
        if (!redirect) {
            this.popup('fileUpload', {percent: 0, loading: true, onClose: () => {
                this.reloadList(0, 1, way);
            }});
        }
        const id = this.getPopupId();
        this.filesXhr({
            action: 'uploadFile',
            data: formData,
            success: (data) => {
                if (data.state) {
                    this.dataSet('conf/popup'+id+'/data/loading', false);
                    if (redirect) {
                        window.location.replace(`${window.location.origin}/#detail/Documents/${data.documentId}`);
                    }
                }
            },
            progress: (data) => {
                this.dataSet('conf/popup'+id+'/data/percent', data.percent);
            },
        });
    }
}
