import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import Loader from '../loader';

export default class AdminIframe extends PureComponent {
    constructor() {
        super();
        this.iframe = React.createRef();
    }
    componentDidMount() {
        const name = this.props.name;
        const way = this.props.way;
        let paramData = {};
        if (this.props.data != null) {
            paramData = this.props.data.get('params');
        }
        sAction.loadAdminCustom(name, way+'/customData', paramData);
        const self = this;
        this.listener = function(evt) {
            self.iframeMessage(evt);
        };
        window.addEventListener('message', this.listener);
        if (this.props.isDetail) {
            this.componentDidUpdate();
        }
    }
    componentDidUpdate() {
        const data = this.props.data;

        let html = '';
        try {
            html = data.get('html');
        } catch (e) { // eslint-disable-line

        }
        this.iframe.current.contentWindow.document.open();
        this.iframe.current.contentWindow.document.write(html);

        if (!html) {
            const name = this.props.name;
            const way = this.props.way;

            let paramData = {};
            if (this.props.data != null) {
                paramData = this.props.data.get('params');
            }
            sAction.loadAdminCustom(name, way+'/customData', paramData);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.listener);
    }
    /**
   *
   * @param {*} message
   */
    iframeMessage(message) {
        let data = message.data;
        const name = this.props.name;
        const way = this.props.way;

        if (data.action === 'submit') {
            sAction.dataSet(way+'/customData/html', 'load');
            sAction.loadAdminCustom(name, way+'/customData', data.data);
        } else if (data.action === 'href') {
            if (data.target === '_blank') {
                window.open(data.url, '_blank');
            } else {
                window.location = data.url;
            }
        } else if (data.action === 'ajax') {
            sAction.adminCustomAjax(name, data, (returnData) => {
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'ajax_silent') {
            sAction.load();
            sAction.rest.post(data.action_url, data, (returnData) => {
                sAction.unLoad();
                if (returnData && returnData.errorMessage) {
                    sAction.error(returnData.errorMessage);
                } else if (typeof returnData !== 'object' || (returnData.status !== 'ok' && returnData.status !== true)) {
                    sAction.error(sAction.translate('LBL_ACTION_FAILED'));
                }
            });
        } else if (data.action === 'relate') {
            data = {
                module: data.module,
            };
            sAction.openRelatePopup(data, (returnData) => {
                sAction.popupHide();
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'load') {
            sAction.load();
        } else if (data.action === 'unLoad') {
            sAction.unLoad();
        } else if (data.action === 'download') {
            sAction.downloadFile({
                id: '',
                name: data.file,
                module: 'acm_coripoReports',
                params: {
                    file: data.file,
                },
            });
        } else if (data.action === 'message') {
            sAction.adminMessage(data.data, (returnData) => {
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        }
    }
    render() {
        const data = this.props.data;
        let load = false;
        if (data == null) {
            load = true;
        } else {
            if (!data.get('html') || data.get('html') === 'load' || data.get('html') == null) {
                load = true;
            }
        }

        return <div className="customReportContainer">
            <div className="customReport">
                {load && <Loader />}
                <iframe style={load ? {display: 'none'}:null} ref={this.iframe} />
            </div>
        </div>;
    }
}

AdminIframe.propTypes = {
    data: PropTypes.shape({
        get: PropTypes.func,
    }),
    isDetail: PropTypes.any,
    name: PropTypes.any,
    way: PropTypes.string,
};
