/**
 *
 * @param token
 * @param type
 * @param _callback
 */
export default function confirmToken(token, type, _callback) {
    this.rest.post('token/confirmToken', {token: token, type: type}, (data) => {
        _callback(data);
    });
}
