/**
 *
 * @param email
 * @param list
 */
export default function emailDeleteFromStringList(email, list) {
    // odstrani vlastni email ze seznamu prijemcu
    // kdyz se v emailu klikne na "Odpovedet vsem"

    if (!list) return '';
    if (!email) email = '';
    email = email.replace(/.*\(|\).*/g, '');

    list = list
        .replace(/\s+,/g, ',')
        .replace(/,\s+/g, ',')
        .split(/,/);

    const regexp = new RegExp('^' + email + '$', 'g');
    for (let i = list.length - 1; i >= 0; i--) {
        if (regexp.test(list[i].replace(/.*<|>.*/g, ''))) {
            list.splice(i, 1);
        }
    }
    return list.join(', ');
}
