/**
 *
 * @param record
 * @param key
 * @param prefix
 */
export default function deleteTagRelate(record, key, prefix) {
    this.load();
    this.rest.post('tags/deleteRelate/', {record: record}, (data) => {
        if (data['status'] == true) {
            this.dataDelete(prefix + '/tags', key);
        }
        this.unLoad();
    });
}
