import PureComponent from '../../pure';
import sAction from 'sAction';

export default class FilterBasic extends PureComponent {
    componentDidUpdate() {
        const actFilter = this.props.actFilter;
        if (actFilter == null || actFilter.actCustom == false) {
            this.props.parent.normal();
        } else {
            this.props.parent.advanced();
        }
    }
    /**
     *
     * @param data
     * @param asc
     */
    order(data, asc) {
        const prefix = this.props.prefix;
        const field = data.get('fieldWithPath') ? data.get('fieldWithPath') : data.get('name');
        sAction.orderBy(field, asc, prefix);
    }
    /**
     *
     * @param field
     * @param type
     * @param value
     * @param groupOperator
     */
    addFilter(field, type, value, groupOperator = 'and') {
        const data = this.props.data;

        const prefix = this.props.prefix;
        const fieldModule = data.get('fieldModule');
        const fieldRel = this.props.fieldRel;
        const filter = {
            field: field,
            fieldRel: fieldRel,
            fieldModule: fieldModule,
            type: type,
            value: value,
        };
        const filters = new Array(filter);
        if (data.type == 'enum' && value == '') {
            /* pokud je typ pole enum tak prázdná hodnota ze selectu zruší filter */
            sAction.removeFilter(field, filters, 'and', prefix);
        } else {
            const data = {
                field: field,
                fieldModule: fieldModule,
                fieldRel: fieldRel,
                filters: filters,
                operator: groupOperator,
                reload: true,
                prefix: prefix,
            };
            sAction.addFilter(data);
        }
    }
    /**
     *
     * @param field
     */
    removeFilter(field) {
        const prefix = this.props.prefix;
        const filter = {
            field: field,
            type: null,
            value: null,
        };
        const filters = new Array(filter);
        const data = {
            field: field,
            filters: filters,
            operator: 'and',
            prefix: prefix,
        };
        sAction.dsClear();
        sAction.dsAdd('set', 'view/removeFieldText', true);
        sAction.dsProcess();
        sAction.removeFilter(data);
    }
    addFieldFunction = (func) => {
        const module = this.props.data.get('fieldModule');
        const field = this.props.data.get('name');
        const prefix = this.props.prefix;
        sAction.addFieldFunction(module+'.'+field, func, prefix);
    };
    /**
     *
     * @param e
     */
    customInputKeyUp(e) {
        if (e.keyCode == 13) {
            const data = this.props.data;
            this.addCustomFilter(data.get('name'));
        }
    }
    /**
     *
     */
    getLines() {
        const actFilter = this.props.actFilter;
        const lines = [];
        if (actFilter && actFilter.filters) {
            actFilter.filters.forEach((line, key) => {
                lines[key] = {
                    select: line.type,
                    input: line.value,
                };
            });
        }
        return lines;
    }

    /**
     *
     * @param key
     * @param value
     * @param input
     */
    updateLines(key, value, input) {
        if (input === 'select') {
            sAction.dataSet(this.props.way + '/filters/' + key + '/type', value);
        } else if (input === 'input') {
            sAction.dataSet(this.props.way + '/filters/' + key + '/value', value);
        }
    }
}
