/**
 *
 * @param {Object} paramData
 * @param {Function} callback
 */
export default function processFileInput(paramData, callback) {
    const tempEl = document.createElement('input');
    tempEl.setAttribute('type', 'file');
    if (paramData.multiple) {
        tempEl.setAttribute('multiple', paramData.multiple);
    }
    if (paramData.accept) {
        // povolene typy souboru pro upload, napr. "image/png,image/jpeg,image/bmp,image/gif" nebo jen "image/*"
        // nebo ".doc,.docx,application/msword" atd.
        tempEl.setAttribute('accept', paramData.accept);
    }
    tempEl.style = 'display: none';
    document.body.appendChild(tempEl);
    tempEl.addEventListener('change', () => {
        const files = [];
        tempEl.files.forEachObject((file, key) => {
            if (key !== 'length') {
                files.push(file);
            }
        });
        tempEl.parentNode.removeChild(tempEl);
        if (callback != null) {
            callback(files);
        } else {
            this.uploadFiles(files, paramData.prefix);
        }
    });
    tempEl.click();
    this.openSubpanel(paramData.way);
}
