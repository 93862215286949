/**
 *
 */
export default function routeGlobalSearch() {
    let str = decodeURIComponent(this.getUrlParam(1));
    console.log('bud', str);

    if (str === undefined) {
        str = this.dataGet('view/searchString');
    }

    const params = {str: str};

    const self = this;
    self.dataSet('conf/popup/show', false);
    this.rest.post('globalSearch', params, function(data) {
        const akce ={
            type: 'GSRESULTSALLOCATION',
            content: {length: data.lists.length, params: params},
            prefix: 'view',
        };
        self.dsAddCustom(akce);

        let ind = 0;

        data.lists.forEach((list) => {
            const akce ={
                type: 'PAGELOADLIST',
                content: list,
                listViewPrefix: 'view/results/'+ind,
                page: list.module,
            };
            self.dsAddCustom(akce);

            const akceFilt = {
                type: 'SETFILTER',
                listViewPrefix: 'view/results/'+ind,
                filter: {field: '*', operator: 'and', operands: [{field: '*', type: 'cont', value: str}]},
            };

            self.dsAddCustom(akceFilt);

            ind++;
        });
        self.dsAdd('set', 'conf/popup/show', false);
        self.dsAdd('set', 'conf/view', 'globalSearch');
        self.dsProcess();
        self.unLoad();
    });
}
