import {DataConstructors} from './DataConstructors';
import {default as pbData} from './pbData/pbData';

/**
 *
 * @param state
 * @param action
 */
function appReducer(state = new DataConstructors['AppData'](), action) {
    const reducerName = 'app'; // jméno reduceru .. kontroluje se kvuli více reducerum
    const akce = action.type;

    if (akce == 'pbDATAFRONTA' && action.reducer == reducerName) {
        state = pbData(state, action);
        //   console.log(state.toJS());
        return state;
    }

    return state;
}

export default appReducer;
