import detailDefault from '../../detailDefault';

export default class detailacm_CampaignsPartipants extends detailDefault {
    load(sAction, data) {
    }
    update(sAction, data) {
        // "Prirazeno" se zkopiruje do nazvu
        if (data.field === 'acm_campaignspartipants_contactscontacts_ida') {
            sAction.saveField({way: data.prefix+'/fields/name', name: 'name', value: data.value.name}, true);
            if (!data.value.id) {
                return;
            }
            const fetchData = {
                action: 'getBeanInfo',
                module: 'Contacts',
                id: data.value.id,
                fields: [
                    'email1',
                    'title',
                    'phone_work',
                    'phone_mobile',
                    'account_id',
                    'account_name',
                ],
            };

            sAction.rest.fetchData('customAction', 'POST', fetchData).then((returnData) => {
                sAction.saveField({way: `${data.prefix}/fields/email`, name: 'email', value: returnData.email1}, true);
                sAction.saveField({way: `${data.prefix}/fields/title`, name: 'title', value: returnData.title}, true);
                sAction.saveField({way: `${data.prefix}/fields/phone_work`, name: 'phone_work', value: returnData.phone_work}, true);
                sAction.saveField({way: `${data.prefix}/fields/phone_mobile`, name: 'phone_mobile', value: returnData.phone_mobile}, true);

                sAction.saveField({
                    way: `${data.prefix}/fields/accounts_acm_campaignspartipants_1_name`,
                    name: 'accounts_acm_campaignspartipants_1accounts_ida',
                    fieldName: 'accounts_acm_campaignspartipants_1_name',
                    type: 'relate',
                    value: {
                        id: returnData.account_id,
                        name: returnData.account_name,
                    },
                }, true);
            });
        }
    }
}
