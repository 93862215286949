/**
 * @param {object} attrs
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @button DetailView/ACLRoles
 */
export default function ACLRolesDuplicate(attrs) {
    this.load();
    const params = {action: 'aclroles_duplicate', record: attrs.id};

    this.rest.post('customAction', params, (resp) => {
        if (resp.status) {
            this.href('#detail/ACLRoles/' + resp.message.data.record);
        } else {
            this.unLoad();
            this.alert(resp.errorMessage.text);
        }
    });
}
