/**
 *
 */
export default function getDateFilterOptions() {
    return [
        {
            value: 'cont',
            label: this.translate('LBL_EQUALS'),
            disabledInput: false,
        },
        {
            value: 'ncont',
            label: this.translate('LBL_NOT_EQUAL'),
            disabledInput: false,
        },
        {
            value: 'moreThan',
            label: this.translate('LBL_DATE_OPERATOR_MORETHAN'),
            disabledInput: false,
        },
        {
            value: 'moreThanInclude',
            label: this.translate('LBL_DATE_OPERATOR_MORETHANINCLUDE'),
            disabledInput: false,
        },

        {
            value: 'lessThan',
            label: this.translate('LBL_DATE_OPERATOR_LESSTHAN'),
            disabledInput: false,
        },
        {
            value: 'lessThanInclude',
            label: this.translate('LBL_DATE_OPERATOR_LESSTHANINCLUDE'),
            disabledInput: false,
        },

        // {
        //     value: "today",
        //     label: this.translate("LBL_DATE_OPERATOR_TODAY"),
        //     disabledInput: true
        // },
        // {
        //     value: "yesterday",
        //     label: this.translate("LBL_DATE_OPERATOR_YESTERDAY"),
        //     disabledInput: true
        // },
        // {
        //     value: "last7days",
        //     label: this.translate("LBL_DATE_OPERATOR_LAST7DAYS"),
        //     disabledInput: true
        // },
        // {
        //     value: "last30days",
        //     label: this.translate("LBL_DATE_OPERATOR_LAST30DAYS"),
        //     disabledInput: true
        // },
        // {
        //     value: "tommorow",
        //     label: this.translate("LBL_DATE_OPERATOR_TOMMOROW"),
        //     disabledInput: true
        // },
        // {
        //     value: "next7days",
        //     label: this.translate("LBL_DATE_OPERATOR_NEXT7DAYS"),
        //     disabledInput: true
        // },
        // {
        //     value: "next30days",
        //     label: this.translate("LBL_DATE_OPERATOR_NEXT30DAYS"),
        //     disabledInput: true
        // },
        // {
        //     value: "lastMonth",
        //     label: this.translate("LBL_DATE_OPERATOR_LASTMONTH"),
        //     disabledInput: true
        // },
        // {
        //     value: "thisMonth",
        //     label: this.translate("LBL_DATE_OPERATOR_THISMONTH"),
        //     disabledInput: true
        // },
        // {
        //     value: "nextMonth",
        //     label: this.translate("LBL_DATE_OPERATOR_NEXTMONTH"),
        //     disabledInput: true
        // },
        // {
        //     value: "lastYear",
        //     label: this.translate("LBL_DATE_OPERATOR_LASTYEAR"),
        //     disabledInput: true
        // },
        // {
        //     value: "thisYear",
        //     label: this.translate("LBL_DATE_OPERATOR_THISYEAR"),
        //     disabledInput: true
        // },
        // {
        //     value: "nextYear",
        //     label: this.translate("LBL_DATE_OPERATOR_NEXTYEAR"),
        //     disabledInput: true
        // },
        {
            value: 'dateNull',
            label: this.translate('LBL_FIELD_NULL'),
            disabledInput: true,
        },
        {
            value: 'dateNnull',
            label: this.translate('LBL_FIELD_NOT_NULL'),
            disabledInput: true,
        }, /* */
    ];
}
