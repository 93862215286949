/**
 * @param {string} way
 * @param {string} module
 * @param {object} params
 * @this sAction
 */
export default function reloadSubpanelByWay(way, module, params = {}) {
    const activitiesModules = ['Calls', 'Meetings', 'Tasks', 'Notes'];

    let subModule = module;
    if (activitiesModules.includes(module)) {
        if (subModule === 'Notes') {
            subModule = 'History';
        } else {
            subModule = 'Activities';
        }
    }
    const subpanels = this.dataGet(way + '/subpanels');
    if (!subpanels) {
        return;
    }

    const subpanelIndex = [];
    subpanels.forEach((subpanel, index) => {
        if (subModule === subpanel.module) {
            subpanelIndex.push(index);
        }
    });
    if (subpanelIndex.length !== 0) {
        subpanelIndex.forEach((index) => {
            const subWay = way + '/subpanels/' + index + '/rows';
            this.reloadList(0, 1, subWay, null, null, null, params);
        });
    } else {
        this.unLoad();
    }
}
