/**
 *
 * @param props
 * @param value
 */
export default function saveCellFieldByProps(props, value) {
    const way = props.way;
    const name = props.data.name;
    const module = props.data.editModule;
    const id = props.data.recordId;
    this.saveCellField({way, value, name, module, id});
}
