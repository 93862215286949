/**
 *
 * @param amount
 */
export default function normalizePrice(amount) {
    if (amount) {
        amount = amount.toString().replace(',', '.');
        amount = amount.split(' ').join('');
        amount = parseFloat(amount);
        if (isNaN(amount)) {
            amount = 0;
        }

        return parseFloat(amount);
    }
    return parseFloat(0);
}
