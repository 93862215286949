import checkDuplicityPost from '../action/DetailView/checkDuplicityPost';

export default class detailDefault {
    load(sAction, data) {
    // call immediately after detailView is load
    }
    update(sAction, data) {
    // call immediately after detailView field is update
    }
    /**
     *
     * @param sAction
     * @param data
     */
    newSubRecord(sAction, data) {
    // call immediately before route sub record
    }
    /**
     *
     * @param sAction
     * @param data
     */
    beforeSave(sAction, data) {
    // call bfore Record is save

        return true;
    }
    /**
     *
     * @param sAction
     * @param data
     */
    updateDetailLine(sAction, data) {
        // Uprava radku detailu
    }
    /**
     *
     * @param sAction
     * @param data
     */
    checkDuplicity(sAction, data) {
        // Kontrola, jestli obsah řádku již neexistuje u jiného záznamu v modulu
        // definováno ve vardefech
        sAction.checkDuplicityPost(data);
    }
}
