import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailViewRow from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailViewRow';

export default function DetailPanelContent(props) {
    /**
     * @return {JSX.Element|void}
     */
    const renderPanelName = () => {
        if (!sAction.translate(props.name?.toUpperCase?.(), props.module)) {
            return;
        }

        return (
            <div className="detailViewPanelHeader">
                <div className="detailViewPanelText">
                    {sAction.translate(props.name?.toUpperCase?.(), props.module)}
                </div>
            </div>
        );
    };

    return (
        <div className="detailViewPanel">
            {renderPanelName()}
            {props.rows.map((row, index) => {
                return (
                    <DetailViewRow
                        key={index}
                        row={row}
                        fields={props.fields}
                        prefix={props.prefix}
                        readonly={props.readonly}
                        acl={props.acl}
                        module={props.module}
                        attemptedSave={props.attemptedSave}
                        rowWay={`${props.panelWay}/rows/${index}`}
                        tabWay={props.tabWay}
                        id={props.id}
                        panelColumns={props.panelColumns}
                    />
                );
            })}
        </div>
    );
}

DetailPanelContent.propTypes = {
    name: PropTypes.string,
    rows: ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf({
        fields: ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf({
            name: PropTypes.string.isRequired,
            columns: PropTypes.number.isRequired,
        })),
    })),
    module: PropTypes.string,
    prefix: PropTypes.string,
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    readonly: PropTypes.bool,
    attemptedSave: PropTypes.bool,
    panelWay: PropTypes.string.isRequired,
    tabWay: PropTypes.string,
    id: PropTypes.string,
    panelColumns: PropTypes.number.isRequired,
};
