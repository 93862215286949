/**
 *
 * @param tag
 * @param record
 * @param module
 */
export default function findTagRelatesOpenPopup(tag, record, module) {
    this.load();
    this.dsClear();

    this.rest.post('globalSearch/', {str: [tag.get('id')], byTag: true}, (data) => {
        data.lists.forEach((list, index) => {
            const akce = {
                type: 'PAGELOADLIST',
                content: list,
                listViewPrefix: 'conf/popup/data/lists/' + index,
                page: list.module,
            };
            this.dsAdd('set', 'conf/popup/data/lists/' + index, {});
            this.dsAddCustom(akce);
        });
        this.rest.post('tags/getTags/', { }, (tags) => {
            this.dsAdd('set', 'conf/popup/data/tags', tags['tags']);

            this.dsAdd('set', 'conf/popup/data/tagData', tag);
            this.dsAdd('set', 'conf/popup/show', true);
            this.dsAdd('set', 'conf/popup/content', 'TagRelatesList');
            this.dsProcess();

            this.unLoad();
        });
    });
}
