/**
 *
 * @param text
 * @param classNames
 */
export default function emailAddVariableHighlights(text, classNames = null) {
    if (text == null) return '';

    return text.replace(
        /(\$[A-Za-z][A-Za-z0-9_]+)/g,
        // '<span class="' + classNames + '">$1</span>'
        '<span style="background-color: rgb(255, 255, 0)">$1</span>',
    );
}
