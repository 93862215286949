/**
 * @this sAction
 *
 * @param {{}}data
 */
export default function invoiceCreateDeliveryList(data) {
    const params = {action: 'invoiceCreateDeliveryList', record: data.id};
    this.rest.fetchData('customAction', 'POST', params, false).then((response) => {
        this.href(`#detail/acm_delivery_lists/${response.data.record}`);
    }).catch((error) => {
        this.error(error.text);
    });
}
