/**
 *
 * @param block_or_type
 * @param way
 * @param value
 * @param like
 * @param inList
 */
export default function dsAdd(block_or_type, way = null, value = null, like = null, inList = null) {
    // funkce na přidání do fronty ke zpracování, přidá bud celý blok, skupinu bloků nebo blok vytvoří a přidá
    const newBlok = null;
    if (way == null) {
        if (Array.isArray(block_or_type) === true) {
            block_or_type.forEach((element) => {
                if (this.dsCheckBlock(element) == true) {
                    this.dataStack.push(element);
                }
            });
        } else {
            if (this.dsCheckBlock(block_or_type) == true) {
                this.dataStack.push(block_or_type);
            }
        }
    } else {
        const block = this.dsBlock(block_or_type, way, value, like, inList);
        if (this.dsCheckBlock(block) == true) {
            this.dataStack.push(block);
        }
    }
}
