import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ListViewSearch from './ListViewSearch';
import ListViewLooks from './ListViewLooks';
import ListViewToggleDefaultFilter from './ListViewToggleDefaultFilter';

export default function ListViewMenu({data, prefix, namespace}) {
    return (
        <div className="listViewActions">
            <ListViewLooks data={data.savedSearch} hasAssignedUser={data.hasAssignedUser} namespace={namespace}
                module={data.modul} prefix={prefix} actSavedSearch={data.actSavedSearch} />
            {data.defaultFilter.size - !!data.defaultFilter.get('tree') > 0 && (data.actSavedSearch === ' ' || !data.actSavedSearch)?
                <div className="rightSideActions">
                    <ListViewToggleDefaultFilter data={data} defaultFilter={data.defaultFilter} prefix={prefix}/>
                    <ListViewSearch data={data.filter} module={data.modul} prefix={prefix} namespace={namespace}/>
                </div> :
                <ListViewSearch data={data.filter} module={data.modul} prefix={prefix} namespace={namespace}/>
            }
        </div>
    );
}

ListViewMenu.propTypes = {
    data: PropTypes.shape({
        modul: PropTypes.string.isRequired,
        savedSearch: ImmutablePropTypes.list,
        actSavedSearch: PropTypes.string,
        filter: ImmutablePropTypes.list,
        defaultFilter: ImmutablePropTypes.map,
        hasAssignedUser: PropTypes.bool.isRequired,
    }).isRequired,
    prefix: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
};
