import React from 'react';
import PropTypes from 'prop-types';
import AcmDateTime from '../../formElements/AcmDatetime.js';
import FormField from './../formField.js';

export default class Datetime2 extends FormField {
    /**
     * @param {string} date
     */
    save(date) {
        if (this.check(date)) {
            const data = {
                way: this.props.way,
                name: this.props.name,
                value: date,
                cancelEdit: false,
                type: 'datetime',
            };
            this.saveField(data);
        }
    }

    /**
     * @param {string} date
     * @returns {boolean}
     */
    check(date) {
        const required = this.props.def.get('required') || false;
        let ok = true;
        const state = {};

        if (!date && required) {
            state.dateOk = false;
            ok = false;
        } else {
            state.dateOk = true;
        }

        this.setState(state);

        return ok;
    }
    render() {
        return (
            <AcmDateTime
                autoOpen={true}
                autoFocus={true}
                onChange={(value) => this.save(value)}
                value={this.props.value}
                onKeyDown={(e, type, save) => this.onKeyDown(e, type, save)}
                {...this.props}/>
        );
    }
}

Datetime2.propTypes = {
    way: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    def: PropTypes.object,
};
