/**
 * Print activites from timeline
 * @param {*} params
 */
export default function printTimeline(params) {
    const {prefix, all} = params;
    let recordData = this.dataGet('view', ['id', 'module', 'name']);
    if (!recordData.id || !recordData.module) {
        recordData = this.dataGet(prefix, ['id', 'module', 'name']);
    }
    const storeData = params.storeData ?? this.dataGet(prefix);
    const data = this.activitiesTimeLineParams(storeData);

    data.limit = storeData.records.size;

    if (all) {
        data['limit'] = '';
        data['filter'] = [];
    }

    this.printFile({
        action: 'printPdf',
        button_id: 'printTimeline',
        id: recordData.id,
        name: recordData.name + ' - aktivity',
        module: 'Timeline',
        filetype: 'pdf',
        relModule: recordData.module,
        params: data,
    });
}
