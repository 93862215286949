/**
 * toto NENI seznam modulu ale kliknuti na tlaciko zpet na list
 * @param {object} params
 * @this sAction
 * @button DetailView
 * @link #link
 */
export default function hrefModuleList(params) {
    if (params.type === 'rightPanel') {
        this.dataSet('rightPanel', {}, 'RightPanel');

        return;
    }
    if (params.blank) {
        window.open('#list/' + params.relModule, '_blank');
    } else {
        this.href('#list/' + params.relModule);
    }
}
