/**
 *
 * @param params
 */
export default function loginAs(params) {
    const data = {
        id: params.id,
    };
    this.rest.post('loginAs', data, (returnData) => {
        if (returnData.state) {
            this.rest.setCookie('sID', returnData.sid, 1);
            window.location.reload();
        } else {
            this.alert(this.translate('LBL_ERROR'));
        }
    });
}
