import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailPanelContent from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailPanelContent';
import DetailTabBlock from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailTabBlock';

export default function DetailTabContent(props) {
    return (
        <>
            <DetailTabBlock>
                {props.tab.panels.map((panel, index) => {
                    return (
                        <DetailPanelContent
                            key={index}
                            name={panel.get('name')}
                            rows={panel.get('rows')}
                            prefix={props.prefix}
                            module={props.module}
                            fields={props.fields}
                            acl={props.acl}
                            readonly={props.readonly}
                            attemptedSave={props.attemptedSave}
                            panelWay={`${props.tabWay}/panels/${index}`}
                            tabWay={props.tabWay}
                            id={props.id}
                            panelColumns={+panel.get('columns')}
                        />
                    );
                })}
            </DetailTabBlock>
        </>
    );
}

DetailTabContent.propTypes = {
    tab: PropTypes.any,
    module: PropTypes.string,
    prefix: PropTypes.string,
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    tabWay: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
    attemptedSave: PropTypes.bool,
    id: PropTypes.string,
};
