import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import Tags from 'ROOT/src/components/Tags/Tags';
import DetailViewActivityButtons from 'ROOT/src/components/DetailViewNew/DetailCard/DetailViewActivityButtons/DetailViewActivityButtons';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default function DetailCardHeader(props) {
    const {name, module, tableName, id, prefix, tags, mode, activities} = props;
    const isNewRecord = !id;
    const menuGroups = sAction.dataGet('menu/groups');
    let icon = '';
    // Get the module icon from store
    menuGroups.forEach((element) => {
        element.buttons.forEach((button) => {
            if (button.name === module) {
                icon = button.icon;
            }
        });
    });

    return (
        <div className="header">
            <div className="title">
                <span className={`detailViewHeaderIcon icon-${module} icon-${icon}`}/>
                <span className="detailViewName">
                    {isNewRecord ? sAction.translate('LBL_NEW_RECORD') : sAction.translate(sAction.decodeHTMLEntities(name), module)}
                </span>

                {
                    !isNewRecord && sAction.getStorage('debug') && sAction.isAcmAdmin() && (
                        <div
                            className="debugDbButton icon-database"
                            onClick={() => sAction.generateDbLink({action: 'detail', table_name: tableName, record: id})}
                        />
                    )
                }
            </div>
            {!isNewRecord ? <Tags way={prefix} record={id} module={module} tags={tags}/> : null}
            {!isNewRecord && mode !== 'edit' && (
                <DetailViewActivityButtons
                    activities={activities}
                    prefix={prefix}
                    parentId={id}
                    parentModule={module}
                    parentName={name}
                />
            )}
        </div>
    );
}

DetailCardHeader.propTypes = {
    activities: ImmutablePropTypes.mapContains({
        calls: PropTypes.string,
        emails: PropTypes.string,
        meetings: PropTypes.string,
        notes: PropTypes.string,
        tasks: PropTypes.string,
        urlquery: PropTypes.string,
    }),
    name: PropTypes.string,
    module: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    id: PropTypes.string,
    tags: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
            id: PropTypes.string,
            name: PropTypes.string,
            relateId: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    prefix: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
};
