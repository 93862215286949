import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import AdminIframe from '../../../../admin/AdminIframe';
import sAction from 'sAction';

export default class AccountsTurnover extends PureComponent {
    setIframeHeight = () => {
        const iframe = document.getElementsByTagName('iframe')[0];
        if (!iframe) {
            return;
        }

        if (iframe.contentWindow) {
            let height;
            try {
                height = iframe.contentWindow.document.body.scrollHeight;
            } catch (e) {
                height = 0;
            }
            const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            if (!height || (height > clientHeight)) {
                height = clientHeight - 400;
                if (height<500) height = 500;
            }
            height = clientHeight - 400;
            if (height<500) height = 500;
            const reportContainer = document.getElementById('reportContainer');
            if (reportContainer) {
                reportContainer.style.height = height + 'px';
            }
        }
    };

    componentDidMount() {
    // if (this._isAdmin) {
        if (this.props.data.get('html')) {
            setTimeout(this.setIframeHeight, 250);
            setTimeout(this.setIframeHeight, 2500);
            setTimeout(this.setIframeHeight, 5000);
            setTimeout(this.setIframeHeight, 10000);
        }
    // }
    }

    componentDidUpdate() {
        this.componentDidMount();
    }

    render() {
        let {way, data, isInTab} = this.props;

        // if (!this._isAdmin) {
        //   return "";
        // }

        data = data.set('params', {recordId: data.get('recordId')});
        let render = <div id="reportContainer" className="detailIframeContainer">
            <AdminIframe way={way.replace('/customData', '')} name={data.get('iframeName')} data={data} isDetail={true} />
        </div>;

        if (!isInTab) {
            render = <div className='detailCard OppRowsContainer'>
                <div className='header'>
                    <div className='title'>
                        <div className='detailViewName'>
                            {sAction.translate('LBL_UNAVAILABLE_OUTSIDE_TAB', 'Accounts')}
                        </div>
                    </div>
                </div>
                {/* <div style={{ padding: 25 }}>*/}
                {/*  {render}*/}
                {/* </div>*/}
            </div>;
        }

        return render;
    }
}

AccountsTurnover.propTypes = {
    data: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
    }),
    isInTab: PropTypes.any,
    way: PropTypes.shape({
        replace: PropTypes.func,
    }),
};
