import PropTypes from 'prop-types';
import React from 'react';

export default function RightPanelVideo(props) {
    // show video preview from props.data.get('url')
    return (<div className="RightPanelVideo">
        <video width="100%" height="auto" controls>
            <source src={props.data.get('url')} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>);
}

RightPanelVideo.propTypes = {
    data: PropTypes.any,
};
