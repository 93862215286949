/**
 *
 * @param {*} paramData
 * @param {*} callback
 */
export default function searchAddressByGPS(paramData, callback) {
    // example: click into map on detail when finding location => should find nearest address
    // old call: POST acmAddressByGPS
    this.rest.fetchMS('maps/v1/getAddressByGPS', 'POST', paramData).then((response) => {
        if (response.status) {
            callback(response?.data);
            return;
        }
        this.error(response.message);
        console.error('FindAddress failed, response: ', response);
        callback(null);
    });
}
