/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Accounts
 * @render src/components/popup/detailApi/AccHierarchyPopup.js
 */
export default function accHierarchy(data) {
    this.popup('accHierarchy', {
        params: data,
        header: this.translate('LBL_ACC_HIERARCHY', 'Accounts'),
    });
}
