/**
 * @param {*} data
 * @param {*} content
 */
export default function emailTemplatesContent(data, content) {
    content.recordName = data.record.name;
    content.tabs = [
        {
            name: 'default',
            panels: [
                {
                    name: '',
                    rows: [],
                },
            ],
        },
    ];
    content.actionButtons = data.menu;
    // _________ SUBPANELS

    content.subpanels = data.subpanels;
    content.customData = {};

    const t = content.tabs[0].panels[0];
    const d = data.def;

    let keys = [];

    const fields = {};

    const rows = [
        ['name', 'type'],
        ['date_entered', 'date_modified'],
        ['to_addrs', 'bcc_addrs'],
        ['cc_addrs', null],
        ['subject', 'description'],
    ];

    rows.forEach((row) => {
        if (row[1]) {
            t.rows.push({
                fields: [{name: d[row[0]].name, columns: 1}, {name: d[row[1]].name, columns: 1}],
            });
        } else {
            t.rows.push({
                fields: [{name: d[row[0]].name, columns: 2}],
            });
        }

        row.forEach((key) => {
            fields[key] = {value: data.record[key], def: d[key]};
        });
    });

    keys = ['assigned_user_name'];
    t.rows.push({
        fields: [{name: d[keys[0]].name, columns: 1}],
    });

    fields.assigned_user_name = {
        value: data.record[keys[0]],
        def: {...d[keys[0]], id_value: data.record.assigned_user_id},
    };

    t.rows.push({
        fields: [{name: d.body_html.name, columns: 2}],
    });

    fields[d.body_html.name] = {
        value: htmlUnescape(data.record.body_html),
        def: {...d.body_html, type: 'HTMLText'},
    };

    const files = {};
    if (data.customData && data.customData.files) {
        data.customData.files.forEachObject((f) => {
            files[f['id']] = {
                id: f['id'],
                source: 'sugar',
                name: f['filename'],
                type: f['file_mime_type'],
                size: f['size'],
                deleted: false,
            };
        });
    }

    t.rows.push({
        fields: [{'name': 'files', 'columns': 2}],
    });

    fields.files = {value: '', def: {type: 'files', files}};

    content.fields = fields;

    const modulAcl = this.getAccess(data.module);
    if (modulAcl != null && data.acl != null) {
        data.acl.forEachObject((a, k) => {
            modulAcl[k] = a;
        });
    }
    content.acl = modulAcl;
}

/**
 * @param {string} str
 * @returns {string}
 */
function htmlUnescape(str) {
    return str == null ?
        '' :
        str
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, '\'')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
}
