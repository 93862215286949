/**
 * Log in to the system
 * @param {string}  code    user typed two factor code
 * @param {string}  token   token id
 */
export default function twoFactorAuth(code, token) {
    this.load();
    this.rest.fetchData('login/twoFactor', 'POST', {code, token}).then(({data}) => {
        this.rest.setCookie('sID', data.sid, 1);
        this.initData(data);
        this.dsProcess();
        this.checkNewNotifications();
        const view = this.getViewName();
        this.route(true, null, view === 'login' ? 'home' : null);
    }).catch((error) => {
        this.unLoad();
        this.error(this.translate(error.text) ?? error);
    });
}
