/**
 *
 * @param record
 */
export default function rightPanelEmailRelations(record) {
    this.openRightPanel('load');
    const paramData = {
        subpanels: true,
    };
    const data = {
        id: record,
        module: 'Emails',
        prefix: 'rightPanel/data/detail',
    };
    this.dsClear();
    this.dsAdd('set', 'rightPanel/content', 'emailRelations');
    this.routeDetailView(data, paramData);
}
