/**
 * pokud v historii jsou dva zaznamy ruznuch modulu chci se vratit na predchozi zaznam
 * v storu je vzdy ulezene cela url proto muzu po rozporasovani pristoup na 4 pozici, pozice modulu
 * @returns {boolean}
 */
export default function checkPreviousUrl() {
    const historyUrls = this.dataGet('history/urls');
    let previousModule = '';
    let shouldGoBack = true;

    if (historyUrls?.size <= 1) {
        return false;
    }

    historyUrls.forEach((historyUrl) => {
        const previousUrl = historyUrl.split('/');
        console.log(previousUrl);
        if (previousUrl[4] && previousModule === previousUrl[4]) {
            shouldGoBack = false;
            return;
        } else if (historyUrl.includes('#merge')) {
            shouldGoBack = false;
            return;
        }
        previousModule = previousUrl[4];
    });

    return shouldGoBack;
}
