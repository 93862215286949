/**
 *
 * @param type
 * @param way
 * @param value
 * @param like
 * @param inList
 */
export default function dslock(type, way, value, like = null, inList = null) {
    // vytvoří a vrátí blok, který se nůže zařadit do fronty
    let akce = null;

    if (type.toUpperCase() != 'DELETE') {
        const set = {way: way, value: value, like: like, inList: inList};
        akce = {
            type: 'DATA' + type.toUpperCase(),
            data: [set],
        };
    } else {
        akce = {
            type: 'DATA' + type.toUpperCase(),
            data: [{way: way, index: value}],
        };
    }
    return akce;
}
