import PropTypes from 'prop-types';
import React from 'react';
import MaterialButton from '@mui/material/Button';

export default class Button extends React.PureComponent {
    /**
     *
     * @param {Event} e
     */
    onClick(e) {
        if (this.props.onClick != null) {
            this.props.onClick(e);
        }
    }
    render() {
        const {className, ...props} = this.props;
        return (
            <MaterialButton
                onClick={(e) => this.onClick(e)}
                variant="contained" className={'acmButton '+className} {...props}>
                {this.props.children}
            </MaterialButton>
        );
    }
}

Button.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    onClick: PropTypes.func,
};
