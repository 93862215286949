/**
 * @param {object} paramData
 * @param {function} callback
 * @this sAction
 */
export default function saveRecordBase(paramData, callback) {
    this.doRoute = true;
    const module = paramData.module;
    const imagesToDelete = this.dataGet('view/imageActions/deleteOnSave');

    // promazani zmen v local storage
    this.removeChangesFromLS(module, this.dataGet('conf/user/id'));

    switch (module) {
        case 'Meetings':
        case 'Calls':
            this.dataSet(paramData.prefix + '/changes/invitees', this.dataGet(paramData.prefix + '/customData/invitees/relatedInv'));
            this.dataSet(paramData.prefix + '/changes/inviteesBackup',
                this.dataGet(paramData.prefix + '/customData/inviteesBackup/relatedInv'));
            break;
        case 'Quotes':
            // ________________________ONLY QUOTES
            // handeling product lines on Quotes
            this.dataSet(paramData.prefix + '/changes/customData', this.getQuoteSaveData(this, paramData.prefix));
            break;
        case 'Opportunities':
            this.dataSet(paramData.prefix + '/changes/customData/productData', this.dataGet(paramData.prefix + '/customData/productData'));
            break;
    }
    // _______________________________________________ONLY QUOTES END
    let sendToCrm = true;
    // _______________________________________________CUSTOM LINES
    const customLinesData = this.dataGet(paramData.prefix + '/customData');
    if (customLinesData && customLinesData.get && customLinesData.get('customLines') && customLinesData.get('customLines').get('lines')) {
        // CONVERT TO CHANGES
        this.dataSet(paramData.prefix + '/changes/customData/customLines', customLinesData.get('customLines').get('lines'));
        // CHECK IF REQUIRED
        const requiredFields = [];
        // Ziska povinna pole
        customLinesData.get('customLines').get('def').get('fields').toJS().forEachObject((field) => {
            if ([1, '1', true].includes(customLinesData.get('customLines').get('moduleData').get(field).get('required'))) {
                requiredFields.push(field);
            }
        });
        // Zjisti jeslti nejake povinne pole je prazdne
        if (Array.isArray(requiredFields) && requiredFields.length) {
            customLinesData.get('customLines').get('lines').toJS().forEachObject((line) => {
                requiredFields.forEach((field) => {
                    this.dsClear();
                    if (!line[field] && line['deleted'] !== 1 && line['deleted'] !== '1') {
                        this.dsAdd('set', `${paramData.prefix}/customData/customLines/showError`, true);
                        sendToCrm = false;
                    } else {
                        this.dsAdd('set', `${paramData.prefix}/customData/customLines/showError`, false);
                    }
                    this.dsProcess();
                });
            });
        }
    }
    // _______________________________________________CUSTOM LINES END

    const boundModules = this.dataGet(paramData.prefix + '/boundModules');
    if (boundModules && !paramData.id) {
        this.dsClear();
        this.dsAdd('set', paramData.prefix + '/changes/customData/boundModules', {});
        boundModules.toJS().forEachObject((module, index) => {
            const way = paramData.prefix + '/boundModules/' + index;
            /** ************** PROJIT KAZDY MODUL JESTLI NEMA PRAZDNE REQUIRED POLE */
            if (module.customData.checked === true) {
                if (!this.requiredFieldsCheck(way)) {
                    sendToCrm = false;
                }
                // Prida do changes data o modulu
                if (sendToCrm) {
                    const emails = this.customFieldEmail(way);
                    if (emails) {
                        module.changes.fields.email1 = emails;
                    }

                    this.dsAdd('set', paramData.prefix + '/changes/customData/boundModules/' + module.module, module.changes);
                }
            }
            /** *********************************************************************/
        });
        this.dsProcess();
    }

    /**
     *
     * @param {*} emails
     */
    const emailControl = (emails) => {
        if (emails) {
            editFields = editFields.setIn(['fields', 'email1'], emails);
        }
    };

    let editFields = this.dataGet(paramData.prefix + '/changes');

    const emails = this.customFieldEmail(paramData.prefix);
    emailControl(emails);
    if (paramData.canSave === true) {
        if (this.custom('beforeSave', paramData)) {
            // Pokud je nejake required pole prazdne, hodi error a skonci
            if (!this.requiredFieldsCheckMsg(paramData.prefix) || !sendToCrm) {
                return;
            }

            editFields = this.dataGet(paramData.prefix + '/changes');
            emailControl(emails);

            const module = paramData.module;
            let src = module + '/';
            const id = paramData.id;
            if (id !== undefined && id !== null) {
                src += id;
            }
            this.load();
            const self = this;

            if (module === 'Users' && id && id === this.dataGet('conf/user/id') && editFields.get('fields')) {
                this.dsClear();

                editFields.get('fields').toJS().forEachObject((value, key) => {
                    if (key === 'records_per_page') {
                        this.dsAdd('set', 'conf/user/records_per_page', value);
                    }
                    if (key === 'records_per_subpanel') {
                        this.dsAdd('set', 'conf/user/records_per_subpanel', value);
                    }
                    if (key === 'profile_photo') {
                        this.dsAdd('set', 'conf/user/profile_photo', value);
                    }
                    if (key === 'user_name') {
                        this.dsAdd('set', 'conf/user/user_name', value);
                    }
                    if (key === 'currency_id') {
                        this.dsAdd('set', 'conf/user/currency', value);
                    }
                    if (key === 'first_name' || key === 'last_name') {
                        const username = this.dataGet(`${paramData.prefix}/fields/first_name/value`) + ' ' +
                        this.dataGet(`${paramData.prefix}/fields/last_name/value`);
                        this.dsAdd('set', 'conf/user/name', username);
                    }
                });
                this.dsProcess();
            }
            this.rest.post('set/' + src, editFields, (msg) => {
                const data = {
                    state: true,
                    data: msg,
                };
                if (module === 'Quotes') {
                    data['cancelPrevisouRequest'] = false;
                    self.rest.post('recalcTopRecent', {}, function() {
                    }, true);
                }
                callback(data);
                // Reload because the category modules needs to get new translates of they name
                if (paramData.module === 'acm_documentsCategory' || paramData.module === 'acm_reportscategory') {
                    window.location.reload();
                }
            });

            if (imagesToDelete) {
                this.rest.fetchData('deleteImages', 'DELETE', {imageNames: imagesToDelete.toJS()}, false)
                    .then(() => {})
                    .catch(() => {});
            }
        }
    } else {
        const data = {
            state: false,
            msg: 'Záznam momentálně nelze uložit',
        };
        callback(data);
    }
}
