/**
 *
 * @param way
 * @param defs
 * @param defaults
 */
export default function customDataAddRow(way, defs, defaults = {}) {
    const recordsWay = way + '/records';
    const row = {id: null};
    defs.forEach((def) => row[def.get('name')] = defaults[def.get('name')] || '');
    const records = this.dataGet(recordsWay);
    const index = records.size;
    let order_index = 0;
    records.forEach((record) => {
        if (record.get('order_index') > order_index) {
            order_index = record.get('order_index');
        }
    });
    order_index = (parseInt(order_index) + 1) + '';
    row['order_index'] = order_index;
    row['order_index_c'] = order_index;
    this.dsClear();
    this.dsAdd('set', recordsWay + '/' + index, row);
    this.dsAdd('set', recordsWay.replace('/customData', '/changes/customData') + '/' + index, row);
    this.dsProcess();
}
