/**
 *
 * @param module
 * @param _callback
 */
export default function getParentFieldOptions(module, _callback) {
    this.rest.post('getParentOptions', {module: module}, (data) => {
        if (data['status']) {
            _callback(data['options']);
        }
    }, false);
}
