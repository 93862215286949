import React from 'react';
import sAction from 'sAction';
import {keyMapConfing} from './Keymap';

export default function ShortcutsHelp() {
    /**
     *
     * @param {Array} buttons
     * @returns {JSX}
     */
    const renderKeys = (buttons) => {
        return buttons.map((button, i) => {
            let btn = button;
            if (button === 'ctrl' && window.navigator.platform.includes('Mac')) {
                btn = '⌘';
            } else if (button === 'alt' && window.navigator.platform.includes('Mac')) {
                btn = '⌥';
            }
            return <div className='shortcutsHelpButton' key={i}>{btn}</div>;
        });
    };

    /**
     * @returns {JSX}
     */
    const renderShortcuts = () => {
        return keyMapConfing.map((item, i) => {
            return (
                <div className='shortcutsHelpItem' key={i}>
                    <div>
                        <span className={`shortcutsHelpIcon icon-${item.icon}`}></span>
                        {sAction.translate(item.label)}
                    </div>
                    <div className='shortcutsHelpButtons' >
                        {renderKeys(item.buttons)}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='shortcutsHelp'>
            <h4>{sAction.translate('LBL_SHORTCUTS')}</h4>
            <div className='shortcutsHelpBox'>
                {renderShortcuts()}
            </div>
        </div>
    );
}
