import DefaultUrl from '../../defaultFields/Url/Url';
import sAction from 'sAction';

export default class Url extends DefaultUrl {
    /**
	 *
	 * @param data
	 */
    saveField(data) {
        sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);
    }
}
