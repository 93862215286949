import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DetailViewFieldsManager from '../../detailView/DetailViewFieldsManager/DetailViewFieldsManager';

import ButtonFloat from '../../formElements/Button';
import sAction from 'sAction';

export default class MassUpdateContent extends PureComponent {
    /**
     * @param {string} prefix
     * @param {string} module
     * @param {object} field
     * @param {number} key
     * @returns {JSX}
     */
    renderField(prefix, module, field, key) {
        return (
            <DetailViewFieldsManager
                prefix={prefix}
                module={module}
                way={prefix + '/fields/' + field.get('name')}
                key={key}
                index={key}
                def={field.def}
                name={field.name}
                labelValue={field.name}
                value={field.value}
                actEdit={field.actEdit}
                events={field.events}
                customStyle={field.customStyle}
                customClass={field.customClass}
                panelColumns={2} // Not sure, aktualne vyzera ze massupdate bude mat vzdy 2 stlpce
                fieldColumns={1}
            />
        );
    }

    /**
     * @param {array} row
     * @param {number} key
     * @returns {JSX}
     */
    renderRow(row, key) {
        const renderFields = [];
        row.forEach((field) => {
            renderFields.push(field);
        });

        return (
            <div key={key} className="detailViewTabContentRow">
                {renderFields}
            </div>
        );
    }

    render() {
        const fields = this.props.data.fields;
        const module = this.props.data.module;
        const prefix = this.props.data.prefix;

        const changed =
      this.props.data.changes.get('fields').size > 0 ? true : false;

        let row = [];
        const renderData = [];

        let ind = 0;

        fields.forEach((field, key) => {
            if (field?.def?.get('required')) {
                field = field.setIn(['def', 'required'], false);
            }
            row.push(this.renderField(prefix, module, field, key));
            ind++;
            if (ind === 2) {
                renderData.push(this.renderRow(row, key));
                row = [];
                ind = 0;
            }
        });
        if (ind !== 0) {
            renderData.push(this.renderRow(row, 'last'));
        }

        return (
            <div className="massUpdateContainer pageContainer">
                <div className="viewActionPanel">
                    <div className="viewActionPanelButtons">
                        {changed && (
                            <React.Fragment>
                                <ButtonFloat
                                    variant="text"
                                    onClick={() => sAction.removeChangesMassUpdate(prefix)}
                                >
                                    <div className={'actionPanelButtonIcon ' + 'icon-undo'} />
                                    {sAction.translate('LBL_RETURN_CHANGES')}
                                </ButtonFloat>
                                <div
                                    className="viewPanelButtonsDelimiter"
                                />
                            </React.Fragment>
                        )}
                        <ButtonFloat
                            variant="text"
                            className="hoverGreen"
                            onClick={() => sAction.saveMassUpdate(prefix)}
                        >
                            <div className={'actionPanelButtonIcon ' + 'icon-saveIcon'} />
                            {sAction.translate('LBL_MASSUPDATE_CONFIRM')}
                        </ButtonFloat>
                        <div
                            className="viewPanelButtonsDelimiter"
                        />
                        <ButtonFloat
                            variant="text"
                            className="hoverRed"
                            onClick={() => sAction.closeRightPanel()}
                        >
                            <div className={'actionPanelButtonIcon ' + 'icon-close'} />
                            {sAction.translate('LBL_MASSUPDATE_CANCEL')}
                        </ButtonFloat>
                        <div
                            className="viewPanelButtonsDelimiter"
                        />
                    </div>
                </div>
                <div className="muDetailViewContainer detailViewContainer">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                {sAction.translate('LBL_MASS_UPDATE_TITLE')}
                            </div>
                        </div>
                        <div className="detailViewTabContent">
                            <div className="muDetailViewTabContentBlok">{renderData}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MassUpdateContent.propTypes = {
    data: PropTypes.object,
};
