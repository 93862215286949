import sAction from 'sAction';

/**
 * @param {Object} data
 */
export default function uploadDocument(data) {
    sAction.processFileInput(data, (files) => {
        sAction.uploadFiles(files, data.prefix, true);
    });
}
