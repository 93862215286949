/**
 *
 * @param title
 * @param callback
 */
export default function openRolesPopup(title, callback) {
    if (!title) title = this.translate('LNK_NEW_ROLE', 'Roles');

    const id = this.getNewPopupId();

    this.dsClear();
    this.dsAdd('set', 'conf/popup' + id + '/show', true);
    this.dsAdd('set', 'conf/popup' + id + '/data', {});
    this.dsAdd('set', 'conf/popup' + id + '/data/header', title);
    this.dsAdd('set', 'conf/popup' + id + '/data/callback', callback);
    this.dsAdd('set', 'conf/popup' + id + '/content', 'roles');
    this.dsProcess();
}
