/**
 *
 * @param prefix
 * @param index
 * @param callback
 */
export default function listviewHasAccess(prefix, index, callback) {
    const data = this.dataGet(prefix);
    const record = data.getIn(['records', index]);
    const module = data.get('modul');
    const rowsAcl = data.getIn(['customData', 'acl']);

    if (rowsAcl != undefined) {
        const acl = rowsAcl.get(record);
        if (acl != undefined) {
            callback(acl.get('edit'));
            return;
        }
    }
    const params = {
        'record': record,
        'module': module,
    };
    this.load();
    this.rest.post('hasAccess', params, (ret) => {
        this.unLoad();
        this.dataSet(prefix+'/customData/acl/'+record, {edit: ret});
        callback(ret);
    });
    callback(false);
}
