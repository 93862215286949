import SimpleFormFromArray from 'ROOT/src/components/formElements/SimpleFormFromArray';
import React from 'react';
import sAction from 'sAction';

/**
 *
 * @param {{}} buttonData
 */
export default function printFile(buttonData) {
    /**
     *
     * @param {{}}data
     */
    const sendRequest = (data) => {
        data['action'] = 'printPdf';

        this.dsClear();
        this.dsAdd('set', 'conf/popup/show', true);
        this.dsAdd('set', 'conf/popup/content', 'fileDownload');
        this.dsAdd('set', 'conf/popup/data/state', 'load');
        this.dsAdd('set', 'conf/popup/data/buttons', []);
        this.dsProcess();

        this.filesXhr({
            action: 'generateFileRequest',
            data: data,
            success: (ret) => {
                if (ret && ret.status == true) {
                    this.dsClear();
                    this.dsAdd('set', 'conf/popup/data/state', 'init');
                    this.dsAdd('set', 'conf/popup/data/token', ret.token);
                    this.dsProcess();
                } else {
                    this.popupHide();
                    this.error('Omlouváme se, nastala neočekávaná chyba');
                }
            },
            error: () => {
                this.popupHide();
                this.error('Omlouváme se, nastala neočekávaná chyba');
            },
        });
    };
    if (!buttonData?.languageSelect) {
        sendRequest(buttonData);
        return;
    }
    const allowedLanguages = this.dataGet(`conf/availableLanguages`).toJS();
    const languageOptions = [];
    for (const key in allowedLanguages) {
        languageOptions.push({value: key, label: allowedLanguages[key]});
    }
    const fields = {
        languageSelected: {
            type: 'enum',
            label: 'LBL_MUTACE',
            defaultValue: 'cs_CZ',
            required: true,
            customOptions: languageOptions,
        },
    };
    const buttons = {
        buttonName: {
            type: 'button',
            label: 'LBL_CONFIRM_BUTTON_LABEL',
            onClick: (returnData) => {
                this.popupHide();
                buttonData['languageSelected'] = returnData.languageSelected;
                sendRequest(buttonData);
            },
        },
    };
    this.popup(
        <div className='editViewPopup'>
            <div className="acmPopupHeader">{sAction.translate('LBL_EV_TAB_EDIT')}</div>
            <div className="acmPopupContent">
                <SimpleFormFromArray
                    style={{width: '100%'}}
                    buttons={buttons}
                    fields={fields}
                />
            </div>
        </div>,
    );
}
