import React from 'react';

import MaterialTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// material
import MaterialDefault from '../formElements/MaterialDefault';

// children
import HomeTabSettingsSubmenu from './HomeTabSettingsSubmenu';
import HomeTabsSubmenu from './HomeTabsSubmenu';
import sAction from 'sAction';

/**
 * @parent  src\components\home\home.js
 *
 * zobrazi menu na homepage
 */
export default class HomeTabs extends MaterialDefault {
    render() {
        const attr = this.createAttr('acmTabContainer');
        delete attr.tabs;

        // prida jednotlive taby + u aktivniho ozubene kolecko
        const tabs = [];
        this.props.tabs.forEach((item, index) => {
            const tabTranslate = sAction.translate(item);
            tabs.push(
                <Tab
                    component="div"
                    key={index}
                    label={
                        <span className="homeTabFontSize">
                            {index !== this.props.value ? (
                                tabTranslate
                            ) : (
                            // ozubene kolecko s kontext menu
                                <HomeTabSettingsSubmenu item={item} id={index} />
                            )}
                        </span>
                    }
                />,
            );
        });

        return (
            <React.Fragment>
                <MaterialTabs {...attr}>{tabs}</MaterialTabs>

                {/* rozklikavaci menu uplne vpravo (pridat stranku, budik, ... ) */}
                <HomeTabsSubmenu way={this.props.way} />
            </React.Fragment>
        );
    }
}
