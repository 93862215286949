import React from 'react';
import DefaultCurrency_id from '../../defaultFields/Currency_id/Currency_id';
import sAction from 'sAction';

export default class Currency_id extends DefaultCurrency_id {
    /**
	 *
	 * @param data
	 */
    saveField(data) {
        sAction.saveCellFieldByProps(this.props, data.value);
    }
}
