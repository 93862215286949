import DefaultBool from '../../defaultFields/Bool/Bool';
import sAction from 'sAction';

export default class Bool extends DefaultBool {
    /**
	 *
	 * @param data
	 */
    saveField(data) {
        sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);
    }
}
