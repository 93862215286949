/**
 * Otevře náhled souboru v novém panelu
 * @param {string}      id            id souboru
 * @param {string}      module        modul souboru
 * @param {string}      value         název souboru
 * @param {string}      type          typ souboru
 * @param {function}    downloadFn    funkce pro stáhnutí souboru
 */
export default function downloadFile(id, module, value, type = 'pdf', downloadFn = null) {
    // open right panel with loading
    this.openRightPanel('load', {name: value});

    // console.log('downloadFile', data);
    if (!id) {
        console.error('Chybí id souboru/záznamu');
    // return; //vyjimka email
    }
    if (!module) {
        console.error('Chybí modul souboru/záznamu');
    // return; //vyjimka email
    }

    this.filesXhr({
        action: 'filePreview',
        data: {id: id, module: module},
        success: (ret) => {
            if (ret.status) {
                const result = ret.message.data;
                if (result.token && result.type === 'local') {
                    const link = this.param.files + 'downloadFile/' + result.token;
                    this.openRightPanel(type, {name: value, url: link, downloadFn: downloadFn});
                } else if (result.type === 'sharepoint') {
                    this.openRightPanel(type, {name: value, url: result.url, downloadFn: downloadFn});
                }
            }
        },
        error: (ret) => {
            console.error('error', ret);
            this.openRightPanel('error', {data: []});
        },
    });
}
