import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class DetailViewActivityButtons extends PureComponent {
    /**
     * Prepare and open the e-mail popup
     * @param {Event} e
     */
    composeEmail = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const {prefix, parentModule: module, parentId: id, parentName: name} = this.props;

        let toAddrs = '';
        if (module !== 'acm_CampaignsAction') {
            const email = sAction.dataGet(prefix + '/fields/email1/def/emails')?.find((r) => r.get('primary_address') === '1');
            if (email) toAddrs = email.get('email_address');
        }
        // TODO: Pridat else - ziskej emaily ucastniku

        const data = {
            parent_type: module,
            parent_id: id,
            parent_name: name,
            to_addrs: toAddrs,
        };

        sAction.openEmailPopup(data);
    };

    render() {
        let {
            activities,
            parentModule,
            parentId,
            parentName,
            prefix,
        } = this.props;

        if (!activities) return '';
        const links = [];

        const activitiesPole = ['Calls', 'Meetings', 'Notes', 'Tasks'];

        // na firme lze vytvorit i kontakt
        if (parentModule === 'Accounts') {
            activitiesPole.unshift('Contacts');
            activities = activities.set('contacts', 1);
        }

        activitiesPole.forEach((module) => {
            if (sAction.hasAccess(module, 'newRecord')) {
                if (
                    activities.get(module.toLowerCase()) >= 1
                ) {
                    const params = {
                        module,
                        parentModule,
                        parentId,
                        parentName,
                        prefix,
                    };
                    if (module === 'Contacts') {
                        params.relateSimple = {
                            fieldName: 'account_name',
                            name: 'account_id',
                            value: {
                                name: parentName,
                                id: parentId,
                            },
                        };
                    }
                    links.push(
                        <TooltipWrapper key={module} label={'LBL_NEW_' + module.toUpperCase()}>
                            <a
                                target="_blank"
                                onClick={() => sAction.newSubRecord(params)}
                            >
                                <span className={'DetailViewActivityButtonsIcon icon-' + module} />
                            </a>
                        </TooltipWrapper>,
                    );
                }
            }
        });
        if (activities.get('emails') === '1') {
            links.push(
                // Poslat e-mail
                <TooltipWrapper key={'emails'} label={'LBL_COMPOSE_EMAIL_BUTTON_LABEL'}>
                    <a
                        target="_blank"
                        onClick={this.composeEmail}
                    >
                        <span className={'DetailViewActivityButtonsIcon icon-Emails'} />
                    </a>
                </TooltipWrapper>,
            );
        }

        if (!links.length) {
            return null;
        }

        return <div className="action">{links}</div>;
    }
}

DetailViewActivityButtons.propTypes = {
    activities: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
    }),
    parentId: PropTypes.any,
    parentModule: PropTypes.string,
    parentName: PropTypes.any,
    prefix: PropTypes.string,
};
