/**
 * @param {number} total
 * @param {number} percent
 * @return {*|(function(*, *): (*))|(function(*, *): (*))}
 * @this sAction
 */
export default function repairSubpanelRowPercent(total, percent) {
    if (total < 100) {
        let actual = 0;
        percent.forEach((per, key) => {
            if (+key === (percent.length -1)) {
                percent[key] = 100 - actual;
            } else {
                actual += per;
            }
        });

        return percent;
    } else if (total > 100) {
        let biggestIndex = null;
        let biggestValue = 0;
        percent.forEach((per, key) => {
            if (per > biggestValue) {
                biggestValue = per;
                biggestIndex = key;
            }
        });
        if (biggestValue > 10) {
            biggestValue -= 10;
            total -= 10;
        } else {
            biggestValue -= 2;
            total -= 2;
        }
        percent[biggestIndex] = biggestValue;

        return this.repairSubpanelRowPercent(total, percent);
    }

    return percent;
}
