/**
 * @param {object} params
 * @param {string} params.type - type of action
 * @param {string} params.module - module name
 * @param {string} params.id - record id
 * @param {string} params.prefix - prefix
 * @this sAction
 * @button DetailView
 * @render confirm
 */
export default function askDeleteRecord(params) {
    this.confirm(this.translate('LBL_BUTTON_CONFIRM_DELETE_RECORD'), () => {
        this.deleteRecord(params);

        if (params.type === 'rightPanel') {
            this.dsClear();
            this.dsAdd('set', params.type + '/show', false);
            this.dsProcess();
        }
    });
}
