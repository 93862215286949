/**
 *
 * @param {object} data
 * @returns {object}
 */
export default function reloadListParams(data) {
    const list = data.list;
    const prefix = data.prefix;
    const savedSearch = data.savedSearch;
    const forceColumns = data.columns;
    const fieldWithPath = data.list?.openFilterData?.def.get('fieldWithPath');

    /*
        Sestavení filtru pro server, podle toho co má uživatel naklikáno přes možnosti filtrace u listview
    */
    const filterParameters = [];
    list?.filter?.forEach((group) => {
        const filters = [];
        let groupOperator = group.operator;
        group.filters.forEach((filter) => {
            let value = filter.value;
            let type = filter.type;
            if (typeof value === 'string') {
                if (value.startsWith('"') && value.endsWith('"')) {
                    type = 'eq';
                    value = value.slice(1, -1);
                } else if (value.startsWith('...')) {
                    type = 'end';
                    value = value.substring(3);
                } else if (value.endsWith('...')) {
                    type = 'start';
                    value = value.slice(0, -3);
                } else if (value.indexOf('...') !== -1) {
                    const explodeValue = value.split('...');
                    value = explodeValue[0];
                    type = 'start';
                    filters.push({
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: 'end',
                        value: explodeValue[1],
                    });
                } else if (['date', 'currency', 'decimal', 'int'].includes(filter.fieldType)) {
                    if (value.startsWith('..')) {
                        type = 'lessThanInclude';
                        value = value.substring(2);
                    } else if (value.endsWith('..')) {
                        type = 'moreThanInclude';
                        value = value.slice(0, -2);
                    } else if (value.indexOf('..') !== -1) {
                        const explodeValue = value.split('..');
                        value = explodeValue[0];
                        type = 'moreThanInclude';
                        filters.push({
                            field: filter.field,
                            fieldModule: filter.fieldModule,
                            fieldRel: filter.fieldRel,
                            type: 'lessThanInclude',
                            value: explodeValue[1],
                        });
                    }
                }
            }

            if (filter.multiValue && value.indexOf(';') !== -1) {
                groupOperator = 'or';
                const explodeValue = value.split(';');
                explodeValue.forEach((val) => {
                    filters.push({
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: 'eq',
                        value: val,
                    });
                });
            } else {
                if (filter.field && type && (value !== '' || ['dateNull', 'dateNnull', 'null', 'nnull'].includes(type))) {
                    const newFilter = {
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: type,
                        value: value,
                    };
                    if (filter.parent_type != null) {
                        newFilter.parent_type = filter.parent_type;
                    }
                    filters.push(newFilter);
                }
            }
        });
        filterParameters.push({
            operator: groupOperator,
            operands: filters,
        });
    });

    let actFilter = null;
    if (filterParameters.length !== 0) {
        actFilter = {
            operator: 'and',
            operands: filterParameters,
        };
    }

    if (prefix === 'reportWindow/results') {
        actFilter = this.reportWindowCreateFilter();
    }

    // _________________________________________________________________________________________________

    /*
   Pokud se jedná o uložené vyhledávání a je na něm v rámci listview volána změna (order, pagination, filter)
   nastaví se "customParameters" na true, aby server věděl že má přepsat hodnoty z načteného pohledu
 */
    let customParameters = false;
    if (savedSearch == null) {
        customParameters = true;
    }
    // ______________________________________________________________________________________________________

    /*
   Pokud se jedná u uložené vyhledávání/pohled předá se ID pohledu do proměné "savedSearchId"
 */
    let savedSearchId = null;
    if (savedSearch != null) {
        savedSearchId = savedSearch;
    } else if (list.actSavedSearch !== null) {
        savedSearchId = list.actSavedSearch;
    }
    // _______________________________________________________________________________________

    /*
 nastavení limitu pro vysledky
 */
    const finalLimit = list.limit;
    if (list.type === 'subpanel') {
    //  finalLimit = 10;
    }
    // _____________________________________

    /*
  Pokud je typ listview subpanel, vezmou se rows z definice subpanelu a pošlou se jako columns ..
  aby se vrátil stejný počet sloupců
 */
    let columns = null;
    if (list.type === 'subpanel' || forceColumns) {
        columns = [];

        list.rows.forEach((row) => {
            columns.push({
                field: row.key.toLowerCase(),
                function: null,
                module: list.modul,
                width: row.width,
                type: row.def.get('type'),
                label: row.label,
                fModule: row.def.get('fieldModule'),
                fName: row.def.get('name'),
            });
        });
    }

    // _______________________________________________________________________________________
    /**
   * pokud jsou data v defaultFilter je potřeba je přidat do filtru k odeslání
   * defaultFilter se nepřidává pokud je aktivní pohled, nebo pokud je defaultFilter vypnutý
   */
    let defaultFilterActive = true;
    if (savedSearchId && savedSearchId !== ' ') {
        defaultFilterActive = false;
    } else if (list.customData.has('useDefaultFilter')) {
        defaultFilterActive = list.customData.get('useDefaultFilter');
    }

    if (list.defaultFilter.size !== 0) {
        const actDefaultFilter = {
            operator: 'and',
            operands: [],
        };
        list.defaultFilter.entrySeq().forEach((e) => {
            const filter = e[1];
            if (e[0] !== 'tree' && !defaultFilterActive) {
                return; // filtr pro kategorie dokumentů je vždy aktivní
            }
            if (filter.operandType === 'relate' && filter.parentId) {
                let value = '';
                try {
                    value = filter.parentId.toJS();
                } catch (error) {
                    value = filter.parentId;
                }
                const operand = {
                    module: filter.module,
                    type: 'relate',
                    relationship: filter.relationship,
                    name: filter.name,
                    filter: {
                        operator: 'and',
                        operands: [{field: 'id', type: filter.type, value: value}],
                    },
                };
                actDefaultFilter.operands.push(operand);
            } else if (filter.operandType !== 'relate') {
                try {
                    const operand = {
                        field: filter.get('field'),
                        type: filter.get('type'),
                        value: filter.get('value'),
                    };
                    actDefaultFilter.operands.push(operand);
                } catch (error) {
                    const operand = {
                        field: filter.field,
                        type: filter.type,
                        value: filter.value,
                    };
                    actDefaultFilter.operands.push(operand);
                }
            }
        });
        if (actDefaultFilter.operands.length !== 0) {
            if (actFilter != null) {
                actDefaultFilter.operands.push(actFilter);
            }
            actFilter = actDefaultFilter;
        }
    }
    // _______________________________________________________________________________________
    /*
   Pokud si člověk vybere pohled "Moje" přidá se do filtru podmínka na assgined_user_id
*/

    if (savedSearchId === 'only_my') {
        const filter = {
            field: 'assigned_user_id',
            type: 'eq',
            value: '{%LOGIN_USER%}',
        };
        const actFilterWithRelate = {
            operator: 'and',
            operands: [],
        };
        actFilterWithRelate.operands.push(filter);
        if (actFilter != null) {
            actFilterWithRelate.operands.push(actFilter);
        }
        actFilter = actFilterWithRelate;
    }
    /*
     Pokud existují nějaké funkce nad fieldem typu SUM,AVG,COUNT přidají se do dalšího parametru pro rest
   */
    const fieldFunction = {};
    const funcList = list.fieldFunction.get('parameters');
    if (funcList !== undefined && funcList !== null) {
        funcList.entrySeq().forEach((e) => {
            fieldFunction[e[0]] = e[1];
        });
    }
    // __________________________________________________________________________________________________
    /*
   Sestavení parametrů aktuálního listview pro server
 */
    let asc = list.asc;
    if (asc === 1) {
        asc = 'ASC';
    } else {
        asc = 'DESC';
    }
    const order = list.orderBy;
    const orderData = [];
    if (Array.isArray(order)) {
        order.forEach((o) => {
            orderData.push({
                field: o,
                sort: asc,
                module: list.modul,
            });
        });
    } else if (order !== undefined && order !== null) {
        orderData.push({
            field: fieldWithPath ? fieldWithPath : order,
            sort: asc,
            module: list.modul,
        });
    }

    const searchData = {
        limit: finalLimit,
        offset: data.offset ?? 0,
        saved_search_id: savedSearchId,
        columns,
        customParameters,
        fieldFunction,
        order: orderData,
        filter: actFilter,
        prefix: list.prefix,
    };
    return searchData;
    // ___________________________________________________
}
