/**
 *
 * @param way
 * @param field
 */
export default function mergeRemoveField(way, field) {
    let record = this.dataGet(way+'/newRecord');
    record = record.delete(field);
    this.dataSet(way+'/newRecord', record);
}
