import React from 'react';
import FilterBasic from './filterBasic';
import classNames from 'classnames';

import sAction from 'sAction';
import Select from '../../formElements/Select';
import InputText from '../../formElements/InputText';

export default class FilterRelate extends FilterBasic {
    constructor(props) {
        super(props);
        this.state = {
            actCustom: false,
            customLines: [{type: null, value: ''}],
        };

        this.customOperator = React.createRef();
    }
    /**
     *
     */
    addCustomFilterLineToView() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd('add', prefix + '/filter', {field: data.get('name'), operator: 'and', actCustom: true}, 'filterGroup');
            sAction.dsAdd('add', prefix + '/filter/' + index + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('add', way + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        }
    }
    /**
     *
     * @param {str} key
     */
    deleteCustomFilterLineFromView(key) {
        const way = this.props.way;
        sAction.dataDelete(way + '/filters', key);
    }
    /**
     *
     * @param {str} data
     * @param {bool} asc
     */
    order(data, asc) {
        //   this.props.parent.props.parent.filterClose();
        const prefix = this.props.prefix;
        const field = data.get('fieldWithPath') ? data.get('fieldWithPath') : data.get('name');
        sAction.orderBy(field, asc, prefix);
    }
    /**
     *
     */
    customOption() {
        this.props.parent.advanced();
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd('add', prefix + '/filter', {field: data.get('name'), operator: 'and', actCustom: true}, 'filterGroup');
            sAction.dsAdd('add', prefix + '/filter/' + index + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        }
    }
    /**
     *
     * @param {str} field
     * @param {str} type
     * @param {str} value
     * @param {str} groupOperator
     */
    addFilter(field, type, value, groupOperator = 'and') {
        //    this.props.parent.props.parent.filterClose();
        const prefix = this.props.prefix;
        const filter = {
            field: field,
            type: type,
            value: value,
        };
        const filters = new Array(filter);
        const data = {
            field: field,
            filters: filters,
            operator: groupOperator,
            reload: true,
            prefix: prefix,
        };
        sAction.addFilter(data);
    }
    /**
     *
     * @param {str} field
     */
    addCustomFilter(field) {
        const prefix = this.props.prefix;
        const filters = [];
        const lines = this.getLines();
        for (const line of lines) {
            const select = line.select;
            if (!select) {
                sAction.error(sAction.translate('LBL_SELECT_FILTER_TYPE'));
                return;
            }
            const text = line.input;
            const filter = {
                field: field,
                type: select,
                value: text,
            };
            filters.push(filter);
        }
        const operator = this.customOperator.current.value;
        const data = {
            field: field,
            filters: filters,
            operator: operator,
            reload: true,
            prefix: prefix,
        };
        sAction.addFilter(data);
    }
    /**
     *
     * @param {str} field
     */
    removeFilter(field) {
        const prefix = this.props.prefix;
        const filter = {
            field: field,
            type: null,
            value: null,
        };
        const filters = new Array(filter);
        const data = {
            field: field,
            filters: filters,
            operator: 'and',
            prefix: prefix,
        };
        sAction.removeFilter(data);

        this.setState({
            actCustom: false,
            customLines: [{type: null, value: ''}],
        });
    }

    /**
     *
     * @param {KeyboardEvent} event
     */
    onKeyDown(event) {
        const data = this.props.data;
        if (+event.keyCode === 13) {
            this.addCustomFilter(data.get('name'));
        }
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter;
        let custom;
        if (actFilter == null || !actFilter.actCustom) {
            custom = (
                <div onClick={() => this.customOption()} className={classNames(['filterButton', {filterButtonDisabled: actFilter}])}>
                    <div className="icon-pencil filterButtonIcon" />
                    {sAction.translate('LBL_CUSTOM_FILTER')}
                </div>
            );
        } else {
            const customLines = [];
            actFilter.filters.forEach((line, key) => {
                customLines.push(
                    <div className="filterLine" key={key}>
                        <Select
                            defaultValue={line.type}
                            options={sAction.getRelateFilterOptions()}
                            onChange={(e) =>
                                this.updateLines(key, e.target.value, 'select')
                            }
                        />
                        <InputText
                            type="text"
                            defaultValue={line.value}
                            onChange={(e) =>
                                this.updateLines(key, e.target.value, 'input')
                            }
                            onKeyDown={(e) => {
                                this.onKeyDown(e);
                            }}
                        />
                        {customLines.length !== 0 && (
                            <div
                                className="customFilterDeleteRow iconCancel"
                                onClick={() => this.deleteCustomFilterLineFromView(key)}
                            />
                        )}
                    </div>,
                );
            });

            custom = <div className="customFilter">
                <div className="filterButton">
                    <div className="icon-pencil filterButtonIcon" />
                    {sAction.translate('LBL_CUSTOM_FILTER')}
                </div>
                <div className="CustomFilterLinesContent">
                    <div className="CustomFilterLines">
                        <div key="customLines">
                            {customLines}
                        </div>
                        <div className="filterLine customFilterAddLineButton" onClick={() => this.addCustomFilterLineToView()}>+</div>
                    </div>
                    <div className="customFilterLinesOperator">
                        <Select
                            key={actFilter.field + '_groupSelect'}
                            defaultValue={actFilter.operator}
                            myRef={this.customOperator}
                            options={[
                                {value: 'and', label: sAction.translate('LBL_AND')},
                                {value: 'or', label: sAction.translate('LBL_OR')},
                            ]}
                        />
                    </div>
                </div>
                <div
                    tabIndex="2" onClick={() => this.addCustomFilter(data.get('name'))}
                    className="filterCustomFilterButton"
                >
                    {sAction.translate('LBL_LIST_FILTER')}
                </div>

            </div>;
        }
        let removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get('name'))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate('LBL_DELETE_FILTER')}
                </div>
            );
        }

        return (
            <div className="filterBox">
                <div
                    onClick={() => this.order(data, 1)}
                    className="filterButton"
                >
                    <div className="icon-arrowDownLight filterButtonIcon"/>
                    {sAction.translate('LBL_ORDER_AZ')}
                </div>
                <div
                    onClick={() => this.order(data, 0)}
                    className="filterButton"
                >
                    <div className="icon-arrowUpLight filterButtonIcon"/>
                    {sAction.translate('LBL_ORDER_ZA')}
                </div>
                <div
                    onClick={() => this.addFilter(data.get('name'), 'nnull', null)}
                    className="filterButton"
                >
                    <div className="icon-pipe filterButtonIcon"/>
                    {sAction.translate('LBL_CONTAINS_DATA')}
                </div>
                <div
                    onClick={() => this.addFilter(data.get('name'), 'null', null)}
                    className="filterButton"
                >
                    <div className="icon-cancelCircle filterButtonIcon"/>
                    {sAction.translate('LBL_NOT_CONTAINS_DATA')}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
