import SimpleFormFromArray from 'ROOT/src/components/formElements/SimpleFormFromArray';
import React from 'react';

/**
 *
 * @param params
 */
export default function sendFileConfirm(params) {
    if (!this.requiredFieldsCheckMsg(params.prefix)) {
        return;
    }

    // popup, kde se zeptame na typ faktury
    const {module} = params;

    const paramData = {...params, filetype: 'pdf', save: true};

    if (module === 'Quotes') {
        this.getSavedPrintFile(paramData);
        return;
    }

    if (['acm_orders', 'acm_invoices'].includes(module)) {
        const data = {
        // Tisk faktury/objednávky
            header: this.translate('LBL_SEND_INV', module),
            buttons: [
                {label: this.translate('LBL_CANCEL'), callback: () => this.popupHide()},
                // Tisk faktury/objednávky
                {
                    label: this.translate('LBL_SEND_INV', module),
                    callback: () => {
                        this.getSavedPrintFile({...paramData, type: 'all'}); this.popupHide();
                    },
                },
                // "Tisk souhrnné faktury/objednávky"
                // {
                //     label: this.translate('LBL_SEND_SUMINV', module),
                //     callback: () => {
                //         this.getSavedPrintFile({...paramData, type: 'summary'}); this.popupHide();
                //     },
                // },
            ],
        };

        // Volba "Poslat souhrnou fakturu/objednávku" pošle fakturu bez řádků faktury/objednávky.
        // Volba "Poslat fakturu/objednávku" pošle fakturu včetně řádků faktury/objednávky.
        const message =
        // this.translate('LBL_TSENDEXT_SUMMARY_INV', module) + '<br>' + this.translate('LBL_SENDTEXT_INV', module);
        this.translate('LBL_SENDTEXT_INV', module);
        this.popup(message, data);
        return;
    }

    // ADD more params here like template
    if (!paramData.languageSelect) {
        // we don't want any popup with form, send it directly
        this.getSavedPrintFile(paramData);
        return;
    }

    const fields = {};
    if (paramData.languageSelect) {
        const allowedLanguages = this.dataGet(`conf/availableLanguages`).toJS();
        const languageOptions = [];
        for (const key in allowedLanguages) {
            languageOptions.push({value: key, label: allowedLanguages[key]});
        }
        fields.languageSelected = {
            type: 'enum',
            label: 'LBL_MUTACE',
            defaultValue: 'cs_CZ',
            required: true,
            customOptions: languageOptions,
        };
    }

    const buttons = {
        buttonName: {
            type: 'button',
            label: 'LBL_CONFIRM_BUTTON_LABEL',
            onClick: (returnData) => {
                this.popupHide();
                // ADD more params here like template
                paramData['languageSelected'] = returnData.languageSelected ?? '';
                this.getSavedPrintFile(paramData);
            },
        },
    };

    this.popup(
        <div className='editViewPopup'>
            <div className="acmPopupHeader">{this.translate('LBL_EV_TAB_EDIT')}</div>
            <div className="acmPopupContent">
                <SimpleFormFromArray
                    style={{width: '100%'}}
                    buttons={buttons}
                    fields={fields}
                />
            </div>
        </div>,
    );
}
