import sAction from 'sAction';

/**
 * Open popup, where enum for lead source is edited
 * Users should add keys to enum for their specific domains, that Leads visited on their webpage, they can be found in Lead description =>
 * @see PluginLeady on BE, function getCompany, where: $bean->lead_source = $domain;
 *
 */
export default function setupLeadSourceDom() {
    this.load();
    this.rest.fetchData('getFieldDefinitionCall/Leads', 'GET').then((response) =>{
        this.unLoad();

        // get actual enum key for lead_source field on Leads
        const enumKey = response['data']?.['definition']?.['lead_source']?.['options'];
        if (!enumKey) {
            this.error(this.translate('LBL_LEADY_SETUP_ENUM_KEY_ERROR'));
            return;
        }

        sAction.dynPopup('EditView/EditEnumPopup', {
            enumKey: enumKey,
            isLocked: true,
            // eslint-disable-next-line no-unused-vars
            onSave: (enumName, enumOptions) => {},
        });
    }).catch((response) => {
        this.error(this.translate(response.text ?? 'LBL_LEADY_SETUP_ENUM_KEY_ERROR_DEFINITION'));
    });
}
