/**
 * isServis
 * @return {boolean}
 */
export default function isServis() {
    if (this.deviceType === 'servis') {
        return true;
    } else {
        return false;
    }
}
