/**
 *
 * @param prefix
 * @param list
 */
export default function getListviewFilter(prefix, list = undefined) {
    if (!list) {
        list = this.dataGet(prefix);
    }
    const savedSearch = list.actSavedSearch;
    const filterData = this.reloadListParams({
        list,
        prefix,
        savedSearch,
        columns: false,
    });
    filterData['selected'] = list.selected;
    filterData['module'] = list.modul;
    filterData['type'] = list.type;

    return filterData;
}
