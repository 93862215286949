/**
 *
 */
export default function openReportWindow() {
    this.load();
    this.rest.get('getAllModules', (data) => {
        this.unLoad();
        this.dsClear();

        const sortedPairs = [];
        data.message.data.modules.forEach((element) => {
            if (element !== 'Calendar' && element !== 'Home') {
                sortedPairs.push({
                    value: element,
                    label: this.translateEnum('moduleList', element) ?? '___',
                });
            }
        });

        sortedPairs.sort(function(a, b) {
            return a.label.localeCompare?.(b.label);
        });

        this.dsAdd('set', 'reportWindow/modules', data);
        this.dsAdd('set', 'reportWindow/sortedPairs', sortedPairs);
        this.dsAdd('set', 'conf/popup/show', true);
        this.dsAdd('set', 'conf/popup/content', 'reportWindow');
        this.dsAdd('set', 'conf/popup/data/header', this.translate('LBL_REPORT_EXTENDED_SEARCH'));
        this.dsProcess();
    });
}
