import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

export default class Timeline extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * onSelect
     * @param {any} e
     * @param {any} value
     */
    onSelect(e, value) {
        if (this.props.onSelect != undefined) { // eslint-disable-line
            this.props.onSelect(e, value);
        }
    }

    /**
     * getTimelinePoint
     * @param {any} data
     * @param {string} state
     * @return {any}
     */
    getTimelinePoint(data, state) {
        const clickable = this.props.clickable;
        const value = this.props.value;

        let color = value.color;
        let textColor = '#fff';
        // for calendar quickCreate prettify pastel color
        if (this.props.way.includes('calendar')) {
            color = sAction.convertToPastelHSL(value.color, .9, 'pastel');
            textColor = sAction.convertToPastelHSL(value.color, 1, 'dark');
        }

        let extraStyle = null;
        let extraStyleArrow = null;
        let extraTextStyle = null;
        if (state === 'colored' || state === 'now') {
            extraStyle = {backgroundColor: color};
            extraTextStyle = {color: textColor};
            extraStyleArrow={borderColor: 'transparent transparent transparent ' + color};
        }

        let title = data.title;
        if (sAction.getStorage('debug')) {
            title = !title ? data.value : title;
        }
        return (
            <div key={data.value} className="acmTimelinePointContainer" style={{maxWidth: (100/data.count) + '%'}}>
                <div className="acmTimelinePoint">
                    <div
                        className={!!clickable === true &&
                            !!data.clickAble === true ? 'acmTimelinePointCenter clickable' : 'acmTimelinePointCenter'}
                        style={extraStyle}
                        onClick={(e) => this.onSelect(e, data.value)}
                        title={title}
                    >
                        <div className="acmTimelinePointOverflow" style={extraTextStyle}>
                            {data.label}
                        </div>
                    </div>
                </div>
                <div className="acmTimeLineContainerArrow">

                </div>
                <div className="acmTimeLineArrow"
                    style={extraStyleArrow}
                />
            </div>
        );
    }

    render() {
        const data = this.props.data;
        const value = this.props.value;

        const renderData = [];
        let pointState = 'colored';
        data.forEach((point) => {
            if (value.value === point.value) {
                pointState = 'now';
            }
            renderData.push(this.getTimelinePoint(point, pointState));
            if (value.value === point.value) {
                pointState = 'alreadySet';
            }
        });

        return <div className="acmTimeline">{renderData}</div>;
    }
}

Timeline.propTypes = {
    clickable: PropTypes.bool,
    data: PropTypes.any,
    onSelect: PropTypes.func,
    value: PropTypes.any,
    way: PropTypes.string,
};
