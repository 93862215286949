/**
 * Function is also called from User detail from button (definition is on BE in site/modules/Users/coripoUsers.php)
 *
 * @this sAction
 * @button DetailView/Users
 */
export default function usrMsgraphSignIn(target = '_blank', useSSO = false) {
    this.load();
    this.rest.post('msgraphSignIn', {
        useSSO: useSSO,
    }, (ret) => {
        this.unLoad();
        if (ret.state) {
            window.open(ret.redirect_url, target);
            window.close();
        }
    });
}
