/**
 * Open activity timeline in popup
 * @param {*} prefix
 */
export default function showActivitiesTimelinePopup(prefix) {
    if (!this.dataGet(prefix + '/header')) {
        this.dataSet(prefix + '/header', this.translate('LBL_TIMELINE_OF_ACTIVITIES'));
    }
    if (!this.dataGet(prefix + '/icon')) {
        this.dataSet(prefix + '/icon', 'icon-clock');
    }

    const data = this.dataGet(prefix);
    this.dsClear();
    this.dsAdd('set', 'conf/popup/content', 'activitiesTimeline');
    this.dsAdd('set', 'conf/popup/data', data);
    this.dsAdd('set', 'conf/popup/show', true);
    this.dsProcess();
}
