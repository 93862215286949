import React from 'react';
import PropTypes from 'prop-types';
import ShortcutManagerContextProvider from 'ROOT/src/contexts/ShortcutManagerContext';
import ShortcutManager from 'ROOT/src/components/Shortcuts/ShortcutManager';

export default function ShortcutContextProvider(props) {
    return (
        <ShortcutManagerContextProvider>
            <ShortcutManager>
                {props.children}
            </ShortcutManager>
        </ShortcutManagerContextProvider>
    );
}

ShortcutContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
