import DefaultParent from '../../defaultFields/Parent/Parent';
import sAction from 'sAction';

export default class Parent extends DefaultParent {
    /**
   *
   */
    cancel() {
        const way = this.props.way;
        const rowIndex = this.props.rowIndex;
        const recordIndex = this.props.recordIndex;
        const path = `${way}/rows/${rowIndex}/records/${recordIndex}/actEdit`;
        sAction.dataSet(path, false);
        sAction.afterLoadListView(this.props.way);
    }

    /**
     *
     */
    getParentModule() {
        return this.props.data.module;
    }

    /**
     *
     * @param data
     * @param doUpdate
     * @param isChangeModule
     */
    saveField(data, doUpdate = true, isChangeModule = false) {
        const way = this.props.way;
        const value = data.value.id;
        const name = this.props.data.def.get('id_name');
        const module = this.props.data.editModule;
        const id = this.props.data.recordId;
        let newModule;
        if (isChangeModule) {
            newModule = data.parent_type;
            const rowIndex = this.props.rowIndex;
            const recordIndex = this.props.recordIndex;
            sAction.changeCellParentModule({way, value, newModule, rowIndex, recordIndex});
        } else {
            newModule = this.getParentModule();
            sAction.saveCellField({way, value, name, module, id, newModule});
        }
    }
}
