import sAction from '../../index';

/**
 *
 */
export default function toggleCalcFilter() {
    // localStorage.setItem('acmCalFiltersVisibility','Chris');

    const filt = localStorage.getItem('acmCalFiltersVisibility');
    if (filt == undefined || filt == 'visible') {
        localStorage.setItem('acmCalFiltersVisibility', 'hidden');
    } else {
        localStorage.setItem('acmCalFiltersVisibility', 'visible');
    }

    const state = !this.dataGet('calendar/filters/filtersDisplayed');
    this.dataSet('calendar/filters/filtersDisplayed', state);

    sAction.calPlanReload('all');
}
