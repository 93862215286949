/**
 * printAssemblyReport
 * @param {object} recordData
 */
export default function printAssemblyReport(recordData) {
    this.printPrepare(recordData);
    // const data = this.dataGet(recordData.prefix + '/customData/customLines');
    // const lines = data.get('lines');
    // let hasmachine = false;
    // lines.forEach((line) => {
    //     if (
    //         line.get('acm_service_order_lines_acm_machinesacm_machines_ida')
    //     ) {
    //         hasmachine = true;
    //     }
    // });
    // if (hasmachine) {
    //      this.printPrepare(recordData);
    // } else {
    //     this.alert('U servisního zakázky musí být vyplněn u řádků aspoň jeden stroj.');
    // }
}
