/**
 *
 * @param message
 * @param title
 */
export default function alert(message, title = 'Info') {
    const data = {
        header: title,
        //  color:"red",
        buttons: [{label: 'OK', callback: () => this.popupHide()}],
    };

    this.popup(message, data);
}
