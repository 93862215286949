/**
 *
 * @param prefix
 * @param viewData
 * @param dashletId
 */
export default function routeCalendarDahlet(prefix, viewData, dashletId) {
    if (viewData.options) {
        this.dsAdd('set', prefix+'/options', viewData.options);
    }

    prefix += '/viewData';

    const action = {
        type: 'CALENDARINIT',
        content: {
            params: viewData.params,
            eventData: viewData.eventData,
            date_start: viewData.date_start,
            date_end: viewData.date_end,
            dashletId: dashletId,
        },
        prefix: prefix,
    };
    this.dsAddCustom(action);

    const views = [{
        data: viewData.views.Meetings,
        prefix: prefix+'/meetingView',
        module: 'Meetings',
    },
    {
        data: viewData.views.Calls,
        prefix: prefix+'/callView',
        module: 'Calls',
    },
    {
        data: viewData.views.Tasks,
        prefix: prefix+'/taskView',
        module: 'Tasks',
    }];

    const self = this;
    views.forEach((view) => {
        const content = {
            id: null,
            actionButtons: [],
            module: view.module,
            subpanels: [],
            type: 'calendar',
        };
        self.detailContent(view.data, content);

        content.subpanels = [];
        const action = {
            type: 'PAGELOADDETAIL',
            content: content,
            prefix: view.prefix,
            function: self,
        };
        self.dsAddCustom(action);
    });
}
