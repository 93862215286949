/**
 * translateCategoryName - translate LBL_ name for *category modules
 * module optional
 * on empty string return enum name
 * @param {string} value
 * @param {string} module
 * @returns {string}
 */
export default function translateCategoryName(value, module) {
    if (['acm_reportscategory', 'acm_documentsCategory', 'ProductCategories'].includes(module)) {
        return this.translate(value, module);
    }

    return value;
}
