/* eslint-disable new-cap */
import {List} from 'immutable';
import {DataConstructors} from '../../store/DataConstructors';

/**
 * prepareFilterStructure
 * @param {array} filter
 * @returns {array} - filter structure
 */
export default function prepareFilterStructure(filter) {
    const groups = [];
    if (!filter) return new List(groups);
    filter.forEach((data) => {
        const filterList = [];
        if (!data.filters) return;

        data.filters.forEach((filterRecord) => {
            filterList.push(new DataConstructors['filterParameter'](filterRecord));
        });

        data.filters = new List(filterList);
        groups.push(new DataConstructors['filterGroup'](data));
    });

    return new List(groups);
}
