import sAction from 'sAction';

/**
 *
 */
const keydownFunction = () => {
    if (!sAction.dataGet('conf/shortcutsHelp')) {
        sAction.dataSet('conf/shortcutsHelp', true);
    }
    document.removeEventListener('keydown', keydownFunction);
};

/**
 *
 */
const keyupFunction = () => {
    sAction.dataSet('conf/shortcutsHelp', false);
    document.removeEventListener('keyup', keyupFunction);
};

/**
 *
 * @param {String} action
 * @param {Event} event
 * @param {*} params
 */
// eslint-disable-next-line no-unused-vars
const handler = (action, event, params) => {
    switch (action) {
        case 'sweetSpot':
            sAction.toggleSweetSpot();
            break;
        case 'hideSweetSpot':
            sAction.hideSweetSpot();
            break;
        case 'notifications':
            document.getElementById('NotificationButton')?.click();
            break;
        case 'reportWindow':
            sAction.openReportWindow();
            break;
        case 'recentReports':
            document.getElementById('recentRecordsButton').click();
            break;
        case 'calendar':
            window.location.hash = '#calendar';
            break;
        case 'home':
            window.location.hash = '#home';
            break;
        case 'shortcutsHelp':
            document.addEventListener('keydown', keydownFunction);
            document.addEventListener('keyup', keyupFunction);
            break;
        default:
            break;
    }
};

export default handler;
