/**
 * Open confirm popup before printing proccess
 * @param {*} prefix
 */
export default function printTimelineConfirm(prefix) {
    const data = {
        header: this.translate('LBL_PRINT_TIMELINE'),
        buttons: [
            {
                label: this.translate('LBL_BTN_PRINT_TIMELINE_ALL'),
                callback: () => {
                    this.printTimeline({prefix, all: true});
                },
            },
            {
                label: this.translate('LBL_BTN_PRINT_TIMELINE_SHOWN'),
                callback: () => {
                    this.printTimeline({prefix, all: false});
                },
            },
        ],
    };
    const message = this.translate('LBL_PRINT_TIMELINE_QUESTION');
    this.popup(message, data);
}
