/**
 * @param {object} params
 */
export default function sendInvites(params) {
    this.load();

    this.rest.post('email/getInvitesData', {module: params.module, id: params.id}, (email) => {
        this.unLoad();

        if (typeof email !== 'object' || email.status === 'error') {
            return;
        }

        /**
         *
         */
        const openEmail = () => {
            const data = {
                parent_type: params.module,
                parent_id: params.id,
                parent_name: params.name,

                to_addrs: email.emails || '',
                name: email.subject,
                description_html: email.message.replace(/{{site_url}}/g, document.location.origin),
                customData: email.customData,
                // disable_recipient_change: true,
                attachments: email.attachments,
            };

            this.openEmailPopup(data);
        };

        if (email.emails_not_found) {
            this.confrim(email.emails_not_found, () => {
                openEmail();
            });
        } else {
            openEmail();
        }
    });
}
