/**
 * dataGet - returns data from redux store
 * @param {string} way
 * @param {any} values
 * @param {bool} toJS
 * @returns {any}
 */
export default function dataGet(way, values = null, toJS = false) {
    if (way.indexOf('/') === -1) {
    // rozparsovaní cesty do pole
        way = [way];
    } else {
        while (way.indexOf('/..') !== -1) {
            way = way.replace(/\/[a-zA-z0-9\-_]+\/[.][.]/, '');
        }
        way = way.split('/');
    }

    const app = this.store.getState().appReducer;
    const data = app.getIn(way);

    if (values != null) {
        const arrayData = {};
        values.forEach((val) => {
            if (!data) {
                // will fail anyways and needed for mobile/moreOptions/activityTimeline
                return [];
            }
            arrayData[val] = data.get(val);
        });
        return arrayData;
    } else {
        if (toJS === true && data) {
            return data.toJS();
        } else {
            return data;
        }
    }
}
