/**
 * closeRightPanel - close right panel
 */
export default function closeRightPanel() {
    const way = this.dataGet('rightPanel/data/reloadList');
    const reloadOnClose = this.dataGet('rightPanel/reloadOnClose');

    if (way && reloadOnClose === true) {
        const offset = this.dataGet(way)?.offset || 0;
        const page = this.dataGet(way)?.page || 1;

        this.reloadList(offset, page, way);
    } else if (!way && reloadOnClose) {
        // if/else condition is commented for easy rollback in case it breaks something
        // if (this.dataGet('view/mode') !== 'detail') {
        this.route();
        // } else {
        //     this.unLoad();
        // }
    }

    this.dataSet('rightPanel', {}, 'RightPanel');
}
