/**
 *
 * @param object data => {action: "funkce ktera se ma na BE vykonat", data: "data, co se v POSTU posilaji na BE"}
 * @param data
 * @returns object {data: object, status: HTTP_CODE}
 */
export default function filesXhrSynchr(data) {
    const xhr = new XMLHttpRequest();
    const xdebug = !this.param.xdebugEnabled ? '' : '?XDEBUG_SESSION_START=1';
    xhr.open('POST', this.param.files + data.action + xdebug, false);
    xhr.setRequestHeader('sID', this.rest.getCookie('sID'));
    // xhr.responseType = "json";
    xhr.send(this.objectToFormdata(data.data));
    return {status: xhr.status, data: JSON.parse(xhr.response)};
}
