import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../pure';

export default class PtItem extends PureComponent {
    render() {
        const data = this.props.data;
        let name = data.get('name');
        const num = data.get('mft_part_num_int');
        if (num) {
            name = num + ' - ' + name;
        }

        return (
            <div className="ptItem" onClick={() => this.props.onClick({id: data.get('id')})}>
                <div className="icon-addIcon quoteFavAddIcon"/>
                <div className="ptItemText">{name}</div>
            </div>
        );
    }
}

PtItem.propTypes = {
    data: PropTypes.shape({
        get: PropTypes.func,
    }),
    onClick: PropTypes.func,
};
