/**
 *
 * @param type
 */
export default function getTimeFormat(type) {
    let ret = '';

    if (Array.isArray(this.userPreferences.time_format)) {
        if (type === 'moment') { // moment.js
            ret = this.userPreferences.time_format.join('').replace('i', 'mm');
        } else if (type === 'datepicker') {
            ret = this.userPreferences.time_format.join('').replace(/[hHiA]/g, function(m) {
                return {
                    'h': 'hh',
                    'H': 'HH',
                    'i': 'mm',
                    'A': 'a',
                }[m];
            });
        }
    } else {
        if (type === 'moment') {
            ret = 'H:mm';
        } else if (type === 'datepicker') {
            ret = 'HH:mm';
        }
    }

    return ret;
}
