/**
 *
 * @param {any} params
 * @param {boolean} hidePopup
 */
export default function printPrepare(params, hidePopup = false) {
    if (this.getViewName() === 'detail' || params.prefix.includes('rightPanel')) {
        if (!this.requiredFieldsCheckMsg(params.prefix)) {
            return;
        }
        this.printFile(params);
    } else if (this.getViewName() === 'list') {
    // list view

        const records = this.dataGet(params.prefix + '/selected').toJS();
        let listViewFilter = null;
        if (records === 'all') {
            const list = this.dataGet(params.prefix);
            const savedSearch = list.actSavedSearch;
            listViewFilter = this.reloadListParams({
                list,
                prefix: params.prefix,
                savedSearch,
                columns: false,
            });
            listViewFilter['selected'] = list.selected;
            listViewFilter['module'] = list.modul;
            listViewFilter['type'] = list.type;
        }

        this.printFile({...params, id: records, listViewFilter});
    }

    if (hidePopup) this.popupHide();
}
