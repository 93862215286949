/**
 *
 * @param {*} data
 * @param {String} way
 * @param {String} prefix
 * @param {String} record - id of record
 * @param {String} module - name of module
 */
export default function createTag(data, way, prefix, record, module) {
    this.rest.post('tags/createTag/', {data: data}, (returnData) => {
        if (returnData['status']) {
            this.addTagRelate(returnData['beanID'], way, prefix, record, module);
        } else {
            this.dsClear();
            this.dsAdd('set', 'conf/popup/data/message', returnData['message']);
            this.dsProcess();
        }
    });
}
