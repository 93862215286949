/**
 *
 * @param e
 * @param data
 */
export default function openNotCenter(e, data) {
    let open = false;
    if (data != null) {
        open = data.open;
    }
    if (e.target.classList.contains('notificationButton')) {
        if (open == false) {
            this.openNotBase(data.limit, data.offsert);
        } else {
            this.dsAdd('set', 'notifications/open', false);
            this.dsAdd('set', 'notifications/load', true);
            this.dsAdd('set', 'notifications/offset', 0);
            this.dsAdd('set', 'notifications/all', false);
        }
        this.dsProcess();
    }
}
