/**
 *
 * @param prefix
 */
export default function removeChangesMassUpdate(prefix) {
    const fields = this.dataGet(prefix+'/fields');

    this.dsClear();

    this.dsAdd('set', prefix+'/changes/fields', {});
    fields.forEach((field) => {
        this.dsAdd('set', prefix+'/fields/'+ field.name+'/value', '');
    });

    this.dsProcess();
}
