/**
 * @param {object} data
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @button DetailView/Quotes
 */
export default function quoteCreateOrder(data) {
    const params = {action: 'quote_create_opp', record: data.id};

    this.load();
    this.rest.post('customAction', params, (resp) => {
        if (resp.errCode === 'OK') {
            this.href('#detail/Opportunities/' + resp.record);
        } else {
            this.unLoad();
            this.alert(resp.errMsg);
        }
    });
}
