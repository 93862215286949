/**
 *
 */
export default function showChannelLog() {
    this.load();
    this.rest.get('showChannelLog', (msg) => {
        this.popup(msg, {
            header: this.translate('LBL_CHANGE_LOG'),
            customClass: 'changeLog',
        });
        this.unLoad();
    });
}
