/**
 *
 * @param column
 */
export default function checkValidColumn(column) {
    // Na menu je potreba aby byla vyjimka
    if (column.def) {
        if (column.def.name === 'currency_id') {
            return true;
        }
        if (column.def.source === 'non-db' && column.def.type === 'varchar') {
            return false;
        }
        return column.def.type !== 'link';
    } else {
        if (column.name === 'currency_id') {
            return true;
        }
        if (column.source === 'non-db' && column.type === 'varchar') {
            return false;
        }
        return column.type !== 'link';
    }
}
