import detailDefault from '../../detailDefault';

export default class detailacm_testovaci_modul extends detailDefault {
    load(sAction, data) {
        const defaultFilter = [
            {
                module: 'Accounts',
                field: 'name',
                value: 'test',
                type: 'cont',
            },
        ];

        sAction.dsClear();
        sAction.dsAdd(
            'set',
            data.prefix +
            '/fields/vazba/def/defaultFilter',
            defaultFilter,
        );
        sAction.dsProcess();
    }
    update(sAction, data) {
        switch (data.field) {
            case 'textarea':
                if (data.value === 'imagepop') {
                    sAction.dynPopup('image/Image.js', {data: {url: 'img/no_img.png'}});
                } else if (data.value === 'filepop') {
                    sAction.dynPopup('Files/FileDownload.js', {data: {state: 'init', token: 'yeiye'}});
                } else if (data.value === 'both') {
                    sAction.dynPopup('image/Image.js', {data: {url: 'img/no_img.png'}});
                    sAction.dynPopup('Files/FileDownload.js', {data: {state: 'init', token: 'yeiye'}});
                }
                break;
            case 'enum_opt':
                sAction.dsClear();
                if (data.value === 'custom') {
                    const customOpt = [
                        {
                            label: 'Nula',
                            value: 'none',
                        },
                        {
                            label: 'Jedna',
                            value: 'one',
                        },
                    ];
                    sAction.dsAdd('set', data.prefix + '/fields/ciselnik/def/customOptions', customOpt);
                } else {
                    sAction.dsAdd('set', data.prefix + '/fields/ciselnik/def/customOptions', null);
                    sAction.dsAdd('set', data.prefix + '/fields/ciselnik/def/options', data.value);
                }
                sAction.dsProcess();
                break;
            default:
                break;
        }
    }
}
