/**
 *
 * @param signature
 * @param props
 */
export default function emailChangeSignature(signature, props) {
    if (signature) {
        if (signature.match(/<body/)) {
            signature = /<body.*?>([\s\S]*)<\/body>/.exec(signature)[1];
        }

        signature = '<div id=\'msgBody\'><p>&nbsp;</p><div id=\'signature\'>' + signature + '</div></div>';
    } else {
        signature = '';
    }

    let contents = this.dataGet(props.way + '/description_html');
    if (contents == undefined) return;

    const original_message_title = this.translate(
        'LBL_EMAIL_ORIGINAL_MESSAGE',
        'Emails',
    );

    // pokud existuje <div id="signature">, nahradi se
    if (contents.match(/<div id=["']signature["']>/)) {
        const doc = new DOMParser().parseFromString(contents, 'text/html');
        let sig = new DOMParser().parseFromString(signature, 'text/html');
        if (sig && sig.getElementById('signature')) {
            sig = sig.getElementById('signature').innerHTML;
        } else {
            sig = '';
        }
        doc.getElementById('signature').innerHTML = sig;
        contents = new XMLSerializer().serializeToString(doc);

    // pokud ne, ale obsahuje citovanou zpravu, prida se pred ni
    } else if (
        contents.match(new RegExp('<p>' + original_message_title + '</p>'))
    ) {
        contents = contents.replace(
            new RegExp(
                '(<p>' + original_message_title + '</p>)',
                'g',
            ),
            signature + '$1',
        );

    // pokud existuje </body>, prida se tam
    } else if (contents.match(/<\/body>/)) {
        contents = contents.replace('</body>', signature + '</body>', contents);

    // jinak se prida na konec
    } else {
        contents += signature;
    }

    this.dsClear();
    this.dsAdd('set', props.way + '/description_html', this.htmlDecode(contents));
    this.dsAdd('set', props.way + '/updateEmailText', true);
    this.dsAdd('set', props.way + '/loaded', true);

    if (props.data.signature !== signature) {
        this.dsAdd('set', props.way + '/signature', signature);
    }

    this.dsProcess();

    // todo: tinyMCE se nemusi nacist/aktivovat hned, tohle docasne reseni, ale funkcni
    const setContent = setInterval(function() {
        try {
            tinyMCE.activeEditor.setContent(value);
            clearTimeout(setContent);
        } catch (e) {

        }
    }, 500);
}
