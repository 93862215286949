/**
 *
 * @param params
 */
export default function createMultipleActivitiesPopup(params) {
    const records = this.dataGet(params.prefix + '/selected');

    const data = params;
    data.prefix = 'conf/popup/data';
    data['records'] = records;
    this.popup('createMultipleActivities', data);
}
