/**
 *
 * @param {Array} menuModules
 * @param {Array} actionPanel
 * @param {Object} subMenu
 * @param {Object} customIcons
 */
export default function initMenu(menuModules, actionPanel, subMenu, customIcons) {
    console.warn('ted init Menu');
    this.dsAdd('set', 'menu/groups', []);
    menuModules.forEach((group, groupIndex) => {
        this.dsAdd(
            'add',
            'menu/groups',
            {name: group.label, icon: group.icon},
            'mainMenuGroup',
        );
        group.buttons.forEach((button, buttonIndex) => {
            let active = false;
            if (module === button) {
                this.dsAdd('set', 'menu/activeIndex', buttonIndex);
                active = true;
            }
            this.dsAdd(
                'set',
                'menu/groups/' + groupIndex + '/buttons/' + buttonIndex,
                {name: button, active, icon: customIcons?.[button]},
                'menuButton',
            );
            // _____________________ SUB MENU
            if (subMenu[button] !== undefined) {
                const subData = subMenu[button];
                this.dsAdd('set', 'menu/groups/'+groupIndex+'/buttons/'+buttonIndex+'/subMenuLines', subData);
            }
            // _____________________
        });
    });
    const openGroups =
    this.getStorage('menuGroups') || Array(menuModules.length).fill(false);
    this.dsAdd('set', 'menu/openGroups', openGroups);
    actionPanel.forEach((panel, index) => {
        this.dsAdd(
            'set',
            'actionPanel/buttons/' + index,
            panel,
            'actionPanelButton',
        );
    });
    const module = this.getModuleName();
    this.dsAdd('set', 'menu/activeModule', module);
}
