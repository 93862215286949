/*
Struktura v LocalStorage:
moduleDefinition_MODULENAME: {
    "detailTimeline" : {
        "hash" : "1231456",
        "definition" : {
            //defs here
        }
    }
    "detailLines" : {
        "module" : "nazev modulu radku"
        "hash" : "1231456",
        "definition" : {
            //defs here
        }
    }
    "detailVardefs" : {
        "hash" : "1231456",
        "definition" : {
            //defs here
        }
    }
}
 */

/**
 * Prida k datum definice modulu, timeline, boundModulu
 */
export default class HashManager {
    constructor(sAction) {
        this.sAction = sAction;
        this.localStorageKeyBase = 'moduleDefinition_';
        this.localStorageKey = '';
        this.definitions = null;
        this.restReturnData = {};
        this.restSubpanelsData = [];
    }

    /**
     * Nastavi restData do classy, nacte vardefy
     *
     * @param {{}} restReturnData pole, co vraci GET/POST detail
     */
    initialization(restReturnData) {
        this.restReturnData = restReturnData;
        this.setLocalStorageKey(this.restReturnData.module);
        this.getDefinitionLocalStorage();
    }

    /**
     * Nastavi klic do LS
     *
     * @param {string} module název modulu, ktereho chci definice
     */
    setLocalStorageKey(module) {
        this.localStorageKey = `${this.localStorageKeyBase}${module}`;
    }

    /**
     *
     * @return {*|{}}
     */
    endWork() {
        const restData = this.restReturnData;
        this.restReturnData = null;
        this.definitions = null;

        return restData;
    }

    /**
     * Vytahne definice z localstorage pro dany modul (this.localStorageKey)
     */
    getDefinitionLocalStorage() {
        this.definitions = JSON.parse(localStorage.getItem(this.localStorageKey));
        if (!this.definitions) {
            this.definitions = {};
        }
        this.changedDefinition = false;
    }

    /**
     * Pokud se zmenili definice (this.changedDefinition), ulozi je do LocalStorage (this.localStorageKey)
     */
    storeDefinitionLocalStorage() {
        if (this.changedDefinition) {
            // nastala zmena, uloz novy definice
            localStorage.setItem(this.localStorageKey, JSON.stringify(this.definitions));
        }
    }

    /**
     * Prida definice poli
     *
     * @param {{}}restReturnData pole, co vraci GET/POST detail
     * @return {Promise<*|{}>}
     */
    async preprocessDefinitions(restReturnData) {
        this.initialization(restReturnData);
        // kvuli afterfetchi, pridava do defu dalsi informaci, napriklad pro uzivatele
        const detailVardefs = await this.getDefinition('detailVardefs', 'getFieldDefinitionCall');
        this.restReturnData.def = {...detailVardefs, ...this.restReturnData.def};
        if (this.restReturnData?.definitionHashes?.detailTimeline) {
            this.restReturnData.timeline = await this.getDefinition('detailTimeline', 'getTimelineDefinitonCall');
        }
        if (this.restReturnData?.definitionHashes?.detailLines) {
            // TODO optimalizace, aby se nekoupirovali radky dokola, mozna velka zatez pri velekm poctu radku
            const customLinesDef = await this.getDefinition('detailLines', 'getDetailLinesDefinitionsCall');
            this.restReturnData.customData.customLines = {...customLinesDef, ...this.restReturnData.customData.customLines};
        }
        this.storeDefinitionLocalStorage(); // musi se ulozit, protoze bound moduly budou ukladat svoje definice
        await this.getBoundModulesDefinitions();

        return this.endWork();
    }

    /**
     * Ziska a nastavi definice vsech boundModulu
     *
     * @return {Promise<void>}
     */
    async getBoundModulesDefinitions() {
        const restModules = this?.restReturnData?.definitionHashes?.boundModules;
        if (!restModules || !this.restReturnData.customData.boundModules) {
            return;
        }
        for (const [boundModuleName, hash] of Object.entries(restModules)) {
            // musim nacist definice, pro modul (zmena klice a nasledne nacteni definic)
            this.setLocalStorageKey(boundModuleName);
            this.getDefinitionLocalStorage();
            this.restReturnData.customData.boundModules[boundModuleName].def = await this.getDefinition(
                'detailVardefs',
                'getFieldDefinitionCall',
                hash,
                boundModuleName,
            );
            this.storeDefinitionLocalStorage();
        }
    }

    /**
     * Porovnani hashe s LS, pripadne ziskani definic z BE
     *
     * @param {string} definitionSelector jmeno polozky, odkud se ma z LS brát hash a definice
     * @param {string} restCall odkud ma ziskat definice, pokud je nenalezne z LS, bez modulu!
     * @param {string} hash hash, ktery se ma porovnavat
     * @param {string} module pro jaky modul chceme definice
     * @return {*|{}} definice
     */
    async getDefinition(
        definitionSelector,
        restCall,
        hash = '',
        module = this.restReturnData.module,
    ) {
        if (hash === '') {
            hash = this.restReturnData?.definitionHashes?.[definitionSelector];
        }
        let definition;
        if (hash && hash === this.definitions?.[definitionSelector]?.hash) { // kontrola, aby hash nebyl napr undefined
            definition = this.definitions?.[definitionSelector].definition;
        } else {
            this.changedDefinition = true;
            // rest call
            let restData = await this.sAction.rest.fetchData(
                `${restCall}/${module}`,
                'GET',
            ).catch(({text}) => {
                this.sAction.error(this.sAction.translate(text));
            });
            // zde by se z restu melo vratit  [text=>'',data => [definition=>[...],hash => '123456']]
            restData = restData.data;
            definition = restData.definition;
            this.definitions[definitionSelector] = {
                definition,
                hash: restData.hash,
            };
        }
        // DATA IN DEF       ADD emails and SS and multirelateOptions
        if (definition.ss) {
            definition.ss.selected = this.restReturnData.record?.acm_ssAllData?.selected ?? [];
            definition.ss.list = this.restReturnData.record?.acm_ssAllData?.list ?? [];
            delete(this.restReturnData.record.acm_ssAllData);
        }
        if (definition.email1) {
            definition.email1.emails = this.restReturnData.record.acm_allEmailAddressesData ?? [];
            delete (this.restReturnData.record.acm_allEmailAddressesData);
        }
        if (this.restReturnData.record.acm_allMultirelateOptions) {
            const multirelateData = this.restReturnData.record.acm_allMultirelateOptions ?? [];
            for (const multiRelateField in multirelateData) {
                definition[multiRelateField]['options'] = multirelateData[multiRelateField];
            }
            delete (this.restReturnData.record.acm_allMultirelateOptions);
        }

        return definition;
    }

    // ********************************* SUBPANELY *******************************************

    /**
     *
     * @param {string} detailModule n8zev modulu detailu
     * @param {[]} data rest data, obsahuji vsechny subpanely
     * @returns {Promise<[]>}
     */
    async processAllSubpanels(detailModule, data) {
        this.restSubpanelsData = data;
        const subpanelsCount = this.restSubpanelsData.length;
        for (let index = 0; index < subpanelsCount; index++) {
            await this.setSubpanelDefs(index, detailModule);
        }

        return this.endWorkAllsubpanels();
    }

    /**
     *
     * @param {int} index cislo, identifikujici subpanel v poli
     * @return {Promise<void>}
     */
    async setSubpanelDefs(index) {
        const module = this.restSubpanelsData[index].module;
        this.setLocalStorageKey(module);
        this.getDefinitionLocalStorage();
        const selector = 'detailVardefs';
        const LSHash = this.definitions?.[selector]?.hash;
        let definition;
        if (LSHash && LSHash === this.restSubpanelsData[index].vardefHash) { // kontrola, aby hash nebyl napr undefined
            definition = this.definitions?.[selector].definition;
        } else {
            this.changedDefinition = true;
            let restData = await this.sAction.rest.fetchData(
                `getSubpanelVardefsCalls/${module}`,
                'GET',
            ).catch(({text}) => {
                this.sAction.error(this.sAction.translate(text));
            });
            // zde by se z restu melo vratit  [text=>'',data => [definition=>[...],hash => '123456']]
            restData = restData.data;
            definition = restData.definition;
            this.definitions[selector] = {
                definition,
                hash: restData.hash,
            };
            this.storeDefinitionLocalStorage();
        }
        // musim spojit data co prijdou z BE (Querry gillie) a co jsou z LS
        this.restSubpanelsData[index].defs = {...definition, ...this.restSubpanelsData[index].defs};
    }

    /**
     * @returns {*}
     */
    endWorkAllsubpanels() {
        const subpanels = this.restSubpanelsData;
        this.restSubpanelsData = null;

        return subpanels;
    }
    // ********************************* SUBPANELY *******************************************
}
