/**
 *
 * @param data
 */
export default function setImportListFilter(data) {
    let list = null;

    const action = this.getStorage('prepareListStructure');
    if (data) {
        action.filter = this.dataGet(data.prefix + '/filter');
        action.actFiltering = this.dataGet(data.prefix + '/actFiltering');
        this.setStorage('prepareListStructure', action);
    }

    list = this.prepareListStructure(action);

    return list;
}
