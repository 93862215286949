import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';

export default class Breadcrumb extends PureComponent {
    constructor() {
        super();
    }

    render() {
        const module = sAction.dataGet('conf/page');
        const data = this.props.data;
        let renderData = [];
        data.forEach((element, index) => {
            if (element.type === 'link') {
                renderData.push(<a key={index} href={element.url}>
                    {element.label}
                </a>);
                renderData.push(<div key={'0'+index} className="breadcrumbDelimiter icon-arrowRight"></div>);
            } else if (element.type === 'text') {
                renderData.push(<span key={index}>{sAction.translate(sAction.decodeHTMLEntities(element.label), module)}</span>);
                renderData.push(<div key={'0'+index} className="breadcrumbDelimiter icon-arrowRight"></div>);
            }
        });
        renderData = renderData.splice(0, renderData.length-1);

        return (
            <div className="headerBreadCrumb">
                {renderData}
            </div>
        );
    }
}

Breadcrumb.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
};
