/**
 * @param {string} prefix
 * @param {object} data
 * @return {{parent: {
 *               relName: string,
 *               module: string,
 *               operandType: string,
 *               name: string,
 *               canDelete: boolean,
 *               relationship: string[],
 *               type: string,
 *               parentId: (*|{}|(function(*): (*))|(function(*): (*)))
 *           }
 *         }|*[]}
 * @this sAction
 */
export default function subpanelGenerateDefaultFilter(prefix, data) {
    if (data) {
        const parModule = data.def.getIn(['rel', 'module']);
        if (data.module === 'Contacts' && parModule === 'Opportunities') {
            const acc = this.dataGet(prefix+'/fields/account_name/def/id_value');
            if (acc) {
                return {
                    parent: {
                        operandType: 'relate',
                        parentId: acc,
                        module: 'Accounts',
                        relationship: ['accounts'],
                        name: 'account_name',
                        relName: 'contacts',
                        type: 'eq',
                        canDelete: true,
                    },
                };
            }
        }
    }

    return [];
}
