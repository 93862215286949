import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';

import HomeTabAddOrEditPopup from '../popup/home/HomeTabAddOrEdit';
import HomeWidgetAddPopup from '../popup/home/HomeWidgetAdd';
import MoreOptions from '../formElements/MoreOptions';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class HomeTabsSubmenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.el = React.createRef();
    }

    // otevreni kontext. menu
    handleClick = () => {
        this.setState({open: true});
    };

    // zavreni kontext. menu
    handleClose = () => {
        this.setState({open: false});
    };

    handleTabAdd = () => {
        this.handleClose();

        // otevre popup okno na pridani noveho tabu
        sAction.popup(
            <HomeTabAddOrEditPopup way={this.props.way} name="" columns={1} add={true} />,
        );
    };

    handleWidgetAdd = () => {
        this.handleClose();

        // otevre popup okno na pridani noveho tabu
        sAction.popup(<HomeWidgetAddPopup />, {header: sAction.translate('LBL_ADD_DASHLETS', 'Home')});
    };

    handleCloneDashboard = () => {
        this.handleClose();

        // otevre popup okno na pridani aktualniho pohledu jinym uzivatelum
        sAction.openRolesPopup(sAction.translate('LBL_CLONE_DASHBOARD', 'Home'), (data) => {
            sAction.homeSetView(data);
        });
    };

    render() {
        const isAdmin = sAction.dataGet('conf/user/is_admin');

        const options = [
            {label: sAction.translate('LBL_ADD_PAGE', 'Home'), value: 'handleTabAdd', icon: 'icon-addPage'},
            {label: sAction.translate('LBL_ADD_DASHLETS', 'Home'), value: 'handleWidgetAdd', icon: 'icon-addWidget'},
        ];

        if (isAdmin) {
            options.push(
                {label: sAction.translate('LBL_CLONE_DASHBOARD', 'Home'), value: 'handleCloneDashboard', icon: 'icon-addLookUser'},
            );
        }

        return (
            <div className="homeTabsAddSubMenu" ref={this.el}>
                {this.props.item}
                <MoreOptions
                    width={200}
                    onRight={true}
                    fromRight={180}
                    onClick={(e, val) => this[val](e)}
                    options={options}
                >
                    <TooltipWrapper label={'LBL_MORE_OPTIONS'}>
                        <div className="MoreOptions icon-More" />
                    </TooltipWrapper>
                </MoreOptions>
            </div>
        );
    }
}

HomeTabsSubmenu.propTypes = {
    item: PropTypes.any,
    way: PropTypes.any,
};
