import React from 'react';
import FilterBasic from './filterBasic';
import classNames from 'classnames';

import sAction from 'sAction';
import Select from '../../formElements/Select';
import AcmDate from '../../formElements/AcmDate';

export default class FilterDate extends FilterBasic {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
        };

        this.input = [];
        this.customOperator = React.createRef();
    }

    /**
     *
     */
    addCustomFilterLineToView() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd('add', prefix + '/filter', {field: data.get('name'), operator: 'and', actCustom: true}, 'filterGroup');
            sAction.dsAdd('add', prefix + '/filter/' + index + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('add', way + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        }
    }

    /**
     * @param {string} key
     */
    deleteCustomFilterLineFromView(key) {
        const way = this.props.way;
        sAction.dataDelete(way + '/filters', key);
    }

    /**
     *
     */
    customOption() {
        this.props.parent.advanced();
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd('add', prefix + '/filter', {field: data.get('name'), operator: 'and', actCustom: true}, 'filterGroup');
            sAction.dsAdd('add', prefix + '/filter/' + index + '/filters', {type: null, value: ''}, 'filterParameter');
            sAction.dsProcess();
        }
    }

    /**
     * @param {string} field
     */
    addCustomFilter(field) {
        const prefix = this.props.prefix;
        const filters = [];
        const lines = this.getLines();
        let error = false;
        lines.forEach((line) => {
            const select = line.select;
            const text = line.input;
            filters.push({
                field: field,
                type: select,
                value: text,
            });
            if (!select) {
                error = true;
            }
        });
        if (error) {
            sAction.error(sAction.translate('LBL_SELECT_FILTER_TYPE'));
            return;
        }
        const operator = this.customOperator.current.value;
        const data = {
            field,
            filters,
            operator,
            prefix,
            reload: true,
        };
        sAction.addFilter(data);
    }

    /**
     * @param {KeyboardEvent} event
     */
    onKeyDown(event) {
        const data = this.props.data;
        if (event.keyCode === 13) {
            this.addCustomFilter(data.get('name'));
        }
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter;
        let custom;
        if (actFilter == null || !actFilter.actCustom) {
            custom = (
                <div onClick={() => this.customOption()} className={classNames(['filterButton', {filterButtonDisabled: actFilter}])}>
                    <div className="icon-pencil filterButtonIcon"/>
                    {sAction.translate('LBL_CUSTOM_FILTER')}
                </div>
            );
        } else {
            const customLines = [];
            const dateOptions = sAction.getDateFilterOptions();

            if (actFilter != null) {
                actFilter.filters.forEach((line, key) => {
                    const select = (
                        <Select
                            key={actFilter.field + '_sekect'}
                            defaultValue={line.type}
                            options={dateOptions}
                            onChange={(e) => {
                                this.updateLines(key, e.target.value, 'select');
                                this.input[key] = e.target.value;
                            }}
                        />
                    );

                    const disabledOptions = [];
                    dateOptions.forEach((value) => {
                        disabledOptions[value.value] = value.disabledInput;
                    });
                    const disabled = disabledOptions[this.input[key]];
                    const defaultValue = line.value ? line.value : null;

                    customLines.push(
                        <div className="filterLine" key={key}>
                            {select}

                            <AcmDate
                                key={actFilter.field + '_input'}
                                onChange={(e) =>
                                    this.updateLines(key, e, 'input')
                                }
                                disabled={disabled}
                                onKeyDown={(e) => {
                                    this.onKeyDown(e);
                                }}
                                value={defaultValue}
                            />
                            {customLines.length !== 0 && (
                                <div
                                    className="customFilterDeleteRow iconCancel"
                                    onClick={() => this.deleteCustomFilterLineFromView(key)}
                                />
                            )}
                        </div>,
                    );
                });
            }
            custom = (
                <div className="customFilter">
                    <div className="filterButton">
                        <div className="icon-pencil filterButtonIcon"/>
                        {sAction.translate('LBL_CUSTOM_FILTER')}
                    </div>
                    <div className="CustomFilterLinesContent">
                        <div className="CustomFilterLines">
                            <div key="customLines">{customLines}</div>
                            <div
                                className="filterLine customFilterAddLineButton"
                                onClick={() => this.addCustomFilterLineToView()}
                            >
                                +
                            </div>
                        </div>
                        <div className="customFilterLinesOperator">
                            <Select
                                key={actFilter.field + '_groupSelect'}
                                defaultValue={actFilter.operator}
                                myRef={this.customOperator}
                                options={[
                                    {value: 'and', label: sAction.translate('LBL_AND')},
                                    {value: 'or', label: sAction.translate('LBL_OR')}]}
                            />
                        </div>
                    </div>

                    <div
                        tabIndex="2"
                        onClick={() => this.addCustomFilter(data.get('name'))}
                        className="filterCustomFilterButton"
                    >
                        {sAction.translate('LBL_LIST_FILTER')}
                    </div>
                </div>
            );
        }
        let removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get('name'))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate('LBL_DELETE_FILTER')}
                </div>
            );
        }

        return (
            <div className="filterBox">
                <div
                    onClick={() => this.order(data, 0)}
                    className="filterButton"
                >
                    <div className="icon-arrowDownLight filterButtonIcon"/>
                    {sAction.translate('LBL_ORDER_NEW')}
                </div>
                <div
                    onClick={() => this.order(data, 1)}
                    className="filterButton"
                >
                    <div className="icon-arrowUpLight filterButtonIcon"/>
                    {sAction.translate('LBL_ORDER_OLD')}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
