/**
 * @param {object} data
 * @returns {object}
 */
export default function calActionContent(data) {
    const retVal = {};
    const panels = data.view.panels;
    const tabs = [];
    let recordName = null;
    for (const key in panels) {
        // eslint-disable-next-line no-prototype-builtins
        if (panels.hasOwnProperty(key)) {
            const value = panels[key];
            const tab = {
                name: key,
                rows: [],
            };

            value.forEach(function(el, rowIndex) {
                if (Array.isArray(el)) {
                    el.forEach(function(field) {
                        const name = typeof field === 'object' ? field.name : field;

                        if (tab.rows[rowIndex] == null) {
                            tab.rows[rowIndex] = [];
                        }

                        let def = data.def[name];
                        if (def == null) {
                            if (name.endsWith('_name')) {
                                const editName = name.substr(0, name.lastIndexOf('_name'));
                                def = data.def[editName];
                            }
                        }

                        if (name === 'duration_hours' && def.type === 'int') {
                            def.type = 'hoursMinutes';
                        }

                        if (name === 'name') {
                            recordName = data.record[name];
                        } else if (name === 'last_name') {
                            recordName = data.record['last_name']+' '+data.record['first_name'];
                        }
                        if (def != null) {
                            if (def.type === 'relate') {
                                def['id_value'] = data.record[def.id_name];
                            }
                        }

                        const filedToStore = {
                            name: name,
                            value: data.record[name],
                            def: def,
                        };

                        tab.rows[rowIndex].push(filedToStore);
                    });
                } else {
                    for (const index in el) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (el.hasOwnProperty(index)) {
                            const field = el[index];

                            if (tab.rows[rowIndex] == null) {
                                tab.rows[rowIndex] = [];
                            }

                            const filedToStore = {
                                name: field.name,
                                value: data.record[field.name],
                                def: data.def[field.name],
                            };

                            tab.rows[rowIndex].push(filedToStore);
                        }
                    }
                }
            });
            tabs.push(tab);
        }
    }
    retVal.recordName = recordName;
    retVal.tabs = tabs;
    // _________ SUBPANELS

    retVal.subpanels = data.subpanels;

    retVal.subpanels = [];
    retVal.actionButtons = [];

    return retVal;
}
