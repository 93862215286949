import moment from 'moment';
import React from 'react';
import Bool from 'ROOT/src/components/formElements/CheckBox';

/**
 *
 * @param value
 * @param def
 */
export default function getValueOfMergeField(value, def) {
    const name = def?.get('name');
    const type = def?.get('type');
    switch (type) {
        case 'datetime':
        case 'datetimecombo':
            return moment(value).format('DD. MM. YYYY HH:mm');
        case 'date':
            return moment(value).format('DD. MM. YYYY');
        case 'multienum':
            const options = this.app_strings[def.get('options')];
            const parsedValue = value.replace(/\^/g, '').split(',');

            value = [];
            parsedValue.forEach((element) => {
                const translatedName = options?.[element];
                if (translatedName) {
                    value.push(<div title={translatedName} className="mergeRecordMultiEnumLine"
                        key={element}>{translatedName}</div>);
                }
            });
            return value;
        case 'enum':
            const enumName = this.app_strings[def.get('options')]?.[value];
            if (enumName) {
                value = <div title={enumName} className="mergeRecordEnumLine" key={value}>{enumName}</div>;
            }
            return value;
        case 'decimal':
        case 'float':
        case 'currency':
            return this.formatNumber(value);
        case 'link':
            return '';
        case 'bool':
            value = <Bool
                defaultValue={value ? 1 : 0}
                readonly={true}
            />;
            return value;

        case 'id':
        case 'currency_id':

            if ([name, type].includes('currency_id')) {
                if (!value) {
                    value = '-99';
                }
                value = this.currencies[value]?.name ?? value;
            }
            return value;
        default:
            return value;
    }
}
