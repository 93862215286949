/**
 *
 */
export default function getDateRelativeFilterOptions() {
    return [

        {
            value: '{%DATE_TODAY%}',
            label: this.translate('LBL_DATE_OPERATOR_TODAY'),
            disabledInput: true,
        },
        {
            value: '{%DATE_YESTERDAY%}',
            label: this.translate('LBL_DATE_OPERATOR_YESTERDAY'),
            disabledInput: true,
        },
        {
            value: '{%DATE_LAST7DAYS%}',
            label: this.translate('LBL_DATE_OPERATOR_LAST7DAYS'),
            disabledInput: true,
        },
        {
            value: '{%DATE_LAST30DAYS%}',
            label: this.translate('LBL_DATE_OPERATOR_LAST30DAYS'),
            disabledInput: true,
        },
        {
            value: '{%DATE_TOMMOROW%}',
            label: this.translate('LBL_DATE_OPERATOR_TOMMOROW'),
            disabledInput: true,
        },
        {
            value: '{%DATE_NEXT7DAYS%}',
            label: this.translate('LBL_DATE_OPERATOR_NEXT7DAYS'),
            disabledInput: true,
        },
        {
            value: '{%DATE_NEXT30DAYS%}',
            label: this.translate('LBL_DATE_OPERATOR_NEXT30DAYS'),
            disabledInput: true,
        },
        {
            value: '{%DATE_LASTMONTH%}',
            label: this.translate('LBL_DATE_OPERATOR_LASTMONTH'),
            disabledInput: true,
        },
        {
            value: '{%DATE_THISMONTH%}',
            label: this.translate('LBL_DATE_OPERATOR_THISMONTH'),
            disabledInput: true,
        },
        {
            value: '{%DATE_NEXTMONTH%}',
            label: this.translate('LBL_DATE_OPERATOR_NEXTMONTH'),
            disabledInput: true,
        },
        {
            value: '{%DATE_LASTYEAR%}',
            label: this.translate('LBL_DATE_OPERATOR_LASTYEAR'),
            disabledInput: true,
        },
        {
            value: '{%DATE_THISYEAR%}',
            label: this.translate('LBL_DATE_OPERATOR_THISYEAR'),
            disabledInput: true,
        },
        {
            value: '{%DATE_NEXTYEAR%}',
            label: this.translate('LBL_DATE_OPERATOR_NEXTYEAR'),
            disabledInput: true,
        },
    ];
}
