/**
 *
 * @param e
 * @param props
 */
export default function detailOnKeyDown(e, props) {
    this.detailTempData[props.way];
    this.baseOnKeyDown(e);
    const keyCode = e.keyCode;

    if (e.ctrlKey === true && keyCode === 13) {
        const field = this.dataGet(props.way+'/tabs/'+props.data.activeTab+'/rows/0/0');
        this.fieldToEdit({name: field, prefix: props.way, way: props.way+'/fields/'+field});
        e.stopPropagation();
    }
    if (e.keyCode === 27) {
        document.getElementById('mainDiv')?.focus();
    }
}
