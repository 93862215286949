/**
 *
 * @param paramData
 */
export default function mergeAddField(paramData) {
    this.dsClear();
    paramData.forEach((child) => {
        if (child.value != '') {
            this.dsAdd('set', child.way+'/newRecord/'+child.field, child.value);
        }
    });
    this.dsProcess();
}
