/**
 * @param {object} reportWindowData
 * @param {object} recordData
 * @param {string} module
 */
export default function setSavedSearch(reportWindowData, recordData, module) {
    this.load();
    this.rest.post('search/' + module, reportWindowData, (returnData) => {
        if (returnData.status === 'ok') {
            let isNew = false;
            if (!recordData.id) {
                isNew = true;
            }
            recordData = returnData['record'];
            this.dataSet('reportWindow/metadata/id', recordData);
            if (this.dataGet('popup2/show')) {
                this.popupHide();
            }

            let contentTranslate = this.translate('LBL_SUCCESSFULLY_CREATED_SEARCH');
            let headerTranslate = this.translate('LBL_SUCCESSFULLY_CREATED_SEARCH_HEADER');
            if (!isNew) {
                contentTranslate = this.translate('LBL_SUCCESSFULLY_EDITED_SEARCH');
                headerTranslate = this.translate('LBL_SUCCESSFULLY_EDITED_SEARCH_HEADER');
            }

            this.popup(contentTranslate, {
                header: headerTranslate,
                buttons: [
                    {
                        label: this.translate('LBL_OK'),
                        callback: () => {
                            this.popupHide();
                        },
                    },
                ],
            });

            if (isNew) {
                this.setSearchMainModule(module);
            }
        }

        this.unLoad();
    });
}
