import sAction from 'sAction';

export default (prefix) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    const data = {
        parent_type: sAction.dataGet(prefix+'/module'),
        parent_id: sAction.getRecordId(),
        parent_name: sAction.dataGet(prefix+'/name'),
        to_addrs: e.target.dataset.email || sAction.dataGet(prefix+'/fields/email1/value'),
    };

    sAction.openEmailPopup(data);
};
