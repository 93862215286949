/**
 *
 */
export default function routeMerge() {
    if (this.dataGet('history/urls')?.size <= 1) {
        const mergeData = localStorage.getItem('merge_params');
        if (mergeData) {
            this.dsAdd('set', 'conf/load', true);
            this.mergeInit(JSON.parse(mergeData));
        }
    }
    this.dsProcess();
}
