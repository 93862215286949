import PropTypes from 'prop-types';
import React from 'react';

export default function RightPanelImage(props) {
    return (<div className="rightPanelImage">
        <div className="rightPanelImageTitle">{props.data.get('name')}</div>
        <img src={props.data.get('url')}/>
    </div>);
}

RightPanelImage.propTypes = {
    data: PropTypes.any,
};
