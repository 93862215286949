/**
 *
 * @param params
 */
export default function printQuote(params) {
    this.popup('printQuotePopup', {
        params: params,
        header: this.translate('LBL_SELECT_PRINT_TEMPLATE', 'Quotes'),
    });
}
