/**
 * Convert value to base64
 *
 * @param {*} value
 * @return {string}
 */
export default function toBase64(value) {
    console.log('FROM BASE64', value);  // eslint-disable-line
    let returnValue = null;
    if (typeof value == 'object') { // eslint-disable-line
        returnValue = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    } else {
        returnValue = btoa(unescape(encodeURIComponent(value)));
    }

    return returnValue;
}
