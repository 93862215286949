/**
 * @param {object} params
 * @this sAction
 * @TODO rest.post => rest.fetchData
 */
export default function deleteSubpanelRels(params) {
    const subpanel = this.dataGet(params.prefix+'/..');
    const parentData = this.dataGet(params.prefix+'/../../..');
    const recModule = subpanel.def.getIn(['rel', 'relName']);

    const relid = [];
    params.relid.forEach((record) => {
        relid.push(record.record);
    });
    params['relid'] = relid;

    params['relname'] = recModule;
    params['id'] = parentData.id;
    params['module'] = parentData.module;
    params['deleted'] = 1;
    this.load();
    this.rest.post('rels', params, (returnData) => {
        if (returnData.status === 'ok') {
            this.route();
        }
    });
}
