/**
 * @param {string} way
 * @param {object} data
 * @param {callback} callback
 */
export default function openDynamicEnum(way, data, callback) {
    let params = data?.def?.get('enumParams');
    if (!params) {
        const enumId = data.def?.get('options');
        const parent = data.def?.getIn(['relData', 'parent']);
        const fields = this.dataGet(way + '/..');
        const enums = {};
        fields.forEach((field) => {
            if (field.def?.get('type') === 'AcmDynamicEnum') {
                const id = field.def?.get('options');
                const value = field.value;
                enums[id] = value;
            }
        });
        params = {
            enum: enumId,
            parentValue: enums[parent],
            enums,
        };
    }
    const currentLanguage = this.dataGet('conf/language');

    this.rest.post('dynamicEnumList', params, (returnData) => {
        returnData['lines'] = returnData['lines'].sort((a, b) =>
            this.translate(String(a.name), params.enum).localeCompare(this.translate(String(b.name), params.enum)), currentLanguage);
        callback(returnData);
    }, false);
}
