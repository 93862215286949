import React from 'react';
import PropTypes from 'prop-types';

export default function EmptyField(props) {
    const style = {width: `${100 / (props.panelColumns || 2) * (props.fieldColumns || 1)}%`};

    return (
        <div
            className={`detailViwRow ${props.customClass??''}`}
            style={style}
        >
        </div>
    );
}

EmptyField.propTypes = {
    panelColumns: PropTypes.number.isRequired,
    fieldColumns: PropTypes.number,
    customClass: PropTypes.string,
};
