/**
 *
 */
export default function isDesktop() {
    if (!this.deviceType) { // zmena kvuli dashboardu na firmach.. chodilo sem undefined.. a dashboard firem nezobrazil
        return true;
    } else {
        return false;
    }
}
