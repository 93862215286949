/**
 * @param {object} params
 * @this sAction
 * @button DetailView/Opportunities
 */
export default function closeOpportunity(params) {
    this.popup('closeOpportunity', {
        params: params,
        header: this.translate('LBL_CLOSE_OPPORTUNITY_TITLE', 'Opportunities'),
    });
}
