import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

export default class ErrorMessage extends React.PureComponent {
    render() {
        let {children: msg, type} = this.props;

        if (typeof msg === 'string') msg = [msg];

        if (!msg.length) return '';

        let render = '';
        switch (type) {
            case 'form':
                render = (
                    <div className="formError">
                        <p>{sAction.translate('LBL_FORM_SUBMIT_ERROR')}:</p>
                        <ul>
                            {msg.map((error) => (
                                <li key={error} dangerouslySetInnerHTML={{__html: error}} />
                            ))}
                        </ul>
                    </div>
                );
                break;
            default:
                render = (
                    <div className="formError">
                        <p>{sAction.translate('LBL_ERROR')}:</p>
                        <ul>
                            {msg.map((error) => (
                                <li key={error} dangerouslySetInnerHTML={{__html: error}} />
                            ))}
                        </ul>
                    </div>
                );
        }

        return render;
    }
}

ErrorMessage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

ErrorMessage.defaultProps = {
    type: 'form',
};
