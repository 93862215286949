import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import sAction from 'sAction';
import Checkbox from '../formElements/CheckBox';

export default function ListViewToggleDefaultFilter({defaultFilter, prefix}) {
    const [status, setStatus] = useState(sAction.dataGet(prefix + '/customData/useDefaultFilter'));

    /**
     *
     */
    const handleClick = () => {
        sAction.dataSet(prefix + '/customData/useDefaultFilter', !status);
        sAction.reloadList(0, 1, prefix);
        setStatus(!status);
    };

    /**
     *
     * @param filter
     */
    const filterOperation = (filter) => {
        if (!filter.field) {
            return '';
        }
        const field = sAction.translate(sAction.dataGet(`view/fields/${filter.field}/def/vname`))?.replace(/:?$/, ': ');
        switch (filter.type) {
            case 'eq':
                return `${field} = "${filter.value}"`;
            case 'neq':
                return `${field} ≠ "${filter.value}"`;
            case 'lessThan':
                return `${field} < "${filter.value}"`;
            case 'moreThan':
                return `${field} > "${filter.value}"`;
            case 'cont':
                return `${field} ∋ "${filter.value}"`;
            case 'ncont':
                return `${field} ∌ "${filter.value}"`;
            case 'contArray':
                return `${field} ∈ "${filter.value}"`;
            case 'ncontArray':
                return `${field} ∉ "${filter.value}"`;
            case 'start':
                return `${field} = "${filter.value}-"`;
            case 'nstart':
                return `${field} ≠ "${filter.value}-"`;
            case 'end':
                return `${field} ≠ "${filter.value}"`;
            case 'null':
                return `${field} ${sAction.translate('LBL_FIELD_NULL')}`;
            case 'nnull':
                return `${field} ${sAction.translate('LBL_FIELD_NOT_NULL')}`;
            default:
                return filter.value;
        }
    };

    if (defaultFilter.has('tree')) {
        defaultFilter.delete('tree');
    }

    return (
        <React.Fragment>
            <Checkbox onChange={handleClick} defaultValue={status ? 1 : 0}/>
            <div className={'actionPanelIcon icon-filter defaultFilterIcon'}/>
            <div className="defaultFilterDisplay">
                {[...defaultFilter.values()].map((filter, key) => (
                    <div key={key}>
                        {filter.operandType === 'relate' ?
                            <>
                                <strong>
                                    {sAction.translate(sAction.dataGet(`view/fields/${filter?.name}/def/vname`))?.replace(/:?$/, ': ')}
                                </strong>
                                <a href={`#detail/${filter.module}/${filter.parentId}`}>
                                    {sAction.dataGet(`view/fields/${filter.name}/value`)}
                                </a>
                            </> : <>
                                {filterOperation(filter)}
                            </>
                        }
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

ListViewToggleDefaultFilter.propTypes = {
    defaultFilter: ImmutablePropTypes.mapOf(
        PropTypes.shape({
            operandType: PropTypes.string,
            name: PropTypes.string,
            module: PropTypes.string,
            parentId: PropTypes.string,
            field: PropTypes.string,
            type: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ])),
            ]),
        }),
        PropTypes.string,
    ).isRequired,
    prefix: PropTypes.string.isRequired,
};
