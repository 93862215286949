/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Users
 */
export default function userSetReplacement(data) {
    this.popup('userSetReplacement', {
        params: data,
        header: this.translate('LBL_SELECT_REPLACEMENT_TITLE', 'Users'),
    });
}
