/**
 *
 */
export default function isMobile() {
    if (this.deviceType === 'mobile') {
        return true;
    } else {
        return false;
    }
}
