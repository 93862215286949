import rest from './rest';
import HashManager from './HashManager';

/**
 * @param {object} paramData
 */
export default function init(paramData) {
    if (window.location.search !== '') {
        const urlParams = new URLSearchParams(window.location.search);
        let urlData = {};
        urlParams.forEach((val, key) => {
            urlData[key] = val;
        });
        processUrlRedirect(urlParams, urlData);

        urlData = this.toBase64(urlData);
        const r = urlParams.get('r');
        if (r != null) {
            const newUrl = window.location.origin+'/#'+r+'/'+urlData;
            const oldUrl = window.location.href;
            window.location.href = newUrl;
            // https://localhost:8080/%3Fr%3Dadmin%2Fcustom%2Fmsgraph  => https://localhost:8080/?r=admin/custom/msgraph
            // this console.log must be after redirecting
            console.log(`[INFO] redirecting from: ${oldUrl} >>> to: ${newUrl}`); // eslint-disable-line
        }
    }

    this.dataReducer = 'app';
    this.param = paramData;

    this.notificationInterval = null;
    this.currencies = null;
    this.customClass = null;
    this.colorTemplate = [
        '#898CFF',
        '#71E096',
        '#5AD0E5',
        '#BB96FF',
        '#FF89B5',
        '#F5A26F',
        '#DA97E0',
        '#EFCE7E',
        '#668DE5',
        '#90D4F7',
        '#ED6D79',
        '#FF96E3',
        '#93BFDC',
        '#E09399',
        '#B6D96E',
        '#FF9393',
        '#79CB9F',
        '#BDCCAA',
        '#5AD0E5',
        '#65D8CC'];
    this.detailTempData = {};
    // eslint-disable-next-line new-cap
    this.rest = new rest(this);
    this.HashManager = new HashManager(this);
    this.objectLoopInit();
    this.overwriteStyle();

    this.hashChangeEvent();
    this.rest.get('checksid', (data) => {
        this.initData(data);
        if (this.getViewName() === 'login') {
            this.illegallyChangeHash('#home');
        }
        this.dsProcess();
        this.route(true, data);
        this.renderReact();
        this.checkNewNotifications();
    });
}

/**
 * processUrlRedirect() - redirect from office365 oAuth
 * @param {array} urlParams
 * @param {object} urlData
 */
function processUrlRedirect(urlParams, urlData) {
    let coripoUrl = urlParams.get('coripo_url');
    if (!coripoUrl) {
        return;
    }

    // remove coripo_url from url used for redirect from office365 oAuth
    const baseUrl = window.location.href.split('?')[0];
    // redirect kvuli zpetne kompatibilite (cemat)
    coripoUrl = coripoUrl.replace('/Emails/', '/EmailClient/');

    let newUrl = `${baseUrl}#${coripoUrl}`;
    if (Object.keys(urlData).length > 1) {
        newUrl += '?';
        Object.keys(urlData).forEach((key) => {
            if (key === 'coripo_url') {
                return;
            }
            newUrl += `${key}=${urlData[key]}&`;
        });
        newUrl = newUrl.slice(0, -1);
    }

    window.location.replace(newUrl);
}
