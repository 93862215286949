/**
 *
 * @param id
 */
export default function reportWindowDeleteSearch(id) {
    if (id != '') {
        let data = this.dataGet('reportWindow');
        data = data.toJS();
        const url = data.module+'/'+id;

        this.load();
        this.rest.delete('search/'+url, {}, (returnData) => {
            if (returnData.status === 'ok') {
                this.popupHide();
            }
            this.unLoad();
        });
    }
}
