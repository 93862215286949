import Button from '../formElements/Button';
import sAction from 'sAction';
import React from 'react';
import * as PropTypes from 'prop-types';
import PureComponent from '../pure';
import EmailImportPopup from '../popup/email/EmailImportPopup';

export default class EmailDetailHeader extends PureComponent {
    reply = (replyMode) => {
        sAction.openEmailPopup({replyMode});
    };

    delete = (email, type) => {
        // Opravdu chcete smazat tento email?
        const msg = sAction.translate('LBL_EMAIL_DELETE_CONFIRM', 'Emails');

        sAction.confrim(msg, () => {
            sAction.popupHide();
            sAction.closeRightPanel();
            sAction.load();
            this.mark(email, type, () => {
                sAction.dataSet('view/activeEmail', {});

                if (sAction.dataGet('rightPanel/show')) {
                    sAction.closeRightPanel();
                    sAction.route();
                }
            });
            sAction.dataSet('view/activeEmail', {});
        });
    };

    mark = (email, type, callBack = undefined) => {
        sAction.rest.post('email/markEmail', {uids: [email.uid], type, ieId: email.ieId, folder: email.mbox}, function() {
            sAction.unLoad();

            callBack && callBack();
        });
    };
    showRelations = () => {
        sAction.rightPanelEmailRelations(this.props.data.uid);
        // sAction.popup(<EmailRelationsPopup id={this.props.data.uid} />);
    };
    openImportPopup = () => {
        const uids = this.props.data.uid;
        sAction.popup(<EmailImportPopup uid={uids} mbox={this.props.data.mbox} ieId={this.props.data.ieId} />);
    };
    close = () => {
        sAction.dataSet('view/activeEmail', {});
        sAction.dataSet('view/selectedEmail', null);
    };

    render() {
        return [
            (sAction.isMobile() && <div
                className="icon-Close rightPanelClose"
                onClick={() => this.close()}
            />),
            (
                <div className="viewActionPanel" key={1}>
                    <div className="viewActionPanelButtons">
                        <Button onClick={() => this.reply('reply')} className="hoverBlue">
                            <div className="actionPanelButtonIcon icon-emailReply" />

                            {/* Odpovědět*/}
                            {sAction.translate('LBL_EMAIL_REPLY')}
                        </Button>
                        <div className="viewPanelButtonsDelimiter" />
                        <Button onClick={() => this.reply('replyAll')} className="hoverBlue">
                            <div className="actionPanelButtonIcon icon-emailReplyAll" />
                            {/* Odpovědět všem*/}
                            {sAction.translate('LBL_EMAIL_REPLY_ALL')}
                        </Button>
                        <div className="viewPanelButtonsDelimiter" />
                        <Button onClick={() => this.reply('forward')} className="hoverBlue">
                            <div className="actionPanelButtonIcon icon-emailForward" />
                            {/* Přeposlat*/}
                            {sAction.translate('LBL_BUTTON_FORWARD', 'Emails')}
                        </Button>
                        {!this.props.data.is_sugarEmail && (
                            <React.Fragment>
                                <div className="viewPanelButtonsDelimiter" />
                                <Button onClick={this.openImportPopup} className="hoverBlue">
                                    <div className="actionPanelButtonIcon icon-download" />
                                    {/* Importovat do systému*/}
                                    {sAction.translate('LBL_EMAIL_ARCHIVE_TO_SUGAR', 'Emails')}
                                </Button>
                            </React.Fragment>
                        )}
                        <React.Fragment>
                            <div className="viewPanelButtonsDelimiter" />
                            <Button onClick={() => this.delete(this.props.data, 'deleted')} className="hoverRed">
                                <div className="actionPanelButtonIcon icon-deleteIcon" />
                                {/* Vymazat*/}
                                {sAction.translate('LBL_DELETE_INLINE', 'Emails')}
                            </Button>
                        </React.Fragment>
                        {(sAction.getModuleName() === 'EmailClient' && this.props.data.is_sugarEmail) && (
                            <React.Fragment>
                                <div className="viewPanelButtonsDelimiter" />
                                <Button onClick={this.showRelations} className="hoverBlue">
                                    <div className="actionPanelButtonIcon icon-emailRelations" />
                                    {/* Zobrazit vztahy*/}
                                    {sAction.translate('LBL_EMAIL_VIEW_RELATIONSHIPS')}
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            ),
        ];
    }
}

EmailDetailHeader.propTypes = {
    data: PropTypes.object,
};
