/**
 * report na full screen
 */
export default function toggleFullscreen() {
    // fullscreen enter
    if (!document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement && !document.webkitFullscreenElement) { // current working methods
        const el = document.querySelector('html');

        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        const open = this.isMobile() ? !this.dataGet('menu/open') : this.dataGet('menu/open');
        if (open) {
            setTimeout(() => {
                this.menuOpen();
            }, 100);
        }
    } else {
        // fullscreen exit
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
        if (!this.isMobile()) {
            this.menuOpen();
        }
    }
}
