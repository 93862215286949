import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-string-refs */

class DragAndDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startX: null,
            startY: null,
            startObjectX: null,
            startObjectY: null,
            startObjectWidth: null,
            startObjectHeight: null,
            target: null,
            collision: [],
            placeholder: null,
        };
        this.addEvent = null;
        this.dragAllow = true;
        this.parentScrollValue = 0;

        this.bodyZoom = 1;
        const body = document.querySelector('body');
        this.bodyZoom = window.getComputedStyle(body).zoom;
    }
    /**
     *
     * @param {*} data
     */
    moveStart(data) {
        /* if(this.props.moveStart != undefined){
        this.props.moveStart(data);
      } */
        data.element.parentNode.removeChild(data.element);
    }
    /**
     *
     * @param {*} data
     */
    moveEnd(data) {
    /*  if(this.props.moveEnd != undefined){
        this.props.moveEnd(data)
      }*/
        this.state.placeholder.parentNode.insertBefore(data.element, this.state.placeholder);
    //   this.state.placeholder.parentNode.removeChild(this.state.placeholder);
    }
    /**
     *
     * @param {*} data
     */
    afterEnd(data) {
        if (this.props.afterEnd != null) {
            this.props.afterEnd(data);
        }
    }
    /**
     *
     * @param {*} e
     */
    mouseDown(e) {
        let moveElement = null;
        if (!!this.dragAllow === true) {
            let handleClass = this.props.handleElementClass;
            const elementClass = this.props.elementsClass;
            if (handleClass == null) {
                handleClass = elementClass;
            }
            let finalHolder = null;
            const target = e.target;
            if (target.classList.contains('handleClass')) {
                finalHolder = target;
            } else {
                // eslint-disable-next-line no-global-assign
                parent = target.closest(handleClass);
                if (parent != null) {
                    finalHolder = parent;
                }
            }

            if (finalHolder != null) {
                if (handleClass !== elementClass) {
                    moveElement = finalHolder.closest(elementClass);
                } else {
                    moveElement = finalHolder;
                }

                if (moveElement != null) {
                    this.setMoveEvent(e, moveElement);
                }
            }

            if (moveElement == null) {
                const resizeHolderClass = this.props.resizeHolder;
                if (resizeHolderClass != null) {
                    const resizeElementClass = this.props.resizeClass;
                    if (resizeElementClass != null) {
                        const resizeElement = e.target.closest(resizeElementClass);
                        if (resizeElement != null) {
                            const resizeType = e.target.dataset.resizetype;
                            if (resizeType == null) {
                                // console.log('holder nemá určený resizeType');
                            } else {
                                this.setResizeEvent(e, resizeElement, resizeType);
                            }
                        }
                    }
                }
            }
        }
    }
    /**
     *
     * @param {*} e
     * @param {*} target
     */
    setMoveEvent(e, target) {
        const self = this;
        const playground = this.refs.playground;
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const dragHolderClass = this.props.containersClass.replace('.', '');
        // const elementClass = this.props.elementsClass.replace('.', '');

        const moveHolderCoordinates = playground.getBoundingClientRect();
        const targetCoordinates = target.getBoundingClientRect();

        const x = targetCoordinates.x - moveHolderCoordinates.x;
        const y = targetCoordinates.y - moveHolderCoordinates.y;
        const width = target.clientWidth;
        const height = target.clientHeight;

        const moveObject = this.refs.moveObject;
        moveObject.style.width = width + 'px';
        moveObject.style.height = height + 'px';
        moveObject.style.left = x + 'px';
        moveObject.style.top = y + 'px';
        moveObject.innerHTML = target.innerHTML;
        moveObject.style.display = 'flex';

        const placeholder = document.createElement('div');
        placeholder.classList.add('reportWindowMovePlaceholder');
        placeholder.style.width = '0px';
        placeholder.style.height = '0px';
        target.parentNode.insertBefore(placeholder, target);
        this.moveStart({element: target});
        const parent = this.refs.playground.parentElement;
        this.parentScrollValue = parent.scrollTop;

        const holders = document.getElementsByClassName(dragHolderClass);
        holders.forEachObject((holder) => {
            holder.classList.add('dragAndDropActiveContainer');
        });

        const colisions = this.getColosionArray();

        placeholder.style.width = width + 'px';
        placeholder.style.height = height + 'px';

        this.setState({
            startX: mouseX,
            startY: mouseY,
            startObjectX: x,
            startObjectY: y,
            target: target,
            collision: colisions,
            placeholder: placeholder,
            dragHolderClass: dragHolderClass,
        });

        this.addEvent = function(e) {
            self.mouseMove(e, function(data) {
                const startX = self.state.startObjectX;
                const startY = self.state.startObjectY;
                const newX = startX + data.x;
                const newY = startY + data.y;
                const moveObject = self.refs.moveObject;
                moveObject.style.left = newX + 'px';
                moveObject.style.top = newY + 'px';

                const placeholder = self.state.placeholder;
                const colisions = self.state.collision;
                // const posun = 0;
                colisions.forEach((child) => {
                    if (
                        child.x - 15 < newX &&
            child.endX > newX &&
            child.y - placeholder.clientHeight < newY &&
            child.endY + 20 > newY
                    ) {
                        child.target.parentNode.insertBefore(placeholder, child.target);
                    }
                });
            });
        };
        playground.addEventListener('mousemove', this.addEvent);
    }
    /**
     *
     * @param {*} e
     * @param {*} callback
     */
    mouseMove(e, callback) {
        const target = this.state.target;
        if (target != null) {
            let left = (e.clientX - this.state.startX);
            left = left * (((100-(this.bodyZoom*100))/100) + 1 );
            const parent = this.refs.playground.parentElement;
            const scrollDiferen = this.parentScrollValue - parent.scrollTop;
            let top = e.clientY - (this.state.startY + scrollDiferen);
            top = top * (((100-(this.bodyZoom*100))/100) + 1 );
            callback({
                x: left,
                y: top,
            });
        }
    }

    /**
     *
     */
    mouseUp() {
        if (window.Cypress) {
            return;
        }
        const moveHolder = this.refs.playground;
        const moveObject = this.refs.moveObject;
        const dragHolderClass = this.props.containersClass.replace('.', '');
        const holders = document.getElementsByClassName(dragHolderClass);
        holders.forEachObject((holder) => {
            holder.classList.remove('dragAndDropActiveContainer');
        });

        moveObject.style.display = 'none';
        moveHolder.removeEventListener('mousemove', this.addEvent);
        if (
            this.state.placeholder != null &&
      this.state.placeholder.parentNode != null
        ) {
            if (this.state.placeholder.parentNode.classList.contains('static')) {
                // empty
            } else {
                this.moveEnd({element: this.state.target, holders: document.getElementsByClassName(dragHolderClass)});
            }
            this.state.placeholder.parentNode.removeChild(this.state.placeholder);
        } else if (this.state.startObjectWidth != null) {
            this.moveEnd({element: this.state.target, resize: true});
        }
        this.setState({
            target: null,
            startObjectWidth: null,
            startObjectHeight: null,
        });
    }
    /**
     *
     * @param {*} e
     * @param {*} resizeElement
     * @param {*} resizeType
     */
    setResizeEvent(e, resizeElement, resizeType) {
        const self = this;
        const startWidth = resizeElement.clientWidth;
        const startHeight = resizeElement.clientHeight;
        const playground = this.refs.playground;

        const mouseX = e.clientX;
        const mouseY = e.clientY;
        this.setState({
            startX: mouseX,
            startY: mouseY,
            target: resizeElement,
            startObjectWidth: startWidth,
            startObjectHeight: startHeight,
        });
        this.addEvent = function(e) {
            self.mouseMove(e, function(data) {
                if (resizeType === 'width') {
                    const newWidth = startWidth + data.x;
                    resizeElement.style.width = newWidth + 'px';
                }
            });
        };
        playground.addEventListener('mousemove', this.addEvent);
    }

    /**
     *
     * @return {*[]}
     */
    getColosionArray() {
        const playground = this.refs.playground;
        const elementClass = this.props.elementsClass.replace('.', '');
        const dragHolderClass = this.props.containersClass.replace('.', '');
        const holders = document.getElementsByClassName(dragHolderClass);
        const moveHolderCoordinates = playground.getBoundingClientRect();

        const colisions = [];
        holders.forEachObject((holder) => {
            holder.children.forEachObject((child) => {
                if (child.classList.contains(elementClass)) {
                    const childCoordinates = child.getBoundingClientRect();
                    const x = childCoordinates.x - moveHolderCoordinates.x;
                    const y = childCoordinates.y - moveHolderCoordinates.y;
                    const data = {
                        target: child,
                        x: x,
                        endX: x + child.clientWidth,
                        y: y,
                        endY: y + child.clientHeight,
                    };
                    colisions.push(data);
                }
            });
        });

        return colisions;
    }

    render() {
        const style = {
            position: 'relative',
            height: 'auto',
        };
        if (this.props.minHeight !== false) {
            style['minHeight'] = '100%';
        }

        return (
            <div
                /* eslint-disable-next-line react/no-string-refs */
                ref="playground"
                onMouseDown={(e) => this.mouseDown(e)}
                onMouseUp={(e) => this.mouseUp(e)}
                style={style}
            >
                {this.props.children}
                <div
                    /* eslint-disable-next-line react/no-string-refs */
                    ref={'moveObject'}
                    className={'reportWindowMoveObject ' + this.props.moveObjectClass}
                />
            </div>
        );
    }
}

DragAndDrop.propTypes = {
    children: PropTypes.any,
    afterEnd: PropTypes.any,
    handleElementClass: PropTypes.any,
    elementClass: PropTypes.any,
    moveObjectClass: PropTypes.any,
    minHeight: PropTypes.any,
    containersClass: PropTypes.any,
    elementsClass: PropTypes.any,
    resizeClass: PropTypes.any,
    resizeHolder: PropTypes.any,
};
export default DragAndDrop;
