/**
 *
 * @param prefix
 * @param data
 */
export default function activitiesTimelineExtend(prefix, data) {
    const params = this.activitiesTimeLineParams(data);

    params.offset = data.records.size;
    this.dsClear();
    this.load();
    this.rest.post('getListActivities', params, (response) => {
        response.records.forEach((record) => {
            this.dsAdd('add', prefix + '/records', record);
        });
        this.dsAdd('set', 'conf/load', false);
        this.dsProcess();
    });
}
