/**
 * @this sAction
 * @button DetailView/Users
 */
export default function usrGoogleSignIn() {
    this.load();
    this.rest.post('googleSignIn', {}, (ret)=>{
        this.unLoad();
        if (ret.state) {
            window.open(ret.redirect_url, '_blank');
        }
    });
}
