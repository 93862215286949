/**
 * @param {function|null|object} onClose
 */
export default function popupHide(onClose) {
    const id = this.getPopupId();

    this.dsClear();
    this.dsAdd('set', `conf/popup${id}/show`, false);
    this.dsAdd('set', `conf/popup${id}/content`, null);
    this.dsAdd('set', `conf/popup${id}/data`, {});
    this.dsAdd('set', `conf/popup${id}/props`, null);
    this.dsAdd('set', `conf/popup${id}/filePath`, null);
    this.dsProcess();

    if (typeof onClose === 'function') {
        onClose();
    } else if (onClose && onClose.closeFnc && typeof onClose.closeFnc === 'function') {
        onClose.closeFnc();
    }
}
