/**
 *
 * @param state
 * @param prefix
 */
export default function toggleAll(state, prefix) {
    const akce = {
        type: 'TOGGLEALL',
        listViewPrefix: prefix,
        state: state,
    };
    this.dataSetCustom(akce);
}
