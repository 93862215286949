/**
 *
 * @param data
 */
export default function filesXhr(data) {
    const xhr = new XMLHttpRequest();
    const xdebug = !this.param.xdebugEnabled ? '' : '?XDEBUG_SESSION_START=1';
    xhr.open('POST', this.param.files + data.action + xdebug);
    xhr.setRequestHeader('sID', this.rest.getCookie('sID'));
    if (data.data.files) {
        xhr.setRequestHeader('upload-Field-Name', data.data.files[0].fieldName);
    }
    xhr.responseType = 'json';
    xhr.upload.onprogress = (e) => {
        const loaded = e.loaded;
        const total = e.total;
        const percent = (loaded / total)*100;
        if (data.progress !== undefined) {
            data.progress({loaded, total, percent});
        }
    };
    xhr.onreadystatechange = (rData) => {
        if (xhr.readyState == 2) {
            if (xhr.status != 200) {
                xhr.responseType = 'text';
            }
        }
        if (xhr.readyState === xhr.DONE) {
            if (xhr.status == 200) {
                if (data.success !== undefined) {
                    data.success(xhr.response);
                }
            } else {
                if (data.error != undefined) {
                    data.error(xhr.response);
                }
            }
        }
    };
    xhr.send(this.objectToFormdata(data.data));
}
