import React from 'react';
import MaterialDefault from './MaterialDefault';
import MaterialRadio from '@mui/material/Radio';

export default class Radio extends MaterialDefault {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
        };
    }
    /**
     *
     * @param {Event} e
     */
    onChange(e) {
        let state = e.target.checked;
        if (state && this.state.value) {
            state = false;
        }

        this.setState({
            value: state,
        });
        if (this.props.onChange != null) {
            this.props.onChange(state);
        }
    }
    render() {
        const attr = this.createAttr('acmRadio');

        return (
            <MaterialRadio
                onChange={(e) => this.onChange(e)}
                checked={this.props.checked ?? this.state.value}
                tabIndex="0"
                {...attr}
            />
        );
    }
}
