import React from 'react';
import sAction from 'sAction';
import PureComponent from '../pure';
import MergeEditField from './MergeEditField';

export default class NewRecordLine extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };
    }

    /**
     *
     * @param field
     */
    removeFromNewRecord(field) {
        const def = this.props.def;
        const way = this.props.way;
        sAction.mergeRemoveField(way, field);
        if (def.get('type') === 'relate' && def.get('source') === 'non-db') {
            sAction.mergeRemoveField(way, def.get('id_name'));
        }
    }

    onBlur = () => {
        this.setState({edit: false});
    };

    onEdit = () => {
        return;
        // je to zde nachystane, ale momentalne nemaji jit upravovat
        this.setState({edit: true});
    };

    render() {
        const value = this.props.value;
        const field = this.props.field;
        const def = this.props.def;
        const type = def.get('type');

        let field_display_value = '';
        if (this.state.edit || type == 'bool') {
            if (type=='bool') {
                this.onBlur = () => {};
            }
            field_display_value = (
                <MergeEditField onBlur={this.onBlur} def={def} field={field}
                    way={this.props.way} value={value}/>);
        } else {
            field_display_value = (
                <div onClick={this.onEdit}>
                    {value != undefined && value !== '' ? value : '\u00A0' }
                </div>);
        }

        return (
            <div className={!value ? 'mergeRecordFieldLine' : 'mergeRecordFieldLine withValue'}
                data-field={field}
            >
                <div className="mergeRecordText">
                    <div className="mergeRecordFieldHeader">
                        {this.props.label}
                        <div className="reportWindowSelectFields">{field + ' | ' + sAction.findTypeTranslation(type)}</div>
                    </div>
                    <div className="mergeRecordFieldValue">
                        {field_display_value}
                    </div>
                </div>
                <div className="mergeRecordButtons">
                    {!!value && (
                        <div className="icon-deleteIcon mergeAddFieldButton" onClick={() => this.removeFromNewRecord(field)} title={sAction.translate('LBL_DELETE')}/>
                    )}
                </div>
            </div>
        );
    }
}
