/**
 * @param {object} paramData
 * @this sAction
 * @button DetailView/acm_templateinvoicing
 * @render src/components/popup/auditLog/auditLog.js
 */
export default function showAuditLog(paramData) {
    this.popup('auditLog', {
        parentId: paramData['id'],
        module: paramData['module'],
        header: this.translate('LBL_CHANGE_LOG', 'Audit'),
    });
}
