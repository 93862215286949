/**
 *
 * @param way
 */
export default function customFieldEmail(way) {
    // natahnuti dat a uprava na pozadovany format pro backend
    const data = this.dataGet(way+'/fields/email1/def/emails');

    if (!data) return false;

    const module = this.dataGet(way+'/module');

    const prefix = module + '0emailAddress';

    const newData = {
        emailAddressWidget: 1,
        useEmailWidget: true,
    };

    newData[prefix+'InvalidFlag'] = [];
    newData[prefix+'OptOutFlag'] = [];
    newData[prefix+'OptOutFlag'] = [];
    newData[module+'_email_widget_id'] = 0;

    data.forEach((email, i) => {
        newData[prefix+i] = email.get('email_address').toLowerCase();
        newData[prefix+'Id'+i] = email.get('email_address_id');

        if (email.get('primary_address') === '1') {
            newData[prefix+'PrimaryFlag'] = prefix+i;
        }
        if (email.get('invalid_email') === '1') {
            newData[prefix+'InvalidFlag'].push(prefix+i);
        }
        if (email.get('opt_out') === '1') {
            newData[prefix+'OptOutFlag'].push(prefix+i);
        }
    });

    return newData;
}
