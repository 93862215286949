/**
 *
 * @param data
 */
export default function emailReply(data) {
    const newData = this.dataGet('view/customData').toJS();
    newData.replyMode = data.replyMode;

    this.openEmailPopup(newData);
}
