import sAction from 'sAction';

/**
 *
 */
export default function clearCalDetailStore() {
    const action = {
        type: 'CALENDARCLEARDATA',
        content: {},
        function: sAction,
    };

    sAction.dsClear();
    sAction.dsAdd('set', 'calendar/activeTab', 'detailMeeting');
    // sAction.dsAdd("set", "calendar/meetingView/orderLines", [])
    // sAction.dsAdd("set", "calendar/meetingView/customData/selectedOrderLines/", [])
    sAction.dsAddCustom(action);
    sAction.dsProcess();

    // po ulozeni noveho zaznamu prenacist externi data, aby byla aktualni a nedala se vytvorit schuzka se stejnym zadanim
    sAction.calPlanReload('external'); // nutne...
}
