/**
 *
 * @param dbParams
 */
export default function generateDbLink(dbParams = {}) {
    const urlParamsStr = '';

    this.rest.post('admin/generateDbLink', dbParams, (retData) => {
        if (retData.status) {
            window.open(retData.message?.data?.url);
        }
    });
}
