/**
 *
 * @param {string} module
 * @returns {
 *     {filter: {
 *         operands: [],
 *         operator: string,
 *     }|boolean|null,
 *     offset: number,
 *     alterName: {},
 *     groupByDate: array,
 *     columns: array,
 *     function: {},
 *     limit: number,
 *     groupBy: array,
 *     order: array}|boolean
 * }
 */
export default function reportWindowCreateBasic(module) {
    const filter = this.reportWindowCreateFilter();
    if (filter !== false) {
        const columns = this.dataGet('reportWindow/columns/active');
        const columnsToServer = [];
        columns.forEach((column) => {
            if (column.def !== undefined) {
                let relationship = column.def.get('relationship');
                if (relationship !== module) {
                    if (
                        relationship !== undefined &&
            relationship !== null &&
            relationship !== ''
                    ) {
                        relationship = relationship.split('/*');
                    }
                } else {
                    relationship = undefined;
                }

                columnsToServer.push({
                    field: column.name,
                    module: column.def.get('fieldModule'),
                    width: column.def.get('width'),
                    relationship: relationship,
                });
            }
        });

        // ________________________________________________________ Seřazení order podle priority
        const order = this.dataGet('reportWindow/orderBy');
        let orderList = [];
        let number = 0;
        order.entrySeq().forEach((e) => {
            const data = e[1];
            if (data.direction !== '') {
                let priority = data.priority;
                if (priority == null) {
                    priority = 1;
                }

                let relationship = data.relationship;
                if (relationship !== module) {
                    if (relationship !== undefined && relationship !== null) {
                        relationship = relationship.split('/*');
                    }
                } else {
                    relationship = undefined;
                }
                orderList[priority + '' + number] = {
                    field: data.field,
                    sort: data.direction,
                    module: data.module,
                    relationship: relationship,
                };
            }
            number += 1;
        });
        orderList = orderList.filter((val) => val);
        // ________________________________________________________ Seřazení order podle priority END

        // ________________________________________________________ Seřazení group podle priority
        this.clearNonValidGroupBy();
        const group = this.dataGet('reportWindow/groupBy');
        let groupList = [];
        number = 0;
        group.entrySeq().forEach((e) => {
            const data = e[1];
            if (data.active === true) {
                let priority = data.priority;
                if (priority == null) {
                    priority = 1;
                }

                let relationship = data.relationship;
                if (relationship !== module) {
                    if (relationship !== undefined && relationship !== null) {
                        relationship = relationship.split('/*');
                    }
                } else {
                    relationship = undefined;
                }

                groupList[priority + '' + number] = {
                    field: data.field,
                    module: data.module,
                    relationship: relationship,
                };
            }
            number += 1;
        });

        const groupByDatePriorityFix = {};
        let counter = 0;
        groupList = groupList.filter((val) => {
            if (!val) {
                return false;
            }
            if (!groupByDatePriorityFix[val.module]) {
                groupByDatePriorityFix[val.module] = {};
            }
            groupByDatePriorityFix[val.module][val.field] = counter++;

            return true;
        });

        // ________________________________________________________ Seřazení group podle priority END

        // ________________________________________________________ Nastaveni group by datum funkci

        const groupByDate = this.dataGet('reportWindow/dateGroupFunction');
        const groupDateList = [];
        number = 0;
        groupByDate.entrySeq().forEach((e) => {
            const data = e[1];
            if (data.groupByDate) {
                let relationship = data.relationship;
                if (relationship !== module && relationship) {
                    relationship = relationship.split('/*');
                } else {
                    relationship = undefined;
                }

                groupDateList[number] = {
                    field: data.field,
                    module: data.module,
                    groupByDate: data.groupByDate,
                    relationship: relationship,
                };
            }
            number += 1;
        });
        const groupByDateArray = [];
        groupDateList.forEach((val) => {
            if (!val) {
                return;
            }
            const orderByIndex = groupByDatePriorityFix[val.module][val.field];
            groupByDateArray[orderByIndex] = val;
        });

        // ________________________________________________________ Nastaveni group by datum funkci END

        // ________________________________________________________ Nastavení funkcí pro jednotlivé pole
        const functionList = {};
        if (groupList.length !== 0) {
            const fieldFunction = this.dataGet('reportWindow/fieldFunction');
            fieldFunction.entrySeq().forEach((e) => {
                const data = e[1];
                functionList[data.field] = data;
            });
        }
        // ________________________________________________________ Nastavení funkcí pro jednotlivé pole

        // ________________________________________________________ Nastavení funkcí pro jednotlivé pole
        const alterNameList = {};
        const alterNames = this.dataGet('reportWindow/alterName');
        alterNames.entrySeq().forEach((e) => {
            const data = e[1];
            alterNameList[e[0]] = data.vname;
        });
        // ________________________________________________________ Nastavení funkcí pro jednotlivé pole

        columnsToServer.forEach((column) => {
            if (
                functionList[column.field] !== undefined &&
        functionList[column.field].function !== ''
            ) {
                column.function = functionList[column.field].function;
            } else {
                column.function = null;
            }
        });

        return {
            limit: 25,
            offset: 0,
            columns: columnsToServer,
            order: orderList,
            groupBy: groupList,
            function: functionList,
            alterName: alterNameList,
            groupByDate: groupByDateArray,
            filter,
        };
    } else {
        return false;
    }
}
