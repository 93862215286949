import React, {Suspense, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default function DetailApiButtons(props) {
    const [Field, setField] = useState(React.lazy(() => import(`./ApiButtons/${props.type}.js`)));

    useEffect(() => {
        setField(React.lazy(() => import(`./ApiButtons/${props.type}.js`)));
    }, [props.type]);

    return (
        <>
            <div className="detailApiButtonContainer">
                <Suspense fallback="">
                    <Field
                        {...props}
                    />
                </Suspense>
            </div>
        </>
    );
}

DetailApiButtons.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    def: ImmutablePropTypes.map,
};
