/**
 *
 * @param way
 */
export default function emailsRefresh(way) {
    this.load();
    this.rest.post(
        'email/checkEmailProgress',
        {currentCount: 0, ieId: this.dataGet(way+'/selectedFolder/ieId')},
        (data) => {
            if (data.status === 'error') {
                this.unLoad();
                this.error(data.message);
            } else {
                this.routeEmail(true);
            }
        },
        false,
    );
}
