/**
 *
 * @param {string} module
 * @param {*} meetType
 * @return {{}}
 */
export default function getActivityColors(module, meetType = '') {
    const colors = {};
    switch (module) {
        case 'Meetings':
            // event.backgroundColor = "rgb(210, 229, 252)";
            switch (meetType) {
                case 'montaz':
                    colors.backgroundColor = '#3e9aa3';
                    colors.eventBackgroundColor = '#3e9aa3'; // kvuli mesicnimu pohledu
                    colors.borderColor = '#3e9aa3';
                    break;
                default:
                    colors.backgroundColor = '#D5F1FD';
                    colors.eventBackgroundColor = '#D5F1FD'; // kvuli mesicnimu pohledu
                    colors.borderColor = '#668DE5';
                    colors.textColor = '#437189';
                    break;
            }
            break;
        case 'Calls':
            colors.eventBackgroundColor = '#FEE4E5'; // kvuli mesicnimu pohledu
            colors.backgroundColor = '#FEE4E5';
            colors.borderColor = '#FABB93';
            colors.textColor = '#7B5D5F';

            break;
        case 'Tasks':
            colors.eventBackgroundColor = '#CFFACD'; // kvuli mesicnimu pohledu
            colors.backgroundColor = '#CFFACD';
            colors.borderColor = '#58C97E';
            colors.textColor = '#647B5D';
            break;
        case 'Emails':
            colors.eventBackgroundColor = '#e8c9f2'; // kvuli mesicnimu pohledu
            colors.backgroundColor = '#e8c9f2';
            colors.borderColor ='#e8c9f2';
            colors.textColor = '#e8c9f2';
            break;
        default:
            break;
    }

    colors.textColor = '#2D3748';
    return colors;
}
