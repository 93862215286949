/**
 *
 * @param content
 * @param data
 */
export default function openRightPanel(content = null, data = {}) {
    const reloadOnClose = this.dataGet('rightPanel/reloadOnClose');
    const reloadList = this.dataGet('rightPanel/data/reloadList');
    this.dataSet('rightPanel', {show: true, content, data}, 'RightPanel');
    this.dataSet('rightPanel/reloadOnClose', reloadOnClose);
    this.dataSet('rightPanel/saveOnClose', false);
    if (reloadList && ! reloadOnClose) {
        this.dataSet('rightPanel/reloadOnClose', reloadList);
    }
}
