import PropTypes from 'prop-types';
import React from 'react';

import {withStyles} from 'tss-react/mui'; // do not use @mui/styles it's deprecated
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';

import IconButton from '@mui/material/IconButton';

import Add from '@mui/icons-material/AddCircleOutline';
import Done from '@mui/icons-material/Done';
import TabContainer from '../../formElements/TabContainer';
import sAction from 'sAction';
import PureComponent from '../../pure';
import Loader from '../../loader';
import InputText from '../../formElements/InputText';
import {EmailPopupButtons} from '../email/shared/EmailPopupButtons';

// defaultni material styly, pozdeji vymazu
const ExpansionPanel = withStyles(
    MuiExpansionPanel,
    () => ({
        root: {
            'border': '1px solid rgba(0,0,0,.125)',
            'boxShadow': 'none',
            '&:before': {
                display: 'none',
            },
        },
        expanded: {
            margin: 'auto',
        },
    }),
);

const ExpansionPanelSummary = withStyles(
    ((props) => <MuiExpansionPanelSummary {...props} />),
    () => ({
        root: {
            'backgroundColor': 'rgba(0,0,0,.03)',
            'borderBottom': '1px solid rgba(0,0,0,.125)',
            'marginBottom': -1,
            'minHeight': 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    }),
);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(
    MuiExpansionPanelDetails,
    () => ({
        root: {
            padding: 0,
            display: 'block',
        },
    }),
);

// parent src\components\home\HomeTabsSubmenu.js
export default class HomeWidgetAddPopup extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activeTab: 0,
            activeModules: [],
            existingViews: [],
            loaded: false,
        };
        this.nameRef = React.createRef();
        this.urlRef = React.createRef();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.findData();

        const existingViews = [];
        const data = sAction.dataGet('view');
        data.tabs.forEach((tab) => {
            tab.cols.forEach((col) => {
                col.rows.forEach((row) => {
                    // console.log("TAB " + i1, tab, "\nCOL " + i2, col, "\nROW " + i3, row);
                    if (!row || !row.def) {
                        return;
                    }
                    if (row.def.get('type') === 'view') {
                        existingViews.push(row.data.get('viewId'));
                    }
                });
            });
        });
        this.setState({existingViews});
    }

    findData = () => {
        this.setState({loaded: false});

        sAction.rest.get('home/getwidgets', (data) => {
            if (!this._isMounted) return;

            this.setState({data, loaded: true});
        });
    };

    changeActiveTab = (newTab) => {
        if (newTab === this.state.activeTab) {
            return;
        }

        this.setState({
            activeTab: newTab,
            activeModules: [],
        });
    };

    handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    setActiveModule = (id) => {
        let a = [...this.state.activeModules];
        if (a.indexOf(id) === -1) {
            a.push(id);
        } else {
            a = a.filter((a) => a !== id);
        }
        this.setState({activeModules: a});
    };

    addWidget = (data) => {
        const ev = this.state.existingViews;

        sAction.homeWidgetAdd(data);
        const existingViews = [...ev, data.id];
        this.setState({existingViews});
    };

    deleteWidget = (viewId) => {
        const existingViews = [];
        const data = sAction.dataGet('view');
        data.tabs.forEach((tab, i1) => {
            tab.cols.forEach((col, i2) => {
                col.rows.forEach((row, i3) => {
                    // console.log("TAB " + i1, tab, "\nCOL " + i2, col, "\nROW " + i3, row);
                    if (!row || !row.def) {
                        return;
                    }
                    if (['view', 'customReport', 'calendar'].includes(row.def.get('type'))) {
                        if (row.data.get('viewId') === viewId) {
                            sAction.dataDelete(
                                'view/tabs/' + i1 + '/cols/' + i2 + '/rows',
                                i3,
                            );
                        } else {
                            existingViews.push(row.data.get('viewId'));
                        }
                    }
                });
            });
        });
        this.setState({existingViews});
    };

    savePopup = () => {
        const url = this.urlRef.current.value;
        if (!url) {
            return;
        }
        let name = this.nameRef.current.value;
        if (!name) {
            name = url.trim().replace(/(https?:\/\/(www\.)?)|(\/.*)/g, '');
            name = name.charAt(0).toUpperCase() + name.slice(1);
        }

        const data = {
            name,
            url,
            type: 'iframe',
        };
        if (url) {
            sAction.homeWidgetAdd(data);
        }
    };

    getIframeContent = () => {
        return (
            <React.Fragment>
                <table className="w100">
                    <tbody>
                        <tr>
                            <th>{sAction.translate('LBL_DASHLET_OPT_TITLE', 'Home')}</th>
                            <td>
                                <InputText
                                    autoFocus
                                    inputRef={this.nameRef}
                                    id="name"
                                    className="withBorder"
                                    type="text"
                                    defaultValue={this.props.name}
                                    fullWidth
                                    onKeyUp={(ev) => {
                                        if (ev.key === 'Enter') {
                                            this.savePopup();
                                            ev.preventDefault();
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>URL</th>
                            <td>
                                <InputText
                                    autoFocus
                                    inputRef={this.urlRef}
                                    id="url"
                                    className="withBorder"
                                    type="text"
                                    defaultValue={this.props.url}
                                    fullWidth
                                    onKeyUp={(ev) => {
                                        if (ev.key === 'Enter') {
                                            this.savePopup();
                                            ev.preventDefault();
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <EmailPopupButtons onSave={this.savePopup} />
            </React.Fragment>
        );
    };

    render() {
        const {data, activeTab, loaded, existingViews} = this.state;
        if (!loaded) return <Loader />;

        const tabs = [
            sAction.translate('LBL_REPORTS', 'Home'), // Reporty
            sAction.translate('LBL_CHARTS', 'Home'), // Grafy
            sAction.translate('LBL_CUSTOM_REPORTS'), // Reporty na míru
            'Web', // iframe na libovlnou adresu
        ];

        const renderMenu = (
            <TabContainer
                onChange={(e, value) => this.changeActiveTab(value)}
                value={activeTab}
                tabs={tabs}
            />
        );

        let content = '';
        if (loaded) {
            let category;
            if (activeTab === 0) category = 'report';
            else if (activeTab === 1) category = 'chart';
            else if (activeTab === 2) category = 'custom';
            else if (activeTab === 3) category = 'iframe';

            // const data = data[category];

            if (category === 'iframe') {
                content = this.getIframeContent();
            } else if (data[category] === undefined) {
                // Nenalezena žádná data.
                content = sAction.translate('LBL_NO_DATA_FOUND', 'Home');
            } else {
                content = Object.keys(data[category]).map((moduleName) => (
                    <div key={moduleName}>
                        {/* nazev modulu - Campaigns, Leads... */}
                        <ExpansionPanel
                            expanded={this.state.activeModules.indexOf(moduleName) !== -1}
                            onChange={() => this.setActiveModule(moduleName)}
                        >
                            <ExpansionPanelSummary>
                                <strong>
                                    {sAction.app_strings.moduleList[moduleName]} (
                                    {data[category][moduleName].length})
                                </strong>
                            </ExpansionPanelSummary>

                            {/* skryvaci obsah modulu */}
                            <ExpansionPanelDetails>
                                {/* klikatelne nazvy ulozenych hledani + popis */}

                                {data[category][moduleName].map((savedSearch) => {
                                    // podminka, jestli uz je budik vlozen / muzeme vlozit
                                    const canAdd =
                    existingViews.indexOf(savedSearch.id) === -1;
                                    let link;
                                    if (!canAdd) {
                                        link = (
                                            <IconButton
                                                onClick={() => this.deleteWidget(savedSearch.id)}
                                            >
                                                <Done />
                                            </IconButton>
                                        );
                                    } else {
                                        link = (
                                            <IconButton onClick={() => this.addWidget(savedSearch)}>
                                                <Add />
                                            </IconButton>
                                        );
                                    }
                                    return (
                                        <div className="homeSavedSearch" key={savedSearch.id}>
                                            <div className="grid-container">
                                                <div className="item1">{link}</div>

                                                <span className="item2 summary">
                                                    {savedSearch.name}
                                                </span>
                                                <div className="item3 description">
                                                    {savedSearch.description || <em>Bez popisu</em>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                ));
            }
        }

        return (
            <React.Fragment>
                <div className="detailView">{renderMenu}</div>

                <div className="homeSavedSearchContent">{content}</div>
            </React.Fragment>
        );
    }
}

HomeWidgetAddPopup.propTypes = {
    name: PropTypes.any,
    url: PropTypes.any,
};
