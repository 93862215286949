import React from 'react';
import PureComponent from '../../pure';
import sAction from 'sAction';
import propTypes from 'prop-types';

export default class RelateField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedCount: 0,
        };
    }
    /**
     *
     */
    openRelateListView() {
        const filterData = sAction.getStorage('fieldRelateSelected') || {};
        const fieldModule = this.props.fieldModule;
        const field = this.props.field;

        let selected = [];

        if (!filterData[fieldModule]) {
            filterData[fieldModule] = {};
        } else {
            selected = filterData[fieldModule][field] || [];
        }

        const paramData = {
            module: this.props.module,
            customData: {
                'listView/selected': selected,
            },
        };

        sAction.openRelatePopup(paramData, (returnData) => {
            if (returnData.selectedData) {
                this.props.onChange(returnData.selectedData);
            } else if (returnData.name) {
                this.props.onChange(returnData.name);
            }

            const id = typeof returnData.id !== 'string' ? returnData.id : [returnData.id];
            const fieldModule = this.props.fieldModule;
            const field = this.props.field;

            filterData[fieldModule][field] = id;

            this.setState({selectedCount: id.length});

            sAction.setStorage('fieldRelateSelected', filterData);
            sAction.popupHide();
        });
    }

    /**
     *
     */
    filterClear() {
        const filterData = sAction.getStorage('fieldRelateSelected') || {};
        const fieldModule = this.props.fieldModule;
        const field = this.props.field;

        if (!filterData[fieldModule]) {
            filterData[fieldModule] = {};
        } else {
            delete filterData[fieldModule][field];
        }

        sAction.setStorage('fieldRelateSelected', filterData);
        this.setState({selectedCount: 0});

        this.props.onChange('');
    }

    render() {
        return (
            <div
                className={'relatedFilterContainer'}
            >
                <div className={'label'}>{this.props.label}</div>
                <div className={'select'} onClick={() => this.openRelateListView()}>
                    <div>
                        {!this.state.selectedCount ? (
                            sAction.translate('LBL_SELECT', '') + '...'
                        ) : (
                            this.state.selectedCount + ' - ' +
                            sAction.translate('LBL_LISTVIEW_SELECTED_OBJECTS').replace(':', '').toLowerCase()
                        )}
                    </div>
                </div>
                <div className="iconset">
                    {!!this.state.selectedCount && (
                        <div className={'iconfa-trash'} onClick={() => this.filterClear()}></div>
                    )}
                    <div className={'icon-search'} onClick={() => this.openRelateListView()}></div>
                </div>
            </div>
        );
    }
}

RelateField.propTypes = {
    module: propTypes.any,
    label: propTypes.any,
    onChange: propTypes.any,
    fieldModule: propTypes.any,
    field: propTypes.any,
};
