/**
 * Kdyz se zmeni typ slevy %/ABS je potreba prepocitat cenikovou slevu
 * @param {string} way pr. "view/customData/productData/groups/0"
 * @param {string|number} newValue
 * @param {object} group
 * @this sAction
 */
export default function changeDiscountSelect(way, newValue, group) {
    this.dsClear();
    group.lines.forEach((line, lineIndex) => {
        this.dsAdd('set', `${way}/lines/${lineIndex}/discount_select`, newValue);
        if (group.discount_field_select === 'discount_amount') {
            let addVal;
            if (parseInt(newValue)) {
                const discount = (this.parseNumber(line.price_after_discount) / 100) * line.discount_amount;
                const tmpTotalPrice = this.parseNumber(line.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - line.list_price) / line.list_price);
            } else {
                addVal = line.list_price - (line.price_after_discount - line.discount_amount);
            }
            this.dsAdd('set', `${way}/lines/${lineIndex}/price_after_add_discount`, addVal);
        } else {
            let priceAfterDiscount = 0;
            if (parseInt(newValue)) {
                priceAfterDiscount = line.list_price * ((100 - group.deal_tot) / 100);
            } else {
                priceAfterDiscount = line.list_price - group.deal_tot;
            }
            this.dsAdd('set', `${way}/lines/${lineIndex}/discount_amount`, this.parseNumber(0));
            this.dsAdd('set', `${way}/lines/${lineIndex}/price_after_add_discount`, this.parseNumber(group.deal_tot));
            this.dsAdd('set', `${way}/lines/${lineIndex}/price_after_discount`, this.parseNumber(priceAfterDiscount));
        }
    });
    this.dsProcess();
}
