import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import AccountsTurnover from './AccountsTurnover';

export default class DetailAccountsTurnoverTab extends PureComponent {
    has_been_activated = false;

    render() {
        const active = this.props.active;

        // dokud neni tab aktivni, nebude se zbytecne renderovat komponenta a volat backend
        if (!active && !this.has_been_activated) {
            return '';
        } else {
            this.has_been_activated = true;
        }

        const way = this.props.way;
        const data = this.props.data;

        return (
            <div className={'detailViewTabContentBlok detailInvComp' + ((active) ? ' select' : '')}>
                <AccountsTurnover way={way} data={data} isInTab={true} active={active}/>
            </div>
        );
    }
}

DetailAccountsTurnoverTab.propTypes = {
    active: PropTypes.any,
    data: PropTypes.any,
    way: PropTypes.any,
};
