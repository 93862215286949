/**
 *
 * @param emails
 */
export default function emailsCheckFormat(emails) {
    if (!emails) return false;

    // viz https://emailregex.com/
    const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]*)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    const emails_split = emails.split(/\s*[,;]+\s*/);
    const email_errors = [];
    emails_split.forEach((email) => {
        const origEmail = email;
        if (email.indexOf('<') !== -1) {
            email = email.match(/<([^>]+)>/);
            if (!email) {
                email_errors.push(this.htmlEscape(origEmail));
                return;
            }
            email = email[1];
        }
        if (!regexp.test(email)) email_errors.push(email);
        // kdyz emaily nejsou oddeleny carkou
        else if (/@.*@/.test(origEmail)) email_errors.push(this.htmlEscape(origEmail));
    });

    return email_errors.length ? email_errors.join(', ') : false;
}
