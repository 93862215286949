import React from 'react';
import PureComponent from '../../pure';

import InputText from '../../formElements/InputText';

import RadioGroup from '../../formElements/RadioGroup';
import sAction from 'sAction';
import {EmailPopupButtons} from '../email/shared/EmailPopupButtons';

/**
 * @parent  src\components\home\HomeTabs.js
 * @parent  src\components\home\HomeTabSettingsSubmenu.js
 * @parent  src\components\home\HomeTabsSubmenu.js
 */
export default class HomeTabAddOrEditPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.props.columns,
        };

        this.state.tabCount = sAction.dataGet(this.props.way.replace(/\/tabs.*/, '/tabs')).size;
        if (this.props.add) {
            this.state.order = this.state.tabCount + 1;
        } else {
            this.state.oldOrder = parseInt(this.props.way.replace(/.*\//, ''));
            this.state.order = this.state.oldOrder + 1;
        }

        this.nameRef = React.createRef();
    }

    updateVal = (name, value) => {
        const pom = {};
        pom[name] = value;
        this.setState(pom);
    };

    savePopup = () => {
        sAction.popupHide();

        const name = this.nameRef.current.value;
        const columns = this.state.columns;
        let order = this.state.order - 1;

        let wasChanged = false;

        // pridani tabu
        if (this.props.add == true) {
            sAction.homeTabAdd(name, columns);
            if (order > this.state.tabCount) {
                order = this.state.tabCount;
            }
            if (this.state.tabCount != order) {
                sAction.homeTabOrderChange(this.props.way + '/' + this.state.tabCount, order);
            }
            wasChanged = true;
        } else {
            // prejmenovani zalozky
            if (this.props.name != name) {
                sAction.homeTabChangeName(this.props.way + '/name', name);
                wasChanged = true;
            }

            // uprava sloupcu
            if (this.props.columns != columns) {
                sAction.homeColumnsChange(this.props.way, columns);
                wasChanged = true;
            }

            // uprava poradi stranky
            if (order >= this.state.tabCount) {
                order = this.state.tabCount - 1;
            }

            if (this.state.oldOrder != order) {
                sAction.homeTabOrderChange(this.props.way, order);
                wasChanged = true;
            }
        }

        // ulozeni layoutu v pripade zmeny
        if (wasChanged) {
            sAction.homeLayoutSave();
        }
    };

    render() {
        const max = this.state.tabCount + (this.props.add ? 1 : 0);

        const options = {
            1: '1',
            2: '2',
            3: '3',
        };

        return (
            <div>
                <div className="acmPopupHeader">
                    {this.props.add == true ?
                        sAction.translate('LBL_ADD_PAGE', 'Home') :
                        sAction.translate('LBL_EDIT_PAGE', 'Home')}
                </div>
                {/* Přidat stránku || Upravit stránku */}
                <div className="acmPopupContent">
                    <div className="w100">
                        <table className="w100">
                            <tbody>
                                <tr>
                                    <th>{sAction.translate('LBL_DASHLET_OPT_TITLE', 'Home')}</th>
                                    <td>
                                        <InputText
                                            autoFocus
                                            inputRef={this.nameRef}
                                            id="name"
                                            className="withBorder"
                                            type="text"
                                            defaultValue={this.props.name}
                                            fullWidth
                                            onKeyUp={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    this.savePopup();
                                                    ev.preventDefault();
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{sAction.translate('LBL_PAGE_ORDER', 'Home') /* Pořadí stránky */}</th>
                                    <td>
                                        <input
                                            className="form-control-sm datetimehours pageOrderInput"
                                            type="number"
                                            min={1}
                                            max={max}
                                            step="1"
                                            value={this.state.order}
                                            onChange={(e) => this.updateVal('order', e.target.value)}
                                            name="order"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{sAction.translate('LBL_NUM_COLUMNS', 'Home') /* Počet sloupců */}</th>
                                    <td>
                                        <RadioGroup
                                            value={this.state.columns.toString()}
                                            options={options}
                                            onChange={(e) => this.updateVal('columns', e.target.value)}
                                            style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'row'}}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <EmailPopupButtons onSave={this.savePopup} />
            </div>
        );
    }
}
