/**
 *
 * @param params
 */
export default function printFileConfirmContracts(params) {
    // popup, kde se zeptame na typ faktury
    const {module} = params;

    let number = this.dataGet(params.prefix+'/fields/c_number').value;
    if (!number) {
        number = params.name;
    }
    params.number = number;

    const data = {
        data: params,
    };

    this.popup('ContractSelectTemplate', data);
}
