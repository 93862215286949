/**
 *
 * @param data
 */
export default function dataArraySet(data) {
    // [{way,value,like}] -> [{way: "value",value:"text",like:"Map"}]
    const akce = {
        type: 'DATASET',
        data: data,
    };
    this.dataDispatch(akce);
}
