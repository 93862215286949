/**
 *
 * @param params
 */
export default function printOpportunity(params) {
    // TODO tady se vyvola popup
    // console.log("print OPP", params);

    this.popup('printOpportunity', {
        params: params,
        header: this.translate('LBL_SELECT_PRINT_TEMPLATE_TITLE', 'Opportunities'),
    });
}
