/**
 *
 * @param prefix
 * @param oldCurrency
 * @param newCurrency
 */
export default function recalculateCustomFields(prefix, oldCurrency, newCurrency) {
    // Pokud je kurz string a hodnota v poli je nenulova pak proved vypocet s touto hodnotou
    let kurz = 0;

    const kurzField = this.dataGet(prefix + '/customData/customLines/info/kurz');
    if (typeof kurzField === 'string' && kurzField !== '') {
        kurz = parseInt(this.dataGet(prefix + '/fields/' + kurzField + '/value'), 10);
    }

    if ((typeof kurzField === 'string' && kurzField !== '') && kurz) {
        if (oldCurrency === '-99' || oldCurrency === -99) {
            recalculateFields(prefix, 1 / kurz, this);
        } else if (newCurrency === '-99' || newCurrency === -99) {
            recalculateFields(prefix, kurz, this);
        }
    } else if (kurzField === true || (typeof kurzField === 'string' && kurzField !== '')) {
        recalculateFromDB(this, prefix, oldCurrency, newCurrency);
        // this.rest.post('getConversionRate', {from: oldCurrency, to: newCurrency}, (returnData) => {
        //     if (returnData.status === true) {
        //         recalculateFields(prefix, returnData.messageData.conversionRate, this);
        //     }
        // });
    }
}

/**
 *
 * @param prefix
 * @param conversionRate
 * @param sAction
 */
function recalculateFields(prefix, conversionRate, sAction) {
    const way = prefix + '/customData/customLines/lines';
    const defs = sAction.dataGet(prefix + '/customData/customLines/def', null, true);

    sAction.dsClear();
    const lines = sAction.dataGet(way, null, true);
    if (!lines) {
        return;
    }
    lines.forEachObject((line, key) => {
        defs.convertFields.forEachObject((field, fieldKey) => {
            if (typeof field === 'string') {
                // Pokud je to string, pak rovnou prevedes
                const newValue = line[field] * conversionRate;
                sAction.dsAdd('set', way + '/' + key + '/' + field, newValue);
            } else if (typeof field === 'object') {
                // Pokud je to array, pak ma podminky a je treba je overit
                let shouldUpdate = true;
                field.forEachObject((value, check) => {
                    if (line[check] !== value) {
                        shouldUpdate = false;
                    }
                });

                if (shouldUpdate === true) {
                    const newValue = line[fieldKey] * conversionRate;
                    sAction.dsAdd('set', way + '/' + key + '/' + fieldKey, newValue);
                }
            }
        });
    });

    sAction.dsProcess();
}

/**
 *
 * @param sAction
 * @param prefix
 * @param oldCurrency
 * @param newCurrency
 */
function recalculateFromDB(sAction, prefix, oldCurrency, newCurrency) {
    const way = prefix + '/customData/customLines/lines';
    const defs = sAction.dataGet(prefix + '/customData/customLines/def', null, true);

    sAction.dsClear();
    const lines = sAction.dataGet(way, null, true);
    if (!lines) {
        return;
    }
    lines.forEachObject((line, key) => {
        defs.convertFields.forEachObject((field, fieldKey) => {
            if (typeof field === 'string') {
                // Pokud je to string, pak rovnou prevedes
                const newValue = sAction.currencyConvert(line[field], oldCurrency, newCurrency);
                sAction.dsAdd('set', way + '/' + key + '/' + field, newValue);
            } else if (typeof field === 'object') {
                // Pokud je to array, pak ma podminky a je treba je overit
                let shouldUpdate = true;
                field.forEachObject((value, check) => {
                    if (line[check] !== value) {
                        shouldUpdate = false;
                    }
                });

                if (shouldUpdate === true) {
                    const newValue = sAction.currencyConvert(line[fieldKey], oldCurrency, newCurrency);
                    sAction.dsAdd('set', way + '/' + key + '/' + fieldKey, newValue);
                }
            }
        });
    });

    sAction.dsProcess();
}
