/**
 *
 * @param name
 * @param data
 * @param callback
 */
export default function adminCustomAjax(name, data, callback) {
    this.adminXHR(name+'/'+data.url, data.data, (reponseData) => {
        callback(reponseData);
    });
}
