/**
 *
 * @param params
 */
export default function changeCellParentModule(params) {
    const way = `${params.way}/rows/${params.rowIndex}/records/${params.recordIndex}/module`;
    this.dsClear();
    this.dsAdd('set', way, params.newModule);
    this.dsProcess();
}
