/**
 *
 * @param params
 */
export default function readFile(params) {
    const prefix = params.prefix;
    const encoding = params.encoding ?? 'UTF-8';
    const file = this.temporaryData.file;
    const reader = new FileReader();
    const keepMapping = params.keepMapping ?? false;
    reader.onload = (e) => {
        if (reader.result !== null) {
            this.dsClear();
            this.dsAdd('set', prefix + '/fileContents', reader.result);
            this.dsProcess();
            this.parseFile({prefix: prefix, keepMapping: keepMapping});
        }
    };
    reader.readAsText(file[0], encoding);
}
