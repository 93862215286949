import React, {useEffect, useState} from 'react';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';

const TranslateAnimation = (props) => {
    const [style, animate] = useSpring(() => ({top: `${props.top}px`}), []);
    const [opacity, setOpacity] = useState(0);
    const [translateY, setTranslateY] = useState(50);

    useEffect(() => {
        setOpacity(1);
        setTranslateY(0);
        animate({
            left: `${props.left}px`,
            top: `${props.top}px`,
        });
    }, [style, props]);

    return (
        <animated.div className={props.className} style={{...style, left: props.left, opacity, transform: `translateY(${translateY}px)`, transition: 'transform 0.6s, opacity 0.6s'}}>
            {props.children}
        </animated.div>
    );
};

TranslateAnimation.defaultProps = {
    left: 0,
    right: 0,
    className: '',
};

TranslateAnimation.propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default TranslateAnimation;
