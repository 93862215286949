import React, {useEffect, Suspense} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailCard from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailCard';
import Loader from 'ROOT/src/components/loader';
import DetailCardHeader from 'ROOT/src/components/DetailViewNew/DetailCard/DetailCardHeader';
import DetailTimelineContainer from 'ROOT/src/components/DetailViewNew/DetailCard/DetailTimelineContainer';
import sAction from 'sAction';
import CustomTopPanel from 'ROOT/src/components/DetailViewNew/DetailCard/TopPanels/CustomTopPanel';

const DetailEmailCard = React.lazy(() => import('ROOT/src/components/DetailViewNew/DetailCard/CustomView/DetailEmailCard'));
const EditViewCard = React.lazy(() => import('ROOT/src/components/DetailViewNew/DetailCard/EditView/EditViewCard'));

export default function DetailCardContainer(props) {
    /**
     * @return {JSX.Element}
     */
    const returnDetailCard = () => {
        switch (props.mode) {
            case 'edit':
                return (
                    <>
                        {props.customData?.get('topPanel') != null && (
                            <CustomTopPanel
                                type={props.customData.get('topPanel').get('type')}
                                module={props.module}
                                panelData={props.customData.get('topPanel').get('panelData')}
                                id={props.id}
                                prefix={props.prefix}
                                mode={props.mode}
                            />
                        )}
                        <DetailTimelineContainer
                            timeline={props.timeline}
                            fields={props.fields}
                            id={props.id}
                            module={props.module}
                            prefix={props.prefix}
                            type={props.type}
                        />
                        <Suspense fallback={<Loader/>}>
                            <EditViewCard
                                prefix={props.prefix}
                                tabs={props.tabs}
                                fields={props.fields}
                                customData={props.customData}
                                acl={props.acl}
                                activeTab={props.activeTab}
                                readonly={props.readonly}
                                module={props.module}
                                id={props.id}
                            />
                        </Suspense>
                    </>
                );
            case 'email':
                return (
                    <Suspense fallback={<Loader/>}>
                        <DetailEmailCard
                            data={props.customData}
                            hideHeader
                            hideTitle
                        />
                    </Suspense>
                );
            default:
                return (
                    <>
                        {props.customData?.get('topPanel') != null && (
                            <CustomTopPanel
                                type={props.customData.get('topPanel').get('type')}
                                module={props.module}
                                panelData={props.customData?.get('topPanel')}
                                id={props.id}
                                prefix={props.prefix}
                                mode={props.mode}
                            />
                        )}
                        {props.view !== 'calendar' && (
                            <DetailTimelineContainer
                                timeline={props.timeline}
                                fields={props.fields}
                                id={props.id}
                                module={props.module}
                                prefix={props.prefix}
                                type={props.type}
                            />
                        )}
                        <DetailCard
                            prefix={props.prefix}
                            tabs={props.tabs}
                            fields={props.fields}
                            customData={props.customData}
                            acl={props.acl}
                            activeTab={props.activeTab}
                            readonly={props.readonly}
                            module={props.module}
                            attemptedSave={props.attemptedSave}
                            id={props.id}
                        />
                    </>
                );
        }
    };
    useEffect(() => {
        if (props.shouldCallLoadHook) {
            sAction.custom('load', {
                prefix: props.prefix,
                module: props.module,
            });
        }
    }, [props.shouldCallLoadHook]);
    useEffect(() => {
        sAction.fillDetailPredefinedFields(props.prefix, props.id);
        if (props.shouldCallLoadHook) {
            sAction.custom('load', {
                prefix: props.prefix,
                module: props.module,
            });
        }
    }, [props.id]);

    return (
        <div className="detailCard">
            <DetailCardHeader
                name={props.name}
                mode={props.mode}
                module={props.module}
                tableName={props.tableName}
                id={props.id}
                tags={props.tags}
                prefix={props.prefix}
                activities={props.activities}
            />
            <div className='detailView'>
                {returnDetailCard()}
            </div>
        </div>
    );
}

DetailCardContainer.propTypes = {
    prefix: PropTypes.string.isRequired,
    view: PropTypes.string,
    mode: PropTypes.string,
    tabs: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            id: PropTypes.number,
            name: PropTypes.string,
            type: PropTypes.string,
            active: PropTypes.bool,
            hidden: PropTypes.bool,
            rows: ImmutablePropTypes.listOf(
                PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
            ),
        }),
    ),
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    changes: ImmutablePropTypes.mapContains({
        customData: PropTypes.object,
        fields: ImmutablePropTypes.map,
        files: ImmutablePropTypes.list,
        forceChange: PropTypes.bool,
    }),
    customData: PropTypes.any, // EV currently ignores customData but they are forwarded anyway
    id: PropTypes.string,
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    activeTab: PropTypes.number.isRequired,
    activities: ImmutablePropTypes.mapContains({
        calls: PropTypes.string,
        emails: PropTypes.string,
        meetings: PropTypes.string,
        notes: PropTypes.string,
        tasks: PropTypes.string,
        urlquery: PropTypes.string,
    }),
    module: PropTypes.string.isRequired,
    name: PropTypes.string,
    readonly: PropTypes.bool,
    tableName: PropTypes.string,
    tags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
    timeline: ImmutablePropTypes.recordOf({
        field: PropTypes.string.isRequired,
        list: ImmutablePropTypes.mapOf(PropTypes.string),
        clickable: PropTypes.bool.isRequired,
        onClick: ImmutablePropTypes.map,
        title: ImmutablePropTypes.mapOf(PropTypes.string),
        colors: ImmutablePropTypes.mapOf(PropTypes.string),
    }),
    type: PropTypes.string,
    attemptedSave: PropTypes.bool,
    shouldCallLoadHook: PropTypes.bool,
};
