import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

const ListViewSearchHistoryItem = (props) => {
    const filter = props.filter;

    /**
     *
     * @param filter
     */
    const renderFilter = (filter) => {
        if (Object.prototype.hasOwnProperty.call(filter, 'operator')) {
            const opContent = [];
            filter.operands.forEach((op) => {
                opContent.push(renderFilter(op, filter.operands.length > 1));
            });
            return opContent.join(` ${sAction.translate('LBL_' + filter.operator.toUpperCase())} `);
        } else {
            return `"${filter.value}"`;
        }
    };

    return (
        <div className={props.className} onClick={props.onClick} value={props.tabIndex}>{renderFilter(filter)}</div>
    );
};

ListViewSearchHistoryItem.propTypes = {
    className: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
};

export default ListViewSearchHistoryItem;
