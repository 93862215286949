/**
 *
 */
export default function reloadListOnClose() {
    const prefix = 'rightPanel/reloadOnClose';
    const isList = this.dataGet('view/category') === 'list';
    if ( ! isList) {
        return;
    }

    this.dsClear();
    this.dsAdd('set', prefix, true);
    this.dsProcess();
}
