/**
 * @param {object} params
 * @this sAction
 * @button DetailView/Opportunities
 * @render src/components/popup/detailApi/OppDuplicatePopup.js
 */
export default function oppDuplicate(params) {
    this.popup('oppDuplicate', {
        params: params,
        header: this.translate('LBL_OPP_DUPLICATE', 'Opportunities'),
    });
}
