/**
 * @param {object} data
 * @this sAction
 * @button DetailView/Leads
 * @link #leadConvert
 */
export default function convertLead(data) {
    const state = this.dataGet(data.prefix+'/fields/status/value');

    if (['Converted', 'Dead', 'Canceled'].includes(state)) {
        this.error(this.translate('LBL_CANNOT_TRANSFER_LEAD', 'Leads'));
    } else {
        this.href('#leadConvert/'+data.id);
    }
}
