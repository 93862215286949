import React from 'react';
import InfoTable from './InfoTable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailDashboard from 'ROOT/src/components/DetailViewNew/DetailCard/TopPanels/DetailDashboard';
import sAction from 'sAction';

export default function CustomTopPanel(props) {
    switch (props.type) {
        case 'infoTable':
            return (
                <InfoTable
                    data={props?.panelData?.get('panelData')}
                    module={props.module}
                />
            );
        case 'dashboard':
        case 'customReport':
            if (!sAction.isDesktop()) {
                return null;
            }
            if (props.mode === 'edit') {
                return null;
            }
            return (
                <DetailDashboard
                    data={props.panelData}
                    id={props.id}
                    module={props.module}
                    way={`${props.prefix}/customData/topPanel/panelData`}
                    type={props.type}
                />
            );
        default:
            return null;
    }
}

CustomTopPanel.propTypes = {
    panelData: ImmutablePropTypes.map,
    type: PropTypes.string,
    module: PropTypes.string,
    prefix: PropTypes.string.isRequired,
    id: PropTypes.string,
    mode: PropTypes.string.isRequired,
};
