/**
 * @param {event} event
 * @param {string} vname
 * @param {string} module
 * @param {string} way
 * @TODO Dead code
 */
export default function editDetailEditTab(event, vname, module, way) {
    event.stopPropagation();
    this.popup('customModuleTranslate', {
        vname: vname,
        module: module,
        header: this.translate('LBL_EDIT_TAB_NAME'),
        way: way,
        callback: (params) => {
            this.setCustomTranslate(params, () => {
                this.dsClear();
                this.dsAdd('set', way + '/name', vname);
                this.dsProcess();
            });
        },
    });
}
