import React from 'react';
import PureComponent from '../pure';
import ToastItem from './ToastItem';

export default class Toast extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;

        const items = [];
        data.forEach((item, i) => {
            items.push(<ToastItem index={i} data={item} key={i} />);
        });

        return <div className="toastContainer">
            {items}
        </div>;
    }
}
