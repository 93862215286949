/**
 *
 * @param data
 */
export default function emailGetCitation(data) {
    // ocitovani puvodni zpravy

    const citation = '<div id=\'msgBody\'><p>&nbsp;</p><div id=\'signature\'></div></div>' +
    '<br />'+this.translate(
        'LBL_EMAIL_ORIGINAL_MESSAGE',
        'Emails',
    )+'<br />' +
    'Od: ' +
    this.htmlEscape(data.from_addr) +
    '<br />' +
    this.translate('LBL_TO', 'Emails') +
    ' ' +
    (data.toaddrs ? this.htmlEscape(data.toaddrs.replace(/<a .*<\/a>/g, '')) : '') +
    '<br />' +
    (data.cc_addrs ? this.translate('LBL_CC', 'Emails') + this.htmlEscape(data.cc_addrs) + '<br />' : '') +
    'Datum: ' +
    this.mysqlToDate(data.date_start + ' ' + data.time_start, true) +
    '<br />'+this.translate('LBL_EMAIL_SUBJECT', 'Emails')+': ' +
    data.name +
    '<br /><br />';

    let description = (data.description ? data.description : this.translate('LBL_EMAIL_NO_TEXT', 'Emails')) + '<br /><br />';

    if (/<body([^>]*)>/.test(description)) {
        description = description.replace(/<body([^>]*)>/, '<body$1>' + citation);
    } else {
        description = citation + description;
    }

    return description;
}
