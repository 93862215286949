import React from 'react';

export default function Url(props) {
    let value = props.value;
    if (value != null && !value.startsWith('http')) {
        value = 'http://'+value;
    }

    const lineRender = <a target="_blank" href={value} rel="noreferrer">{props.value}</a>;
    return (
        <div className={'newListViewLine ' + props.extraClass} onClick={props.onClick}>{lineRender}</div>
    );
}
