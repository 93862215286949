import React from 'react';
import PureComponent from '../../pure';

import AcmDate from '../../formElements/AcmDate';

export default class DateField extends PureComponent {
    render() {
        return (
            <AcmDate
                autoFocus={false}
                label={this.props.label}
                defaultValue={this.props.defaultValue}
                onChange={(e) => this.props.onChange(e)}
                onBlur={(e) => this.props.onChange(e.target.value)}
                freeFormat={true}
                from={this.props.from ?? null}
            />
        );
    }
}
