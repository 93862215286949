/**
 *
 * @param data
 */
export default function rightPanelMassUpdate(data) {
    if (!this.hasAccess(data.module, 'massupdate')) {
        this.error(this.translate('LBL_ERROR_LIST_EDIT_NOT_PERMITTED', 'ACL'));
        return;
    }

    let filterData = null;
    if (data.selected === 'all') {
        const list = this.dataGet(data.prefix);

        const savedSearch = list.actSavedSearch;
        filterData = this.reloadListParams({
            list,
            prefix: data.prefix,
            savedSearch,
            columns: false,
        });
        filterData['selected'] = data.selected;
        filterData['module'] = list.modul;
        filterData['type'] = list.type;
    }

    const paramsAcl = {
        'record': data.selected,
        'module': data.module,
        'filter': filterData,
        'checkMassUpdate': true,
    };

    this.load();
    this.rest.post('hasAccess', paramsAcl, (ret) => {
        this.unLoad();

        if (ret) {
            this.openRightPanel('load');
            this.dsClear();

            this.rest.get('defs/' + data.module, (resp) => {
                const prefix = 'rightPanel/data';

                const akceRP = {
                    type: 'MASSUPDATEFIELDS',
                    content: {
                        fields: resp,
                        module: data.module,
                        selected: data.selected,
                        filterData: filterData,
                    },
                    prefix: prefix,
                };

                this.dsAddCustom(akceRP);

                this.dsAdd('set', 'rightPanel/content', 'massUpdateView');

                this.dsProcess();
            });
        } else {
            this.error(this.translate('LBL_ERROR_LIST_EDIT_NOT_PERMITTED', 'ACL'));
        }
    });
}
