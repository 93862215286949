/**
 *
 * @param data
 */
export default function printFileWord(data) {
    data['action'] = 'printWord';

    this.dsClear();
    this.dsAdd('set', 'conf/popup/show', true);
    this.dsAdd('set', 'conf/popup/content', 'fileDownload');
    this.dsAdd('set', 'conf/popup/data/state', 'load');
    this.dsAdd('set', 'conf/popup/data/buttons', []);
    this.dsProcess();

    this.filesXhr({
        action: 'generateFileRequest',
        data: data,
        success: (ret) => {
            console.log('ret', ret);
            if (ret.status == true) {
                this.dsClear();
                this.dsAdd('set', 'conf/popup/data/state', 'init');
                this.dsAdd('set', 'conf/popup/data/token', ret.token);
                this.dsProcess();
            }
        },
    });
}
