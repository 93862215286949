import React from 'react';
import shallowEqual from 'react-pure-render/shallowEqual';

export default class PureComponent extends React.Component {
    /**
   *
   * @param nextProps
   * @param nextState
   */
    shouldComponentUpdate(nextProps, nextState) {
        return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
    }
    /**
     *
     * @param error
     * @param info
     */
    componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
        window.reactAppErrorComponent = this;
        this.setState({
            hasError: true,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this);
        //  console.log(this.props,prevProps)
    }
}
