/**
 * @param {string} filePath rest of path to component from components/popup
 * @param {object} props props forwarded to component
 */
export default function dynPopup(filePath, props) {
    document.activeElement.blur();
    const id = this.getNewPopupId();
    this.dsClear();
    this.dsAdd('set', 'conf/popup'+id+'/show', true);
    this.dsAdd('set', 'conf/popup'+id+'/content', 'dynamic');
    this.dsAdd('set', 'conf/popup'+id+'/props', props);
    this.dsAdd('set', 'conf/popup'+id+'/filePath', filePath);
    this.dsProcess();
}
