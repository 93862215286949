import detailDefault from '../../detailDefault';

export default class detailacm_Gifts extends detailDefault {
    load(sAction, data) {
    }

    update(sAction, data) {
        switch (data.field) {
            case 'acm_gifts_contactscontacts_ida':
                const params = {
                    module: 'Contacts',
                    id: data.value.id,
                    action: 'getAccountInfo',
                };
                sAction.rest.post('customAction', params, function(resp) {
                    if (resp.status) {
                        const fieldData = {
                            'way': data.prefix+'/fields/accounts_acm_gifts_1_name',
                            'name': 'accounts_acm_gifts_1accounts_ida',
                            'fieldName': 'accounts_acm_gifts_1_name',
                            'type': 'relate',
                            'value': {
                                'id': resp.message.data.id,
                                'name': resp.message.data.name,
                            },
                        };
                        sAction.saveField(fieldData, true);
                    }
                });
                break;
            case 'acm_gifts_acm_giftsstockacm_giftsstock_ida':
                const giftName = sAction.dataGet( data.prefix + '/fields/acm_gifts_acm_giftsstock_name/value');
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/name', giftName);
                sAction.dsAdd('set', data.prefix + '/fields/name/value', giftName);
                sAction.dsAdd('set', data.prefix + '/changes/fields/name', giftName);
                sAction.dsProcess();
                break;
        }
    }
}
