/**
 *
 * @param url
 * @param data
 * @param callback
 */
export default function customReportXHR(url, data, callback) {
    const xhr = new XMLHttpRequest();
    const query = window.location.hash.replace(/.*\?/, '');
    const xdebug = !this.param.xdebugEnabled ? '' : '?XDEBUG_SESSION_START=1';
    xhr.open('POST', this.param.reports + url + xdebug);
    // xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    // xhr.withCredentials = true;
    xhr.setRequestHeader('sID', this.rest.getCookie('sID'));
    xhr.onreadystatechange = (data) => {
        if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 401) {
                this.routeLogin();
                return;
            }
            if (xhr.status === 200) {
                callback(xhr.response);
            }
        }
    };
    if (data != null) {
        let param = '';
        data.forEachObject((val, key) => {
            if (val == null) return;
            if (Array.isArray(val)) {
                val.forEach( (valP) => {
                    param += '&'+key+'='+valP;
                });
            } else {
                param += '&'+key+'='+val;
            }
        });
        if (query) param = query + '&' + param;
        xhr.send(param);
    } else {
        xhr.send();
    }
}
