/**
 * @param {object} params
 * @param {object} paramData
 */
export default function popupDetail(params, paramData = {}) {
    const id = this.getNewPopupId('relateListView');

    this.dsClear();
    this.dsAdd('set', 'conf/popup' + id + '/content', 'load');
    this.dsAdd('set', 'conf/popup' + id + '/show', true);
    this.dsProcess();

    this.rest.fetchData(`detail/${params.module}/${params.record}`, 'POST', paramData).then((responseData) => {
        const data = responseData.data;
        this.HashManager.preprocessDefinitions(data).then((data) => {
            const content = {
                id: params.record, module: params.module, actionButtons: [],
            };

            const prefix = 'conf/popup' + id + '/data/detailView';

            data.menu.forEach((button) => {
                if (button.id === 'save_record_and_leave') {
                    button.newRecord = false;
                }
                if (button.id === 'back_to_list') {
                    button.onClick = 'popupHide';
                    button.params.push({
                        name: 'closeFnc', value: () => {
                            this.removeStorage(`changes-${params.module}-${this.dataGet('conf/user/id')}`);
                        },
                    });
                }
                if (button.id === 'save_record') {
                    button.onClick = 'saveRecordPopup';
                    button.params.push({
                        name: 'saveCallback', value: (ret) => {
                            if (params.saveCallback != null) {
                                params.saveCallback(ret);
                            }
                        },
                    });
                }
            });

            this.detailContent(data, content);
            content.subpanels = [];
            content.type = 'popup';

            const action = {
                type: 'PAGELOADDETAIL', content: content, prefix: prefix, function: this,
            };

            this.dsClear();
            this.dsAddCustom(action);
            this.dsAdd('set', 'conf/popup' + id + '/data/header', this.translate('LBL_MODULE_NAME', params.module));

            this.dsAdd('set', 'conf/popup' + id + '/content', 'popupDetail');
            if (data['customData'] && !data['customData']['productData']) {
                this.dsAdd('set', prefix + '/customData', data['customData']);
            }
            this.dsProcess();
        });
    }).catch((responseData) => {
        this.popupHide();
        const header = this.translate(responseData?.text) ?? this.translate('LBL_ERROR');
        const body = this.translate(responseData?.data?.errorDescription) ?? '';
        this.error(body, header);
    });
}
