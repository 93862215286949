/**
 *
 * @param name
 * @param dynamicEnum
 */
export default function getEnumForSelect(name, dynamicEnum = false) {
    let selectOptions = [];
    try {
        selectOptions = dynamicEnum ? this.app_strings['dynamicEnum'][name] : this.app_strings[name];
    } catch (e) {
        console.log('Chybí číselník ' + name);
    }

    if (!selectOptions) {
        console.log('Chybí číselník' + name);
        console.log(this.app_strings[name]);
        return [];
    }

    const optionsRender = [];
    selectOptions.forEachObject((value, key) => {
        optionsRender.push({value: key, label: value});
    });
    return optionsRender;
}
