/**
 *
 * @param data
 */
export default function filesNewRecord(data) {
    const tempEl = document.createElement('input');
    tempEl.setAttribute('type', 'file');
    tempEl.style = 'display: none';
    document.body.appendChild(tempEl);

    tempEl.addEventListener('change', (e) => {
        const formData = new FormData();
        tempEl.files.forEachObject((file, key) => {
            if (key != 'length') {
                formData.append('files[]', file, file.name);
            }
        });
        tempEl.parentNode.removeChild(tempEl);

        const treeData = this.dataGet(data.prefix+'/listViewTree');
        if (treeData !== null) {
            const activeCategory = treeData.get('activeCategory');
            const fieldName = treeData.get('field');
            const fieldData = {};
            fieldData[fieldName] = activeCategory;
            formData.append('fieldData['+fieldName+']', activeCategory);
        }
        data.rel.forEachObject((value, key) => {
            formData.append('relData['+key+']', value);
        });

        this.sendFiles(formData, (returnData) => {
            if (returnData.state == true) {
                this.reloadList(0, 1, data.prefix);
            }
        });
    });
    tempEl.click();
}
