/**
 * Translate template
 * @param {string}  key             translation key
 * @param {string}  module          module name
 * @param {object}  variables       placeholders to replace
 * @param {bool}    removeUnused    remove unused placeholders
 * @returns {string} translation
 */
export default function translate(key, module, variables, removeUnused = true) {
    let translation = this.translate(key, module);

    variables.forEachObject((replaceTo, replaceFrom) => {
        if (!/^[a-zA-Z0-9]+$/i.test(replaceFrom)) return;
        translation = translation.replaceAll(`{#${replaceFrom}#}`, replaceTo);
    });

    return removeUnused ?
        translation.replace(/((?=^|[^\\]){#[\w]+#})|(\\)({#[\w]+#})/g, '$3') :
        translation.replace(/(\\)({#[\w]+#})/g, '$2');
}
