// Keymap is config object for the library
export const Keymap = {
    'Default': {
        // 'Key_event_name' : 'pressed_key',
        'newRecord': {
            'osx': 'command + option + n',
            'windows': 'ctrl + alt + n',
            'linux': 'ctrl + alt + n',
            'other': 'ctrl + alt + n',
        },
        'saveRecord': {
            'osx': 'command + option + s',
            'windows': 'ctrl + alt + s',
            'linux': 'ctrl + alt + s',
            'other': 'ctrl + alt + s',
        },
        'returnToList': {
            'osx': 'command + option + backspace',
            'windows': 'ctrl + alt + backspace',
            'linux': 'ctrl + alt + backspace',
            'other': 'ctrl + alt + backspace',
        },
        'searchInList': {
            'osx': 'command + option + f',
            'windows': 'ctrl + alt + f',
            'linux': 'ctrl + alt + f',
            'other': 'ctrl + alt + f',
        },
        'lookSelect': {
            'osx': 'command + option + v',
            'windows': 'ctrl + alt + v',
            'linux': 'ctrl + alt + v',
            'other': 'ctrl + alt + v',
        },
        'saveAndExit': {
            'osx': 'command + option + shift + s',
            'windows': 'ctrl + alt + shift + s',
            'linux': 'ctrl + alt + shift + s',
            'other': 'ctrl + alt + shift + s',
        },
        'previous': {
            'osx': 'command + option + left',
            'windows': 'ctrl + alt + left',
            'linux': 'ctrl + alt + left',
            'other': 'ctrl + alt + left',
        },
        'next': {
            'osx': 'command + option + right',
            'windows': 'ctrl + alt + right',
            'linux': 'ctrl + alt + right',
            'other': 'ctrl + alt + right',
        },
        'exportList': {
            'osx': 'command + option + e',
            'windows': 'ctrl + alt + e',
            'linux': 'ctrl + alt + e',
            'other': 'ctrl + alt + e',
        },
        'listFirst': {
            'osx': 'command + option + shift + home',
            'windows': 'ctrl + alt + shift + home',
            'linux': 'ctrl + alt + shift + home',
            'other': 'ctrl + alt + shift + home',
        },
        'listLast': {
            'osx': 'command + option + shift + end',
            'windows': 'ctrl + alt + shift + end',
            'linux': 'ctrl + alt + shift + end',
            'other': 'ctrl + alt + shift + end',
        },
        'listSelectAll': {
            'osx': 'command + option + a',
            'windows': 'ctrl + alt + a',
            'linux': 'ctrl + alt + a',
            'other': 'ctrl + alt + a',
        },
        'listDeselectAll': {
            'osx': 'command + option + shift + a',
            'windows': 'ctrl + alt + shift + a',
            'linux': 'ctrl + alt + shift + a',
            'other': 'ctrl + alt + shift + a',
        },
        'tabFirst': {
            'osx': 'command + option + shift + left',
            'windows': 'ctrl + alt + shift + left',
            'linux': 'ctrl + alt + shift + left',
            'other': 'ctrl + alt + shift + left',
        },
        'tabLast': {
            'osx': 'command + option + shift + right',
            'windows': 'ctrl + alt + shift + right',
            'linux': 'ctrl + alt + shift + right',
            'other': 'ctrl + alt + shift + right',
        },

        // ---------- Global shortcuts -------------
        'sweetSpot': {
            'osx': 'command + option + space',
            'windows': 'ctrl + alt + space',
            'linux': 'ctrl + alt + space',
            'other': 'ctrl + alt + space',
        },
        'notifications': {
            'osx': 'command + option + 1',
            'windows': 'ctrl + alt + 1',
            'linux': 'ctrl + alt + 1',
            'other': 'ctrl + alt + 1',
        },
        'recentReports': {
            'osx': 'command + option + 2',
            'windows': 'ctrl + alt + 2',
            'linux': 'ctrl + alt + 2',
            'other': 'ctrl + alt + 2',
        },
        'reportWindow': {
            'osx': ['command + option + shift + F', 'command + option + 3'],
            'windows': ['ctrl + alt + shift + F', 'ctrl + alt + 3'],
            'linux': ['ctrl + alt + shift + F', 'ctrl + alt + 3'],
            'other': ['ctrl + alt + shift + F', 'ctrl + alt + 3'],
        },
        'calendar': {
            'osx': 'command + option + c',
            'windows': 'ctrl + alt + c',
            'linux': 'ctrl + alt + c',
            'other': 'ctrl + alt + c',
        },
        'home': {
            'osx': 'command + option + home',
            'windows': 'ctrl + alt + home',
            'linux': 'ctrl + alt + home',
            'other': 'ctrl + alt + home',
        },
        'shortcutsHelp': {
            'osx': 'command + i',
            'windows': 'ctrl + i',
            'linux': 'ctrl + i',
            'other': 'ctrl + i',
        },
        'hideSweetSpot': {
            'osx': 'esc',
            'windows': 'esc',
            'linux': 'esc',
            'other': 'esc',
        },
    },
};

// keymapConfing list of shortcuts that will be displayed in help modal (ShortcutsHelp.js)
export const keyMapConfing = [
    {
        icon: 'help-circle',
        label: 'LBL_SHORTCUTS',
        buttons: ['ctrl', 'i'],
    },
    {
        icon: 'addIcon',
        label: 'LBL_NEW_RECORD',
        buttons: ['ctrl', 'alt', 'n'],
    },
    {
        icon: 'saveIcon',
        label: 'LBL_SAVE',
        buttons: ['ctrl', 'alt', 's'],
    },
    {
        icon: 'undo',
        label: 'LBL_BACK_TO_LIST',
        buttons: ['ctrl', 'alt', '⌫'],
    },
    {
        icon: 'search',
        label: 'LBL_LIST_SEARCH',
        buttons: ['ctrl', 'alt', 'f'],
    },
    {
        icon: 'eye',
        label: 'LBL_LIST_LOOKS',
        buttons: ['ctrl', 'alt', 'v'],
    },
    {
        icon: 'saveIcon',
        label: 'LBL_SAVE_RECORD_LEAVE',
        buttons: ['ctrl', 'alt', 'shift', 's'],
    },
    {
        icon: 'f1fa',
        label: 'LBL_LIST_NEXT',
        buttons: ['ctrl', 'alt', '⇾'],
    },
    {
        icon: 'f1fb',
        label: 'LBL_LIST_PREVIOUS',
        buttons: ['ctrl', 'alt', '⇽'],
    },
    {
        icon: 'download',
        label: 'LBL_EXPORT',
        buttons: ['ctrl', 'alt', 'e'],
    },
    {
        icon: 'f217',
        label: 'LBL_LIST_SELECT_ALL',
        buttons: ['ctrl', 'alt', 'a'],
    },
    {
        icon: 'f216',
        label: 'LBL_LIST_DESELECT_ALL',
        buttons: ['ctrl', 'alt', 'shift', 'a'],
    },
    {
        icon: 'f1fa',
        label: 'LBL_NEXT_TAB',
        buttons: ['ctrl', 'alt', '⇾'],
    },
    {
        icon: 'f1fb',
        label: 'LBL_PREVIOUS_TAB',
        buttons: ['ctrl', 'alt', '⇽'],
    },
    {
        icon: 'search',
        label: 'LBL_SWEETSPOT',
        buttons: ['ctrl', 'alt', '␣'],
    },
    {
        icon: 'notify',
        label: 'LBL_NOTIFICATION',
        buttons: ['ctrl', 'alt', '1'],
    },
    {
        icon: 'recentRecords',
        label: 'LBL_RECENT_RECORDS_HEADER',
        buttons: ['ctrl', 'alt', '2'],
    },
    {
        icon: 'filter',
        label: 'LBL_VIEW_CREATING',
        buttons: ['ctrl', 'alt', '3'],
    },
    {
        icon: 'calendar',
        label: 'LBL_CALENDAR',
        buttons: ['ctrl', 'alt', 'c'],
    },
    {
        icon: 'home',
        label: 'LBL_HOME_SHORTCUT',
        buttons: ['ctrl', 'alt', 'home'],
    },
];
