import sAction from 'sAction';
import React from 'react';

/**
 * @param {array} view
 * @param {string} module
 * @param {srting} level
 * @param {array} options
 */
export default function useSaveEditView(view, module = 'Accounts', level = 'custom', options = []) {
    sAction.load();
    sAction.rest.fetchData('editView/saveEditView', 'POST', {
        view: view,
        module: module,
        level: level,
        options: options,
    }).then(({text}) => {
        sAction.unLoad();
        sAction.popup(sAction.translate(text), {
            header: sAction.translate('LBL_EV_SAVED'),
            buttons: [
                {
                    label: (
                        <>
                            <div className='icon-quill buttonIcon' />
                            {sAction.translate('LBL_EV_CONTINUE_EDIT')}
                        </>
                    ), callback: () => {
                        sAction.popupHide();
                    },
                },
                {
                    label: (
                        <>
                            <div className='icon-door buttonIcon' />
                            {sAction.translate('LBL_CONTINUE_TO_DETAIL')}
                        </>
                    ), callback: () => {
                        sAction.popupHide();
                        sAction.route();
                        // DM hot-fix for translation reload
                        location.reload();
                    },
                },
            ],
        });
    }).catch(({text}) => {
        sAction.unLoad();
        sAction.error(sAction.translate(text));
    });
}
