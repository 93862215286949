import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';

import WaveEffect from '../formElements/WaveEffect';
import SubMenuLines from './SubMenuLines';

import sAction from 'sAction';

class MenuButton extends PureComponent {
    /**
     *
     * @param {Object} e
     * @param {string} vname
     */
    showLabel(e, vname) {
        if (!this.props.menuOpen) {
            const target = e.target;
            const top = target.getBoundingClientRect().top;

            const label = document.querySelector('.menuHoverLabel');
            label.style.display = 'flex';
            label.style.top = top + 'px';
            label.innerHTML = vname;
        }
    }

    render() {
        const data = this.props.button;
        const activeModule = this.props.activeModule;
        const vname = sAction.app_strings.moduleList[data.get('name')];
        const iconColor = this.props.iconColor;
        const subMenuLines = data.subMenuLines;
        let renderClass = 'menuButton';
        let showSubmenu = false;
        let url = '#list/' + data.name;
        if (data.name.toLowerCase() === activeModule.toLowerCase()) {
            renderClass += ' select';
            showSubmenu = true;
        } else if (subMenuLines != null) {
            subMenuLines.forEach((line) => {
                if (line.get('value') === activeModule) {
                    renderClass += ' select';
                    showSubmenu = true;
                }
            });
        }

        if (data.name === 'Home') {
            if (!activeModule) {
                renderClass += ' select';
            }
            url = '#home';
        } else if (data.name === 'Calendar') {
            url = '#calendar';
        }

        let subMenuLinesRender = null;
        if (subMenuLines !== null && showSubmenu) {
            subMenuLinesRender = (
                <SubMenuLines menuOpen={this.props.menuOpen} activeModule={activeModule} data={subMenuLines} />
            );
        }

        return (
            <React.Fragment>
                <WaveEffect className="menuButtonContainer">
                    <a
                        id={data.name}
                        href={url}
                        className={renderClass}
                        title={vname}
                        onMouseEnter={(e) => this.showLabel(e, vname)}
                    >
                        <div
                            className={`icon-module icon-${data.name} icon-${data.icon} menuButtonIcon`}
                            style={{color: iconColor}}
                        />
                        <span>{vname}</span>
                    </a>
                </WaveEffect>
                {subMenuLinesRender}
            </React.Fragment>
        );
    }
}

MenuButton.propTypes = {
    activeModule: PropTypes.string,
    button: PropTypes.shape({
        get: PropTypes.func,
        icon: PropTypes.any,
        name: PropTypes.string,
        subMenuLines: PropTypes.shape({
            forEach: PropTypes.func,
        }),
    }),
    iconColor: PropTypes.any,
    menuOpen: PropTypes.bool,
    way: PropTypes.any,
};
export default MenuButton;
