import React, {useState, useEffect, useRef} from 'react';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';

const PopperAnimation = (props) => {
    const ref = useRef(null);
    const [pop, setPop] = useState(true);
    const [style, animate] = useSpring(() => ({height: `${props.startingHeight}px`, width: `${props.startingWidth}px`}), []);

    const open = props.open;

    useEffect(() => {
        animate({
            height: (open ? ref.current.offsetHeight : 0) + 'px',
            width: (open ? ref.current.offsetWidth : 0) + 'px',
        });
    }, [style, open, pop]);

    if (pop) {
        // "zobrazí" seznam v plné velikosti, aby jej popper mohl správně umístit
        setTimeout(() => {
            setPop(false);
        }, 30);
        return (
            <div className={props.className} style={{width: 'fit-content', visibility: 'hidden'}} ref={ref}>
                {props.children}
            </div>
        );
    }

    return (
        <animated.div className={props.className} style={{overflow: 'hidden', ...style}}>
            <div style={{width: 'fit-content'}} ref={ref}>
                {props.children}
            </div>
        </animated.div>
    );
};

PopperAnimation.defaultProps = {
    startingHeight: 0,
    startingWidth: 0,
    className: '',
};

PopperAnimation.propTypes = {
    open: PropTypes.bool.isRequired,
    startingHeight: PropTypes.number,
    startingWidth: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default PopperAnimation;
