import {Map} from 'immutable';

/**
 *
 * @param data
 * @param module
 * @param prefix
 */
export default function loadImportedList(data, module, prefix) {
    this.dsClear();
    const listData = data?.message?.data?.imported;

    const content = this.listContent(listData);
    content.orderBy = null;
    content.asc = null;
    const akce = {
        type: 'PAGELOADLIST',
        page: module,
        content: content,
        buttons: listData.menu,
        defaultFilter: new Map(listData.defaultFilter),
    };
    this.setStorage('prepareListStructure', akce);

    const view = this.getViewName();
    const filterData = this.getStorage('listFilterData');
    if (filterData) {
        filterData[view+module] = {};
        this.setStorage('listFilterData', filterData);
    }

    this.dsAddCustom(akce);

    this.dsAdd('set', prefix + '/category', 'list');
    this.dsProcess();
    sessionStorage.setItem('listImported', 'true');

    const list = this.dataGet(prefix);
    const offset = 0;
    const page = 1;
    const savedSearch = list.savedSearch;

    // pro spravnou funkcnost strankovani v rightpanelu
    const searchData = this.reloadListParams({list, prefix, savedSearch, offset, page});
    this.setStorage('listViewSearch', {module: module, data: searchData});
    this.dsClear();
    this.dsAdd('set', `${prefix}/customData`, {
        ignoredRows: data?.message?.data?.ignoredRows,
        fileToken: data?.message?.data?.newFile,
    });
    this.dsProcess();

    this.unLoad();
}
