/**
 *
 * @param paramData
 */
export default function reportWindowExport(paramData) {
    let fileName = paramData.name;
    if (fileName == '' || fileName == null) {
        fileName = 'export';
    }

    const data = this.dataGet('reportWindow');

    const columns = data.columns.active;
    const columnsDef = [];
    columns.forEach((column) => {
        const def = column.def.toJS();
        def.translate = this.translate(def.vname, def.fieldModule);
        columnsDef[def.fieldModule + '.' + def.name] = def;
    });

    const module = data.module;
    const listViewData = this.reportWindowCreateBasic(module);
    listViewData['selected'] = 'all';
    const formData = {
        filter: listViewData,
        module: module,
        exportType: paramData['type'],
        def: columnsDef,
        addId: paramData['addId'],
        totalRow: paramData['totalRow'],
        action: 'listviewExport',
        name: paramData['name'],
    };

    this.popup('fileDownload', {state: 'load'});
    this.filesXhr({
        action: 'generateFileRequest',
        data: formData,
        success: (ret) => {
            if (ret.status == true) {
                this.popup('fileDownload', {state: 'init', token: ret.token, header: this.translate('LBL_DOWNLOAD_FILE')});
            }
        },
    });
}
