import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import sAction from 'sAction';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import * as languages from '@mui/x-date-pickers/locales';
import * as languagesMonthsAndDays from 'date-fns/locale';
import InputText from './InputText';
import Event from '@mui/icons-material/Event';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class AcmDateTime extends React.Component {
    constructor(props) {
        super();
        this.state = {
            value: this.entryFormat(props.value),
            open: true,
        };
        this.input = React.createRef();
        this.dateTimeFormat = sAction.getDateFormat('datepicker')+' '+sAction.getTimeFormat('datepicker');
    }
    /**
     *
     * @param {Date} date
     * @returns {*}
     */
    entryFormat(date) {
        if (!date) {
            return null;
        }
        return date;
    }
    /**
     *
     * @param {Date} date
     * @param {Boolean} fromInput
     */
    onAccept(date, fromInput=false) {
        const dateObject = moment(date, 'DD.MM.YYYY HH:mm').toDate();
        const setDate = moment(fromInput ? dateObject : date).format('YYYY-MM-DD HH:mm:ss');
        this.setState({value: dateObject});
        this.props.onChange(setDate === 'Invalid date' ? '' : setDate);
    }

    /**
     *
     * @param {*} e
     * @param {*} type
     * @param {*} save
     */
    onKeyDown(e, type, save) {
        this.props.onKeyDown(e, type, save);
    }

    render() {
        const currentLanguage = sAction.dataGet('conf/language');
        const inputDate = moment(this.state.value).format('DD.MM.YYYY HH:mm') === 'Invalid date' ?
            '' : moment(this.state.value).format('DD.MM.YYYY HH:mm');

        return (
            <div>
                {this.state.open ?
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        localeText={languages[currentLanguage.replace('_', '')]?.components.MuiLocalizationProvider.defaultProps.localeText}
                        adapterLocale={currentLanguage === 'en_us' ? languagesMonthsAndDays.enGB :
                            languagesMonthsAndDays[currentLanguage?.slice(0, 2)]}
                    >
                        <MobileDateTimePicker
                            clearable
                            open={this.state.open}
                            className={'AcmDate ' + (this.props.className || null) + ' ' + (this.props.error ? 'error' : null)}
                            value={this.state.value ? moment(this.state.value).toDate() : null}
                            autoFocus={this.props.autoFocus ?? false}
                            onAccept={(date) => this.onAccept(date)}
                            onClose={() => this.props.onClose != null ? this.props.onClose() : this.setState({open: false})}
                            ampm={false}
                            format={this.dateTimeFormat}
                            inputRef={this.input}
                            displayWeekNumber={true}
                            error={this.props.error}
                            closeOnSelect={true}
                            slotProps={{
                                actionBar: {actions: ['clear', 'today', 'cancel', 'accept']},
                                dialog: {onKeyDown: (e) => this.onKeyDown(e, null, false)},
                            }}
                        />
                    </LocalizationProvider> :
                    <div className="acmDateFreeFormat">
                        <InputText
                            key={this.state.value}
                            label={this.props.label}
                            defaultValue={inputDate}
                            onBlur={(e) => this.onAccept(e.target.value, true)}
                        />
                        <TooltipWrapper label={'LBL_DATEPICKER_OK'}>
                            <span onClick={() => this.setState({open: true})} className='acmDateFreeFormatIconBox'>
                                <Event className='acmDateFreeFormatIcon'/>
                            </span>
                        </TooltipWrapper>
                    </div>}
            </div>

        );
    }
}

AcmDateTime.propTypes = {
    autoFocus: PropTypes.bool,
    autoOpen: PropTypes.bool,
    className: PropTypes.any,
    error: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClose: PropTypes.func,
    value: PropTypes.any,
};
