/* eslint-disable new-cap */
import {Map, List} from 'immutable';
import {DataConstructors} from '../../../DataConstructors';

/**
 *
 * @param {*} data
 * @returns {*}
 */
export default function processCustomData(data) {
    let customData = null;
    const module = data.module;
    if (data.customData === null) {
        return customData;
    }

    if (data.customType === 'leadConvert') {
        let relatedId = null;
        let relatedName = null;

        if (data.customData.related) {
            relatedId = data.customData.related.record,
            relatedName = data.customData.related.name;
        }
        customData = new DataConstructors['leadConvertCustomData']({
            beanMark: data.customData.beanMark,
            checked: data.customData.checked,
            required: data.customData.required,
            boundable: data.customData.boundable,
            related_id: relatedId,
            related_name: relatedName,
            candidates: new List(data.customData.candidates),
            allowCopyActivities: data.customData.allowCopyActivities || false,
        });
    } else if (module === 'acm_coripoReports') {
        customData = new DataConstructors['permisisonAccess']({
            roles: data.customData.roles,
            users: data.customData.users,
            allowAll: data.customData.allowAll,
        });
    } else if (module === 'Quotes') {
        const groups = [];

        data.customData.productData.groups.forEach(function(group) {
            const products = [];

            group.lines.forEach(function(line) {
                line.cost_price = parseFloat(line.cost_price);
                line.list_price = parseFloat(line.list_price);
                line.discount_price = parseFloat(line.discount_price);
                line.discount_amount = parseFloat(line.discount_amount);
                products.push(new DataConstructors['product'](line));
            });

            group.lines2.forEach(function() {
            // new DataConstructors["productField"];

            });

            groups.push(new DataConstructors['productGroup']({
                id: group.id,
                name: group.name,
                type: group.type,
                description: group.description,
                bundle_stage: group.bundle_stage,
                cenotvorba: group.cenotvorba,
                deal_tot: group.deal_tot,
                discount_field_select: group.discount_field_select,
                cenotvorba_discount_select: group.cenotvorba_discount_select,
                lines: List(products),
            }));
        });

        let danSazba = '';
        if (data.fields && data.fields.danova_sazba !== undefined) {
            danSazba = data.fields.danova_sazba.value;
        }

        customData = new Map({
            productData: new DataConstructors['productData']({
                groups: new List(groups),
                shipping: data.customData.productData.shipping,
                currency_id: data.customData.currency_id,
                cenotvorba: data.customData.productData.cenotvorba,
                dph: danSazba,
            }),
            productDataBackup: new DataConstructors['productData']({
                groups: new List(groups),
                shipping: data.customData.productData.shipping,
                cenotvorba: data.customData.productData.cenotvorba,
                currency_id: data.customData.currency_id,
                dph: danSazba,
            }),
        });
    } else if (module === 'Opportunities' && data.customData.productData !== undefined) {
        // let products = [];
        // data.customData.productData.lines.forEach(function(line){
        //   line.discount_price = parseFloat(line.discount_price);
        //   products.push(new DataConstructors["product"](line));
        // });
        //
        // customData = new Map({
        //   productData: new Map({lines : List(products)}),
        //   productDataBackup: new Map({lines : List(products)}),
        // })

    } else if (module === 'ACLRoles' || module === 'Users') {
        customData = new Map({
            html: '',
            recordId: data.customData.recordId,
            iframeName: data.customData.iframeName,
        });
    } else if (data.customData && data.customData.detailViewLines) {
        const detailViewLines = data.customData.detailViewLines;
        detailViewLines.defs = new List(detailViewLines.defs.map((def) => new Map(def)));
        detailViewLines.defaults = new Map(detailViewLines.defaults);
        detailViewLines.records = new List(detailViewLines.records.map((record) => new Map(record)));
        detailViewLines.sortedBy = new Map({name: null, desc: false});
        customData = {detailViewLines};
    }
    // Muze byt kdekoli z jakyhokoli duvodu
    if (data.customData && data.customData.customLines) {
        const customLines = data.customData.customLines;
        if (!customLines?.lines) {
            customLines.lines = [];
        }
        customData = {customLines};
    }

    return customData;
}
