/**
 *
 * @param {Object} translationData
 * @param {string} languages
 */
export default async function createTranslation(translationData, languages=null) {
    const paramdData ={languageData: translationData, languages: languages};
    await this.rest.fetchData('createTranslation', 'POST', paramdData )
        .then((res)=>{ })
        .catch((res)=>{
            console.warn(res);
        });
}
