import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import MaterialTooltip from '@mui/material/Tooltip';

/**
 * Wraps children component and show tooltip - on hover by default
 *
 * children acts as anchor - is required
 *
 * styles in acmTooltip.less
 *
 * wrapping div will be created for React components
 * more here: https://mui.com/material-ui/guides/composition
 *
 * MUI placing options should be enough
 * but if you need something custom (and there no other ways)
 * define css for tooltip and arrow (like done in .error)
 * and use in 'mode' prop
 *
 * default placing: bottom
 *
 * @param {any} props
 * @returns {JSX.Element}
 */
export default function TooltipWrapper(props) {
    const attr = {};
    const {
        children,
        disabled,
        label,
        mode,
        module,
        onClose,
        placement,
        show,
        title,
    } = props;

    let text = '';

    if (title) {
        text = title;
    } else if (label) {
        text = module ?
            sAction.translate(label, module) :
            sAction.translate(label);
    }

    if (show !== undefined) {
        attr.open = show;
    }

    attr.disableInteractive = onClose === undefined;

    const content = [
        <div key={'text'}>{text}</div>,
        (onClose && <div className="icon-Close" onClick={onClose} key={'close'}/>),
    ];

    return (
        <MaterialTooltip
            arrow
            classes={{
                arrow: 'acmTooltip ' + mode,
                tooltip: 'acmTooltip ' + mode,
            }}
            placement={placement || 'bottom'}
            title={(!disabled && text) ? content : ''}
            enterDelay={1000}
            {...attr}
        >
            {(React.isValidElement(children) && typeof children.type === 'function') ?
                <div>{children}</div> :
                children
            }
        </MaterialTooltip>
    );
}

TooltipWrapper.propTypes = {
    children: PropTypes.object, // anchor component to be wrapped
    disabled: PropTypes.bool, // hide tooltip despite hover-show
    label: PropTypes.string, // language label, like LBL_SOME_NAME...
    mode: PropTypes.string, // assign custom class, like 'error', 'warning'...
    module: PropTypes.string, // if presented, label will be searched in this module language file
    onClose: PropTypes.func, // show close btn and assign callback to it
    placement: PropTypes.string, // possible values is here: https://mui.com/material-ui/react-tooltip/
    show: PropTypes.bool, // show tooltip by condition, instead of hover
    title: PropTypes.string, // text in tooltip. Have higher priority, than label
};
