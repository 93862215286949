// import {calPlanReload} from "../../exportConfig"

/**
 *
 * @param {Object} params
 * @param {Function|undefined} callback
 * @see all usage of the function!!! (timeline, activity save button... )
 *
 */
export default function calSaveActivityDetailHelper(params, callback) {
    const prefix = params.prefix;
    const prefixInv = params.prefixInv;
    const canSave = this.dataGet(prefix + '/canSave');

    let id = this.dataGet(prefix + '/id');

    const module = this.dataGet(prefix + '/module');

    const fields = this.dataGet(prefix + '/fields');

    const editFields = this.dataGet(prefix + '/changes/fields');
    const invitees = this.dataGet(prefixInv + '/relatedInv').toJS();
    const selectedOrderLines = this.dataGet(prefix + '/customData/selectedOrderLines');

    const self = this;
    self.dsClear();

    const ignoredFields = ['id'];
    const visibleFields = [];
    const tabs = this.dataGet(prefix + '/tabs');

    tabs.forEach((tab) => {
        tab.panels.forEach((panel) => {
            panel.get('rows').forEach((row) => {
                row.fields.forEach((line) => {
                    if (tab.hidden) {
                        ignoredFields.push(line.name);
                    } else {
                        visibleFields.push(line.name);
                    }
                });
            });
        });
    });

    /* prefix + '/hiddenFields' neexistuje/bylo zruseno ... a zda se ze bez nahrady
    const hiddenFields = this.dataGet(prefix + '/hiddenFields');
    hiddenFields?.forEach((field) => {
        ignoredFields.push(field);
    }); */

    if (canSave) {
        if (!this.custom('beforeSave', {})) {
            // todo
        }

        let sendToCrm = true;
        fields.valueSeq().forEach((data) => {
            if (ignoredFields.includes(data.name)) {
                return;
            }
            if (!visibleFields.includes(data.name)) {
                return;
            }
            if (data.def) {
                if (data.def.get('required') && (data.value === '' || data.value === null)) {
                    self.dsAdd('set', prefix + '/fields/' + data.name + '/def/isInvalid', true);
                    self.dsAdd('set', prefix + '/fields/' + data.name + '/customClass', 'error');
                    sendToCrm = false;
                } else {
                    self.dsAdd('set', prefix + '/fields/' + data.name + '/def/isInvalid', false);
                    self.dsAdd('set', prefix + '/fields/' + data.name + '/customClass', '');
                }
            }
        });

        self.dsProcess();

        if (!sendToCrm) {
            // todo
        } else {
            self.load();

            if (id == null) {
                id = '';
            }

            const pomEditFields = {
                fields: editFields.toJS(),
                invitees: invitees,
                selectedOrderLines: selectedOrderLines,
                orderID: this.dataGet(prefix + '/customData/oID'),
            };
            this.rest.post('set/' + module + '/' + id, pomEditFields, (msg) => {
                const data = {
                    state: true,
                    data: msg,
                };
                let preserveChangesWay = prefix.split('/');
                preserveChangesWay.pop();
                preserveChangesWay = preserveChangesWay.join('/');
                // [PREDEFINED FIELDS] cal get init can reload fields and data now after save
                this.dataSet(`${preserveChangesWay}/preserveChangesAndFieldsOnViews`, false);
                const userID = this.dataGet('conf/user/id');
                this.popupHide(this.clearCalDetailStore.bind(null, params.pomWay));
                // prenacteni dat po pridani
                if (prefix.indexOf('calendar') !== -1) {
                    this.calGetEvents(true, params.prefix, userID, () => {
                        // prevent early reload
                    });
                }

                this.dataSet('calendar/updateEvent', msg.record);

                // this.calPlanReload('external');
                // this.clearCalDetailStore();

                if (callback !== undefined) {
                    callback(data);
                }
                self.unLoad();

                this.calPlanReload('external');
                this.clearCalDetailStore();
            });

            this.removeChangesFromLS(module, this.dataGet('conf/user/id'));
        }
    }
}
