/**
 *
 * @param groupIndex
 */
export default function toggleMenuGroup(groupIndex) {
    const path = 'menu/openGroups';
    const groupsCount = this.dataGet('menu/groups').size;
    const storage = this.getStorage('menuGroups') || Array(groupsCount).fill(false);
    storage[groupIndex] = !storage[groupIndex];
    this.setStorage('menuGroups', storage);
    this.dataSet(path, storage);
}
