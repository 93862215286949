import sAction from 'sAction';

/**
 *
 * @param action
 * @param event
 * @param params
 */
const handler = (action, event, params) => {
    const currentTab = sAction.dataGet(`${params.way}/activeTab`);

    switch (action) {
        case 'newRecord':
            if (params.scope.indexOf('popup') === -1) {
                sAction.detailNewRecord({
                    module: params.module,
                    way: params.way,
                });
            }
            break;
        case 'saveRecord':
            saveRecord(params.scope, params.way, params.module, false);
            break;
        case 'returnToList':
            if (params.scope === 'main') {
                window.location.hash = '#list/' + params.module;
            }
            break;
        case 'saveAndExit':
            saveRecord(params.scope, params.way, params.module, true);
            break;
        case 'previous':
            moveTab(params.way, currentTab, 'previous');
            break;
        case 'next':
            moveTab(params.way, currentTab, 'next');
            break;
        case 'tabFirst':
            moveTab(params.way, currentTab, 'first');
            break;
        case 'tabLast':
            moveTab(params.way, currentTab, 'last');
            break;
        default:
            break;
    }
};

/**
 * @desc saves record or saves and leaves record, based on exit param
 * @param {string} scope
 * @param {string} way
 * @param {string} module
 * @param {boolean} exit
 */
const saveRecord = (scope, way, module, exit) => {
    if (sAction.dataGet(`conf/${scope}/data/prefix`) === 'calendar') {
        document.getElementById('btn_save_record').click();
        return;
    }
    const saveData = {
        prefix: way,
        id: sAction.dataGet(`${way}/id`),
        module: module,
        canSave: true,
        type: sAction.dataGet(`${way}/type`),
        saveCallback: () => sAction.reloadSubpanelByWay('view', module),
    };
    if (scope.indexOf('popup') !== -1) {
        sAction.saveRecordPopup(saveData);
        return;
    }
    exit ? sAction.saveRecordLeave(saveData) : sAction.saveRecord(saveData);
};

/**
 * @desc Changes active detailview tab based on action
 * @param {string} way
 * @param {int} currentIndex
 * @param {string} action
 */
const moveTab = (way, currentIndex, action) => {
    let newIndex;
    const tabs = sAction.dataGet(`${way}/tabs`);
    const originalIndex = sAction.dataGet(`${way}/activeTab`);
    switch (action) {
        case 'previous':
            newIndex = currentIndex - 1 < 0 ? tabs.size - 1 : currentIndex - 1;
            if (tabs.getIn([newIndex, 'hidden'])) {
                moveTab(way, newIndex, action);
                return;
            }
            break;
        case 'next':
            newIndex = (currentIndex + 1) % tabs.size;
            if (tabs.getIn([newIndex, 'hidden'])) {
                moveTab(way, newIndex, action);
                return;
            }
            break;
        case 'first':
            newIndex = 0;
            if (tabs.getIn([newIndex, 'hidden'])) {
                moveTab(way, newIndex, 'next');
                return;
            }
            break;
        case 'last':
            newIndex = tabs.size - 1;
            if (tabs.getIn([newIndex, 'hidden'])) {
                moveTab(way, newIndex, 'previous');
                return;
            }
            break;
        default:
            return;
    }
    sAction.changeDetailTab(newIndex, way, originalIndex);
};

export default handler;
