/**
 *
 * @param module
 */
export default function getAccess(module) {
    const modulAcl = this.userPreferences.acl[module];
    if (modulAcl != undefined) {
        return Object.assign({}, modulAcl);
    }
    return modulAcl;
}
