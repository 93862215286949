import Immutable, {List, Map} from 'immutable';

import {DataConstructors} from '../DataConstructors';
import {customReducers} from './custom';

/**
 *
 * @param state
 * @param action
 */
export default function pbData(state, action) {
    const fronta = action.fronta;
    // var t0 = performance.now();
    fronta.forEach((f) => {
        const fakce = f.type;

        if (fakce == 'DATASET' || fakce == 'DATAADD' || fakce == 'DATADELETE') {
            const data = f.data;
            var element = data[0];
            if (element.way.indexOf('/') == -1) {
                // rozparsovaní cesty do pole
                element.way = [element.way];
            } else {
                element.way = element.way.split('/');
            }
        }
        if (fakce == 'DATASET') {
            // reducer pro nastavení hodnoty
            const value = returnImmutable(element);
            state = state.setIn(element.way, value); // nastavení hodnoty
        } else if (fakce == 'DATAADD') {
            // reducer pro přidání do listu
            let actList = state.getIn(element.way);
            if (actList == null) {
                actList = List();
            }
            const val = returnImmutable(element); // defaultní hodnota
            actList = actList.push(val);

            state = state.setIn(element.way, actList); // nastavení hodnoty
        } else if (fakce == 'DATADELETE') {
            // var actList = state.getIn(element.way);
            // var newList = [];
            // if (actList != null) {
            //   actList.forEach((el, i) => {
            //     if (i != element.index) {
            //       newList.push(el); // pouze ty co mají jiný index
            //     }
            //   });
            //   state = state.setIn(element.way, List(newList));
            // }

            element.way.push(element.index);
            state = state.deleteIn(element.way);
        } else {
            // ________________ CUSTOM REDUCERY
            customReducers.forEach((reducer) => {
                state = reducer(state, f);
            });
            // ________________ CUSTOM REDUCERY END
        }
    });
    /*
  var t1 = performance.now();
  console.log("Call to do reducers " + (t1 - t0) / 1000 + " seconds.");
  */
    console.log(state.toJS());
    return state;
}
/**
 *
 * @param element
 */
function returnImmutable(element) {
    let value = element.value;
    if (
        DataConstructors[element.like] != undefined &&
    typeof value === 'object'
    ) {
        value = returnMap(value);
        value = new DataConstructors[element.like](value); // pokud existuje nadefinovaný rekord tak se vytvoří
    } else {
        if (Array.isArray(value) === true) {
            // pokud je hodnota Array přetvoří se na List()
            const newList = [];
            value.forEach((val) => {
                if (
                    DataConstructors[element.inList] != undefined &&
          typeof val === 'object'
                ) {
                    val = returnMap(val);
                    newList.push(new DataConstructors[element.inList](val)); // pokud mají být hodnoty v listu typu record
                } else if (
                    typeof val === 'object' &&
          val !== null &&
          value.props === undefined
                ) {
                    if (Immutable.isImmutable(val)) {
                        newList.push(val);
                    } else {
                        val = returnMap(val);
                        newList.push(new Map(val)); // pokud jsou hodnoty objekt, založí se jako map()
                    }
                } else {
                    newList.push(val);
                }
            });
            value = List(newList); // vytvoření listu
        } else if (
            typeof value === 'object' &&
      value !== null &&
      value.props === undefined
        ) {
            // pokud je value typu objekt vytvoří se map()
            if (!Immutable.isImmutable(value)) {
                value = returnMap(value);
                value = new Map(value);
            }
        }
    }
    // console.log(value);
    return value;
}
/**
 *
 * @param object
 */
function returnMap(object) {
    for (let [k, v] of Object.entries(object)) {
        if (Array.isArray(v) === true) {
            v = returnMap(v);
            object[k] = List(v);
        } else if (typeof v === 'object' && v !== null && v.props === undefined) {
            if (!Immutable.isImmutable(v)) {
                v = returnMap(v);
                object[k] = new Map(v);
            } else {
                object[k] = v;
            }
        } else {
            object[k] = v;
        }
    }
    return object;
}
