/**
 *
 * @param {string} composedStreet
 * @param {Map} item
 * @param {bool} readonly
 * @param {string} fieldName
 * @param {string} prefix
 * @param {string} way
*/
export default function openOpenStreetMapPopup(composedStreet, item, readonly, fieldName, prefix, way) {
    let streetDecomposed = composedStreet;
    const regCisloDomu = new RegExp('( [0-9]*/[0-9]*)$');
    let cisla = streetDecomposed.match(regCisloDomu)?.[0]?.trim();
    if (cisla) {
        cisla = cisla?.split('/');
        streetDecomposed = streetDecomposed.replace(regCisloDomu, '');
    } else {
        const regOnlyNumber = new RegExp('^[0-9]*$');
        cisla = streetDecomposed.match(regOnlyNumber);
        streetDecomposed.replace(regOnlyNumber, '');
    }

    item = item.set('ulice', streetDecomposed)
        .set('x', item.get('gpsX'))
        .set('y', item.get('gpsY'))
        .set('cislo_dom', cisla?.[0] ?? '')
        .set('cislo_orient', cisla?.[1] ?? '');
    this.popup('openStreetMap', {
        item: item,
        x: item.x,
        y: item.y,
        name: composedStreet,
        sidebar: true,
        sidebarSearch: true,
        header: this.translate('LBL_MAP'),
        readonly,
        callback: (data) => {
            this.saveAcmAddressField(data, {name: fieldName}, prefix, way);
        },
    });
}
