import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import Loader from 'ROOT/src/components/loader';
import ImmutablePropTypes from 'react-immutable-proptypes';

const PermissionAccess = React.lazy(() => import('ROOT/src/components/detailView/Permissions/PermissionAccess'));
const ACLRoles = React.lazy(() => import('ROOT/src/components/detailView/ACLRoles/ACLRoles'));
const OppProductComponent = React.lazy(() => import('ROOT/src/components/detailView/Opportunities/OppProductComponent'));
const InviteesContainer = React.lazy(() => import('ROOT/src/components/popup/calendarWindow/inviteesContainer'));
const ProductComponent = React.lazy(() => import('ROOT/src/components/detailView/Quotes/ProductComponent'));

export default function DetailSpecialPanelsContainer(props) {
    const opportunitiesSupanel = sAction.dataGet('conf/opportunities_subpanel');
    if (!props.customData) {
        return null;
    }
    let isPopup = false;
    if (props.way.includes('popup')) {
        isPopup = true;
    }
    switch (props.module) {
        case 'acm_coripoReports':
            return (
                <Suspense fallback={<Loader/>}>
                    <PermissionAccess
                        way={props.prefix}
                        acl={props.acl}
                        data={props.customData}
                    />
                </Suspense>
            );
        case 'ACLRoles':
            return props.customData.get('recordId') && (
                <Suspense fallback={<Loader/>}>
                    <ACLRoles
                        way={props.way}
                        data={props.customData}
                    />
                </Suspense>
            );
        case 'Opportunities':
            if (opportunitiesSupanel === 'old') {
                return (
                    <Suspense fallback={<Loader/>}>
                        <OppProductComponent
                            data={props.customData.get('productData')}
                            way={`${props.way}/productData`}
                            readonly={props.readonly}
                            prefix={props.prefix}
                        />
                    </Suspense>
                );
            } else {
                return null;
            }
        case 'Meetings':
        case 'Calls':
            if (props.type === 'calendar' || props.type === 'popup') {
                return null;
            }
            if (isPopup) {
                return null;
            }

            return (
                <Suspense fallback={<Loader/>}>
                    <div className="detailInvComp detailInvCustComp">
                        <InviteesContainer
                            data={props.customData.toJS().invitees}
                            prefixComponent={`${props.way}/invitees`}
                            prefix={props.prefix}
                            detailWay={props.prefix}
                            pomClass="detailView"
                            isPopup={isPopup}
                        />
                    </div>
                </Suspense>
            );
        case 'Quotes':
            return (
                <Suspense fallback={<Loader/>}>
                    <ProductComponent
                        data={props.customData.get('productData')}
                        accountDiscount={sAction.dataGet(`${props.prefix}/fields/account_discount/value`)}
                        cenotvorba={sAction.dataGet(`${props.prefix}/fields/cenotvorba/value`)}
                        way={`${props.way}/productData`}
                        readonly={props.readonly}
                    />
                </Suspense>
            );
    }

    return null;
}

DetailSpecialPanelsContainer.propTypes = {
    customData: PropTypes.any,
    id: PropTypes.string,
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    prefix: PropTypes.string,
    readonly: PropTypes.bool,
    module: PropTypes.string.isRequired,
    way: PropTypes.string.isRequired,
    type: PropTypes.string,
};
