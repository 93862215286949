import React, {PureComponent} from 'react';
import Button from '../formElements/Button';
import ViewPanelMenuOthers from './viewPanelMenuOthers';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class ViewPanelMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            buttonLimit: -1,
        };
        this.panel = React.createRef();
    }
    componentDidMount() {
    // prepocitani limitu poctu tlacitek, kolik se jich vleze na sirku obrazovky
        let panelWidth = this.panel.current.clientWidth;
        panelWidth = panelWidth - 160;
        const buttons = this.panel.current.querySelectorAll('.acmButton');
        let buttonsWidth = 0;
        let doUpdate = true;
        buttons.forEach((button, index) => {
            const buttonWidth = button.clientWidth;
            buttonsWidth += buttonWidth + 10;
            if (buttonsWidth > panelWidth && doUpdate) {
                this.setState({
                    buttonLimit: index,
                });
                doUpdate = false;
            }
        });
    }

    componentDidUpdate() {
        // kdyz se napr. meni stav nabidky a pribude hodne novych tlacitek, tak je treba prepocitat limit tlacitek
        // aby se nezobrazovaly mimo obrazovku
        this.componentDidMount();
    }

    /**
     * handles click on button
     * @param {string} action
     * @param {string} buttonId
     * @param {object} params
     */
    onClick(action, buttonId, params) {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const paramData = {
            prefix: prefix,
            module:
        !data.get('module') ?
            data.get('modul') :
            data.get('module'),
            id: data.id,
            type: data.type,
            name: data.name,
            buttonId: buttonId,
        };

        // pokud se klika na nejake tlacitko v listu, poslou se rovnou i id zaznamu + pripadny filtr
        // todo: udelat refactorizaci starych kodu, kdy se tohle delalo ve funkcich zvlast
        if (!paramData.id && data.selected) {
            paramData.id = data.selected;
            if (paramData.id === 'all') { // Kdyz se klikne na Oznacit vsechny zaznamy
                paramData.filter = sAction.getListviewFilter(prefix, data);
            }
        }

        if (action != null && sAction[action] != null) {
            if (params != null) {
                params.forEach((param) => {
                    if (param.get('type') === 'store') {
                        paramData[param.get('name')] = data.get(param.get('value'));
                    } else if (param.get('type') === 'selector') {
                        const element = document.querySelector(param.get('value'));
                        paramData[param.get('name')] = element.value;
                    } else {
                        paramData[param.get('name')] = param.get('value');
                    }
                });
            }
            sAction[action](paramData);
        } else {
            console.error('akce "' + action + '" není definována');
        }
    }
    render() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const renderButtons = [];
        const renderButtonsOthers = [];
        let returnChangesDisabled = true;
        if (sAction.getViewName() === 'detail' && data.type !== 'popup') {
            if (
                (data.changes.get('fields').size > 0 ||
          (data.changes.get('customData') &&
            data.changes.get('customData').size > 0) ||
          data.changes.get('forceChange')) &&
        data.id != null
            ) {
                returnChangesDisabled = false;
            }
            renderButtons.push(
                <Button
                    onClick={() => sAction.removeChangesOnRecord(prefix)}
                    key={'returnChanges'}
                    disabled={returnChangesDisabled}
                    id={'actionButtonReturnChanges'}
                >
                    <TooltipWrapper label={'LBL_RETURN_CHANGES'} disabled={returnChangesDisabled}>
                        <React.Fragment>
                            <div
                                className={'actionPanelButtonIcon icon-undo'}
                                id={'returnChangesIcon'}
                            />
                        </React.Fragment>
                    </TooltipWrapper>
                </Button>,
            );
            renderButtons.push(
                <div
                    className="viewPanelButtonsDelimiter"
                    key={'returnChanges' + '_delimiter'}
                />,
            );
        }
        data.get('buttons').forEach((button, index) => {
            const buttonAcl =
        button.def.get('acl') == null ? 'edit' : button.def.get('acl');
            const acl = data.acl;
            if (acl != null && !acl.get(buttonAcl)) {
                return;
            } else if (acl == null &&!sAction.hasAccess(data.module == null ? data.modul : data.module, buttonAcl)) {
                return;
            }

            let buttonClass = '';
            if (button.def.get('extraClass') != null) {
                buttonClass += ' ' + button.def.get('extraClass');
            }
            const actionName = button.def.get('onClick');

            let params = button.def.get('params');
            if (params == null) {
                params = [];
            }
            let disabled = false;
            if (
                data.selected !== undefined &&
        data.selected.size === 0 &&
        button.def.get('multiple')
            ) {
                disabled = true;
            }

            if (
                data.changes !== undefined &&
        (data.changes.get('fields').size > 0 ||
          (data.changes.get('customData') &&
            data.changes.get('customData').size > 0) ||
          data.changes.get('forceChange')) &&
        data.id != null &&
        button.def.get('savedRecord') === true
            ) {
                disabled = true;
            }

            let add = true;
            if (
                button.def.get('newRecord') === false &&
        (data.id === '' || data.id == null)
            ) {
                add = false;
            }
            if (button.def.get(data.type) === false) {
                add = false;
            }
            if (add) {
                const buttonRender = (
                    <Button
                        onClick={() => this.onClick(actionName, button.id, params)}
                        className={buttonClass}
                        key={button.id}
                        id={button.id}
                        disabled={disabled}
                    >
                        {button.def.get('iconClass') != null && (
                            <div
                                className={
                                    'actionPanelButtonIcon ' +
                  'icon-' +
                  button.def.get('iconClass')
                                }
                                title={sAction.getStorage('debug') ? actionName : undefined}
                            />
                        )}
                        {sAction.translate(button.def.get('label'), data.get('module'))}
                    </Button>
                );
                if (button.def.get('others')) {
                    renderButtonsOthers.push(buttonRender);
                } else {
                    if (+this.state.buttonLimit === -1 || index < this.state.buttonLimit) {
                        renderButtons.push(buttonRender);
                        renderButtons.push(
                            <div
                                className="viewPanelButtonsDelimiter"
                                key={button.id + '_delimiter'}
                            />,
                        );
                    } else {
                        renderButtonsOthers.push(buttonRender);
                    }
                }
            }
        });

        // _______________________________________________ stránkování mezi záznamy
        let beforeNext = null;
        if (data.id) {
            const lastSearch = sAction.getStorage('listViewSearch');
            if (lastSearch && lastSearch.module === data.module) {
                const number =
          lastSearch.data.offset +
          (sAction.getStorage('listViewSearchIndex') || 0) +
          1;
                beforeNext = (
                    <div className="detailPagination">
                        {number > 1 && (
                            <div
                                className="arrow icon-pageBack"
                                onClick={() => sAction.changeRecord('back', prefix, data.type)}
                            />
                        )}
                        {number}
                        <div
                            className="arrow icon-pageNext"
                            onClick={() => sAction.changeRecord('next', prefix, data.type)}
                        />
                    </div>
                );
            }
        }

        return (
            <div
                className={(renderButtons.length !==0 || renderButtonsOthers.length !== 0) ? 'viewActionPanel' : 'hidePanel' }
                ref={this.panel}
            >
                <div className="viewActionPanelButtons">
                    {renderButtons}
                    {renderButtonsOthers.length !== 0 &&
            <ViewPanelMenuOthers buttons={renderButtonsOthers} buttonLimit={this.state.buttonLimit} />
                    }
                </div>
                {beforeNext}
            </div>
        );
    }
}
ViewPanelMenu.propTypes = {
    prefix: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};
