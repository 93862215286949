// productSelector
import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../pure';

import ProductSelectorList from './productSelectorList';

import PtItem from './ptItem';

import sAction from 'sAction';

// ExpansionPanel
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export default class ProductSelector extends PureComponent {
    constructor() {
        super();
        this.state = {
            showList: true,
            showGroups: true,
        };
    }

    /**
     *
     * @param {*} item
     * @param {String} way
     */
    handleProdTemplSel(item, way) {
        // eslint-disable-next-line no-unused-vars
        const searchData = {
            fields: ['id'],
            getFields: ['id', 'name',
                'cost_price', 'list_price',
                'discount_price', 'currency_id',
                'description', 'mft_part_num',
                'mft_part_num_int', 'picture'],
            value: item.id,
            module: 'ProductTemplates',
        };

        sAction.getProductTemplate(item.id, 'addProdLine', way);

        // sAction.quickSearch(searchData, data => {
        //     var line = data[0];
        //     if(data.length > 0){
        //         // const currency_id = sAction.dataGet(detailWay+"/customData/currency_id");
        //         const currency_id = sAction.dataGet(detailWay+"/fields/currency_id/value");
        //         line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id);
        //         line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id);
        //         var newItem = line;
        //         newItem.product_template_id = newItem.id;
        //         newItem.id = "";
        //         sAction.updateDetailCustom("addProdLine", {way: way, item:newItem});
        //     }
        // });
    }

    /*  categoryChange(id){
          const way = "rightPanel/data/listView";
          sAction.dataSet(way+"/listViewTree/activeCategory",id);
          sAction.reloadList(0,1,way);
          /*
          const way = this.props.way;
          sAction.dataSet(way+"/listViewTree/activeCategory",id);
          sAction.reloadList(0,1,way);

      }     */

    /*
    <DragAndDropCoripo
                containersClass=".favDragDropCont"
                elementsClass=".ptItem"
               // handleElementClass=".moveHolder"
                wayPrefix=""
               //moveStart={data => this.moveStart(data)}
                afterEnd={data => this.moveEnd(data)}
                moveObjectClass="subpanelMove"
                >*/

    render() {
        const data = this.props.data;
        const self = this;
        const detailWay = this.props.detailWay;

        const favComp = [];
        data.favorites.forEach(function(fav, ind) {
            favComp.push(
                <PtItem
                    key={ind}
                    data={fav}
                    pomKey={ind}
                    onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)}
                />,
            );
        });

        const topComp = [];
        data.top.forEach(function(top, ind) {
            topComp.push(
                <PtItem
                    key={ind}
                    data={top}
                    pomKey={ind}
                    onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)}
                />,
            );
        });

        const recentComp = [];
        data.recent.forEach(function(recent, ind) {
            recentComp.push(
                <PtItem
                    key={ind}
                    data={recent}
                    pomKey={ind}
                    onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)}
                />,
            );
        });

        let listClass = '';

        if (!this.state.showList) {
            listClass += ' hidden';
        }
        let groupClass = '';
        if (!this.state.showGroups) {
            groupClass += ' hidden';
        }

        const customFooter = <>
            <Accordion defaultExpanded={false} className="prodSelectoracmContainer">
                <AccordionSummary className="acmExpansionPanelSummary" expandIcon={<ExpandMoreIcon />}>
                    <div className="prodSelGroupTitle">{sAction.translate('LBL_SECTION_RECENT', 'Quotes')}</div>
                </AccordionSummary>
                <AccordionDetails className="acmExpantionPanelDetails">
                    <div className={'prodSelectorGroupContainer' + groupClass} >
                        {/* <div className="favContainer">

                            <div className="prodSelGroup favDragDropCont" data-way="rightPanel/data/favorites">
                                <div className="prodSelGroupTitle">{sAction.translate("LBL_SECTION_FAVORITES", "Quotes")}</div>
                                <div className="prodSelGroupItemContainer">
                                    {favComp}
                                </div>
                            </div>
                        </div> */}

                        <div className="prodSelGroup">
                            <div className="prodSelGroupItemContainer">
                                {recentComp}
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={false} className="prodSelectoracmContainer">
                <AccordionSummary className="acmExpansionPanelSummary" expandIcon={<ExpandMoreIcon />}>
                    <div className="prodSelGroupTitle">{sAction.translate('LBL_SECTION_TOP', 'Quotes')}</div>
                </AccordionSummary>
                <AccordionDetails className="acmExpantionPanelDetails">
                    <div className={'prodSelectorGroupContainer' + groupClass} >
                        <div className="prodSelGroup">
                            <div className="prodSelGroupItemContainer">
                                {topComp}
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>;

        return (
            <div className="prodSelector">
                <div className={'prodSelectorList' + listClass}>
                    <ProductSelectorList
                        data={data.listView}
                        selectCallBack={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)}
                        customFooter={customFooter}
                    />
                </div>
            </div>
        );
    }
}

ProductSelector.propTypes = {
    data: PropTypes.shape({
        favorites: PropTypes.shape({
            forEach: PropTypes.func,
        }),
        groupWay: PropTypes.any,
        listView: PropTypes.any,
        recent: PropTypes.shape({
            forEach: PropTypes.func,
        }),
        top: PropTypes.shape({
            forEach: PropTypes.func,
        }),
    }),
    detailWay: PropTypes.any,
};
