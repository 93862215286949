import {Map, List} from 'immutable';
import {DataConstructors} from '../../../DataConstructors';

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function calendarCustom(state, action) {
    const allState = state;
    if (action.type === 'CALENDARINIT') {
        const prefix = action.prefix.split('/');
        const params = action.content.params;
        const filters = new DataConstructors['calFilters']({ // eslint-disable-line
            showMeetings: params.showMeetings,
            showCalls: params.showCalls,
            showTasks: params.showTasks,
            showCompleted: params.showCompleted,
            meetStates: List(params.meetStates), // eslint-disable-line
            callStates: List(params.callStates), // eslint-disable-line
            taskStates: List(params.taskStates), // eslint-disable-line
            unpackActivities: params.unpackActivities,
            filtersDisplayed: (
                localStorage.getItem('acmCalFiltersVisibility') == null ||
                localStorage.getItem('acmCalFiltersVisibility') === 'visible') ?
                true : false,
        });

        let eventData = null;
        let dateStart = null;
        let dateEnd = null;
        let dashletId = null;

        if (action.content.eventData) {
            const events = action.content.eventData;
            dateStart = action.content.date_start;
            dateEnd = action.content.date_end;
            dashletId = action.content.dashletId;

            eventData = {Meetings: [], Calls: [], Tasks: []};

            events.Meetings.forEach((ev) => {
                eventData.Meetings.push(new DataConstructors['calendarEvent'](ev)); // eslint-disable-line
            });
            events.Calls.forEach((ev) => {
                eventData.Calls.push(new DataConstructors['calendarEvent'](ev)); // eslint-disable-line
            });
            events.Tasks.forEach((ev) => {
                eventData.Tasks.push(new DataConstructors['calendarEvent'](ev)); // eslint-disable-line
            });
        }

        const calendar = new DataConstructors['calendar']({ // eslint-disable-line
            sharedView: params.sharedView,
            filters: filters,
            setting: new Map(params),
            eventData: eventData,
            meetingView: new DataConstructors['DetailView']({type: 'calendar'}),
            callView: new DataConstructors['DetailView']({type: 'calendar'}),
            taskView: new DataConstructors['DetailView']({type: 'calendar'}),
            detailView: new DataConstructors['DetailView']({type: 'popup'}),
            date_start: dateStart,
            date_end: dateEnd,
            dashletId: dashletId,
        });

        return allState.setIn(prefix, calendar);
    } else if (action.type === 'CALENDARLOADQUICKFORM') {
        const prefix = action.prefix.split('/');

        const data = action.content.invitees;

        const relatedInv = new DataConstructors['invitees']({ // eslint-disable-line
            Users: List(data.Users), // eslint-disable-line
            Contacts: List(data.Contacts), // eslint-disable-line
            Leads: List(data.Leads), // eslint-disable-line
        });

        const searchInv = new DataConstructors['invitees']({ // eslint-disable-line
            Users: List(), // eslint-disable-line
            Contacts: List(), // eslint-disable-line
            Leads: List(), // eslint-disable-line
        });

        const invitees = new Map({
            relatedInv: relatedInv,
            searchInv: searchInv,
        });

        return allState.setIn(prefix, invitees).setIn(action.prefix?.split('/'), invitees);
    } else if (action.type === 'CALENDARSEARCHINVITEES') {
        const prefix = action.prefix.split('/');

        const relPref = prefix.slice();
        relPref.push('relatedInv');

        prefix.push('searchInv');

        const fieldArrState = state.getIn(relPref).toJS();
        const invitees = {Users: [], Contacts: [], Leads: []};

        action.content.invitees.Users?.forEach((searchInv) => {
            if (!fieldArrState.Users.find(function(inv) {
                return (inv.id == searchInv.id); // eslint-disable-line
            })) {
                invitees.Users.push(searchInv);
            }
        });

        action.content.invitees.Contacts?.forEach((searchInv) => {
            if (!fieldArrState.Contacts.find(function(inv) {
                return (inv.id == searchInv.id); // eslint-disable-line
            })) {
                invitees.Contacts.push(searchInv);
            }
        });

        action.content.invitees.Leads?.forEach((searchInv) => {
            if (!fieldArrState.Leads.find(function(inv) {
                return (inv.id == searchInv.id); // eslint-disable-line
            })) {
                invitees.Leads.push(searchInv);
            }
        });

        return allState.setIn(prefix, new Map(invitees));
    } else if (action.type === 'CALENDARCLEARDATA') {
    // DOSTAT
        if (action.content.prefix) {
            let calState = state.getIn(action.content.prefix.split('/'));

            const emptyList = new DataConstructors['invitees']({ // eslint-disable-line
                Users: List(), // eslint-disable-line
                Contacts: List(), // eslint-disable-line
                Leads: List(), // eslint-disable-line
            });

            const invitees = new Map({
                relatedInv: emptyList,
                searchInv: emptyList,
            });

            calState = calState.setIn(['invitees'], invitees);

            calState = clearDetailFields(calState, 'meetingView');
            calState = clearDetailFields(calState, 'callView');
            calState = clearDetailFields(calState, 'taskView');

            return allState.setIn(action?.content?.prefix.split('/'), calState);
        }
    } else if (action.type === 'CALENDARINITACTIVITY') {
        const data = action.content.invitees;

        const prefix = action.prefix.split('/');

        const invList = new DataConstructors['invitees']({ // eslint-disable-line
            Users: List(data.Users), // eslint-disable-line
            Contacts: List(data.Contacts), // eslint-disable-line
            Leads: List(data.Leads), // eslint-disable-line
        });

        return allState.setIn(prefix, new Map(invList));
    }

    return allState;
}
/**
 *
 * @param {*} calState
 * @param {*} viewName
 * @returns
 */
function clearDetailFields(calState, viewName) {
    let fieldArrState = calState.getIn([viewName, 'fields']);

    fieldArrState.toJS().forEachObject((field) => {
        fieldArrState = fieldArrState.setIn([field.name, 'value'], field.def.default ?? null);
        fieldArrState = fieldArrState.setIn([field.name, 'def', 'isInvalid'], false);
    });

    calState = calState.setIn(
        [viewName, 'changes'],
        new Map({fields: new Map(),
            files: new Map(),
            customData: new Map(),
            forceChange: false},
        ),
    );

    return calState.setIn([viewName, 'fields'], fieldArrState);
}
