/**
 *
 */
export default function reportWindowCreate() {
    const self = this;
    this.dataSet('reportWindow/results', {});
    const module = this.dataGet('reportWindow/module');
    const listViewData = this.reportWindowCreateBasic(module);
    listViewData['savedSearch'] = true;
    if (listViewData != false) {
        this.rest.post('list/' + module, listViewData, (data) => {
            const content = self.listContent(data);
            const akce = {
                type: 'PAGELOADLIST',
                page: module,
                content: content,
                listViewPrefix: 'reportWindow/results',
                function: self,
            };
            self.dsClear();
            self.dsAdd('set', 'reportWindow/filterAlert/alert', false);
            self.dsAddCustom(akce);
            self.dsAdd('set', 'reportWindow/actContent', 'result');
            self.dsAdd('set', 'reportWindow/results/type', 'reportWindow');
            self.dsProcess();
        });
    } else {
        this.dataSet('reportWindow/filterAlert', {alert: true, message: 'Špatně nastavený filtr - nelze pokračovat'});
    }
}
