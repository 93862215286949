/**
 * @param {string} prefix
 */
export default function activateListViewFiltering(prefix) {
    const src = prefix+'/actFiltering';
    const value = this.dataGet(src);

    this.dsClear();
    this.dsAdd('set', src, !value);
    if (value) {
        this.dsAdd('set', prefix+'/filter', []);
    }
    // smazani poctu zaznamu, aby se neukazovala spatna hodnota kdyz zacneme filtrovat
    this.dsAdd('set', `${prefix}/rowTotalCount`, null);
    this.dsProcess();
    const list = this.getViewName() === 'import' ? this.setImportListFilter({prefix: prefix}) : null;
    this.reloadList(0, 1, prefix, null, null, list);
}
