/**
 *
 * @param text
 * @param classNames
 */
export default function emailRemoveVariableHighlights(text, classNames = null) {
    // fce nalezne elementy podle dane html class a odstraneni je
    // pricemz zachova obsah elementu

    const div = document.createElement('div');
    div.innerHTML = text;

    // nalezeni tagu
    // var el = div.getElementsByClassName(classNames);
    const el = div.getElementsByTagName('span');

    // promazani tagu
    // while (el.length) {
    let i = el.length - 1;
    for (; i > -1; i--) {
        const parent = el[i].parentNode;
        if (el[i].style.cssText !== 'background-color: rgb(255, 255, 0);') {
            continue;
        }

        // zachovani obsahu tagu
        while (el[i].firstChild) {
            parent.insertBefore(el[i].firstChild, el[i]);
        }
        // odstraneni tagu
        parent.removeChild(el[i]);
    }

    return div.innerHTML;
}
