/**
 * Open right panel with activity timeline
 * @param {*} prefix
 */
export default function showActivitiesPanel(prefix) {
    if (typeof prefix === 'object') {
        prefix = prefix.prefix;
    }
    const params = this.dataGet(prefix, ['id', 'module', 'name']);
    if (!params?.id && !params?.relModule) {
        params.id = this.dataGet('view/id');
        params.module = this.dataGet('view/module');
    }
    this.openRightPanel('load');
    this.dsClear();
    this.dataSet('rightPanel/data', params, 'ActivitiesTimelineView');
    this.reloadActivitiesPanel('rightPanel/data');
}
