import React from 'react';

export default function Currency_id(props) {
    let lineRender = null;
    try {
        lineRender = props.currencies[props.value].symbol;
    } catch (e) {
        console.log(props.currencies);
        console.log('Nenalezna měna s ID: ' + props.value);
        lineRender = props.value;
    }
    return (
        <div className={'newListViewLine '} onClick={props.onClick}>{lineRender}</div>
    );
}
