import DefaultVarchar from '../../defaultFields/Varchar/Varchar';
import sAction from 'sAction';

export default class Varchar extends DefaultVarchar {
    /**
     * @param {object} data
     */
    saveField(data) {
        sAction.saveCellFieldByPropsWithDefaultValue(this.props, data.value, this.defaultValue);
    }
}
