import sAction from 'sAction';

/**
 * @returns {Void} Function for logou
 */
export default function logOut() {
    // save of dashboard - mainly due to the stamping of the column
    if (sAction.dataGet('conf/view') === 'home') {
        sAction.homeLayoutSave();
    }

    sAction.routeLogin();
}
