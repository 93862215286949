/**
 *
 */
export default function getNumberFilterOptions() {
    return [
        {
            value: 'eq',
            label: this.translate('LBL_EQUALS'),
            disabledInput: false,
        },
        {
            value: 'neq',
            label: this.translate('LBL_NOT_EQUAL'),
            disabledInput: false,
        },
        {
            value: 'lessThan',
            label: this.translate('LBL_FIELD_LESSTHAN'),
            disabledInput: false,
        },
        {
            value: 'moreThan',
            label: this.translate('LBL_FIELD_MORETHAN'),
            disabledInput: false,
        },
        {
            value: 'null',
            label: this.translate('LBL_FIELD_NULL'),
            disabledInput: true,
        },
        {
            value: 'nnull',
            label: this.translate('LBL_FIELD_NOT_NULL'),
            disabledInput: true,
        },
    ];
}
