/**
 *
 * @param params
 * @param hidePopup
 */
export default function printWordPrepare(params, hidePopup = false) {
    if (this.getViewName() === 'detail' || params.prefix.includes('rightPanel')) {
        this.printFileWord(params);
    } else if (this.getViewName() === 'list') {
        const list = this.dataGet(params.prefix);
        const selected = list.selected;

        selected.forEach((id) => {
            // ziskame jmeno
            let name = list.rows.find((r) => r.get('key') === 'NAME');
            name = name.records.find((r) => r.get('id') === id).get('value');

            this.printFileWord({...params, name, id, filetype: 'word'});
        });
    }

    if (hidePopup) this.popupHide();
}
