/**
 *
 * @param prefix
 * @param record
 * @param module
 */
export default function openAddTagRelatePopup(prefix, record, module) {
    this.load();
    this.rest.post('tags/getTags/', {record: record, module: module}, (data) => {
        data['prefix'] = prefix;
        data['record'] = record;
        data['module'] = module;
        this.popup('tagAddRelate', data);
        this.unLoad();
    });
}
