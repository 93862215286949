import {Map} from 'immutable';

/**
 * @param {object} paramData
 * @param {function} callback
 * @this sAction
 * @button DetailView
 */
export default function saveRecord(paramData, callback = null) {
    const self = this;
    const id = paramData.id;
    this.saveRecordBase(paramData, (data) => {
        if (data.state === true) {
            const msg = data.data;
            if (this.checkDeletedRecord(msg, paramData)) {
                self.dsClear();
                self.dsAdd('set', paramData.prefix + '/changes', {fields: new Map(), files: []});
                // this is weird, i want call detail load function, but have to set false to really call it
                self.dsAdd('set', paramData.prefix + '/shouldCallHooks/shouldCallLoadHook', false);
                self.dsProcess();
                if (typeof callback === 'function') {
                    callback(msg.record);
                } else if (paramData.type === 'rightPanel' || paramData.type === 'popup') {
                    if (paramData.type === 'rightPanel') {
                        this.dataSet('rightPanel/reloadOnClose', true);
                        if (this.dataGet('rightPanel/closeOnSave') === true) {
                            this.closeRightPanel();
                            this.route();

                            return;
                        } else {
                            this.reloadListOnClose();
                        }
                    }

                    const otherData = {
                        subpanels: false,
                        cancelPreviousRequest: data['cancelPreviousRequest'] ?? true,
                    };
                    paramData.id = msg.record;
                    self.routeDetailView(paramData, otherData);
                } else {
                    if (!id) {
                        window.location.hash = '#detail/' + msg.module + '/' + msg.record;
                    } else {
                        const otherData = {
                            cancelPreviousRequest: data['cancelPreviousRequest'] ?? true,
                        };
                        self.routeDetailView(paramData, otherData);
                    }
                }
            }
        } else {
            self.error(data.msg);
        }
    });
}
