/**
 *
 * @param paramData
 */
export default function filesNewVersion(paramData) {
    const tempEl = document.createElement('input');
    tempEl.setAttribute('type', 'file');
    tempEl.style = 'display: none';
    document.body.appendChild(tempEl);

    tempEl.addEventListener('change', (e) => {
        const formData = {
            files: [],
        };
        tempEl.files.forEachObject((file, key) => {
            if (key != 'length') {
                formData['files'].push(file);
            }
        });
        formData.id = paramData.id;

        this.popup('fileUpload', {percent: 0, loading: true, onClose: () => {
            this.route();
        }});
        const id = this.getPopupId();
        this.filesXhr({
            action: 'updateFile',
            data: formData,
            success: (data) => {
                if (data.state == true) {
                    this.dataSet('conf/popup'+id+'/data/loading', false);
                }
            },
            progress: (data) => {
                this.dataSet('conf/popup'+id+'/data/percent', data.percent);
            },
        });
    });
    tempEl.click();
}
