import sAction from 'sAction';

/**
 *
* @param {Bool} state
* @param {string} prefix
*/
export default function subpanelsChangeState(state, prefix) {
    const subpanels = sAction.dataGet(prefix + '/subpanels');
    subpanels.forEach((subpanel, i) => {
        const way = `${prefix}/subpanels/${i}`;
        if (state) {
            const parentData = this.dataGet(prefix);
            const rel = subpanel.def.get('rel');
            const subData = {
                parentId: parentData.id,
                parentModule: rel.get('module'),
                module: subpanel.module,
                relName: rel.get('relName'),
                prefix: prefix,
                way: way,
                def: subpanel.def,
            };
            sAction.reloadSubpanel(subData, false);
        }
        sAction.dataSet(`${way}/def/open`, state);
        sAction.listViewCount(`${way}/rows`, false);
    });
}
