import detailDefault from '../../detailDefault';

export default class detailDocuments extends detailDefault {
    load(sAction, data) {
        const id = sAction.dataGet(data.prefix+'/id');
        if (id) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/filename_helper/def/readonly', true);
            sAction.dsProcess();
        }
    }

    update(sAction, data) {
        if (data.field == 'filename_helper') {
            if (data.value.name) {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/document_name/value', data.value.name);
                sAction.dsAdd('set', data.prefix+'/changes/fields/document_name', data.value.name);
                sAction.dsProcess();
            }
        }
    }
}
