/**
 *
 * @param params
 */
export default function raiseFileUpload(params) {
    this.processFileInput({}, (file) => {
        this.dsClear();
        const prefix = params.prefix;
        this.temporaryData = {file: file};
        const encoding = this.dataGet(prefix + '/fileEncoding') ?? 'UTF-8';
        this.readFile({prefix: prefix, encoding: encoding});
    });
}
