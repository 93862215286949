/**
 * resets listview layout to default
 * @param {string} module       module to reset listview for
 * @param {array}  views        views to reset layout for
 * @param {string} listViewType type of list view (list/subpanel/popup)
 * @param {string} parent       parent module of subpanel
 * @param {string} level        level of listview (intern/custom/third)
 */
export default function defaultListViewEditSetDefault(module, views, listViewType = 'list', parent = '', level = 'third') {
    this.confirm(this.translate('LBL_LISTVIEW_DEFAULT_CONFIRM'), () => {
        this.load();

        let url = `listViewEditLayout/${module}/${listViewType}`;
        if (listViewType === 'subpanel') {
            url += `/${parent}`;
        }

        this.rest.fetchData(url, 'DELETE', {views, level}).then(() => {
            this.popupHide();
            if (listViewType === 'popup') {
                this.selectLook(' ', 'conf/popup/data/listView');
            } else {
                this.route();
            }
        }).catch((error) => {
            this.unLoad();
            console.error(error);
            this.error(error.text ?? error);
        });
    });
}
