/**
 *
 * @param relatesList
 */
export default function formatRelatesMapping(relatesList) {
    const relateMapping = {};

    relatesList.forEach((relate) => {
        relateMapping[relate.field] = relate.value.id ?? relate.value;
    });

    return relateMapping;
}
