import React from 'react';

import PureComponent from '../../pure';
import sAction from 'sAction';
import Select from '../../formElements/Select';
import NewActivityButton from '../../subpanel/NewActivityButton';
import ImmutablePropTypes from 'react-immutable-proptypes';
import propTypes from 'prop-types';
import ActivitiesTimelineItem from 'ROOT/src/components/RightPanel/ActivitiesTimeline/ActivitiesTimelineItem';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class ActivitiesTimeline extends PureComponent {
    constructor(props) {
        super(props);
        this.timeline = React.createRef();
    }

    /**
     * @desc Toggles visibility of activities based on their module
     * @param {string} module
     */
    toogleActivities(module) {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const moduleData = data.activities.get(module);

        if (moduleData === undefined) {
            sAction.dataSet(prefix + '/activities/' + module, {show: false});
        } else {
            sAction.dataSet(
                prefix + '/activities/' + module + '/show',
                !moduleData.get('show'),
            );
        }
        sAction.reloadActivitiesPanel(prefix);
    }

    /**
     * @desc Filters activities based on their status
     * @param {string} value
     */
    statusChange(value) {
        const prefix = this.props.prefix;
        sAction.dataSet(prefix + '/status', value);
        sAction.reloadActivitiesPanel(prefix);
    }

    componentDidMount() {
        const popupContainer = this.timeline.current.closest('.popupContent');
        if (popupContainer) {
            popupContainer.style.width = '90%';
            popupContainer.style.height = '80%';
        }
        this.timeline.current.addEventListener('scroll', (e) =>
            this.handleScroll(e),
        );
    }

    /**
     * @desc If user scrolls past max-height, sActions loads more activities
     * @param {Event} e
     */
    handleScroll(e) {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
            const prefix = this.props.prefix;
            const data = this.props.data;
            sAction.activitiesTimelineExtend(prefix, data);
        }
    }

    /**
     * @desc Switches order of activities
     */
    handleSwitchOrder() {
        const prefix = this.props.prefix;
        const order = this.props.data.get('order');
        if (order === 'desc') {
            sAction.dataSet(prefix + '/order', 'asc');
        } else {
            sAction.dataSet(prefix + '/order', 'desc');
        }
        sAction.reloadActivitiesPanel(prefix);
    }

    /**
     * @desc toggles long/short description on activities, based on isShow
     * @param {MouseEvent} e
     * @param {number} index
     * @param {boolean} isShow
     */
    showMore(e, index, isShow) {
        e.preventDefault();
        sAction.activitiesTimelineShowMore(index, isShow);
    }

    render() {
        const prefix = this.props.prefix;
        const data = this.props.data;

        const records = data.get('records');
        const activities = data.get('activities');

        const activityModule = ['Calls', 'Meetings', 'Tasks', 'Emails'];
        const activityModuleDoms = {
            'Calls': 'call_status_dom',
            'Meetings': 'meeting_status_dom',
            'Tasks': 'task_status_dom',
            'Emails': 'dom_email_status',
        };
        let activityDom = {};
        for (const [key, value] of Object.entries(activityModuleDoms)) { // eslint-disable-line
            activityDom = {
                ...activityDom,
                ...sAction.app_strings[value],
            };
        }

        const activityButtons = [];
        activityModule.forEach((module) => {
            const colors = sAction.getActivityColors(module);
            const iconColor = sAction.convertToPastelHSL(colors.eventBackgroundColor, 1, 'dark');
            const style = {
                backgroundColor: colors.eventBackgroundColor,
                color: iconColor,
            };
            const className = 'activity' + module + ' ' + (activities.getIn([module, 'show']) === false ? ' filter' : '');

            activityButtons.push(
                <TooltipWrapper label={'LBL_' + module.toUpperCase()} module={module}>
                    <button
                        style={style}
                        key={module}
                        onClick={() => this.toogleActivities(module)}
                        className={className}
                    >
                        <span style={style} className={'icon-' + module}/>
                    </button>
                </TooltipWrapper>,
            );
        });

        const filterButtons = (
            <div className="filterButtons">
                {activityButtons}
                <TooltipWrapper label={'LNK_PRINT'}>
                    <button
                        className="activityPrint"
                        onClick={() => sAction.printTimelineConfirm(prefix)}
                    >
                        <span className={'icon-Print'}/>
                    </button>
                </TooltipWrapper>
                {!sAction.isMobile() &&
                    <TooltipWrapper label={'LBL_MAXIMIZE'}>
                        <button
                            onClick={() => sAction.showActivitiesTimelinePopup(prefix)}
                            className="activityResize"
                        >
                            <span className={'icon-Resize'}/>
                        </button>
                    </TooltipWrapper>
                }
            </div>
        );

        const newActivity = (
            <NewActivityButton
                parent={''}
                prefix={prefix}
                activities={[
                    {module: 'Meetings'},
                    {module: 'Calls'},
                    {module: 'Tasks'},
                    {
                        label: sAction.translate('LBL_COMPOSE_EMAIL_BUTTON_LABEL'),
                        module: 'Emails',
                        icon: 'icon-Emails',
                    },
                ]}
            />
        );

        const statusItems = Object.entries(
            activityDom,
        ).map(([key, value]) => ({label: value, value: key}));
        const options = [
            {label: sAction.translate('LBL_ALL'), value: ''},
            ...statusItems,
        ];

        const planned = sAction.dataGet(prefix + '/status');
        const statusFilter = (
            <div className="timelineFilter">
                {!sAction.isMobile() &&
                    <div className="newActivityButton">
                        {newActivity}
                    </div>
                }
                <div className="filterStatusEnum">
                    <Select
                        options={options}
                        onChange={(e) => this.statusChange(e.target.value)}
                        defaultValue={planned}
                    />
                </div>
            </div>
        );

        const format = data.get('format');
        const activitiesRecords = records ?
            records.map((record, index) => {
                return (
                    <ActivitiesTimelineItem
                        format={format}
                        index={index}
                        record ={record}
                        activityModuleDoms={activityModuleDoms}
                        key={index}
                    />
                );
            }) :
            [];

        const timeline = (
            <div className={'activitiesListContainer'}>
                <div className="activitiesList">{activitiesRecords}</div>
            </div>
        );

        const lineEdge = (
            <div className="switchOrder">
                <TooltipWrapper label={'LBL_SORT'} placement={'right'}>
                    <span className="icon" onClick={() => this.handleSwitchOrder()}>
                        <span className="icon-SwitchOrder"/>
                    </span>
                </TooltipWrapper>
            </div>
        );

        return (
            <div className={'activitiesTimeline' + (sAction.isMobile() ? ' mobileActivityTimeline' : '')} ref={this.timeline}>
                {filterButtons}
                {statusFilter}
                {lineEdge}
                {timeline}
            </div>
        );
    }
}

ActivitiesTimeline.propTypes = {
    data: ImmutablePropTypes.recordOf({
        activities: ImmutablePropTypes.map.isRequired,
        status: propTypes.string,
        module: propTypes.string.isRequired,
        id: propTypes.string.isRequired,
        records: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
        rows: ImmutablePropTypes.list.isRequired,
        inPopup: propTypes.bool.isRequired,
        order: propTypes.string,
        format: ImmutablePropTypes.map.isRequired,
        limit: propTypes.number,
        offset: propTypes.number,
    }).isRequired,
    prefix: propTypes.string.isRequired,
};
