/**
 *
 * @param direction
 * @param prefix
 * @param type
 */
export default function beforeRecord(direction, prefix, type) {
    const data = this.getStorage('listViewSearch');
    const searchData = data.data;
    const index = this.getStorage('listViewSearchIndex');
    searchData.limit = 1;
    searchData.offset += index;
    if (direction === 'next') {
        searchData.offset += 1;
    } else if (direction === 'back') {
        searchData.offset -= 1;
    }

    if (type == 'rightPanel') {
        this.openRightPanel('load');
    } else {
        this.load();
    }
    if (searchData.offset >= 0) {
        this.rest.post('list/'+data.module, searchData, (data) => {
            try {
                const id = data.records[0].id;
                this.setStorage('listViewSearchIndex', searchData.offset);
                console.log(id);
                if (type == 'rightPanel') {
                    this.rightPanelDetail(data.module, id);
                } else {
                    this.unLoad();
                    window.location.hash = 'detail/'+data.module+'/'+id;
                }
            } catch (e) {
                if (type == 'rightPanel') {
                    this.closeRightPanel();
                } else {
                    this.unLoad();
                }
            }
        });
    } else {
        this.unLoad();
    }
}
