import sAction from 'sAction';

/**
 * get subpanel header name
 *
 *
 * @param {object} subpanel
 * @returns {string}
 */
export default function getSubpanelHeaderName(subpanel) {
    const parentModule = subpanel.def.getIn(['rel', 'module']) ?? '';
    const relName = subpanel.def.getIn(['rel', 'relName']) ?? '';
    const subpanelNameKey1 = subpanel.module ? `LBL_${subpanel.module.toUpperCase()}_SUBPANEL_TITLE` : '';
    const subpanelNameKey2 = (subpanel.module && relName)? `LBL_${relName.toUpperCase()}_FROM_${subpanel.module.toUpperCase()}_TITLE` : '';
    const translations = subpanel.def.get('translations');
    let subpanelName = '';
    if (translations) {
        subpanelName = translations.get(sAction.dataGet('conf/language')) ?? '';
    }
    if (subpanelName === '') {
        subpanelName = sAction.translate(subpanelNameKey2, parentModule);
    }
    if (subpanelName === subpanelNameKey2) {
        subpanelName = sAction.translate(subpanelNameKey1, parentModule);
    }
    if (subpanelName === subpanelNameKey1) {
        subpanelName = sAction.app_strings.moduleList[subpanel.module] || subpanel.module;
    }
    if (!subpanelName) {
        subpanelName = subpanel.getIn(['def', 'rel', 'module']) ?? '';
        console.error('Chybí jméno subpanelu !');
    }

    return subpanelName;
}
