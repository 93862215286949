/**
 * @param {object} formulas
 * @param {string} formulaName
 * @returns {string}
 */
function checkFormulaParent(formulas, formulaName) {
    if (formulas[formulaName]) {
        if (typeof formulas[formulaName] === 'string') {
            formulaName = checkFormulaParent(formulas, formulas[formulaName]);
        }

        return formulaName;
    }

    return 'default';
}

/**
 * @param {string} key
 * @param {object} formulas
 * @param {object} switches
 * @returns {string}
 */
function getFormulaName(key, formulas, switches) {
    let formulaName = 'default';
    if (switches[key]) {
        formulaName = switches[key];
    }
    if (!formulas[key][formulaName]) return 'default';
    if (typeof formulas[key][formulaName] === 'string') {
        formulaName = checkFormulaParent(formulas[key], formulas[key][formulaName]);
    }

    return formulaName;
}

/**
 * @param {string} key
 * @param {object} formulas
 * @return {string}
 */
function getFormulaReferenceKey(key, formulas) {
    const formulaDefinition = formulas[key];
    if (typeof formulaDefinition === 'string') {
        return getFormulaReferenceKey(formulaDefinition, formulas);
    }

    return key;
}

/**
 * @param {string} way
 * @param {string} lineKey
 * @param {string} field
 * @returns {object}
 */
export default function onLineFieldUpdate(way, lineKey, field) {
    let key = field;
    const changes = {};

    const formulas = this.dataGet(way + '/customData/customLines/def/formula').toJS();
    if (!formulas || !formulas[key]) {
        return;
    }
    if (typeof formulas[key] === 'string') {
        key = getFormulaReferenceKey(formulas[key], formulas);
    }

    const selects = this.dataGet(way + '/customData/customLines/def/switch').toJS();
    const selectValues = {};
    for (const [select, formula] of Object.entries(selects)) {
        selectValues[formula] = this.dataGet(way + '/customData/customLines/lines/' + lineKey + '/' + select);
    }
    const formulaName = getFormulaName(key, formulas, selectValues);
    if (typeof formulas[key][formulaName] !== 'object') {
        return;
    }

    this.dsClear();
    const formula = formulas[key][formulaName];
    formula.forEachObject((formDef, colKey) => {
        const stack = [];
        formDef.forEach((form) => {
            let num;
            switch (form.type) {
                case 'num':
                    num = parseFloat(form.value);
                    if (isNaN(num)) {
                        num = 0;
                    }
                    stack.push(num);
                    break;
                case 'var':
                    num = changes[form.value] ?? this.dataGet(`${way}/customData/customLines/lines/${lineKey}/${form.value}`);
                    if (num === undefined) {
                        return false;
                    }
                    if (typeof num === 'boolean') {
                        num = num ? 1 : 0;
                    }
                    num = this.parseNumber(num); // because float and currency can be string with spaces
                    if (isNaN(num)) {
                        num = 0;
                    }
                    stack.push(num);
                    break;
                case 'op':
                    if (typeof stack[stack.length - 1] === 'number' && typeof stack[stack.length - 2] === 'number') {
                        switch (form.value) {
                            case '+':
                                stack[stack.length - 2] = stack[stack.length - 2] + stack.pop();
                                break;
                            case '-':
                                stack[stack.length - 2] = stack[stack.length - 2] - stack.pop();
                                break;
                            case '*':
                                stack[stack.length - 2] = stack[stack.length - 2] * stack.pop();
                                break;
                            case '/':
                                stack[stack.length - 2] = stack[stack.length - 2] / stack.pop();
                                break;
                            default:
                                return false;
                        }
                    } else {
                        return false;
                    }
                    break;
                default:
                    return false;
            }
        });
        if (+stack.length !== 1) {
            return false;
        }
        this.dsAdd(
            'set',
            `${way}/customData/customLines/lines/${lineKey}/${colKey}`,
            Math.round(stack[0] * 100) / 100,
        );
        // this.dsAdd(
        //     'set',
        //     `${way}/changes/customData/customLines/${lineKey}/${colKey}`,
        //     Math.round(stack[0] * 100) / 100,
        // );
        changes[colKey] = Math.round(stack[0] * 100) / 100;
    });
    this.dsProcess();
    return changes;
}
