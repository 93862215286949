/**
 *
 * @param id
 * @param way
 * @param data
 */
export default function loadCustomReport(id, way, data = null) {
    this.customReportXHR(id, data, (responseData) => {
        this.dsClear();
        if (!data?.isDashboard) {
            this.dsAdd('set', way, {});
        }

        this.dsAdd('set', way+'/html', responseData);
        this.dsAdd('set', way+'/reportId', id);
        this.dsAdd('set', way+'/reportLoaded', true);
        this.dsAdd('set', way+'/reactReport', false);
        this.dsProcess();
    });
}
