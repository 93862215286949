/**
 *
 * @param d
 */
export default function addFunction(d) {
    const arrName = Object.keys(d);
    const arr = arrName.map((k) => d[k]);
    arr.forEach((f, k) => {
        this[arrName[k]] = f;
    });
}
