/**
 *
 * @param record
 * @param fields
 * @param params
 * @param callback
 * @param callbackFail
 */
export default function calSaveActivity(record, fields, params, callback, callbackFail = null) {
    const req = {
        'record': record,
        'fields': fields,
        'params': params,
    };

    const self = this;
    self.rest.post('saveActivity', req, function(data) {
        // if(data.retCode == "OK"){
        if (data.status) {
            callback(self);
        } else {
            self.error(data.errorMessage.text);
            if (callbackFail !== null) {
                callbackFail();
            }
        }
    });
}
