import React from 'react';

import sAction from 'sAction';

// kdyz se neco podela na homepage, tak aby slo klikat na leve menu a zbytek webu mohl fungovat

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    /**
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h2>Omlouváme se, něco se pokazilo a bude třeba obnovit stránku.</h2>
                    <details style={{whiteSpace: 'pre-wrap'}}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}
