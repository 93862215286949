/**
 *
 * @param groups
 */
export default function loadMenuGroupsOpen(groups) {
    this.dsClear();
    const menuGroups = this.getStorage('menuGroups') || [];
    menuGroups.forEach((open, index) => {
        const path = 'menu/groups/'+index+'/open';
        this.dsAdd('set', path, open);
    });
    this.dsProcess();
}
