/**
 * @this sAction
 *
 * @param {{}}data
 */
export default function invoiceCreatePackingList(data) {
    const params = {action: 'invoiceCreatePackingList', record: data.id};
    this.rest.fetchData('customAction', 'POST', params, false).then((response) => {
        this.href(`#detail/acm_packing_lists/${response.data.record}`);
    }).catch((error) => {
        this.error(error.text);
    });
}
