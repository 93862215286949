import useIsEmptyObject from 'ROOT/src/hooks/Common/useIsEmptyObject';

/**
 * issue coripo/coripo#2060:
 *      I should close activity and open new with sAction.detailPredefinedFields, but calendar works how it works, and it wasn't
 *      direct approach, so I had to look in the store, if view for creating new activity was defined, store those view
 *      (because there were already data filled from predefined fields) and then save it back to store
 *      look for [PREDEFINED FIELDS] in comments
 *
 * @param {*} paramData
 * @param {*} callback
 * @return {Promise<void>}
 */
export default async function calGetInitData(paramData, callback) {
    const self = this;
    /**
     * [PREDEFINED FIELDS]
     * @type {{}} holds data for views from store with already defined fields and data from predefined fields
     */
    const saveViewWithDefinedFields = {};
    const callCronRest = await this.rest.fetchData('calGetInitData', 'POST', paramData, false).catch((data) => {
        console.error(data);
    });

    const data = callCronRest.data;

    const views = [
        {
            data: data.views.Meetings, prefix: paramData.prefix + '/meetingView', module: 'Meetings',
        }, {
            data: data.views.Calls, prefix: paramData.prefix + '/callView', module: 'Calls',
        }, {
            data: data.views.Tasks, prefix: paramData.prefix + '/taskView', module: 'Tasks',
        },
    ];

    const inviteesPath = `${paramData.prefix}/invitees/relatedInv`;
    // [PREDEFINED FIELDS] preserve changes over multiple calls of calGetInitData
    const wayPreserveChangesAndFieldsOnViews = `${paramData.prefix}/preserveChangesAndFieldsOnViews`;
    const preserveChangesAndFieldsOnViews = self.dataGet(wayPreserveChangesAndFieldsOnViews);
    const invitees = self.dataGet(inviteesPath).toJS();

    self.calEvents.sharedView = data.params?.sharedView;
    self.calEvents.sharedIds = data.params?.sharedIds;

    const params = data.params;

    if (params != null) {
        self.dsClear();
        action = {
            type: 'CALENDARINIT',
            content: {params: params},
            prefix: paramData.prefix,
        };
        self.dsAddCustom(action);
        if (preserveChangesAndFieldsOnViews) {
            views.forEach((view) => {
                const storeDataView = self.dataGet(view.prefix);
                // [PREDEFINED FIELDS] store data from view to variable: saveViewWithDefinedFields
                saveViewWithDefinedFields[view.prefix] = storeDataView;
                if (!useIsEmptyObject(storeDataView?.toJS().fields)) {
                    // [PREDEFINED FIELDS] action "CALENDARINIT" deletes everything in view for creating new activity,
                    // so we have to put data back(if the were initialized) before dsProcess
                    self.dsAdd('set', view.prefix, storeDataView);
                    self.dsAdd('set', wayPreserveChangesAndFieldsOnViews, preserveChangesAndFieldsOnViews);
                }
            });
        }
        self.dsProcess();
    }

    const eventData = data.eventData;

    self.dsClear();

    views.forEach((view) => {
        const content = {
            id: null,
            actionButtons: [],
            module: view.module,
            subpanels: [],
        };

        const storeDataView = saveViewWithDefinedFields[view.prefix];
        // [PREDEFINED FIELDS] if we have stored data, don't initialize fields again
        if (!useIsEmptyObject(storeDataView?.toJS().fields) && preserveChangesAndFieldsOnViews) {
            return;
        }
        self.detailContent(view.data, content);

        content.subpanels = [];

        const action = {
            type: 'PAGELOADDETAIL',
            content: content,
            prefix: view.prefix,
            function: self,
        };
        self.dsAddCustom(action);
    });

    let action = {
        type: 'CALENDARLOADQUICKFORM',
        content: {
            invitees: invitees ?? {Users: [], Contacts: [], Leads: []},
        },
        prefix: paramData.prefix + '/invitees',
        prefixBackup: paramData.prefix + '/inviteesBackup',
    };
    self.dsAddCustom(action);
    self.dsProcess();
    if (!self.calEvents) {
        self.calEvents = [];
    }
    self.calEvents.calendars = [];

    eventData.calendars.forEach((cal) => {
        // default je tu, aby se vyresetovalo pro sdileny pohled
        const pomEvents = {
            'Meetings': [],
            'Calls': [],
            'Tasks': [],
        };

        cal.Meetings.forEach((act) => {
            pomEvents.Meetings.push(act);
        });

        cal.Calls.forEach((act) => {
            pomEvents.Calls.push(act);
        });

        cal.Tasks.forEach((act) => {
            pomEvents.Tasks.push(act);
        });

        self.calEvents.calendars[cal.id] = {
            id: cal.id,
            events: pomEvents,
        };
    });

    callback(self, false);
}
