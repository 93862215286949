/**
 *
 * @param id
 * @param props
 */
export default function emailChangeTemplate(id, props) {
    const parseData = {
        id,
        sendTo: props.data.to_addrs,
        parent_type: props.data.parent_type,
        parent_id: props.data.parent_id,
    };

    this.load();

    this.rest.post('email/getEmailTemplate', parseData, (data) => {
        this.dsClear();
        if (data != null && typeof data === 'object') {
            let content = this.htmlDecode(data.body_html || '');
            if (content.match(/<\/body>/)) {
                content = content.replace('</body>', props.data.signature + '</body>');
            } else {
                content += props.data.signature;
            }

            this.dsAdd('set', props.way + '/name', data.subject || '');

            data.to_addrs && this.dsAdd('set', props.way + '/to_addrs', data.to_addrs);

            if (data.cc_addrs) {
                this.dsAdd('set', props.way + '/cc_addrs', data.cc_addrs);
                this.dsAdd('set', props.way + '/ccExpanded', true);
            }

            if (data.bcc_addrs) {
                this.dsAdd('set', props.way + '/bcc_addrs', data.bcc_addrs);
                this.dsAdd('set', props.way + '/bccExpanded', true);
            }

            this.dsAdd('set', props.way + '/emailTemplate', id);
            this.dsAdd(
                'set',
                props.way + '/description_html',
                this.emailAddVariableHighlights(content),
            );
            this.dsAdd('set', props.way + '/updateEmailText', true);
        }
        this.dsAdd('set', 'conf/load', false);
        this.dsProcess();
    }, false);

    this.emailGetTemplateAttachments(id, props.way + '/attachments');
}
