import React from 'react';
import PropTypes from 'prop-types';

import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default function Relate(props) {
    const value = props.value;
    const id = props.id;
    const module = props.module;

    let element = null;
    if (sAction.hasAccess(module, 'detail')) {
        element = <a href={`#detail/${module}/${id}`}>
            {sAction.translate(value, module)}
        </a>;
    } else {
        element = <span>
            {sAction.translate(value, module)}
        </span>;
    }
    return (
        <div className={'newListViewLine ' + props.extraClass}
            onClick={(ev) => {
                props.onClick(ev);
            }}>
            {value && value !== ' ' && (
                <TooltipWrapper label={'LBL_OPEN_IN_NEW_WINDOW'}>
                    <a
                        href={`#detail/${module}/${id}`}
                        className={`relatedIcon icon-${module}`}
                        target="_blank" rel="noreferrer"
                    />
                </TooltipWrapper>
            )}
            {id === undefined || id === '' || id == null ? (
                sAction.translate(value, module)
            ) : (
                element
            )}
        </div>
    );
}

Relate.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    module: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    extraClass: PropTypes.string,
};
