/**
 *
 * @param prefix
 * @param saveAttempt
 */
export default function requiredFieldsCheckMsg(prefix, saveAttempt = true) {
    if (!prefix) {
        console.error('Neni vyplnen prefix');
    }
    const customLinesError = this.dataGet(`${prefix}/customData/customLines/showError`);
    if (!this.requiredFieldsCheck(prefix, saveAttempt) || customLinesError) {
        this.error(this.translate('LBL_REQUIRED_FIELDS_NOT_FILLED'));

        return false;
    }

    return true;
}
