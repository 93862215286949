/**
 *
 * @param field
 * @param func
 * @param prefix
 */
export default function addFieldFunction(field, func, prefix) {
    this.dataSet(prefix+'/fieldFunction/parameters/'+field, func);
    this.reloadList(0, 1, prefix);
}
