/**
 *
 * @param way
 * @param value
 * @param like
 * @param likeInList
 */
export default function dataSet(way, value, like = null, likeInList = null) {
    this.dataArraySet([{way: way, value: value, like: like, inList: likeInList}]);
}
