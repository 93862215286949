/**
 *
 * @param params
 */
export default function rightPanelProduct(params) {
    this.openRightPanel('load');

    // console.log("rightPanelProduct", params);

    this.dsClear();

    const self = this;

    this.rest.post('list/ProductTemplates', {secondView: true}, function(data) {
        const akceRP = {
            type: 'QUOTERPDATAINIT',
            content: {records: data.specRecords},
            prefix: 'rightPanel/data',
        };

        self.dsAddCustom(akceRP);

        const akceTree = {
            type: 'PRODUCTCATEGORIESTREE',
            content: data.productCatogories,
            prefix: 'rightPanel/data/categories',
        };
        self.dsAddCustom(akceTree);

        const content = self.listContent(data);

        const akce ={
            type: 'PAGELOADLIST',
            page: 'ProductTemplates',
            content: content,
            buttons: [],
            function: self,
            listViewPrefix: 'rightPanel/data/listView',
        };
        self.dsAddCustom(akce);
        self.dsAdd('set', 'rightPanel/data/groupWay', params.way);
        self.dsAdd('set', 'rightPanel/data/listView/type', 'rightPanelQuotes');
        self.dsAdd('set', 'rightPanel/customWidth', '60%');

        self.dsAdd('set', 'rightPanel/content', 'productView');
        self.dsProcess();
        self.afterLoadListView(params.way);
    });
}
