import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';

export default class NotificationItem extends PureComponent {
    /**
     *
     */
    delete() {
        const index = this.props.index;
        const data = this.props.data;
        sAction.setNotification(data.id, {deleted: 1}, index);
    }
    /**
     *
     * @param seen
     */
    setSeen(seen) {
        const index = this.props.index;
        const data = this.props.data;

        if (seen == '1') {
            sAction.setNotification(data.id, {seen: 0}, index);
        } else if (seen == '0') {
            sAction.setNotification(data.id, {seen: 1}, index);
        }
    }
    /**
     *
     */
    read() {
        const index = this.props.index;
        const data = this.props.data;
        if (data.seen != '1') {
            sAction.setNotification(data.id, {seen: 1}, index);
        }
    }

    render() {
        const data = this.props.data;
        let name = data.name.replace('{RECORD_NAME}', data.record_name);
        name = name.replace(
            '{MODULE_NAME}',
            sAction.app_strings['moduleList'][data.parent_module],
        );
        let description = data.description.replace(
            '{RECORD_NAME}',
            data.record_name,
        );
        description = description.replace(
            '{MODULE_NAME}',
            sAction.app_strings['moduleList'][data.parent_module],
        );
        const src = data.url;

        let containerClass = 'notificationItem';
        if (data.seen == '0') {
            containerClass += ' notSeen';
        }
        containerClass += ' '+data.type;
        const date = sAction.dateFormat(data.notify_date);
        return (
            <div className={containerClass}>
                <a href={src} className="notificationItemText" onClick={() => this.read()}>
                    <div className="notificationItemHeader">{name}</div>
                    <div className="notificationItemDesc">{description}</div>
                    <div className="notificationItemDate">{date}</div>
                </a>
                <div className="notificationItemButtons">
                    <div className="icon-closeIconLight notificationRemoveButton" onClick={() => this.delete()} />
                    <div style={{fontSize: '1.1rem'}}className="icon-eye notificationRemoveButton" onClick={() => this.setSeen(data.seen)} />
                </div>
            </div>
        );
    }
}
