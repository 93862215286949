/**
 *
 * @param {*} params
 * @param {*} callback
 */
export default function calEventsSearch(params, callback) {
    this.rest.post('calEventsSearch', params, function(data) {
        callback(data);
    });
}
