import React, {PureComponent} from 'react';
import MaterialPopper from '@mui/material/Popover';
import PopperAnimation from '../animation/PopperAnimation';
import PropTypes from 'prop-types';

export default class MoreOptions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            top: null,
            left: null,
            loading: false,
        };
        this.button = React.createRef();
        this.popup = React.createRef();
    }
    /** */
    open() {
        const viewportOffset = this.button.current.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        const top = viewportOffset.top;
        const left = viewportOffset.left;
        this.setState({
            open: !this.state.open,
            left: left,
            top: top,
        });
        if (this.props.onRight) {
            setTimeout(() => {
                const el = document.querySelector('.acmMoreOptionsPopup .MuiPopover-paper');
                const st = window.getComputedStyle(el, null);
                let transform = st.getPropertyValue('transform-origin');
                transform = transform.split(' ');
                let x = parseFloat(transform[0].replace('px', ''));
                if (this.props.fromRight != null) {
                    x = x - this.props.fromRight;
                }
                el.style.left = parseFloat(el.style.left.replace('px', '')) + x;

                if (el.offsetHeight+top < window.innerHeight) {
                    el.style.top = top;
                }
            }, 30);
        }
    }
    /**
     * @param {Event} e
     * @param {*} value
     */
    onClick(e, value) {
    // prevent doubleClick
        if (this.state.loading) {
            return;
        }
        this.setState({
            loading: true,
            open: false,
        });

        if (this.props.onClick != null) {
            this.props.onClick(e, value);
        }

        setTimeout(() => {
            this.setState({loading: false});
        }, 500);
    }

    render() {
        const options = this.props.options;
        const optionsRender = [];
        if (options != null) {
            options.forEach((button) => {
                const style = {};
                if (this.props.width != null) {
                    style['width'] = this.props.width+'px';
                }

                optionsRender.push(<div
                    style={style}
                    className="moreOptionMenuItem" key={button.value} onClick={(e) => this.onClick(e, button.value)}>
                    {button.icon != null &&
        <div className={'moreOptionIcon '+button.icon} />
                    }
                    {button.label}</div>);
            });
        }

        return (
            <div className="moreOptionsContainer">
                <div className="moreOptionsButton" ref={this.button} onClick={() => this.open()}>
                    {this.props.children}
                </div>
                <MaterialPopper
                    className="acmMoreOptionsPopup"
                    anchorEl={this.button.current}
                    open={this.state.open}
                    onClose={() => this.setState({open: false})}
                >
                    <PopperAnimation open={this.state.open}>
                        {optionsRender}
                    </PopperAnimation>
                </MaterialPopper>
            </div>
        );
    }
}

MoreOptions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    width: PropTypes.number,
    options: PropTypes.any,
    onClick: PropTypes.func,
    fromRight: PropTypes.any,
    onRight: PropTypes.any,
};
