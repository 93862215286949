import React, {PureComponent} from 'react';
import Button from '../../../formElements/Button';
import sAction from 'sAction';

export class EmailPopupButtons extends PureComponent {
    hidePopup = () => {
        sAction.popupHide();
    };
    render() {
        return (
            <div className="acmPopupButtons">
                <Button className="hoverGreen" onClick={this.props.onSave}>
                    <div className="actionPanelButtonIcon icon-saveIcon" /> {sAction.translate('LBL_SAVE', 'Home')} {/* Uložit */}
                </Button>
                <Button className="hoverRed" onClick={this.hidePopup}>
                    <div className="actionPanelButtonIcon icon-deleteIcon" /> {sAction.translate('LBL_CANCEL', 'Home')} {/* Zrušit */}
                </Button>
            </div>
        );
    }
}
