import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';
import CalExtEvent from './calExtEvent';

class calExternalEvents extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            eventsPayed: [],
            eventsInProcess: [],
            changeMe: true, // docasna hodnota. Kdyz menim komplet subtabs, tak se nespusti render, takze zatim forcuju takhle
            subtabs: {
                payed: true,
                inProgress: true,
            },
        };
    }

    // calendarRef = React.createRef();
    componentDidUpdate(prevProps) {
        if (this.props.reload !== prevProps.reload) {
            this.getExtEvents();
        }
    }
    componentDidMount() {
        this.getExtEvents();
    }

    /**
     *
     */
    getExtEvents() {
    // mozna by jsme mohli tahat jen data pro otevrene sekce, ale uvidime jestli bude potreba
        const getEvents = {
            Payed: {
                module: 'orders',
                filter: 'Payed',
                date_start: '',
                date_end: '',
            },
            InProcess: {
                module: 'orders',
                filter: 'InProcess',
                date_start: '',
                date_end: '',
            },
        };
        getEvents.forEachObject((value, key) => {
            sAction.calGetExternalEvents(value.module, value.filter, {
                'date_start': value.date_start,
                'date_end': value.date_end,
            }, (events) => {
                if (events.status) {
                    const name = 'events' + key;
                    this.setState({[name]: events.message.data});
                } else {
                    sAction.alert(events.errorMessage.text);
                }
            });
        });

        sAction.calPlanReload(); // aby se nastavili jako draggable, tak je treba prenacist kalendare ... muzeme zkusit vymyslet jinak
    }

    render() {
        return (
            <div
                id="external-eventsNew"
                className={'filter-externalEvents'}
                style={{
                    // padding: "10px",
                    width: '100%',
                    height: 'auto',
                    maxHeight: '-webkit-fill-available',
                }}
            >
                {/* //TODO predelat do komponenty podle typu eventu*/}
                <div className={'externalEventsSubtab'}>
                    <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('payed')}>
                        <span style={{'width': '100%'}}>
                            <div className={'calEventIcon icon-acm_orders'}/><strong> Zaplacené </strong><div className={'calEventIcon float-right icon-'+(this.state.subtabs.payed?'minus':'plus')}/>
                        </span>
                    </div>
                    {this.state.subtabs.payed && (
                        <div className={'filterRowState'}>
                            {this.state.eventsPayed.map((event) => (
                                <CalExtEvent event={event} key={'ee-' + event.id}/>
                            ))}
                        </div>
                    )}
                </div>
                <div className={'externalEventsSubtab'}>
                    <div className={'calFiltersRow sectionRow'} onClick={() => this.handleSubtabSelect('inProgress')}>
                        <span style={{'width': '100%'}}>
                            <div className="calEventIcon icon-acm_orders"/><strong> Rozpracované </strong><div className={'calEventIcon float-right icon-'+(this.state.subtabs.inProgress?'minus':'plus')}/>
                        </span>
                    </div>
                    {this.state.subtabs.inProgress && (
                        <div className={'filterRowState'}>
                            {this.state.eventsInProcess.map((event) => (
                                <CalExtEvent event={event} key={'ee-' + event.id}/>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handleSubtabSelect = (subtabID) => {
        const newState = this.state.subtabs;
        newState[subtabID] = !newState[subtabID];
        this.setState({
            subtabs: newState,
            changeMe: !this.state.changeMe,
        }); // TODO najit reseni bez changeMe BS
    };
}

export default calExternalEvents;
