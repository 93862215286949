/**
 * @desc store fields to sAction.predefinedFields and function sAction.fillDetailPredefinedFields(...) fills them to detail
 * @param {object} param
 * @this sAction
 * @button DetailView
 */
export default function duplicateRecord(param) {
    const module = param.module;
    this.detailPredefinedFields = [];
    const detail = this.dataGet(param.prefix);
    if (param.type === 'rightPanel') {
        this.dataSet('rightPanel/closeOnSave', true);
    }
    this.dsClear();
    const ignoreFields = [
        'assigned_user_link',
    ];

    detail.fields.entrySeq().forEach((e) => {
        const name = e[0];
        const field = e[1];
        const value = field.value;
        const def = field.def.toJS();
        const type = def.type;

        if (def.forbidDuplicate === true) {
            // zakladni pole (id, date_entered, date_modified) maji nastaveny forbid v site/include/SugarObjects/templates/basic/vardefs.php
            // assigned assigned_user_name, assigned_user_id v \site\include\SugarObjects\implements\assignable\vardefs.php
            return; // funguje jako continue
        }
        if (ignoreFields.includes(name)) {
            return; // funguje jako continue
        }

        switch (type) {
            case 'parent':
                this.detailPredefinedFields.push({
                    name: name,
                    parentName: value,
                    parentId: def.id_value,
                    parentModule: def.parent_type_value,
                    type: 'parent',
                });

                return; // funguje jako continue

            case 'relate':
                if (!value) {
                    return; // funguje jako continue
                }
                if (!def.id_value) {
                    return; // funguje jako continue
                }
                this.detailPredefinedFields.push({
                    name: def.id_name,
                    fieldName: name,
                    value: {name: value, id: def.id_value},
                    type: 'relate_simple',
                });

                return; // funguje jako continue

            case 'email':
                this.detailPredefinedFields.push({name: name, type: 'email', value: def.emails});

                return; // funguje jako continue

            case 'link':
                // value muze byt:
                // 1)   id navazane polozky
                // 2)   nazev navazane polozky
                // 3)   prazdnej objekt

                if (!value) { // existuje ID vazby
                    return; // funguje jako continue
                }

                if (Object.getPrototypeOf(value) === Object.prototype) { // pokud je prazdnej objekt, vyskoc
                    return; // funguje jako continue
                }

                // eslint-disable-next-line no-case-declarations
                let additionalField = detail.fields.get(def.relationship); // definice, ve ktere je ulozeno jmeno navazane polozky
                // eslint-disable-next-line no-case-declarations
                const relatedName = additionalField?.value; // jmeno navazane polozky
                if (!relatedName) {
                    return; // funguje jako continue
                }
                if (relatedName !== value) { // nasel jsem ID a jmeno navazane polozky
                    this.detailPredefinedFields.push({
                        name: name,
                        fieldName: `${def.relationship}_name`,
                        value: {name: relatedName, id: value},
                        type: 'relate_simple',
                    });

                    return; // funguje jako continue
                } // => pokud je value jmeno navazane polozky, chybi mi ID a nemuzu dokoncit vazbu a hledam ID:

                additionalField = detail.fields.get(def.id_name);
                // eslint-disable-next-line no-case-declarations
                const id = additionalField?.value;
                if (!id) {
                    return; // funguje jako continue
                }
                if (id !== value) { // nasel jsem ID a jmeno navazane polozky
                    this.detailPredefinedFields.push({
                        name: def.id_name,
                        fieldName: `${def.relationship}_name`,
                        value: {name: value, id: id},
                        type: 'relate_simple',
                    });

                    return; // funguje jako continue
                }

                return; // funguje jako continue

            case 'Ss':
                this.detailPredefinedFields.push({name: name, type: 'Ss', value: def.selected});

                return; // funguje jako continue

            case 'image':
                // kopie image na BE
                // eslint-disable-next-line no-case-declarations
                const result = this.filesXhrSynchr({
                    action: 'duplicateImage',
                    data: {id: value}, // posilam ID obrazku
                });
                if (result.status === 200) {
                    this.detailPredefinedFields.push({name: name, value: result.data.id, type: 'field'});
                }

                return; // funguje jako continue

            case 'hoursMinutes':
                this.detailPredefinedFields.push({name: name, value: value, valueMinutes: def.minutesValue, type: 'hoursMinutes'});

                return; // funguje jako continue

            default:
                // pokud je to klasickej field
                this.detailPredefinedFields.push({name: name, value: value, type: 'field'});

                return; // funguje jako continue
        }
    });

    const customData = this.dataGet(`${param.prefix}/customData`)?.toJS();
    if (customData) {
        if (customData.customLines) {
            // potrebuju promazat ID aby se vytvorili nove zaznamy pro duplikovanou vec
            customData?.customLines?.lines?.forEachObject((line) => {
                line.id = null;
            });
        }
        if (customData.productData) {
            customData?.productData?.lines?.forEachObject((line) => {
                line.id = null;
            });
            customData?.productData?.groups?.forEachObject((line) => {
                line.id = null;
            });
        }
        this.detailPredefinedFields.push({value: customData, type: 'customData'});
    }
    this.dsProcess();

    // zobrazeni noveho zaznamu
    switch (param.type) {
        case 'rightPanel':
            this.rightPanelDetail(module, '');
            break;

        case 'popup':
            // teoreticky by nemelo nastat, ulozeny detail se nikde neda otevrit
            this.popupDetail({
                module: module,
                record: '',
            });
            break;
        default:
            window.location.hash = '#detail/' + module;
            break;
    }
}
