import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailSpecialPanelsContainer from 'ROOT/src/components/DetailViewNew/DetailPanels/DetailSpecialPanelsContainer';
import DetailSubpanelsContainer from 'ROOT/src/components/DetailViewNew/DetaulSubpanels/DetailSubpanelsContainer';
import Loader from 'ROOT/src/components/loader';
import BoundModules from 'ROOT/src/components/DetailViewNew/BoundModules/BoundModules';
import sAction from 'sAction';

const CustomDetailLines = React.lazy(() => import('ROOT/src/components/detailView/CustomLines/CustomDetailLines'));

export default function DetailPanelsContainer(props) {
    const opportunitiesSupanel = sAction.dataGet('conf/opportunities_subpanel');
    const customLines = opportunitiesSupanel !== 'new' && props.module === 'Opportunities' ? false : true;

    return (
        <div className="detailPanels">
            <DetailSpecialPanelsContainer
                customData={props.customData}
                module={props.module}
                id={props.id}
                prefix={props.prefix}
                way={`${props.prefix}/customData`}
                acl={props.acl}
                readonly={props.readonly}
                type={props.type}
            />
            {!props.id && props.boundModules &&
                <Suspense fallback={<Loader/>}>
                    <BoundModules
                        boundModules={props.boundModules}
                        prefix={`${props.prefix}/boundModules`}
                    />
                </Suspense>
            }
            {
                // detail lines must have date, on BE in Detail.php allow customData for rightpanel
                props.customData?.get?.('customLines') &&
                !['popup'].includes(props.type) && customLines && (
                    <Suspense fallback={<Loader/>}>
                        <CustomDetailLines
                            module={props.module}
                            way={props.prefix}
                            prefix={props.prefix}
                            id={props.id}
                            data={props.customData.get?.('customLines')}
                            type={props.type}
                            acl={props.acl}
                        />
                    </Suspense>
                )
            }
            {(props.id
            ) && (
                <DetailSubpanelsContainer
                    subpanelsTabs={props.subpanelsTabs}
                    activeSubpanelTab={props.activeSubpanelTab}
                    subpanels={props.subpanels}
                    shouldFetchSubpanels={props.shouldFetchSubpanels}
                    prefix={props.prefix}
                    acl={props.acl}
                    module={props.module}
                    id={props.id}
                    way={`${props.prefix}/subpanels`}
                />
            )}
        </div>
    );
}

DetailPanelsContainer.propTypes = {
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    activeSubpanelTab: PropTypes.number,

    prefix: PropTypes.string,
    type: PropTypes.string,
    module: PropTypes.string,
    id: PropTypes.string,
    subpanels: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            module: PropTypes.string,
            def: ImmutablePropTypes.map,
            rows: ImmutablePropTypes.record,
        }),
    ),
    customData: PropTypes.any,
    boundModules: ImmutablePropTypes.listOf(ImmutablePropTypes.record),
    shouldFetchSubpanels: PropTypes.bool.isRequired,
    readonly: PropTypes.bool,
    subpanelsTabs: PropTypes.any,
};
