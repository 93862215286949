/**
 * zmaže aktuální textové vyhledávání pro daný modul
 *
 * @param {string} module modul, které mu se maže vyhledávání
 * @param {string} prefix bere se z něj view, pro jterý je vyhledávání mazáno
 */
export default function clearSearchContext(module, prefix) {
    const view = prefix.split('/').slice(-1)[0];
    const searchContext = this.getStorage('searchContext');

    if (searchContext && searchContext[`${module}_${view}`]) {
        searchContext[`${module}_${view}`] = {
            searchData: [],
        };
    }
    this.setStorage('searchContext', searchContext);
}
