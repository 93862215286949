/**
 * Open right panel with activity timeline
 * @param {*} data
 */
export default function openActivityTimeline(data) {
    this.openRightPanel('load');
    this.dsClear();
    this.dataSet('rightPanel/data', data, 'ActivitiesTimelineView');
    this.dataSet('rightPanel/data/status', 'Planned');
    this.reloadActivitiesPanel('rightPanel/data');
}
