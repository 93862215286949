/**
 * @param {object} sendData
 * @param {string} prefix
 * @this sAction
 * @TODO rest.post => rest.fetchData
 */
export default function finstat(sendData, prefix = 'view') {
    this.load();
    const url = sendData.ico ? 'finstat' : 'finstat2';
    if (!sendData.ico && !sendData.name) {
        this.error(this.translate('LBL_FINSTAT_ERR', 'Accounts'));
        this.unLoad();

        return;
    }
    this.rest.post('detailapi/' + url, sendData, (data) => {
        data = JSON.parse(data);
        if (data.state === false) {
            this.error(this.translate(data.message, 'Accounts'));
            this.unLoad();

            return;
        }

        this.unLoad();

        const fields = ['billing', 'primary', 'Contactsprimary'];

        fields.forEach((field) => {
            data.forEachObject((v, k) => {
                if (k.charAt(0) === '_') {
                    data[field + k] = v;
                    delete data[k];
                }
            });
        });

        if (typeof data !== 'object') {
            this.error(this.translate('LBL_ARES_NO_DATA', 'Accounts'));

            return;
        }

        if (!data.num) {
            this.error(
                this.translate('LBL_ARES_NOT_FOUND', 'Accounts'),
            );

            return;
        }

        if (data.num && data.num > 1) {
            delete data.num;
            const params = {
                'header': this.translate('LBL_VYBER', 'acm_reports'),
                'accounts': data,
            };
            this.popup('finstatChooseAccountPopup', params );

            return;
        }

        this.updateFields(data, prefix);

        if (data.ticker_symbol) {
            this.checkICO(data.ticker_symbol);
        }
    });
}
