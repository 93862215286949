/**
 * checks password against given requirements
 * @param {string}  password            password to check
 * @param {number}  minLength           minimum required length of password
 * @param {number}  maxLength           maximum length of password
 * @param {Array}   extraRequirements   array of objects containing regex to check for
 * @returns {boolean} true if password is valid
 */
export default function checkPassword(password, minLength, maxLength, extraRequirements) {
    if (password.length < minLength || password.length > maxLength) {
        return false;
    }
    return extraRequirements.map((item) => password.match(item.regex)).every((x) => x);
}
