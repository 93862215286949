/**
 *
 * @param json
 */
export default function fromJSON(json) {
    try {
        const obj = JSON.parse(json);
        return obj;
    } catch (error) {
        console.log('Špatný formát json');
        console.log(json);
        return null;
    }
}
