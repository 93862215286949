/**
 *
 * @param oldUrl
 */
export default function needSave(oldUrl = null) {
    let view = this.getViewName();
    if (oldUrl != null) {
        try {
            oldUrl = oldUrl.split('#');
            oldUrl = oldUrl[1].split('/');
            oldUrl = oldUrl[0];
            view = oldUrl;
        } catch (e) {
            view = '';
        }
    }
    if (view == 'detail') {
        const field_changes = this.dataGet('view/changes/fields');
        const file_changes = this.dataGet('view/changes/files');
        const forceChange = this.dataGet('view/changes/forceChange');

        if (field_changes && field_changes.size > 0 || file_changes && file_changes.size > 0 || forceChange) {
            return true;
        }
    }

    return false;
}
