/**
 * Loads data from backend for AcmRestEnum
 * "return" Options for option list
 *
 * @param {string} prefix
 * @param {string} way
 * @param {Map} fieldDef
 * @param {function} callback
 */
export default function openAcmRestEnum(prefix, way, fieldDef, callback) {
    const params = {
        endPoint: fieldDef.get('endPoint'),
        optionsKey: fieldDef.get('optionsKey'),
        id: this.dataGet(`${prefix}/id`),
    };
    this.rest.fetchData(params.endPoint, 'POST', params, false).then((returnData) => {
        const options = returnData['data']['options'];
        let linesOptions = [];
        if (returnData['data']['canAddNew'] === true) {
            linesOptions.push({
                label: this.translate('LBL_ADD_NEW_VALUE'),
                value: 'acm_add_new',
                type: 'button',
            });
        }
        options.forEachObject((line, index) => {
            const option = {
                value: index,
                label: line,
            };
            linesOptions.push(option);
        });
        linesOptions = processOptions(linesOptions);
        callback(linesOptions);
    }) .catch((returnData) => {
        this.unLoad();
        this.error(this.translate(returnData['text']));
    });
}

/**
 *
 * @param {array} options
 * @returns {*}
 */
const processOptions = (options) => {
    const retOptions = options.sort((a, b) => {
        if (a.value === 'acm_add_new') {
            return -1;
        }
        if (b.value === 'acm_add_new') {
            return 1;
        }

        return a.label.localeCompare(b.label);
    });

    return retOptions;
};
