/**
 *
 * @param noteId
 * @param way
 */
export default function reuploadEmailFiles(noteId, way) {
    const formData = {
        noteId,
    };
    const list = this.dataGet(way);
    if (list.listViewTree !== undefined) {
        if (list.listViewTree !== null) {
            const activeCategory = list.listViewTree.get('activeCategory');
            const fieldName = list.listViewTree.get('field');
            formData['fieldData'] = {};
            formData['fieldData'][fieldName] = activeCategory;
        }
        // ______________________________________ if is subpanel
        if (list.type == 'subpanel') {
            var relData = this.dataGet(way + '/../def/rel');
            const parentId = this.dataGet(way + '/../../../id');

            var relData = {
                module: relData.get('module'),
                relname: relData.get('relName'),
                id: parentId,
            };
            formData['relData'] = relData;
        }

        // ______________________________________ if is subpanel
        this.dataSet(way+'/dropZoneActive', false);
        this.filesXhr({
            action: 'reuploadEmailFile',
            data: formData,
            success: (data) => {
                if (data.state == true) {
                    this.reloadList(0, 1, way);
                }
            },
        });
    }
}
