/**
 * vepise data v url parametrech do fieldu na detailu
 * @param prefix
 * @example http://localhost:8080/#detail/Calls/?parent_type=Opportunities&parent_id=181ac4da-bc3c-d9c9-1f8b-59dc81b34629&parent_name=registr%20smluv
 *
 */

/**
 *
 * @param prefix
 */
export default function detailUrlParamsToFields(prefix) {
    let url = window.location.hash;

    if (!/\?/.test(url)) return;

    url = url.replace(/.*\?/, '');

    url = JSON.parse(
        '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function(key, value) {
            return key === '' ? value : decodeURIComponent(value);
        },
    );

    // zpracovani fieldu Tyka se: (parent_name, parent_id, parent_type)
    if (url.parent_type) {
        this.saveField(
            {
                cancelEdit: false,
                fieldName: 'parent_name',
                name: 'parent_id',
                parent_type: url.parent_type,
                type: 'parent',
                value: {id: null, name: null},
                way: prefix + '/fields/parent_name',
            },
            true,
        );

        delete url.parent_type;
    }

    if (url.parent_name) {
        this.saveField(
            {
                fieldName: 'parent_name',
                name: 'parent_id',
                type: 'relate',
                value: {id: url.parent_id, name: url.parent_name},
                way: prefix + '/fields/parent_name',
            },
            true,
        );

        delete url.parent_name;
        delete url.parent_id;
    }

    // lze libovolne rozsirit a zpracovat dalsi parametry, budto obecne nebo nejak konkretne na dalsi fieldy...

    url.forEachObject((v, k) => {
        console.error('nezpracovany url parametr:', k + '=' + v);
    });
}
