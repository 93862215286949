/**
 * translateEnum - translate enum
 * module optional
 * on empty string return enum name
 * @param {string} enumName
 * @param {string} key
 * @param {string} module
 * @returns {string}
 */
export default function translateEnum(enumName, key, module = null) {
    return this.translate(enumName, module, key, true);
}
