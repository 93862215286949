/**
 * @param {object} paramData
 * @this sAction
 * @button DetailView
 */
export default function saveRecordLeave(paramData) {
    const self = this;
    this.saveRecordBase(paramData, (data) => {
        if (data.state === true) {
            const msg = data.data;
            self.dsClear();
            self.dataSet(paramData.prefix + '/changes', {fields: [], files: []});
            // if(this.getModuleName() != paramData.module){
            if (paramData.type === 'rightPanel') {
                this.dataSet('rightPanel/reloadOnClose', true);
                this.reloadListOnClose();
                this.closeRightPanel();
            } else if (this.checkPreviousUrl()) {
                history.back();
            } else {
                window.location.hash = '#list/' + msg.module;
            }
        } else {
            self.error(data.msg);
        }
    });
}
