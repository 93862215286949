/**
 * @param {string} way
 * @this sAction
 */
export default function saveSubpanelOrder(way) {
    const data = this.dataGet(way);
    const module = data.module;
    const subpanels = data.subpanels;

    const subpanelsNewOrder = {};
    subpanels.forEach((subpanel, key) => {
        subpanelsNewOrder[subpanel.module] = {
            order: key,
            open: subpanel.def.get('open'),
        };
    });

    const newData = {
        category: module+'_subpanel',
        contents: {
            all: subpanelsNewOrder,
        },
    };
    // Coripo pri ukladani preferenci nemusi cekat na navrat
    // this.load();
    this.rest.post('userPrfUpdate', newData, () => {
    // if(returnData.state === true){
    //     this.unLoad();
    // }
    });
}
