import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import Loader from '../loader';
import Button from 'ROOT/src/components/formElements/Button';

export default class Report extends PureComponent {
    constructor() {
        super();
        this.state = {
            acmDate: false,
            acmDateValue: null,
        };
        this.reload = true;
        this.iframe = React.createRef();
    }
    componentDidMount() {
        let id = null;
        const way = this.props.way;
        if (this.props.reportId !== undefined) {
            id = this.props.reportId;
        } else {
            id = window.location.hash.split('/');
            id = id[1];
        }
        let paramData = {};
        if (this.props.paramData !== undefined) {
            paramData = this.props.paramData;
        }

        paramData['isWidget'] = this.props.widget;
        paramData['way'] = this.props.way;
        paramData['reportId'] = this.props.reportId;
        paramData['widgetId'] = this.props.widgetId;
        paramData['widget'] = this.props.widget;
        paramData['dashboardVersion'] = this.props.dashboardVersion;
        paramData['style'] = sAction.getStorage('style');
        sAction.loadCustomReport(id, way, paramData);
        const self = this;
        this.listener = function(evt) {
            self.iframeMessage(evt);
        };
        window.addEventListener('message', this.listener);
    }

    componentDidUpdate() {
        const data = this.props.data;

        let html = '';
        try {
            html = data.get('html');
        } catch (e) {
            // byl tu prazdny catch
            console.log('empty catch!'); // eslint-disable-line
        }

        this.iframe.current.contentWindow.postMessage(this.props.dashboardVersion, '*');

        if (this.reload) {
            this.iframe.current.contentWindow.document.open();
            this.iframe.current.contentWindow.document.write(html);
        }

        if (!html) {
            let id = null;
            const way = this.props.way;
            if (this.props.reportId !== undefined) {
                id = this.props.reportId;
            } else {
                id = window.location.hash.split('/');
                id = id[1];
            }

            let paramData = {};
            if (this.props.paramData !== undefined) {
                paramData = this.props.paramData;
            }

            paramData['isWidget'] = this.props.widget;
            paramData['isDashboard'] = this.props.isDashboard;
            paramData['way'] = this.props.way;
            paramData['reportId'] = this.props.reportId;
            paramData['widgetId'] = this.props.widgetId;
            paramData['widget'] = this.props.widget;
            paramData['dashboardVersion'] = this.props.dashboardVersion;
            paramData['style'] = sAction.toBase64(sAction.getStorage('style'));
            sAction.loadCustomReport(id, way, paramData);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.listener);
    }

    /**
     * Processing of the iframe request
     * @param {*} message
     * @returns
     */
    iframeMessage(message) {
        let data = message.data;
        let id = null;
        if (this.props.reportId !== undefined) {
            id = this.props.reportId;
        } else {
            id = window.location.hash.split('/');
            id = id[1];
        }
        const way = this.props.way;

        // vyjimka aby se na home dashboardu neprenacitaly vsechny
        // reporty, ale jen ten, ktery uzivatel odeslal
        if (data.data && data.data.way && data.data.way !== way) {
            return;
        }

        if (data.action === 'submit') {
            sAction.dataSet(way+'/html', 'load');

            data.data['widgetId'] = this.props.widgetId;
            data.data['isWidget'] = this.props.widget;

            sAction.loadCustomReport(id, way, data.data);
        } else if (data.action === 'href') {
            if (data.target === '_blank') {
                window.open(data.url, '_blank');
            } else {
                window.location = data.url;
            }
        } else if (data.action === 'ajax') {
            sAction.customReportAjax(id, data, (returnData) => {
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'relate') {
            data = {
                module: data.module,
                defaultFilter: data?.defaultFilter,
                selectedActive: data.selectedActive,
            };
            sAction.openRelatePopup(data, (returnData) => {
                sAction.popupHide();
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'download') {
            sAction.downloadFile({
                id: '',
                name: data.file,
                module: 'acm_coripoReports',
                params: {
                    file: data.file,
                },
            });
        } else if (data.action === 'message') {
            this.reportMessage(data.data, (returnData) => {
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'setTitle') {
            document.title = data.data;
            if (!sAction.isMobile() && document.querySelector('.headerBreadCrumb span')) {
                document.querySelector('.headerBreadCrumb span').innerHTML = data.data;
            }
        } else if (data.action === 'accSwitchDashboard') {
            this.props.callback(data.data.company, data.data.init);
        } else if (data.action === 'createMktPopupNew') {
            sAction.CreateMktPopup({data: data, mode: 'new'}, (returnData) => { // eslint-disable-line
                sAction.popupHide();
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'createMktPopupExisting') {
            sAction.CreateMktPopup({data: data, mode: 'edit'}, (returnData) => { // eslint-disable-line
                sAction.popupHide();
                this.iframe.current.contentWindow.postMessage(returnData, '*');
            });
        } else if (data.action === 'openActivityTimeline') {
            sAction.openActivityTimeline(data);
        } else if (data.action === 'toggleFullscreen') {
            sAction.toggleFullscreen();
        } else if (data.action === 'openRightPanel') {
            if (data.data?.flag === 'serviceCalendar') {
                this.reload = false;
            }
            sAction.rightPanelDetail(data.data.module, data.data.id);
        }
    }

    /**
     * processing of the report request
     * @param {*} data
     * @param {*} callback
     */
    reportMessage(data, callback) {
        if (data.action === 'acmDate') {
            sAction.popup('acmDate', {value: data.data.value, iframeReport: true, callback: (date) => {
                callback(date);
            }});
        }
        if (data.action === 'acmDateTime') {
            sAction.popup('acmDateTime', {value: data.data.value, iframeReport: true, callback: (date) => {
                callback(date);
            }});
        }
    }
    render() {
        const data = this.props.data;
        let load = false;
        if (data == null) {
            load = true;
        } else {
            if (data.get('html') === '' || data.get('html') === 'load') {
                load = true;
            }
        }
        const isDashboard = this.props.paramData?.isDashboard ?? false;
        const isWidget = this.props.widget ?? false;
        const mobileHref = sAction.isMobile() ? '/mobile' : '';

        return (
            <div className="reportViewContainer">
                {!isDashboard && !isWidget && (<div className="viewActionPanel">
                    <div className="viewActionPanelButtons">
                        <Button href={`${mobileHref}/#list/acm_coripoReports`} key={'returnChanges'}>
                            <>
                                <div className={'actionPanelButtonIcon ' + 'icon-undo'}/>
                                {sAction.app_strings['LBL_RETURN_BACK']}
                            </>
                        </Button>
                        <Button
                            href={`${mobileHref}/#customReports/${this.props.data?.get('reportId')}`}
                            key={'openNewTab'}
                            target='_blank'>
                            <React.Fragment>
                                <div className={'actionPanelButtonIcon ' + 'icon-newTab'} />
                                {sAction.app_strings['LBL_OPEN_NEW_TAB']}
                            </React.Fragment>
                        </Button>
                    </div>
                </div>)}
                <div className="customReportContainer">
                    <div className="customReport">
                        {load && <Loader/>}
                        <iframe style={load ? {display: 'none'} : null} ref={this.iframe}/>
                    </div>
                </div>
            </div>
        );
    }
}

Report.propTypes = {
    callback: PropTypes.func,
    dashboardVersion: PropTypes.any,
    data: PropTypes.shape({
        get: PropTypes.func,
    }),
    paramData: PropTypes.shape({
        isDashboard: PropTypes.bool,
    }),
    reportId: PropTypes.any,
    way: PropTypes.string,
    widget: PropTypes.any,
    widgetId: PropTypes.any,
    isDashboard: PropTypes.bool,
};
