/**
 *
 * @param data
 */
export default function removeFilter(data) {
    const filterGroup = {
        'filters': data.filters,
        'operator': data.operator,
        'field': data.field,

    };

    const akce = {
        type: 'REMOVEFROMFILTER',
        listViewPrefix: data.prefix,
        filter: filterGroup,
    };
    this.dataSetCustom(akce);
    let list = null;

    if (this.getViewName() === 'import') {
        list = this.setImportListFilter(data);
    }

    this.reloadList(0, 1, data.prefix, null, null, list);
}
