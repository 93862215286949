import React from 'react';
import ListviewMassAssignSS from '../../components/popup/listview/ListviewMassAssignSS';

/**
 *
 * @param params
 */
export default function massAssignSS(params) {
    this.popup(<ListviewMassAssignSS prefix={params.prefix} />);
}
