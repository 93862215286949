/**
 *
 */
export default function routeHome() {
    this.rest.get('home/getdashboard', (data) => {
        let activeId = this.getStorage('homeActiveTab');
        if (activeId == null) activeId = 0;
        if (data?.error?.code === 401) {
            this.dsAdd('set', 'conf/view', 'login');
        }
        this.dsAdd('set', 'view', {activeId}, 'Home');

        if (!data.tabs) {
            data = {'activeId': 0, 'tabs': []};
        }

        // tabs
        this.dsAdd('set', 'view/tabs', []);
        data.tabs.forEach((tab, index) => {
            this.dsAdd(
                'add',
                'view/tabs',
                {name: tab.name},
                'HomeTabs',
            );

            // cols
            tab.cols.forEach((col, colindex) => {
                this.dsAdd(
                    'add',
                    'view/tabs/' + index + '/cols',
                    { },
                    'HomeColumns',
                );

                // rows
                let rowindex = 0;
                col.rows.forEach((row) => {
                    if (!row.name) {
                        return;
                    }
                    let saveData = {};
                    if (row.def && row.def.type == 'view') {
                        saveData = {viewId: row.data.viewId, module: row.data.module, viewData: null};
                        if (row.data.options!==undefined) saveData['options'] = row.data.options;
                    } else {
                        saveData = row.data;
                    }
                    this.dsAdd(
                        'add',
                        'view/tabs/' + index + '/cols/' + colindex + '/rows',
                        {
                            name: row.name,
                            def: row.def,
                            data: saveData,
                        },
                        'HomeColumnsRows',
                    );
                    if (row.def && row.def.type == 'view' && row.data.viewData != '') {
                        const content = this.listContent(row.data.viewData);
                        content.orderBy = row.data.orderBy;
                        content.asc = row.data.asc;
                        const akce = {
                            type: 'PAGELOADLIST',
                            page: row.data.module,
                            content: content,
                            listViewPrefix: 'view/tabs/' + index + '/cols/' + colindex + '/rows/'+rowindex+'/data/viewData',
                            function: this,
                            savedSearch: saveData.viewId,
                        };
                        this.dsAdd('set', akce.listViewPrefix, {});
                        this.dsAddCustom(akce);
                        this.dsAdd('set', 'view/tabs/' + index + '/cols/' + colindex + '/rows/'+rowindex+'/data/viewData/type', 'widget');
                    } else if (row.def && row.def.type == 'calendar') {
                        const prefix = 'view/tabs/' + index + '/cols/' + colindex + '/rows/'+rowindex+'/data';
                        this.routeCalendarDahlet(prefix, row.data.viewData, index+'_'+colindex+'_'+rowindex);
                    }

                    rowindex++;
                });
            });
        });
        this.dsProcess();
    });
}
