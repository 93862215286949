import React, {useEffect, useRef} from 'react';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';

const ResizeAnimation = (props) => {
    const ref = useRef(null);
    const [style, animate] = useSpring(() => ({height: `${props.height}px`, width: `${props.width}px`}), []);

    useEffect(() => {
        animate({
            height: `${ref.current.offsetHeight}px`,
            width: `${ref.current.offsetWidth}px`,
        });
    }, [style, props]);

    return (
        <animated.div className={props.className} style={{overflow: 'visible', ...style}}>
            <div style={{width: 'fit-content'}} ref={ref} className={props.innerClassName}>
                {props.children}
            </div>
        </animated.div>
    );
};

ResizeAnimation.defaultProps = {
    height: 0,
    width: 0,
    className: '',
};

ResizeAnimation.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default ResizeAnimation;
