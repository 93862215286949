import React, {useContext} from 'react';
import {ShortcutProvider, Shortcuts} from '@kovarjan/react-shortcut-manager';
import {ShortcutManagerContext} from 'ROOT/src/contexts/ShortcutManagerContext';
import {Keymap} from './Keymap';
import globalHandler from './Handlers/Global';
import PropTypes from 'prop-types';

/**
 * @constructor
 * @param props
 * @return {JSX.Element}
 * @TODO
 */
export default function ShortcutManager(props) {
    // TODO
    const {callHandler} = useContext(ShortcutManagerContext);
    return (
        <ShortcutProvider shortcuts={Keymap} withGlobals={true}>
            <Shortcuts name={'Default'} global={true} handler={globalHandler} alwaysFire={true}>
                <Shortcuts name={'Default'} handler={callHandler} alwaysFire={true}>
                    {props.children}
                </Shortcuts>
            </Shortcuts>
        </ShortcutProvider>
    );
}

ShortcutManager.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
