import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailViewFieldsManager from 'ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewFieldsManager';
import sAction from 'sAction';

/**
 * @param {string} field
 * @param {string} key
 * @param {int} index
 * @param {number} percent
 * @param {object} props
 * @param {int} columns
 * @return {JSX.Element}
 */
function getFieldRender(field, key, index, percent, props, columns) {
    const fieldData = props.fields?.get?.(field);

    return (
        <DetailViewFieldsManager
            prefix={props.prefix}
            module={props.module}
            way={props.prefix + '/fields/' + field}
            percent={percent}
            key={key}
            index={index}
            rowWay={props.rowWay}
            tabWay={props.tabWay}
            id={props.id}
            readonly={props.readonly}
            acl={props.acl}
            // mode={props.mode}
            attemptedSave={props.attemptedSave}
            name={fieldData.name}
            value={fieldData.value}
            actEdit={fieldData.actEdit}
            customStyle={fieldData.customStyle}
            customClass={fieldData.customClass}
            def={fieldData.def}
            events={fieldData.events}
            selectForEdit={fieldData.selectForEdit}
            group={true}
            fieldColumns={columns}
            panelColumns={props.panelColumns}
        />
    );
}

export default function DetailViewRow(props) {
    return (
        <div className="detailViewTabContentRow">
            {props.row.fields.map((field, index) => {
                const fieldDef = props.fields?.get?.(field?.name);
                if (!fieldDef) {
                    return (
                        <DetailViewFieldsManager
                            mode='empty'
                            fieldColumns={field?.columns}
                            panelColumns={props.panelColumns}
                            key={index}
                        />
                    );
                }
                let renderData = null;
                if (
                    ['shipping_address', 'billing_address', 'primary_address', 'secondary_address', 'alt_address']
                        .includes(fieldDef.getIn(['def', 'group'])) &&
                    [
                        'shipping_address_street',
                        'billing_address_street',
                        'primary_address_street',
                        'secondary_address_street',
                        'alt_address_street',
                    ].includes(fieldDef.getIn(['def', 'name']))
                ) {
                    const groupName = fieldDef.getIn(['def', 'group']);
                    const street = getFieldRender(
                        groupName + '_street',
                        index + '_street',
                        index,
                        100,
                        props,
                        field?.columns,
                    );
                    const city = getFieldRender(
                        groupName + '_city',
                        index + '_city',
                        index,
                        100,
                        props,
                        field?.columns,
                    );
                    const psc = getFieldRender(
                        groupName + '_postalcode',
                        index + '_postalcode',
                        index,
                        100,
                        props,
                        field?.columns,
                    );
                    const state = getFieldRender(
                        groupName + '_state',
                        index + '_state',
                        index,
                        100,
                        props,
                        field?.columns,
                    );
                    const kraj = getFieldRender(
                        groupName + '_kraj',
                        index + '_kraj',
                        index,
                        100,
                        props,
                        field?.columns,
                    );
                    const country = getFieldRender(
                        groupName + '_country',
                        index + '_country',
                        index,
                        100,
                        props,
                        field?.columns,
                    );

                    const extraStyle = {
                        width: `${100 / (props.panelColumns || 2) * (field.columns || 1)}%`,
                    };

                    renderData = (
                        <div
                            key={groupName}
                            className="detailViwRowGroup"
                            style={extraStyle}
                        >
                            <div className="detailViwRowGroupHeader">
                                {sAction.translate('LBL_' + groupName.toUpperCase())}
                            </div>
                            {street}
                            {city}
                            {psc}
                            {state}
                            {kraj}
                            {country}
                        </div>
                    );
                }

                return renderData === null ? (
                    <DetailViewFieldsManager
                        prefix={props.prefix}
                        module={props.module}
                        way={`${props.prefix}/fields/${fieldDef.name}`}
                        percent={50}
                        key={index}
                        index={index}
                        rowWay={props.rowWay}
                        tabWay={props.tabWay}
                        id={props.id}
                        readonly={props.readonly}
                        acl={props.acl}
                        attemptedSave={props.attemptedSave}
                        name={fieldDef.name}
                        value={fieldDef.value}
                        actEdit={fieldDef.actEdit}
                        customStyle={fieldDef.customStyle}
                        customClass={fieldDef.customClass}
                        def={fieldDef.def}
                        events={fieldDef.events}
                        selectForEdit={fieldDef.selectForEdit}
                        fieldColumns={field?.columns}
                        panelColumns={props.panelColumns}
                    />
                ) : (
                    <React.Fragment key={index}>
                        {renderData}
                    </React.Fragment>
                );
            })}
        </div>
    );
}

DetailViewRow.propTypes = {
    row: ImmutablePropTypes.recordOf({
        fields: ImmutablePropTypes.listOf(ImmutablePropTypes.recordOf({
            name: PropTypes.string.isRequired,
            columns: PropTypes.number.isRequired,
        })),
    }),
    prefix: PropTypes.string.isRequired,
    module: PropTypes.string,
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    readonly: PropTypes.bool,
    attemptedSave: PropTypes.bool,
    rowWay: PropTypes.string.isRequired,
    tabWay: PropTypes.string,
    id: PropTypes.string,
    panelColumns: PropTypes.number.isRequired,
};
