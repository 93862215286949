/**
 *
 */
export default function routeLeadConvert() {
    const self = this;
    const id = this.getUrlParam(1);

    this.rest.post('leadConvert/'+id, {}, function(data) {
        const modules = data.modules;
        const akce = {
            type: 'LEADCONVERTALLOCATION',
            content: {length: Object.keys(modules).length, record: id, name: data.name},
            prefix: 'view',
        };
        self.dsAddCustom(akce);
        self.dsProcess();

        let ind = 0;
        modules.forEachObject((module) => {
            const content = {module: module.module};
            self.detailContent(module, content);
            content.changes = module.changes;
            content.customType = 'leadConvert';
            const akce = {
                type: 'PAGELOADDETAIL',
                content: content,
                prefix: 'view/detailViews/'+ind,
                function: self,
            };
            self.dsAddCustom(akce);
            self.dsProcess();
            ind++;
        });

        self.unLoad();
    });
}
