/**
 * @param {object} paramData
 */
export default function reportWindowSaveSearch(paramData) {
    const name = paramData.name;
    const id = paramData.id;
    const data = this.dataGet('reportWindow')?.toJS();
    let url = data.module;

    if (id) {
        url = data.module + '/' + id;
    }

    if (!paramData.id && this.isDuplicateSearch(paramData.name)) {
        this.popup(this.translate('LBL_EXISTING_SEARCH'), {
            header: this.translate('LBL_EXISTING_SEARCH_HEADER'),
            color: 'red',
            buttons: [
                {
                    label: this.translate('LBL_CREATE_SEARCH'),
                    callback: () => {
                        this.setSavedSearch(data, paramData, url);
                    },
                },
                {
                    label: this.translate('LBL_CANCEL'),
                    callback: () => {
                        this.popupHide();
                    },
                },
            ],
        });
        return;
    }

    data.name = name;
    delete data.fields;
    delete data.modules;
    delete data.relate;
    delete data.actContent;
    delete data.results;
    delete data.savedSearch;

    data.listViewFilter = this.reportWindowCreateBasic();
    data.metadata = paramData;

    this.setSavedSearch(data, paramData, url);
}
