/**
 *
 * @param self
 * @param data
 * @param way
 * @param folder
 * @param dataconstructor
 */
function recurse(self, data, way, folder, dataconstructor) {
    const newData = {...data, children: [], way: way + '/' + (folder + 1)};

    self.dsAdd('add', way, newData, dataconstructor);

    folder += 1;

    const count = data.children.length;
    for (let i = 0; i < count; i++) {
        recurse(self, data.children[i], way + '/' + folder + '/children', i - 1, dataconstructor);
    }
}

/**
 *
 * @param init
 * @param openEmail
 */
export default function routeEmail(init = false, openEmail = false) {
    const prefix = 'view';

    this.dsAdd('set', 'conf/view', 'email');

    this.dsAdd('set', prefix, {}, 'Emails');

    this.load();
    this.rest.post('email/getAllFolders', {}, (data) => {
        let way = prefix + '/folders';

        this.dsAdd('set', way, []);
        data.sugar.forEach((folder, i) => {
            recurse(this, folder, way, i - 1, 'EmailFolders');
        });

        way = prefix + '/foldersIMAP';

        this.dsAdd('set', way, []);
        data.imap.forEach((folder, i) => {
            recurse(this, folder, way, i - 1, 'EmailFoldersIMAP');
        });

        if (init) {
            this.emailGetList({emailUIAction: 'getLastOpenFolder'}, prefix, false, false, false, lastOpenFolders);
        } else {
            this.dsAdd('set', 'conf/load', false);
            this.dsProcess();
            lastOpenFolders(this);
        }

        // otevreni nejakeho konkretniho emailu pri prokliku ze subpanelu...
        if (openEmail) {
            this.emailGetDetail(
                {
                    ieId: '',
                    mbox: 'sugar::Emails',
                    seen: 1,
                    uid: this.getRecordId(),
                    way: '',
                },
                'view',
                'email',
                true,
            );
        }
    }, false);
}

/**
 *
 * @param self
 */
function lastOpenFolders(self) {
    // zapamatovane slozky
    self.dsClear();
    const email_folders = self.getStorage('email_folders') || {};
    email_folders.forEachObject((v, k) => {
    // cesta se muze zmenit a bez nasledujici podminky aplikace spadne na neexistujici ceste
        if (self.dataGet(k + '/expanded') == undefined) return;
        if (v) self.dsAdd('set', k + '/expanded', true);
    });
    self.dsProcess();
}
