/**
 * adds a cookie
 * @param {string} cname cookie name
 * @param {*} cvalue cookie value
 * @param {string} exdays days the cookie is valid for
 */
export default function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + '; ' + expires;
}