/**
 *
 * @param params
 */
export default function displayQuickEdit(params) {
    const {module, record, prefix} = params;

    const self = this;

    this.rest.get('quickform/' + module + '/' + record,
        function(data) {
            const invitees = data.invitees;

            const actionButtons = [];

            let content = {
                id: record,
                module: module,
                actionButtons: actionButtons,
            };

            self.detailContent(data, content);
            content.subpanels = [];

            let action = {
                type: 'PAGELOADDETAIL',
                content: content,
                prefix: prefix+'/detailView',
                function: self,
            };

            self.dsClear(); // nemuze byt jeden nahore a jeden dole, jinak chybuje edit popup TODO zjistit proc a opravit
            self.dsAddCustom(action);
            self.dsProcess();

            content = {
                invitees: invitees,
            };

            action = {
                type: 'CALENDARLOADQUICKFORM',
                content: content,
                prefix: prefix+'/invitees',
            };

            self.dsClear();
            self.dsAddCustom(action);
            self.dsProcess(); // nemuze byt jeden nahore a jeden dole, jinak chybuje edit popup TODO zjistit proc a opravit

            self.popup('calQuickEdit', {...params});
        },
    );
}
