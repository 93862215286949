/**
 * @param {object} newRecord
 * @param {object} subpanel
 * @param {string} prefix
 * @param {string} way
 * @param {bool} deleted
 * @this sAction
 */
export default function subppanelChangeRelate(newRecord, subpanel, prefix, way, deleted = 0) {
    let recModule = subpanel.def.getIn(['rel', 'relName']);
    let relid = newRecord.id; // kdyz se do subpanelu priradi zaznam v popup okne
    if (['Activities', 'History'].includes(recModule)) {
        recModule = newRecord.record.module.toLowerCase();
        relid = newRecord.record.record;
    }
    if (deleted) {
        // kdyz se maze zaznam
        relid = newRecord.record.record;
    }
    if (!relid && typeof newRecord.record === 'string') {
        relid = newRecord.record;
    }
    const parentData = this.dataGet(prefix);

    let filterData = [];
    if (relid === 'all') {
        // pokud se jedna o list v popupu, je treba vytahnout filtr
        const listPrefix = 'conf/popup/data/listView';
        const list = this.dataGet(listPrefix);

        if (list) {
            const savedSearch = list.actSavedSearch;
            filterData = this.reloadListParams({
                list,
                prefix: listPrefix,
                savedSearch,
                columns: false,
            });

            filterData['selected'] = list.selected;
            filterData['module'] = list.modul;
            filterData['type'] = list.type;
        }
    }

    const data = {
        module: parentData.module,
        relname: recModule,
        id: parentData.id,
        relid,
        deleted: deleted,
        filter: filterData,
    };
    this.load();
    this.rest.post('rels', data, (returnData) => {
        if (returnData.status === 'ok') {
            // const subData = {
            //     parentId: parentData.id,
            //     parentModule: parentData.module,
            //     module: subpanel.module,
            //     relName: data.relname,
            //     prefix: prefix,
            //     way: way,
            //     subpanelAction: 'new_relate',
            // };
            this.reloadSubpanelByWay(prefix, subpanel.module);
        }
        this.popupHide();
        // this.route(); // nove zruseno kvuli #775 a #1339
    });
}
