/**
 *
 */
export default function getLanguage() {
    const cookieLang = this.rest.getCookie('language');
    const defaultLang = this.dataGet('conf/language');
    const languageList = this.app_strings?.languageList;

    if (cookieLang) {
        return cookieLang;
    }
    if (defaultLang) {
        return defaultLang;
    }
    if (languageList) {
        // vrati prvni jazyk ze seznamu
        return Object.keys(languageList)[0];
    }
    return 'cs_CZ';
}
