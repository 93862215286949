/**
 * @param {object} data
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @button DetailView/Opportunities
 */
export default function ordCreateInvoice(data) {
    const params = {action: 'ord_create_invoice', record: data.id};
    const self = this;

    this.rest.post('customAction', params, function(returnData) {
        if (returnData.status) {
            self.href('#detail/acm_invoices/' + returnData.message.data.record);
        } else {
            self.alert(returnData.errorMessage.text);
        }
    });
}
