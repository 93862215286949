import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import ImportFileCell from './ImportFileCell';

class ImportFileRow extends PureComponent {
    constructor(props) {
        super(props);
    }

    createCells = (data, cells, count, additionalData) => {
        for (let i = 0; i < count; i++) {
            const field = data.get(i) !== undefined ? data.get(i) : null;
            cells.push(
                <ImportFileCell key={'field'+i} data={field} />,
            );
        }

        additionalData.forEach((addData, key) => {
            if ( ! addData || addData.get('visible') === false) {
                return;
            }

            cells.push(
                <ImportFileCell key={key} data={addData.get('inputValue')}/>,
            );
        },
        );
    };

    render() {
        const data = this.props.data;
        const cells = [];
        if (data !== undefined) {
            this.createCells(data, cells, this.props.columnCount, this.props.additionalData);
        }

        return (
            <tr>
                {cells}
            </tr>);
    }
}

export default ImportFileRow;
