import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

export default function InfoTable(props) {
    const data = props?.data;
    const module = props?.module;
    const linesRender = [];
    data?.get('lines')?.forEach((line, index) => {
        const trRender = [];
        line?.forEach((cell, cellIndex) => {
            let value = cell?.get('value');
            if (cell?.get('type') === 'url') {
                value = <a onClick={(e) => {
                    e?.stopPropagation();
                }} title={value} href={cell?.get('url')}>{value}</a>;
            }
            const label = sAction?.translate(cell?.get('label'), module);
            const labelIcon = cell?.get('labelIcon') != null ?
                <div className={cell?.get('labelIcon')+' labelIcon'} /> : null;
            trRender?.push(<td key={cellIndex}>
                {labelIcon}
                {label+' '}
                {value}
            </td>);
        });
        linesRender?.push(<tr key={index}>{trRender}</tr>);
    });

    return (
        <div className="detailTopInfo">
            {data?.get('header') != null &&
            <div className="detailViewPanelHeader">
                {sAction?.translate(data?.get('header'), module)}
            </div>
            }
            <table>
                <tbody>
                    {linesRender}
                </tbody>
            </table>
        </div>
    );
}

InfoTable.propTypes = {
    data: PropTypes.object,
    module: PropTypes.string.isRequired,
};
