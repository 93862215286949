/**
 *
 * @param message
 * @param callback
 * @param callbackCancel
 * @param importance
 */
export default function confrim(message, callback, callbackCancel = () => this.popupHide(), importance = 'low') {
    this.confirm(message, callback, callbackCancel, importance);
}
