/**
 *
 * @param action
 * @param event
 * @param params
 */
const handler = (action, event, params) => {
    switch (action) {
        default:
            break;
    }
};

export default handler;
