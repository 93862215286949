/**
 * @param {object} data
 * @param {string} prefix
 * @this sAction
 */
export default function updateFields(data, prefix = 'view') {
    this.dsClear();

    const existingFields = [...this.dataGet(prefix + '/fields').keys()];
    data.forEachObject((value, key) => {
        if (value !== '' && value != null && existingFields.indexOf(key) !== -1) {
            if (key === 'email1') {
                if (value) {
                    const emailsWay = prefix + '/fields/email1/def/emails';
                    const emails = this.dataGet(emailsWay);
                    let emailIndex = 0;
                    value.forEach((email) => {
                        const emailExists = emails.find((e) => e.get('email_address') === email);
                        if (!emailExists) {
                            this.dsAdd('set', emailsWay + '/' + (emails.size + emailIndex) + '/error', false);
                            this.dsAdd('set', emailsWay + '/' + (emails.size + emailIndex) + '/email_address', email);
                            emailIndex += 1;
                        }
                    });
                    this.dsAdd('set', prefix + '/fields/email1/value', true);
                    this.dsAdd('set', prefix + '/fields/email1/def/edit', true);
                    this.dsAdd('set', prefix + '/changes/fields/email1', true);
                }
            } else {
                this.dsAdd('set', prefix + '/fields/' + key + '/value', value);
                this.dsAdd('set', prefix + '/changes/fields/' + key, value);
            }
        } else {
            // console.log("ignoruji field " + key);
        }
        if (key === 'sic_code' && value === '' && value != null && existingFields.indexOf(key) !== -1) {
            this.error(this.translate('LBL_IC_NOT_FOUND'));
            this.dsAdd('set', prefix + '/fields/sic_code/value', '');
            this.dsAdd('set', prefix + '/changes/fields/sic_code', '');
            this.dsAdd('set', prefix + '/fields/name/value', '');
            this.dsAdd('set', prefix + '/changes/fields/name', '');
        }
    });

    this.dsAdd('set', 'conf/load', false);
    this.dsAdd('set', prefix + '/changes/forceChange', true);

    this.dsProcess();
}
