/**
 *
 */
export default function clearToast() {
    const items = document.querySelectorAll('.toastContainer > .toastItem');
    let clear = true;
    items.forEach((item) => {
        const height = item.offsetHeight;
        if (height != 0) {
            clear = false;
        }
    });
    if (clear == true) {
        this.dataSet('conf/toast', []);
    }
}
