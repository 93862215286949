/**
 *
 * @param id
 * @param data
 * @param callback
 */
export default function customReportAjax(id, data, callback) {
    this.customReportXHR(id+'/'+data.url, data.data, (reponseData) => {
        callback(reponseData);
    });
}
