import React from 'react';
import FilterBasic from './filterBasic';

import MultiSelect from '../../formElements/MultiSelect';
import TabContainer from '../../formElements/TabContainer';
import sAction from 'sAction';

export default class FilterEnum extends FilterBasic {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        const actFilter = this.props.actFilter;
        let operatorTab = 0;
        if (actFilter != null) {
            if (actFilter.get('operator') == 'or') {
                operatorTab = 1;
            }
        }
        this.state = {
            operatorTab,
            multiSelectOpen: false,
        };
    }
    componentDidUpdate() {
        const actFilter = this.props.actFilter;
        if (actFilter == null || actFilter.actCustom == false) {
            this.props.parent.normal();
        } else {
            this.props.parent.advanced();
        }
    }
    /**
     *
     * @param values
     */
    multiEnumValue(values) {
        if (values.length === 0) return;
        const data = this.props.data;
        const parentType = this.props.parent.props.def.get('type');
        let type = 'contArray';
        if (parentType === 'multienum') {
            type = 'contLikeArray';
        }
        if (this.state.operatorTab == 0) {
            this.addFilter(data.get('name'), type, values);
        } else {
            const prefix = this.props.prefix;
            const field = data.get('name');
            const filters = [];
            values.forEach((value) => {
                filters.push({
                    field: field,
                    type: 'cont',
                    value: value,
                });
            });
            const groupOperator = 'or';
            const params = {
                field: field,
                filters: filters,
                operator: groupOperator,
                prefix,
                reload: true,
            };
            sAction.addFilter(params);
        }
    }

    /**
     *
     * @param event
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({multiSelectOpen: false});
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter;

        let defaultValue = null;
        if (actFilter != null) {
            if (actFilter.get('operator') == 'or') {
                defaultValue = [];
                actFilter.get('filters').forEach((filter) => {
                    defaultValue.push(filter.get('value'));
                });
            } else {
                defaultValue = actFilter.filters.get(0).value;
            }
        }

        const options = sAction.app_strings[data.get('options')];
        const optionsToSelect = [];
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                const value = options[key];
                optionsToSelect.push({value: key, label: value});
            }
        }

        const custom = [
            <div className="filterEnumSelectContainer" key="select" ref={this.wrapperRef}>
                {data.get('type') == 'multienum' && (
                    <TabContainer tabs={['AND', 'OR']} value={this.state.operatorTab}
                        onChange={(e, value) => this.setState({operatorTab: value, multiSelectOpen: true})}
                    />
                )}
                <MultiSelect
                    ref="input"
                    id={data.get('options')}
                    options={optionsToSelect}
                    defaultValue={defaultValue}
                    open={this.state.multiSelectOpen}
                    valueFromProps={true}
                    onBlur={(values) => this.multiEnumValue(values)}
                />
            </div>,
        ];

        let removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get('name'))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate('LBL_DELETE_FILTER')}
                </div>
            );
        }
        return (
            <div className="filterBox">
                <div
                    onClick={() => this.order(data, 1)}
                    className="filterButton"
                >
                    <div className="icon-arrowDownLight filterButtonIcon" />
                    {sAction.translate('LBL_ORDER_AZ')}
                </div>
                <div
                    onClick={() => this.order(data, 0)}
                    className="filterButton"
                >
                    <div className="icon-arrowUpLight filterButtonIcon" />
                    {sAction.translate('LBL_ORDER_ZA')}
                </div>
                <div
                    onClick={() => this.addFilter(data.get('name'), 'nnull', null)}
                    className="filterButton"
                >
                    <div className="icon-pipe filterButtonIcon" />
                    {sAction.translate('LBL_CONTAINS_DATA')}
                </div>
                <div
                    onClick={() => this.addFilter(data.get('name'), 'null', null)}
                    className="filterButton"
                >
                    <div className="icon-cancelCircle filterButtonIcon" />
                    {sAction.translate('LBL_NOT_CONTAINS_DATA')}
                </div>
                {custom}
                {removeFilterButton}
            </div>
        );
    }
}
