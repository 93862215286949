/**
 *
 * @param actTable
 */
export default function repairListHeight(actTable) {
    if (actTable != null) {
        actTable = actTable.parentElement;
        const row = actTable.querySelector('.newListViewLines');
        const lineHeight = row.querySelector('.newListViewRow:not(.checkBoxRow) .newListViewLine').clientHeight;
        const children = row.querySelector('.newListViewRow').childNodes.length + 1; // +1 protože ještě header
        const height = children * (lineHeight+1) + 60;
        actTable.style.height = height + 'px';
    }
}
