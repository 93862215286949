import React from 'react';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default function FileName(props) {
    /**
     * download file in preview
     */
    const downloadFile = () => {
        props.onGetFile({id: props.id, module: props.module});
    };

    let lineRender = (
        <a
            title={props.value}
            href={'#detail/' + (props.module === 'FieldFile' ? 'Documents' : props.module) + '/' + props.id}
        >
            {props.value}
        </a>
    );

    if (props.module === 'DocumentRevisions' && !sAction.getStorage('debug')) {
        lineRender = (
            <div title={props.value}>
                {props.value}
            </div>
        );
    }

    let className = 'newListViewLine';
    if (props.extraClass !== undefined) {
        className += ' '+props.extraClass;
    }

    let showImage = <div className="icon-eye inactive notAllowedClass">&nbsp;</div>;
    if (/\.(bmp|jpg|jpeg|png|gif|svg|webp|ico)$/i.test(props.value)) {
        if (props?.data?.module === 'FieldFile') {
            showImage =
            <div className="icon-eye pointerClass" onClick={() =>
                sAction.openRightPanel('image', {
                    name: props.value,
                    url: sAction.param.files.split('files/')[0] + 'upload/' + props.data.id,
                })}>
                &nbsp;
            </div>;
        } else {
            showImage =
             <div className="icon-eye pointerClass" onClick={() =>
                 sAction.openRightPanel('image', {name: props.value, url: sAction.param.files + 'image/'+ props.id})}>
                &nbsp;
             </div>;
        }
    } else if (/\.(pdf)$/i.test(props.value)) {
        showImage =
            <div className="icon-eye pointerClass" onClick={() =>
                sAction.filePreview(props.id, props.module, props.value, 'pdf', downloadFile)
            }>
                &nbsp;
            </div>;
    } else if (/\.(mp4)$/i.test(props.value)) {
        showImage =
            <div className="icon-eye pointerClass" onClick={() =>
                sAction.filePreview(props.id, props.module, props.value, 'mp4', downloadFile)
            }>
                &nbsp;
            </div>;
    } else if (/\.(docx)$/i.test(props.value)) {
        showImage =
            <div className="icon-eye pointerClass" onClick={() =>
                sAction.filePreview(props.id, props.module, props.value, 'docx', downloadFile)
            }>
                &nbsp;
            </div>;
    } else if (/\.(xlsx|xls)$/i.test(props.value)) {
        showImage =
            <div className="icon-eye pointerClass" onClick={() =>
                sAction.filePreview(props.id, props.module, props.value, 'xlsx', downloadFile)
            }>
                &nbsp;
            </div>;
    }

    showImage = (
        <TooltipWrapper label={'LBL_PREVIEW_FILE'}>
            {showImage}
        </TooltipWrapper>
    );

    let download = <div onClick={() => {
        props.onGetFile({id: props.id, module: props.module});
    }} className="icon-download listDownLoadIcon"></div>;
    if (props.module === 'Notes' && props.value === '____') {
        download = <div className="icon-download listDownLoadIcon inactive notAllowedClass" />;
    }

    return (
        <div className={className}>{download}{showImage}{lineRender}</div>
    );
}

FileName.propTypes = {
    module: PropTypes.any,
    id: PropTypes.any,
    onGetFile: PropTypes.any,
    value: PropTypes.any,
    data: PropTypes.any,
    extraClass: PropTypes.any,
};
