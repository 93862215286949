/**
 *
 * @param data
 */
export default function previewFile(data) {
    if (!data.id) {
        console.error('Chybí id souboru/záznamu');
    // return; //vyjimka email
    }
    if (!data.module) {
        console.error('Chybí modul souboru/záznamu');
    // return; //vyjimka email
    }
    // this.popup("filePreview",{state:"load"});

    this.filesXhr({
        action: 'previewFile',
        data: data,
        success: (ret) => {
            if (ret.status == true) {
                window.open(ret.url, '_blank');
                // console.log('in');
                // this.dsClear();
                // const id = this.dataGet("conf/popup2/show") ? "2" : "";
                // this.dsAdd("set","conf/popup"+id+"/data/state","init")
                // this.dsAdd("set","conf/popup"+id+"/data/token",ret.url)
                // this.dsProcess();
                return;
            }
            this.error(this.translate(ret.errorMessage.text));
        },
    });
}
