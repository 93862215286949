import React from 'react';
import PureComponent from '../../pure';

import sAction from 'sAction';

import CalBody from '../../calendar/calBody';

class CalendarDashlet extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            cssSelector: 'dashFC_'+props.reportId,
        };
    }

    render() {
        const userID = sAction.dataGet(this.props.way+'/data/options/user_id');
        // let userName = sAction.dataGet(this.props.way+'/data/options/users').get(userID);
        return (
            <div className = "DashletCalendarContainer" style={{'overflow': 'auto'}}>
                <CalBody key={'calBody' + userID}
                    data={this.props.data}
                    // viewsNumber={1} // widgety maji vlastni pocitani velikosti
                    classBoard={'calFullWidth'}
                    cssSelector={this.state.cssSelector}
                    // userName={userName} // nechceme zobrazovat, jelikoz zatim ve widgetu nejde sdilet, coz dava smysl... at si udelaji 2 widgety
                    userID={userID}
                    way={this.props.way} // widget specific
                />
            </div>
        );
    }
}
export default CalendarDashlet;
