import detailDefault from "../../detailDefault";
import $ from "jquery";

export default class detailacm_services extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
  }

  update(sAction, data) {

    switch (data.field) {
      case "type":
        this.updateDetailApiVisibility(sAction, data)
        break;
      case "domain_url":
        let name = sAction.dataGet(data.prefix + "/fields/name/value");
        if (!name) {
          this.setDomainAsName(sAction, data)
        }
        break;
    }
  }
  setDomainAsName(sAction, data) {
    var newName = data;
    sAction.dsAdd("set", data.prefix + "/fields/name/value", newName);
    sAction.dsAdd("set", data.prefix + "/changes/fields/name", newName);
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let type;
    if (data.value) {
      type = data.value;
    } else {
      type = sAction.dataGet(data.prefix + "/fields/type/value");
    }
    var textarea = document.getElementsByClassName("acm_services_detail_text");
    // textarea.style.display = 'none';

    // viditelné pro všechny případy po vybrání typu
    var visibleTrue = ['domain_url','acm_services_accounts_name','price_annual','description','status','period_from', 'period_to','assigned_user_name','name','currency_id','acm_services_producttemplates_1_name','domain_url','tariff_independent'];
    // visibleFalse se následně přepisuje, ale visibleTrue jen doplňuje a False se provede po True.
    var visibleFalse = ['acm_services_accounts_name','dont_invoice_next','price_annual','description','status','period_to','period_from','assigned_user_name','name','currency_id','acm_services_producttemplates_1_name', 'serverSize_total','serverSize_mail','serverSize_web','serverSize_other','serverSize_source','serverSize_lastCheck','emailsActive','tariff_independent'];
    //END


    sAction.dsClear();
    if (type === "hosting") {
      visibleTrue.push('period_from', 'prepaid', 'ssl_working', 'serverSize_total','serverSize_mail','serverSize_web','serverSize_other','serverSize_source','serverSize_lastCheck','emailsActive','domain_url');
      visibleFalse = ['expiration_email', 'top_lvl_domail','dont_invoice_next'
        , 'unlimited_validity', 'certificate_type', 'crDate', 'trDate', 'up_Date'
        ];

      $("#lbl_detailview_panel1").show();
      $("#lbl_detailview_panel2").show();
      $("#lbl_detailview_panel3").show();
      $("#lbl_detailview_panel4").show();


      // nastaveni defaultniho datumu pro typ hosting
      let period_from = sAction.dataGet(data.prefix + "/fields/period_from/value");
      let period_to = sAction.dataGet(data.prefix + "/fields/period_to/value");

      var today = new Date();
      var d = String(today.getDate()).padStart(2, '0');
      var m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var y = today.getFullYear();

      if (!period_from){
        sAction.dsAdd("set", data.prefix + "/fields/period_from/value", y+"-"+m+"-"+d);
        sAction.dsAdd("set", data.prefix + "/changes/fields/period_from", y+"-"+m+"-"+d);
      }
      if (!period_to){
        sAction.dsAdd("set", data.prefix + "/fields/period_to/value", y+1+"-"+m+"-"+d);
        sAction.dsAdd("set", data.prefix + "/changes/fields/period_to", y+1+"-"+m+"-"+d);
      }
      //END

    } else if (type === "domain") {
      visibleTrue.push('period_from', 'expiration_email', 'crDate', 'trDate', 'up_Date', 'prepaid','domain_url');
      visibleFalse = ['unlimited_validity', 'top_lvl_domail', 'certificate_type','dont_invoice_next', 'ssl_working', 'serverSize_total','serverSize_mail','serverSize_web','serverSize_other','serverSize_source','serverSize_lastCheck','emailsActive'];

      $("#lbl_detailview_panel1").show();
      $("#lbl_detailview_panel2").show();
      $("#lbl_detailview_panel3").hide();
      $("#lbl_detailview_panel4").hide();
    } else if (type === "ssl") {
      visibleTrue.push('period_from', 'unlimited_validity', 'certificate_type', 'ssl_working','domain_url');
      visibleFalse = ['expiration_email', 'top_lvl_domail','dont_invoice_next'
        , 'prepaid', 'crDate', 'trDate', 'up_Date', 'serverSize_total','serverSize_mail','serverSize_web','serverSize_other','serverSize_source','serverSize_lastCheck','emailsActive'];

      $("#lbl_detailview_panel1").show();
      $("#lbl_detailview_panel2").show();
      $("#lbl_detailview_panel3").hide();
      $("#lbl_detailview_panel4").hide();
    } else {
      visibleFalse.push('period_from', 'expiration_email', 'top_lvl_domail','dont_invoice_next'
        , 'unlimited_validity', 'certificate_type', 'prepaid', 'crDate', 'trDate', 'up_Date', 'ssl_working', 'serverSize_total','serverSize_mail','serverSize_web','serverSize_other','serverSize_source','serverSize_lastCheck','emailsActive','domain_url');
      visibleTrue = ['type'];

      $("#lbl_detailview_panel1").hide();
      $("#lbl_detailview_panel2").hide();
      $("#lbl_detailview_panel3").hide();
      $("#lbl_detailview_panel4").hide();
    }

    visibleTrue.forEach(function ($vt) {
      sAction.dsAdd("set", data.prefix + "/fields/" + $vt + "/def/visible", true);
    });
    visibleFalse.forEach(function ($vt) {
      sAction.dsAdd("set", data.prefix + "/fields/" + $vt + "/def/visible", false);
    });

    sAction.dsProcess();
  }
}


