import React from 'react';
import PureComponent from '../pure';
import MenuGroup from './menuGroup';
import sAction from 'sAction';
import PropTypes from 'prop-types';

export default class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.coripoVersion = document.querySelector('#coripoVersion').value;
        this.clientVersion = document.querySelector('#clientVersion').value;
        this.state = {
            smallWindow: window.innerWidth < 600,
            forceOpen: false,
            forceClose: false,
        };
    }

    handleResize = () => {
        if (window.innerWidth < 600) {
            if (!this.state.forceOpen) {
                sAction.dataSet('menu/open', false);
            }
            this.setState({smallWindow: true, forceClose: false});
        } else {
            this.setState({smallWindow: false, forceOpen: false});
            if (!this.state.forceClose) {
                sAction.dataSet('menu/open', true);
            }
        }
    };

    /** */
    hideLabel() {
        const data = this.props.data;
        if (!data.open) {
            const label = document.querySelector('.menuHoverLabel');
            label.style.display = 'none';
        }
    }

    componentDidMount() {
        this.handleResize();
    }

    render() {
        window.addEventListener('resize', this.handleResize);
        const data = this.props.data;
        const openGroups = data.openGroups;
        const alertMessage = this.props.alertMessage;
        let groupsRender = [];
        if (data.groups.size > 1) {
            data.groups.forEach((group, groupIndex) => {
                groupsRender.push(
                    <MenuGroup
                        data={group}
                        key={groupIndex}
                        groupIndex={groupIndex}
                        activeModule={data.activeModule}
                        open={openGroups.get(groupIndex) === true}
                        menuOpen={data.open}
                    />,
                );
            });
        } else if (data.groups.size === 1) {
            groupsRender = (
                <MenuGroup
                    data={data.groups.get(0)}
                    groupIndex={0}
                    activeModule={data.activeModule}
                    oneGroup={true}
                    open={true}
                    menuOpen={data.open}
                />
            );
        }
        let openButtonClass = 'navToogle icon-arrowLeft';
        if (!data.open) {
            openButtonClass = 'navToogle icon-arrowRight';
        }

        let navClass = '';
        if (!data.open) {
            navClass += ' min';
        }
        if (alertMessage) {
            navClass += ' alertMessageActive';
        }

        // sweetSpotFocusHideInput is input for focus after closing sweetspot
        return (
            <nav key="nav" className={`acmNavbar ${navClass}`} id='nav'>
                <div
                    className={
                        data.groups.size > 1 ? 'buttonsHolder withGroups' : 'buttonsHolder'
                    }
                    onMouseLeave={() => this.hideLabel()}
                >
                    {groupsRender}
                </div>
                <div
                    className="menuCreatedBy"
                    title={`CORIPO: ${this.coripoVersion}\nBUILD: ${this.clientVersion}`}
                >
                    {'Created by'}
                    <a href='https://coripo.cz/' target="_blank" rel='noreferrer'>
                        <img alt='Acmark logo' src={sAction.param.acmarkLogo}/>
                    </a>
                </div>
                <div
                    className={openButtonClass} onClick={() => {
                        sAction.menuOpen();
                        this.setState({forceOpen: true, forceClose: true});
                    }}
                />
                <input id='sweetSpotFocusHideInput' style={{width: 0, overflow: 'hidden', height: 0, opacity: 0}} />
            </nav>
        );
    }
}

Menu.propTypes = {
    data: PropTypes.object,
    alertMessage: PropTypes.string,
};
