/**
 *
 * @param newHash
 */
export default function illegallyChangeHash(newHash) {
    this.doRoute = false;
    window.location.hash = newHash;
    setTimeout(() => {
        this.doRoute = true;
    }, 0);
}
