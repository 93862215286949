/**
 * Otevre popup okno pro napsani e-mailu
 * @param {object} data - email data {}, parametry viz DataConstructors.EmailCompose
 * @param {string} data.to_addrs - Adresati oddeleni carkou nebo strednikem
 * @param {string} data.emailTemplate - Sablona
 * @param {string} data.description_html - Telo emailu
 * @param {boolean} data.disable_recipient_change - True => nepujde zmenit adresaty
 * @param {string} data.customData - custom data
 * @param {string} data.parent_type - modul parenta
 * @param {string} data.parent_id - id parenta
 * @param {string} data.parent_name - nazev parenta
 */
export default function openEmailPopup(data = {}) {
    // prilohy se nebudou vubec ukladat, pokud nejsou v objektu {} - kdyz se zmeni na immutable list() misto map(), nepojedou pak vubec...
    if (data.attachments !== null &&
    (typeof data.attachments !== 'object' ||
      (Array.isArray(data.attachments) && data.attachments.length === 0))) {
        delete data.attachments;
    }

    const id = this.getNewPopupId();

    this.dsClear();
    this.dsAdd('set', 'conf/popup' + id + '/show', true);
    this.dsAdd('set', 'conf/popup' + id + '/content', 'emailCompose');
    this.dsAdd('set', 'conf/popup' + id + '/data', data, 'EmailCompose');
    this.dsAdd('set', 'conf/popup' + id + '/data/header', this.translate('LBL_COMPOSEEMAIL', 'Emails'));
    this.dsProcess();
}
