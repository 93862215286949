import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from 'ROOT/src/store';

class storeAction {
    constructor(store) {
        this.store = store;
        this.customClassPole = {};
        this.customClass = null;
        this.fields = {};
        this.listFields = {};
        this.app_strings = {};
        this.moduleTranslate = {};
        this.userPreferences = {};
        // LISTVIEW
        this.resizeStart = null;
        this.resizeWidthStart = null;
        this.activeResizeRow = false;

        const self = this;
        document.onkeydown = (e) => {
            this.baseOnKeyDown(e);
        };
        window.onbeforeunload = function() {
            if (self.needSave()) {
                return 'Do you really want to leave our brilliant application?';
            } else {
                return;
            }
        };

        // ___________________________________________________ .closest for IE
        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector;
        }
        if (!Element.prototype.closest) {
            Element.prototype.closest = function(selector) {
                let el = this;
                while (el) {
                    if (el.matches(selector)) {
                        return el;
                    }
                    el = el.parentElement;
                }
            };
        }
    }
}

import * as d from './exportConfig';

const arrName = Object.keys(d);
const arr = arrName.map((k) => d[k]);
arr.forEach(function(f, k) {
    storeAction.prototype[arrName[k]] = f;
});

// eslint-disable-next-line new-cap
const sAction = new storeAction(store);

import * as c from '../_custom/exportConfig';

const customArrName = Object.keys(c);
const customArr = customArrName.map((k) => c[k]);
customArr.forEach(function(f, k) {
    sAction.customClassPole[customArrName[k]] = f;
});

import * as l from '../components/listEditableFields/exportConfig';

const listFieldsArrName = Object.keys(l);
const listFieldsArr = listFieldsArrName.map((k) => l[k]);
listFieldsArr.forEach(function(f, k) {
    sAction.listFields[listFieldsArrName[k]] = f;
});

class appConstructor {
    constructor(data) {
        data.renderReact = function() {
            const App = sAction.appComponent;
            const root = createRoot(document.querySelector(data.container));
            root.render((
                <Provider store={store}>
                    <App/>
                </Provider>
            ));
        };
        sAction.init(data);
    }
}

window.CORIPO = appConstructor;
if (window.Cypress || sAction.getStorage('debug')) {
    window.sAction = sAction;
}
export default sAction;
