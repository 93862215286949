/**
 *
 */
export default function logoutAs() {
    this.rest.post('logoutAs', {}, (returnData) => {
        if (returnData.state) {
            this.rest.setCookie('sID', returnData.sid, 1);
            window.location.reload();
        } else {
            this.alert(this.translate('LBL_ERROR'));
        }
    });
}
