import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import TabSelect from '../formElements/TabSelect';
import ImportRelateHeaderSelect from './ImportRelateHeaderSelect';
import PropTypes from 'prop-types';

class ImportFileHeader extends PureComponent {
    constructor(props) {
        super(props);
    }

    addFieldSelects = (headerArray, options, count) => {
        const mapping = this.props.data.get?.('mapping')?.get?.('fileMapping')?.toJS?.();
        const duplicates = this.props.data.get('mapping').get?.('duplicates')?.toJS();
        for (let i = 0; i < count; i++) {
            let clearLabel = null;
            let labelClass = 'withBorder';
            if (mapping?.[i]?.['value']) {
                clearLabel = true;
            }
            if (duplicates?.[i]) {
                labelClass += ' error';
            }

            let defaultValue = mapping?.[i]?.['label'];
            if (!defaultValue) {
                // Bacha, false !== null. Na false je ignorovani na vypsani
                if (defaultValue === false) {
                    defaultValue = sAction.translate('LBL_IGNORE_FIELD');
                } else {
                    defaultValue = sAction.translate('LBL_CHOOSE_MAPPING');
                }
            }
            const fieldName = mapping?.[i]?.['value'] ?? null;
            const fieldNameString = fieldName ? ` (${fieldName})` : '';
            if (!['relate', 'link'].includes(mapping?.[i]?.['type'])) {
                headerArray.push(
                    <th key={'field' + i}>
                        <TabSelect
                            // containerClassName="withBorder"
                            search={true}
                            numOfTabSelect={i}
                            options={options}
                            onChange={(e) => this.onChange(e)}
                            container={'importView'}
                            label={defaultValue + fieldNameString}
                            clearLabel={clearLabel}
                            containerClassName={labelClass}
                            defaultValue={mapping?.[i]?.['value']}
                        />
                    </th>,
                );
            } else {
                const relateDef = this.props.data?.get?.('fields')?.get?.('relate')?.toJS().find((field) => {
                    return field.id_name === mapping?.[i]?.['value'];
                });
                const moduleString = ` (${sAction.translateEnum('moduleList', relateDef['module'])})`;
                headerArray.push(
                    <th key={'field' + i} className={'relationSelectHeader'}>
                        <TabSelect
                            // containerClassName="withBorder"
                            search={true}
                            numOfTabSelect={i}
                            options={options}
                            onChange={(e) => this.onChange(e)}
                            container={'importView'}
                            label={defaultValue + moduleString}
                            clearLabel={clearLabel}
                            containerClassName={labelClass}
                            defaultValue={mapping?.[i]?.['value']}
                            containerStyle={{
                                maxWidth: '100%',
                                height: '50%',
                                display: 'inline-flex',
                                whiteSpace: 'break-spaces',
                            }}
                        />
                        <ImportRelateHeaderSelect
                            mapping={mapping?.[i]}
                            index={i}
                            key={mapping?.[i]?.['value']}
                            containerClassName={labelClass}
                            relateDef={relateDef}
                            way={`${this.props.way}/mapping/fileMapping`}
                        />
                    </th>,
                );
            }
        }

        return headerArray;
    };

    addAdditionalData = (headerArray, additionalData) => {
        additionalData.forEach((data, key) => {
            if (!data || data.get('visible') === false) {
                return;
            }
            let labelClass = null;
            if (data.get('duplicate') === true) {
                labelClass = 'error';
            }
            const name = data.get('label');
            const fieldName = data.get('field');
            const fieldNameString = fieldName ? ` (${fieldName})` : '';
            headerArray.push(
                <th className="relateHeader" key={key}>
                    <div className={`${labelClass} relateHeaderCell`}>
                        <span>{name + fieldNameString}</span>
                        <span
                            className={'icon icon-detailCancel'}
                            onClick={() =>
                                sAction.deleteColumn({
                                    way: data.get('way'),
                                    index: key,
                                })
                            }
                        />
                    </div>
                </th>,
            );
        });

        return headerArray;
    };

    constructHeader = (data, additionalData) => {
        let header = [];
        const columnCount = data.get('columnCount');

        const options = this.props.options;
        header = this.addFieldSelects(header, options, columnCount);
        header = this.addAdditionalData(header, additionalData);
        return header;
    };

    onChange = (e) => {
        sAction.dsClear();
        sAction.dsAdd(
            'set',
            this.props.way + '/mapping/fileMapping/' + e.numOfTabSelect,
            {value: e.value, type: e.type, tab: e.activeTab, label: e.label},
        );
        sAction.dsProcess();
    };

    render() {
        const data = this.props.data;
        const additionalData = this.props.additionalData;
        let header = [];
        if (data !== undefined) {
            header = this.constructHeader(data, additionalData);
        }

        return <tr>{header}</tr>;
    }
}

ImportFileHeader.propTypes = {
    data: PropTypes.any,
    additionalData: PropTypes.any,
    way: PropTypes.string,
    options: PropTypes.any,
};

export default ImportFileHeader;
