import React from 'react';
import PureComponent from '../../pure';

import sAction from 'sAction';
import Select from '../../formElements/Select';
import Loader from '../../loader';
import Button from '../../formElements/Button';

export default class ListviewMassAssignSS extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            options: [],
        };

        this.ss = '';
    }

    componentDidMount() {
        sAction.rest.get('getSSGroups', (returnData) => {
            const options = [];
            returnData.forEach((option) => {
                options.push({label: option.name, value: option.id});
            });

            this.setState({loaded: true, options});
        });
    }

    assign = (del = false) => {
        sAction.load();

        const records = sAction.dataGet(this.props.prefix + '/selected');
        const module = sAction.dataGet(this.props.prefix + '/modul');
        let ids = [];
        let filterData = null;
        if (records === 'all') {
            ids = 'all';
            const list = sAction.dataGet(this.props.prefix);
            const savedSearch = list.actSavedSearch;
            filterData = sAction.reloadListParams({
                list,
                prefix: this.props.prefix,
                savedSearch,
                columns: false,
            });
            filterData['selected'] = list.selected;
            filterData['module'] = list.modul;
            filterData['type'] = list.type;
        } else {
            records.forEach((id) => {
                ids.push(id);
            });
        }

        sAction.rest.post(
            'massAssignSS',
            {ss: this.ss, ids, module, delete: del, filter: filterData},
            (returnData) => {
                sAction.unLoad();
                if (returnData !== true) {
                    // Nastala chyba
                } else {
                    sAction.popupHide();
                    sAction.dataSet(this.props.prefix+'/selected', []);
                }
            },
        );
    };

    onChange = (e) => {
        this.ss = e.target.value;
    };

    render() {
        if (!this.state.loaded) return <Loader />;

        return (
            <div>
                <div className="acmPopupHeader">{sAction.translate('LBL_MASS_ASSIGN', 'SS')}</div>
                <div className="acmPopupContent">
                    <table className="w100">
                        <tbody>
                            <tr>
                                <th>{sAction.translate('LBL_GROUP', 'SS')}</th>
                                <td className="w100">
                                    <Select options={this.state.options} onChange={this.onChange} containerClassName="withBorder" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="acmPopupButtons">
                    <Button onClick={() => this.assign()}>
                        {sAction.translate('LBL_ASSIGN', 'SS')}
                    </Button>
                    <Button onClick={() => this.assign(true)}>
                        {sAction.translate('LBL_REMOVE', 'SS')}
                    </Button>
                </div>

            </div>
        );
    }
}
