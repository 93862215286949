import sAction from 'sAction';
import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ShortcutManagerContext} from 'ROOT/src/contexts/ShortcutManagerContext';

export default function ShortcutWrapper(props) {
    if (sAction.isDesktop()) {
        const {setNewNamespace, clearNewNamespace} = useContext(ShortcutManagerContext);
        useEffect(() => {
            setNewNamespace(props.namespace, props.mode);
            return () => {
                clearNewNamespace(props.namespace);
            };
        }, [props.mode]);
    }

    return (
        <>
            {props.children}
        </>
    );
}

ShortcutWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    namespace: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
};
