/**
 *
 * @param way
 * @param filter
 * @param record
 * @param module
 */
export default function getTags(way, filter, record, module) {
    this.rest.post('tags/getTags/', {filter: filter, record: record, module: module}, (data) => {
        this.dsClear();
        this.dsAdd('set', way + '/data/tags', []);
        this.dsAdd('set', way + '/data/tags', data['tags']);
        this.dsProcess();
    });
}
