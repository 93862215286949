/**
 *
 * @param akce
 */
export default function dataDispatch(akce) {
    // vytvoří pole které zpracuje reducers
    let reducerArray = null;
    if (Array.isArray(akce) === true) {
        reducerArray = akce;
    } else if (typeof akce === 'object') {
        reducerArray = [akce];
    }
    const frontaAkce = {
        type: 'pbDATAFRONTA',
        reducer: this.dataReducer,
        fronta: reducerArray,
    };

    if (reducerArray != null) {
        this.store.dispatch(frontaAkce);
    }
}
