/**
 * updateDynamicEnum
 * @param {string} prefix
 * @param {any} field
 * @param {any} enumList
 * @param {any} disabledOptions
 */
export default function updateDynamicEnum(prefix, field, enumList, disabledOptions) {
    const list = this.app_strings[enumList];
    const options = [];
    list.forEachObject((option, key) => {
        let disabled = false;

        if (disabledOptions.includes(key)) {
            disabled = true;
        }
        options.push({
            value: key,
            label: option,
            disabled: disabled,
        });
    });

    this.dataSet(prefix + '/fields/' + field + '/def/customOptions', options);
}
