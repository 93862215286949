/**
 *
 * @param state
 * @param way
 * @param rowWay
 * @param prefix
 */
export default function editFieldPosition(state, way, rowWay, prefix) {
    console.log(prefix);
    let fields = this.dataGet(prefix+'/editFieldsPosition');
    let change = false;
    this.dataSet(way+'/selectForEdit', state);
    if (fields.get('a') == null && state == true) {
        this.dsClear();
        this.dsAdd('set', prefix+'/editFieldsPosition/a', {rowWay, way});
        this.dsAdd('set', prefix+'/hiddenFieldButton', true);
        this.dsProcess();
    } else if (fields.get('b') == null && state == true) {
        this.dataSet(prefix+'/editFieldsPosition/b', {rowWay, way});
        change = true;
    }

    if (fields.getIn(['a', 'rowWay']) == rowWay && state == false) {
        this.dataSet(prefix+'/editFieldsPosition/a', null);
    }
    if (fields.getIn(['b', 'rowWay']) == rowWay && state == false) {
        this.dataSet(prefix+'/editFieldsPosition/b', null);
    }
    if (change) {
        fields = this.dataGet(prefix+'/editFieldsPosition');
        const a = this.dataGet(fields.getIn(['a', 'rowWay']));
        const b = this.dataGet(fields.getIn(['b', 'rowWay']));

        this.dsClear();
        this.dsAdd('set', prefix+'/hiddenFieldButton', false);
        this.dsAdd('set', fields.getIn(['a', 'rowWay']), b);
        this.dsAdd('set', fields.getIn(['b', 'rowWay']), a);
        this.dsAdd('set', fields.getIn(['a', 'way'])+'/selectForEdit', false);
        this.dsAdd('set', fields.getIn(['b', 'way'])+'/selectForEdit', false);
        this.dsAdd('set', prefix+'/editFieldsPosition/a', null);
        this.dsAdd('set', prefix+'/editFieldsPosition/b', null);
        this.dsProcess();
    }
}
