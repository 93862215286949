/**
 *
 * @param props
 */
export default function emailPrepareReplyData(props) {
    const {data: {replyMode}, way} = props;

    let data = '';
    if (replyMode && replyMode !== 'email') {
        const module = this.getModuleName();
        if (module === 'EmailClient') {
            data = this.dataGet('view/activeEmail');
        } else if (this.dataGet('rightPanel/show')) {
            // detail emailu se otevira mimo Email klient - v subpanelu do rightpanelu
            data = this.dataGet('rightPanel/data');
        } else if (module === 'Emails') {
            data = this.dataGet('view/customData');
        }
    }

    // exit pokud neodpovidame na zadny email
    if (!data) {
        this.dataSet(way + '/filledComposeCache', 0);
        return;
    }

    let to_addrs = '';
    let cc_addrs = '';
    let name = '';
    let description = this.emailGetCitation(data);
    let attachments = {};

    // odpovidame a mame vybran nejaky email

    // uprava inline obrazku
    description = description ?
        description.replace(
            /['"]cid:([^"'@]+)(@[^"']+)?["']/g,
            '"'+this.param.files + 'emailImg/$1/' + this.rest.getCookie('sID')+'"',
        ) :
        '';

    // uprava predmetu emailu
    if (data.name) {
        if (replyMode === 'forward') {
            name = 'Fw: ' + data.name.replace(/^Fw:\s*/i, '');
        } else {
            name = 'Re: ' + data.name.replace(/^Re:\s*/i, '');
        }
    } else {
        name = 'Re: ';
    }

    // ziskani prijemcu
    if (replyMode === 'reply') {
    // odpovedet jednomu odesilateli
        to_addrs = data.reply_to_addr ? data.reply_to_addr : data.from_addr; // do adresy noveho prijemce se napise adresa puvodniho odesilatele
        cc_addrs = '';
    } else if (replyMode === 'forward') {
    // preposlat - vynuluji se vsichni prijemci
        to_addrs = '';
        cc_addrs = '';
        // pridani priloh
        attachments = data.attachments_array;

        attachments = {};
        // pokud je imap tak jine chovani
        let i = 0;
        data.attachments_array.map((v) => {
            i++;
            attachments[v.get('id') || i] = {
                id: v.get('id') || i,
                name: v.get('filename'),
                size: v.get('size'),
                source: data.is_sugarEmail ? 'sugartemplate' : 'imap',
                type: '',
            };
            if (!data.is_sugarEmail) {
                attachments[v.get('id') || i].imapData = {
                    uid: data.uid,
                    ieId: data.ieId,
                    partnum: v.get('partnum'),
                };
            }
        });
    } else if (replyMode === 'replyAll') {
    // odpovedet vsem - ziskani vsech adres
        to_addrs = data.reply_to_addr ? data.reply_to_addr : data.from_addr;
        if (data.toaddrs) {
            to_addrs = (to_addrs ? to_addrs + ', ' : '') + data.toaddrs;
        }
        cc_addrs = data.cc_addrs;
    }

    if (to_addrs) to_addrs = this.htmlDecode(to_addrs);

    cc_addrs = cc_addrs ? this.htmlDecode(cc_addrs) : '';

    this.dsClear();
    this.dsAdd('set', way + '/to_addrs', to_addrs);
    this.dsAdd('set', way + '/cc_addrs', cc_addrs);
    this.dsAdd('set', way + '/bcc_addrs', '');
    this.dsAdd('set', way + '/name', name);
    if (cc_addrs) this.dsAdd('set', way + '/ccExpanded', true);
    this.dsAdd('set', way + '/bccExpanded', false);
    this.dsAdd('set', way + '/description_html', description);
    this.dsAdd('set', way + '/filledComposeCache', 0);
    this.dsAdd('set', way + '/attachments', attachments);
    this.dsAdd('set', way + '/parent_name', data.parent_name);
    this.dsAdd('set', way + '/parent_id', data.parent_id);
    this.dsAdd('set', way + '/parent_type', data.parent_type);
    this.dsProcess();
}
