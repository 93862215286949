import DragAndDrop from './DragAndDrop';
import sAction from 'sAction';

export default class DragAndDropCoripo extends DragAndDrop {
    componentDidMount() {
        const dragHolderClass = this.props.containersClass.replace('.', '');
        const holders = document.getElementsByClassName(dragHolderClass);
        holders.forEachObject((holder) => {
            if (holder.dataset.way === null) {
                this.dragAllow = false;
            }
        });
        const elementClass = this.props.elementsClass.replace('.', '');
        const elements = document.getElementsByClassName(elementClass);
        elements.forEachObject((holder) => {
            if (holder.dataset.way == null) {
                this.dragAllow = false;
            }
        });
    }
    /**
     *
     * @param {*} data
     */
    moveStart(data) {
        let way = data.element.dataset.way;
        if (this.props.wayPrefix != null) {
            way = this.props.wayPrefix + way;
        }
        sAction.dataSet(way + '/def/show', false);
    }
    /**
     *
     * @param {*} dragData
     */
    moveEnd(dragData) {
        if (!!dragData.resize === true) {
            const element = dragData.element;
            const newWidth = element.clientWidth;
            const parent = document.querySelector('.columnsLine');
            const parentWidth = parent.clientWidth;
            const percent = (newWidth/parentWidth)*100;
            let way = element.dataset.way;
            if (this.props.wayPrefix != null) {
                way = this.props.wayPrefix + way;
            }
            sAction.dataSet( way + '/def/width', percent+'%');
        } else {
            const data = {
                dragData: dragData,
                wayPrefix: this.props.wayPrefix,
            };
            sAction.dragAndDropSave(data);
            this.afterEnd(data);
        }
    }
}
