/**
 *
 * @param {*} paramData
 */
export default function deleteRecord(paramData) {
    const self = this;
    const module = paramData.module;
    const isFromRightPanel = paramData.type === 'rightPanel';
    const imagesToDeleteOnSave = this.dataGet('view/imageActions/deleteOnSave') ?? [];
    const imagesToDeleteOnLeave = this.dataGet('view/imageActions/deleteOnLeave') ?? [];
    const imagesToDeleteOnDelete = this.dataGet('view/imageActions/deleteOnDelete') ?? [];
    const imagesToDelete = [...imagesToDeleteOnSave, ...imagesToDeleteOnLeave, ...imagesToDeleteOnDelete];

    // prevent delte of Acmark users
    if (paramData?.module === 'Users' && paramData?.id?.length <= 6) {
        this.popupHide();
        this.error(this.translate('LBL_DELETE_ACMARK_USER_ERROR'));

        return;
    }

    // promazani zmen v local storage
    this.removeChangesFromLS(module, this.dataGet('conf/user/id'));

    this.dsClear();
    if (paramData.type !== 'listView') {
        this.dsAdd('set', 'conf/load', true);
    }
    this.dsAdd('set', 'conf/popup/show', false);
    this.dsProcess();

    this.rest.delete('delete/'+module+'/'+paramData.id, null, () => {
        if (imagesToDelete.length > 0) {
            this.rest.fetchData('deleteImages', 'DELETE', {imageNames: imagesToDelete}, false)
                .then(() => {})
                .catch(() => {});
        }
        self.dataSet(paramData.prefix+'/changes', {fields: [], files: []});
        if ( ! isFromRightPanel) {
            window.location.href = '#list/'+module;

            return;
        }

        this.route();
    });
}
