import {List} from 'immutable';

/**
 *
 * @param val
 * @param prefix
 */
export default function selectLook(val, prefix) {
    this.dsClear();
    this.dsAdd('set', prefix + '/actFiltering', false);
    this.dsAdd('set', prefix + '/filter', List());
    this.dsAdd('set', prefix + '/orderBy', '');
    this.dsProcess();
    this.reloadList(0, 1, prefix, val);
}
