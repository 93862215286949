/**
 *
 * @param type
 */
export default function calPressButton(type) {
    const divs = document.querySelectorAll('.fc-'+type+'-button');
    for (let i = 0; i < divs.length; ++i) {
        divs[i].click();
    }
}
