/**
 *
 * @param {{}} paramData
 * @param {function} callback
 * @returns {{data: {}}}
 */
export default function searchAcmAddress(paramData, callback) {
    const query = paramData.query ?? '';
    if ((!query || query === ' ') && !paramData.city) {
        const emptyResponse = {data: {}};
        callback(emptyResponse);
        return emptyResponse;
    }

    // old call: POST acmAddress
    this.rest.fetchMS('maps/v1/get-poi', 'GET', {
        query: query,
        city: paramData.city ?? '',
    }).then((response) => {
        if (response.status) {
            callback(response.data);
            return response.data;
        }
        this.error(response.message);
        console.error('Get poi failed, response: ', response);
        callback([]);
        return response;
    });
}
