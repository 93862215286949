/**
 * @param {string} prefix
 * @param {HTMLElement|undefined|null}table
 * @param {string} type
 * @this sAction
 */
export default function afterLoadListView(prefix, table, type = 'listView') {
    let listTable = [table];
    if (table === undefined) {
        listTable = document.querySelectorAll('.newListView');
    }

    if (!listTable || !listTable.length) {
        return;
    }

    listTable.forEach((actTable) => {
        if (actTable === null) {
            return;
        }
        const widgetParent = actTable.closest('.widgetListView');

        const tableWidth = actTable.clientWidth;
        const tds = actTable.querySelectorAll('.setWidth');
        const endOfLine = tds.length / 2;

        const view = this.getViewName();
        const module = this.dataGet('view/modul');
        const way = view + module;
        const filterData = this.getStorage('listFilterData');

        let widthInfo = null;
        if (module && view) {
            if (filterData && filterData[view + module]) {
                widthInfo = filterData[way]['widthInfo'];
            }
        }
        const columnsWidth = this.dataGet(`${prefix}/rows`)?.reduce((width, column) => width + +column.width?.replace('%', '') || 0, 0);
        let index = 1;
        let alreadySet = 0;
        let alreadySetPercent = 0;
        if (type !== 'subpanel') {
            tds.forEach((td) => {
                // pokud uzivatel resizoval je potreba vytahnout data o jeho nastavene sirce sloupcu
                if (widthInfo) {
                    const widthData = filterData[way]['widthInfo'].find((o) => o['tableIndex'] === index);

                    if (widthData) {
                        td.style.minWidth = widthData.minWidth;
                        td.style.maxWidth = widthData.maxWidth;
                        if (index === endOfLine) {
                            index = 1;
                        } else {
                            index++;
                        }

                        return;
                    }

                    if (index === endOfLine) {
                        index = 1;
                    } else {
                        index++;
                    }
                }

                const last = td.dataset.last;

                let percent = 10;
                if (td.dataset.width != null) {
                    percent = parseInt(td.dataset.width);
                }
                const newTableWidth = actTable.querySelectorAll('.filterContainer') ? tableWidth-25 : tableWidth;
                let newWidth = newTableWidth * (percent / 100);
                if (last === 'false') {
                    alreadySet += newWidth;
                    if (columnsWidth < 100) {
                        alreadySetPercent += percent;
                    }
                } else {
                    if (columnsWidth < 100) {
                        newWidth = newTableWidth * ((100 - alreadySetPercent) / 100);
                    }
                    if (tableWidth > alreadySet + newWidth) {
                        // Pokud se nejedná o widget na homepage tak se odečte více kvůli tomu že jsou viditelné checkboxy
                        newWidth = tableWidth - alreadySet - (widgetParent !== null ? 10 : 35);
                        // if (widgetParent !== null) {
                        //     newWidth = tableWidth - alreadySet - 10;
                        // }
                    }
                    alreadySet = 0;
                    alreadySetPercent = 0;
                }
                newWidth = newWidth - 0.2; // Maybe can be fix it better but this works and its just 0.2 pixel
                td.style.minWidth = newWidth + 'px';
                td.style.maxWidth = newWidth + 'px';
                td.style.width = newWidth + 'px';
            });
        }
        if (type === 'subpanel' || type === 'widget') {
            let count = true;
            let totalPercent = 0;
            let percentArray = [];
            const isMaxSize = !!actTable.closest('.maxSize');
            let maxSizeDiff = 0;
            if (isMaxSize) {
                maxSizeDiff = 31;
            }

            const newWidth = actTable.querySelectorAll('.newlistviewline.subpanelEditButton') ? tableWidth-30 - maxSizeDiff : tableWidth;
            tds.forEach((td) => {
                const last = td.dataset.last;
                if (count) {
                    let percent;
                    if (td.dataset.width != null) {
                        percent = parseInt(td.dataset.width);
                    } else {
                        percent = (30 / tableWidth)*100;
                    }
                    if (!isFinite(percent)) {
                        percent = 0;
                    }
                    percentArray.push(percent);
                    totalPercent += percent;
                }
                if (last === 'true') {
                    count = false;
                }
            });

            percentArray = this.repairSubpanelRowPercent(totalPercent, percentArray);

            let lastIndex = 0;
            tds.forEach((td, key) => {
                const last = td.dataset.last;
                const percent = percentArray[key-lastIndex];

                td.style.width = percent/100 * (newWidth);
                td.style.minWidth = percent/100 * (newWidth);
                td.style.maxWidth = percent/100 * (newWidth);

                if (last === 'true') {
                    lastIndex = key+1;
                }
            });
            this.repairSubpanelHeight(actTable);
        }
    });
}
