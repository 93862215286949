/**
 *
 * @param paramData
 */
export default function deleteColumn(paramData) {
    const way = paramData.way;
    const index = paramData.index;

    let relates = this.dataGet(way);
    if ( ! relates || relates.size === 0 || ! relates.get(index)) {
        this.popupHide();
        return;
    }
    const mappedField = relates.get(index).get('field');
    let mappedFields = this.dataGet('view/mappedRelates');
    const fieldIndex = mappedFields.indexOf(mappedField);

    mappedFields = mappedFields.delete(fieldIndex);
    relates = relates.remove(index);

    this.dsClear();
    this.dsAdd('set', way, relates);
    this.dsAdd('set', 'view/mappedRelates', mappedFields);
    this.dsProcess();
    this.popupHide();
}
