import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';

export default class ListViewTableDropZone extends PureComponent {
    /**
     *
     */
    dragLeave() {
        const way = this.props.way;
        sAction.dataSet(way+'/dropZoneActive', false);
    }
    /**
     *
     * @param e
     */
    drop(e) {
        e.preventDefault();
        const way = this.props.way;

        // kdyz uzivatel chce pretahnout email z praveho panelu
        const noteId = e.dataTransfer.getData('text');
        if (noteId) {
            sAction.reuploadEmailFiles(noteId, way);
            return;
        }

        sAction.processFiles(e, way, (files) => {
            sAction.uploadFiles(files, way);
        });
    }

    render() {
        const data = this.props.data;
        return (
            <div
                className="listViewTableDropZone uploadIcon"
                onDragLeave={() => this.dragLeave()}
                onDrop={(e) => this.drop(e)}
            />
        );
    }
}
