/**
 *
 */
export default function calActionPanel() {
    const data = this.dataGet('actionPanel');
    const module = this.getModuleName();
    this.dsClear();
    data.buttons.forEach((button, index) => {
        if (button.def.get('module') == module) {
	        this.dsAdd('set', 'actionPanel/buttons/'+index+'/def/extraClass', 'hoverBlue select');
        } else {
	        this.dsAdd('set', 'actionPanel/buttons/'+index+'/def/extraClass', 'hoverBlue');
        }
    });
    this.dsProcess();
    return this.dataGet('actionPanel');
}
