/**
 *
 * @param d
 * @param substractDays
 */
function formatDate(d, substractDays) {
    let dd = d.getDate() - substractDays;
    let mm = d.getMonth() + 1; // January is 0!

    const yyyy = d.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    d = dd + '. ' + mm + '. ' + yyyy;
    return d;
}

/**
 *
 * @param d
 */
function formatDate_nice(d) {
    const dd = d.getDate();
    const mm = d.getMonth() + 1; // January is 0!

    let yyyy = d.getFullYear();

    if (yyyy === new Date().getFullYear()) yyyy = '';

    d = dd + '. ' + mm + '. ' + yyyy;
    return d;
}

/**
 *
 * @param originalDate
 * @param fullFormat
 */
export default function mysqlToDate(originalDate, fullFormat = false) {
    if (!originalDate) return '';
    const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})( [0-9]{2}:[0-9]{2}:[0-9]{2})?$/;
    if (regex.test(originalDate) !== true) return originalDate;

    let d = originalDate.replace(/([0-9]{4})-([0-9]{2})-([0-9]{2})/, '$3.$2.$1');

    if (fullFormat) return d;

    d = d.split(' ');

    const [date, time] = d;

    const todayDate = new Date();
    const today = formatDate(todayDate, 0);
    const yesterday = formatDate(todayDate, 1);

    if (date == today) return this.translate('LBL_EMAIL_DATE_TODAY')+', ' + time;

    if (date == yesterday) return this.translate('LBL_EMAIL_DATE_YESTERDAY')+', ' + time;

    const niceDate = formatDate_nice(new Date(originalDate));
    if (niceDate.indexOf('NaN') !== -1) console.error(originalDate);

    return niceDate;
}
