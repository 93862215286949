import React from 'react';
import PureComponent from '../pure';

export default class Tag extends PureComponent {
    /**
     *
     * @param e
     * @param tag
     */
    onClick(e, tag) {
        if (this.props.onClick != undefined) {
            this.props.onClick(e, tag);
        }
    }

    render() {
        const tag = this.props.tag;

        let className = tag.get('type') ? 'tag' + tag.get('type') + ' tag' : 'tag';
        className += ' colored';
        this.props.onClick ? (className += ' clickable') : null;

        return (
            <span
                className={className}
                key={tag.get('id')}
                onClick={(e) => this.onClick(e, tag)}
            >
                <div className="tagText">
                    <div style={{fontSize: '0.8rem'}}>
                        {tag.get('name')}
                    </div>
                </div>
            </span>
        );
    }
}
