/**
 *
 * @param name
 * @param way
 * @param paramData
 */
export default function loadAdminCustom(name, way, paramData) {
    if (typeof paramData === 'string') {
        paramData = {data: paramData};
    }
    this.adminXHR(name, paramData, (reponseData) => {
        this.dsClear();
        this.dsAdd('set', way+'/html', reponseData);
        this.dsProcess();
    });
}
