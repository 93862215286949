import React from 'react';
import PureComponent from '../../pure';
import Select from '../../formElements/Select';
import sAction from 'sAction';

export default class EnumField extends PureComponent {
    render() {
        const options = sAction.getEnumForSelect(this.props.options, this.props.dynEnum);
        const colors = this.props.colors ? sAction.app_strings[this.props.colors] : null;
        return (
            <Select
                label={this.props.label}
                options={options}
                colors={colors}
                value={this.props.defaultValue}
                myRef={this.headerInput}
                onChange={(e) => this.props.onChange(e.target.value)}
            />
        );
    }
}
