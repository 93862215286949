/**
 *
 * @param props
 */
export default function emailRefreshVariables(props) {
    const name = this.dataGet(props.way + '/name');
    let body_html = this.dataGet(props.way + '/description_html');
    if (!name && !body_html) return;

    // exit, pokud zprava neobsahuje zadnou promennou
    if (!RegExp('\\$').test(body_html) && !RegExp('\\$').test(name)) return;
    body_html = this.emailRemoveVariableHighlights(body_html);

    const parseData = {
        sendTo: props.data.to_addrs,
        parent_type: props.data.parent_type,
        parent_id: props.data.parent_id,
        body_html,
        name,
    };
    this.load();
    this.rest.post('email/refreshVariables', parseData, (data) => {
        this.dsClear();
        this.dsAdd(
            'set',
            props.way + '/description_html',
            this.emailAddVariableHighlights(this.htmlDecode(data.body_html)),
        );
        this.dsAdd('set', props.way + '/name', data.name);
        this.dsAdd('set', props.way + '/updateEmailText', true);
        this.dsAdd('set', 'conf/load', false);

        this.dsProcess();
    });
}
