export default function HomeWidgetRefresh(type, way) {
    this.dsClear();

    console.log('HomeWidgetRefresh', type, way);

    if (type === 'customReport') {
        this.dsAdd('set', way + '/data/viewData', null);
        this.dsProcess();
    } else if (type === 'view') {
        this.load();
        const sendData = this.dataGet(way+'/data');
        const id = sendData.get('viewId');
        const limit = sendData.getIn(['options', 'recordsPerPage']);
        console.log('getwidget 1');
        this.rest.post(
            'home/getwidget',
            {id, search_module: sendData.get('module'), limit},
            (data) => {
                this.unLoad();
                const content = this.listContent(data);
                const akce = {
                    type: 'PAGELOADLIST',
                    page: sendData.get('module'),
                    content: content,
                    listViewPrefix: way + '/data/viewData',
                    function: this,
                    savedSearch: id,
                };
                this.dsAdd('set', akce.listViewPrefix, {});
                this.dsAddCustom(akce);
                this.dsProcess();
            }, false,
        );
    } else if (type == 'calendar') {
        const self = this;
        const dashletId = self.dataGet(way+'/data/viewData/dashletId');
        this.dsAdd('set', way + '/data/viewData', null);
        this.dsProcess();

        this.load();
        console.log('getwidget 2');
        this.rest.post(
            'home/getwidget',
            {search_module: 'Calendar', dashletId: dashletId},
            (data) => {
                self.unLoad();
                self.dsClear();
                self.routeCalendarDahlet(way+'/data', data, dashletId);
                self.dsProcess();
            }, false,
        );
    }
}
