/**
 *
 * @param data
 * @param prefix
 */
export default function emailGetDetailRightPanel(data, prefix) {
    this.load();

    const url = 'email/getSingleMessageFromSugar';

    this.rest.post(url, data, (activeEmail) => {
        if (activeEmail.status !== 'error') {
            this.dsAdd('set', prefix + '/data', {...activeEmail, seen: 1}, 'EmailDetail');

            this.dsAdd('set', 'conf/load', false);
            this.dsProcess();
        }
    });
}
