/**
 * ---prettify color---
 * convertToPastelHSL
 * @param {string} color
 * @param {float} alpha
 * @param {string} type
 * @returns {string|null}
 */
export default function convertToPastelHSL(color, alpha = 1, type = 'pastel') {
    if (!color) return null;
    // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    const hsl = rgbToHsl(r, g, b);

    let lum = 68;
    let hue = hsl.hue * 360;

    let sat = hsl.sat * 100;
    if (hue > 90 && hue < 100) hue -= 20; // convert light green to lighter green
    if (hue > 140 && hue < 150) hue -= 60; // convert green to light green
    if (hue > 210 && hue < 230) hue -= 40; // convert blue to light blue
    if (hue > 320 && hue < 350) hue += 15; // convert red to more red

    if (hue < 0) hue = 0;
    if (hue > 360) hue = hue - 360;
    if (hue > 210 && hue < 280 ) lum = 88; // make purple lighter
    if (hue > 45 && hue < 130 ) lum = 60; // make green-yellow darker
    if (hue > 320 || hue < 20) lum = 75; // make red lighter
    hue = Math.round(hue);
    sat = Math.round(sat);

    switch (type) {
        case 'pastel':
            return `hsla(${hue},80%,${lum}%,${alpha})`;
        case 'pastel2':
            lum -= 10;
            return `hsla(${hue},80%,${lum}%,${alpha})`;
        case 'dark':
            lum = 24;
            return `hsla(${hue},${sat}%,${lum}%,${alpha})`;
        default:
            return `hsla(${hue},${sat}%,${lum}%,${alpha})`;
    }
}

/**
 *
 * @param {int} r
 * @param {int} g
 * @param {int} b
 * @returns {{hue: number, sat: number, lum: number}}
 */
function rgbToHsl(r, g, b) {
    // https://stackoverflow.com/questions/3732046/how-do-you-get-the-hue-of-a-xxxxxx-colour
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b); const min = Math.min(r, g, b);
    let h; let s; const l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return {hue: h, sat: s, lum: l};
}
