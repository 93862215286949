/**
 * @param {number|string} index
 * @param {string} prefix
 * @param {number|string} prevIndex
 * @this sAction
 */
export default function changeDetailTab(index, prefix, prevIndex) {
    if (index !== prevIndex) {
        this.dsClear();

        this.dsAdd('set', prefix+'/tabs/'+prevIndex+'/active', false);
        this.dsAdd('set', prefix+'/tabs/'+index+'/active', true);
        this.dsAdd('set', prefix+'/activeTab', index);
        this.dsProcess();
    }
}
