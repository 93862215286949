/**
 *
 * @param data
 */
export default function toList(data) {
    const view = this.getViewName();
    if (view == 'list') {
        this.reload();
    } else {
        location.href = '#list/'+data;
    }
}
