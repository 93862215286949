import React from 'react';
import ReactDOM from 'react-dom';
import PureComponent from '../pure';
import sAction from 'sAction';
import Loader from '../loader';
import DropdownAnimation from '../animation/DropdownAnimation';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class RecentRecordsManager extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showComponent: false,
            dataReturned: false,
            rows: [],
        };
        this.restDataEmptyMessage = null;
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    /**
     *
     */
    changeState() {
        if (this.state.showComponent) {
            this.setState({
                showComponent: false,
                dataReturned: false,
                rows: [],
            });

            this.restDataEmptyMessage = null;
        } else {
            this.setState({showComponent: true});
            this.getDataFromRest();
        }
    }

    handleClickOutside = (event) => {
        if (this.state.showComponent) {
            // eslint-disable-next-line react/no-find-dom-node
            const domNode = ReactDOM.findDOMNode(this);

            if (!domNode || !domNode.contains(event.target)) {
                this.setState({
                    showComponent: false,
                    dataReturned: false,
                    rows: [],
                });
            }
        }
    };

    /**
     *
     */
    getDataFromRest() {
        sAction.rest.post('getRecentRecords', {}, (returnData) => {
            if (returnData.message.text === 'success') {
                returnData.message.data.forEach((data) => {
                    const url = `#detail/${data.moduleName}/${data.itemId}/`;
                    this.state.rows.push(<tr className="recordLine" key={data.itemId}
                        onClick={() => window.location.replace(url)}>
                        <td className="dateTimeItemRecords">{data.dateTime}</td>
                        <td className="itemName">{sAction.translate(data.item_summary, data.moduleName)}
                            <span
                                className="moduleName">{' (' + sAction.translate('LBL_MODULE_NAME', data.moduleName) + ')'}
                            </span>
                        </td>
                    </tr>);
                });
            } else {
                this.restDataEmptyMessage = sAction.translate('LBL_NO_RECENT_RECORDS', returnData.message.text);
            }
            this.setState({dataReturned: true});
        });
    }

    /**
     *
     * @return {JSX.Element}
     */
    renderTable() {
        return (
            <>
                {
                    !this.restDataEmptyMessage ?
                        <table className="recordsTable">
                            <thead className="recordsHeader">
                                <tr className="tableHead">
                                    <th>{sAction.translate('LBL_RECENT_RECORDS_HEADER', 'Poslední záznamy')}</th>
                                </tr>
                            </thead>
                            <tbody className="recentRecordsLineContainer">{this.state.rows}</tbody>
                        </table> :
                        <div className="messageContainer">
                            <div className="messageContent">{this.restDataEmptyMessage}</div>
                        </div>
                }
            </>
        );
    }

    /**
     *
     * @return {string}
     */
    getClass() {
        if (!this.state.showComponent) {
            return '';
        }
        return 'open';
    }

    render() {
        return (
            <TooltipWrapper label={'LBL_RECENT_RECORDS_HEADER'} disabled={this.state.showComponent}>
                <div className={`icon-recentRecords recordsButtonContainer ${this.getClass()}`} onClick={() => this.changeState()} id='recentRecordsButton'>
                    <DropdownAnimation className="recentRecordsContainer" open={this.state.showComponent}>
                        <div className="recentRecordsContent" onBlur={() => this.changeState()}>
                            {this.state.dataReturned ? this.renderTable() : <Loader key={'zero'}/>}
                        </div>
                    </DropdownAnimation>
                </div>
            </TooltipWrapper>
        );
    }
}
