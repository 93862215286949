import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DetailTimeline from './DetailTimeline/DetailTimeline';

export default function DetailTimelineContainer(props) {
    if (!props.timeline) {
        return null;
    }

    return (
        <DetailTimeline
            id={props.id}
            data={props.timeline}
            module={props.module}
            way={props.prefix}
            field={props.fields.get(props.timeline?.field)}
            type={props.type}
            options={props.fields.getIn([props.timeline?.field, 'def', 'options'])}
            customOptions={props.fields.getIn([props.timeline?.field, 'def', 'customOptions'])}
        />
    );
}

DetailTimelineContainer.propTypes = {
    timeline: ImmutablePropTypes.recordOf({
        field: PropTypes.string.isRequired,
        list: ImmutablePropTypes.mapOf(PropTypes.string).isRequired,
        clickable: PropTypes.bool,
        onCLick: ImmutablePropTypes.mapOf(PropTypes.string),
        titles: ImmutablePropTypes.mapOf(PropTypes.string),
        colors: ImmutablePropTypes.mapOf(PropTypes.string).isRequired,
    }),
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    id: PropTypes.string,
    module: PropTypes.string,
    type: PropTypes.string,
    prefix: PropTypes.string,
};
