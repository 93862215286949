/**
 * @param {object} prefix
 * @this sAction
 * @button ConvertLead/Leads
 * @TODO rest.post => rest.fetchData
 */
export default function convertLeadSave(prefix) {
    const data = this.dataGet(prefix);
    const name = data.name;
    const rec = data.record;
    const modules = data.detailViews;
    let sendToCrm = true;

    const self = this;

    let errMsg = '';

    const wrongFields = [];
    const correctFields = [];
    const moduleClasses = [];

    modules.forEach((module, ind) => {
        module = module?.toJS();
        const customData = module.customData;
        let moduleValid = true;
        if (module.canSave !== true) {
            sendToCrm = false;
            moduleValid = false;
            errMsg += self.translate('LBL_MODULE_NAME', module.module)+': Záznam momentálně nelze uložit<br>';
        }

        // required modules
        if (customData?.required) {
            if (!customData?.checked) {
                if (customData?.related_id === undefined) {
                    sendToCrm = false;
                    moduleValid = false;
                    errMsg += self.translate('LBL_MODULE_NAME', module.module)+': Tento modul je povinný<br>';
                } else if (!customData?.related_id) {
                    sendToCrm = false;
                    moduleValid = false;
                    errMsg += self.translate('LBL_MODULE_NAME', module.module)+': Tento modul je povinný<br>';
                }
            }
        }

        // required fields
        if (customData?.checked && customData?.create) {
            const fields = module.fields;
            let hasWrongField = false;
            const visibleFields = [];
            module.tabs.forEach((tab) => {
                tab.panels.forEach((panel) => {
                    if (tab.hidden === true) {
                        return;
                    }
                    panel.rows.forEachObject((row) => {
                        row.fields.forEachObject((rowField) => {
                            visibleFields.push(rowField.name);
                        });
                    });
                });
            });
            fields.forEachObject((field) => {
                if (field.name !== 'id') {
                    if (field.def && field.def.required === true && (field.value === '' || field.value === null)) {
                        if (visibleFields.includes(field.name)) {
                            wrongFields.push({name: field.name, index: ind, class: 'error', module: module.module});
                            hasWrongField = true;
                        }
                    } else if (field.def && field.def.required === true &&
                        (field.value) && field.customClass === 'error'
                    ) {
                        correctFields.push({name: field.name, index: ind, class: ''});
                    }
                }
            });

            if (hasWrongField) {
                errMsg += self.translate('LBL_MODULE_NAME', module.module)+':  Nejsou vyplněny všechny povinné údaje<br>';
                sendToCrm = false;
                moduleValid = false;
            }
        }

        if (moduleValid) {
            moduleClasses.push({index: ind, class: ''});
        } else {
            moduleClasses.push({index: ind, class: 'error'});
        }
    });
    if (sendToCrm === false) {
        self.dsClear();
        wrongFields.forEach((field) => {
            const tmp = self.dataGet(prefix+'/detailViews/'+module.index+'/customData');
            if (!tmp) {
                self.dsAdd('set', prefix+'/detailViews/'+module.index+'/customData', {});
            }
            self.dsAdd('set', prefix+'/detailViews/'+field.index+'/fields/'+field.name+'/customClass', field.class);
        });

        correctFields.forEach((field) => {
            const tmp = self.dataGet(prefix+'/detailViews/'+module.index+'/customData');
            if (!tmp) {
                self.dsAdd('set', prefix+'/detailViews/'+module.index+'/customData', {});
            }
            self.dsAdd('set', prefix+'/detailViews/'+field.index+'/fields/'+field.name+'/customClass', field.class);
        });

        moduleClasses.forEach((module) => {
            const tmp = self.dataGet(prefix+'/detailViews/'+module.index+'/customData');
            if (!tmp) {
                self.dsAdd('set', prefix+'/detailViews/'+module.index+'/customData', {});
            }

            self.dsAdd('set', prefix+'/detailViews/'+module.index+'/customData/customClass', module.class);
        });

        self.dsProcess();
        self.error(errMsg);
    } else {
        const params = {record: rec, modules: []};
        self.load();
        modules.forEach((module, moduleIndex) => {
            if (module.customData?.checked) {
                if (module.customData?.create) {
                    let changes = module.changes;
                    const emails = self.customFieldEmail(prefix + '/detailViews/' + moduleIndex);
                    if (emails) {
                        changes = changes.setIn(['fields', 'email1'], emails);
                    }
                    params.modules.push({
                        module: module.module,
                        changes,
                        newRecord: true,
                        beanMark: module.customData?.beanMark,
                        activities: module.customData?.allowCopyActivities && module.customData?.activities,

                    });
                } else {
                    params.modules.push({
                        module: module.module,
                        record: module.customData?.related_id,
                        beanMark: module.customData?.beanMark,
                        newRecord: false,
                        activities: module.customData?.allowCopyActivities && module.customData?.activities,
                    });
                }
            }
        });
        self.rest.post('leadConvertSave', params, (returnData) => {
            if (returnData.status === true) {
                self.dsClear();
                const action = {
                    type: 'LEADCONVERTHANDLERESULTS',
                    content: {data: returnData.message.data, name: name},
                    prefix: 'view',
                };
                self.dsAddCustom(action);
                self.dsProcess();
            } else {
                self.error(returnData.errorMessage.text);
            }
            self.unLoad();
        });
    }
}
