/**
 *
 * @param way
 * @param type
 */
export default function reportWindowAddToGroup(way, type) {
    this.dsClear();
    if (type == 'field') {
        this.dsAdd('add', way+'/children', {}, 'selectField');
    } else if (type == 'group') {
        this.dsAdd('add', way+'/children', {}, 'reportWindowGroup');
    }
    this.dsAdd('set', 'reportWindow/filterAlert/alert', false);
    this.dsProcess();
}
