/**
 * @param {object} newRecord
 * @param {object} subpanel
 * @param {string} prefix
 * @this sAction
 * @TODO rest.post => rest.fetchData
 */
export default function deleteRecordSubpanel(newRecord, subpanel, prefix = 'view') {
    let recModule = subpanel.module;
    const relid = newRecord.record.record || newRecord.record;
    if (['History', 'Activities'].includes(recModule)) {
        recModule = newRecord.record.module;
    }

    this.confrim(this.translate('LBL_BUTTON_CONFIRM_DELETE_RECORD'), () => {
        this.rest.delete(
            'delete/' + recModule + '/' + relid,
            null,
            (returnData) => {
                if (!returnData.status) {
                    this.reloadSubpanelByWay(prefix, subpanel.module);
                    this.popupHide();
                } else {
                    this.error('Špatný návrat ze serveru');
                }
            },
        );
    });
}
