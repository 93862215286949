import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../inputFieldClass';
import Select from '../../formElements/Select';
import sAction from 'sAction';

export default class DefaultAcmDynamicEnum extends InputField {
    constructor() {
        super();
        this.state = {
            state: 'fine',
            options: [],
        };
        this.input = React.createRef();
    }

    componentDidMount() {

    }

    /**
     * @param {string} value
     * @return {boolean}
     */
    check(value) {
        const required = this.props.def.get('required') || false;

        let ok = true;
        if (required === true && value === '') {
            ok = false;
            this.setState({ok});
        }

        return ok;
    }

    /**
     * @param {string} path
     */
    cancel(path) {
        if (path) {
            sAction.dataSet(path + '/actEdit', false);
        }
    }

    /**
     * @param {event} e
     * @param {object} param
     */
    save(e, param = {}) {
        const value = e.target.value;

        if (this.check(value)) {
            const data = {
                way: this.props.way,
                name: this.props.name,
                value,
                ...param,
            };

            if (e.keyCode === 13) {
                data['cancelEdit'] = false;
            }

            this.saveField(data);
        }
    }
    render() {
        let path = this.props.way + '/rows/' + this.props.rowIndex + '/records/' + this.props.recordIndex;
        if (this.props.rowIndex === undefined || this.props.recordIndex === undefined) {
            path = this.props.way;
        }
        let options = [];
        if (this.props.def.get?.('options') && sAction.app_strings['dynamicEnum']?.[this.props.def.get('options')]) {
            options = sAction.app_strings['dynamicEnum'][this.props.def.get('options')];
        } else {
            console.warn('Chybějící dynamický číselník');
        }
        const optionsToSelect = [];
        options.forEachObject((option, key) => {
            optionsToSelect.push({label: option, value: key});
        });
        const forceOpen = this.props.def.get('forceOpen') || this.props.listField || this.forceOpen;

        return (
            <Select
                options={optionsToSelect}
                defaultValue={this.props.value}
                onKeyDown={(e) => this.onKeyDown(e, null, false)}
                open={forceOpen === true ? forceOpen : false}
                autoFocus={forceOpen !== true ? true : forceOpen}
                onChange={(e) => this.save(e)}
                onClose={() => this.cancel(path)}
            />
        );
    }
}

DefaultAcmDynamicEnum.propTypes = {
    def: PropTypes.object,
    way: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rowIndex: PropTypes.number,
    recordIndex: PropTypes.number,
    listField: PropTypes.bool,
};
