import PropTypes from 'prop-types';
import React, {useEffect, useState, Suspense} from 'react';
import Loader from '../loader';

const OutTable = React.lazy(
    () => import('react-excel-renderer').then((module) => ({default: module.OutTable})),
);

export default function RightPanelExcel(props) {
    const [state, setState] = useState({
        cols: [],
        rows: [],
    });

    useEffect(() => {
        fetch(props.data.get('url')).then((res) => res.blob()).then(async (blob) => {
            import('react-excel-renderer').then((excel) => {
                // eslint-disable-next-line new-cap
                excel.ExcelRenderer(blob, (err, resp) => {
                    if (err) {
                        console.warn(err);
                    } else {
                        const cols = [{
                            key: 0,
                            name: <span className='iconfas-tableLeftCorner'/>,
                        }];
                        resp.cols.forEach((element, i) => {
                            cols.push({
                                key: i + 1,
                                name: element.name,
                            });
                        });

                        setState({
                            cols: cols,
                            rows: resp.rows,
                        });
                    }
                });
            });
        });
    }, []);

    return (
        <div className="RightPanelWord">
            <Suspense fallback={<Loader/>}>
                <OutTable
                    data={state.rows}
                    columns={state.cols}
                    tableClassName="ExcelTable2007"
                    tableHeaderRowClass="heading"
                    withZeroColumn={true}
                    withoutRowNum={false}
                />
            </Suspense>
        </div>
    );
}

RightPanelExcel.propTypes = {
    data: PropTypes.any,
};
