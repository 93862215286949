/**
 * @this sAction
 * @button DetailView/Users
 */
export default function userCancelReplacement() {
    this.confrim(this.translate('LBL_CONFIRM_CANCELING_REPLACEMENT'), () => {
        const params = {action: 'user_cancel_replacement'};
        this.popupHide();
        const self = this;
        this.rest.post('customAction', params, function(returnData) {
            if (returnData.status) {
                self.route();
            } else {
                self.error(returnData.errorMessage.text);
            }
        });
    });
}
