/**
 * calToggleSharedView
 * @param {string} calActiveView
 */
export default function calToggleSharedView(calActiveView = null) {
    const state = !this.calEvents.sharedView;

    this.calEvents.sharedView = state;

    const calSettings = this.dataGet('calendar/setting').set('sharedView', state);
    this.dataSet('calendar/setting/sharedView', state);
    this.saveCalSettings(calSettings, () => {
        // menime komplet nastaveni uzivatele, protoze jinak si to pri dalsim prenacteni prepiseme.
        // V budoucnu tam udelat nejakou docasnou picovinu{this.calEvents.sharedView},
        // ale nevim jak to vyuzit v calBody, aby se to bralo pri prerenderovani
        // neni nutne upgradovat, ale behalo by to rychleji s tim, ze by jsme si v pameti drzeli vice dat

        if (calActiveView && state) {
            // not ideal but works for now -> to sync calendar views in shared calendar
            setTimeout(() => {
                this.dataSet('calendar/calViewType', calActiveView);
            }, 2500);
        }
    }, state);
}
