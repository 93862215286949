/**
 *
 * @param prefix
 */
export default function checkDetailUrl(prefix) {
    let hash = window.location.hash;
    hash = hash.split('/');
    if (hash[3] == 'rel') {
        let urlData = null;
        try {
            urlData = this.fromBase64(hash[4]);
        } catch (e) {

        }
        let fieldWasFind = false;
        if (urlData != null) {
            const detail = this.dataGet(prefix);
            detail.fields.entrySeq().forEach((e) => {
                const key = e[0];
                const value = e[1];
                if (value.def.get('type') == 'relate' && value.def.get('module') == urlData.module) {
                    fieldWasFind = true;
                    const data = {
                        way: prefix+'/fields/'+key,
                        fieldName: key,
                        name: value.def.get('id_name'),
                        type: 'relate',
                        value: {id: urlData.id, name: urlData.name},
                        cancelEdit: true,
                    };
                    this.saveField(data);
                }
            });
            if (fieldWasFind == false) {
                console.log(urlData);
                this.dataSet(prefix+'/changes/relationships', urlData);
            }
        }
    }
}
