/**
 *
 * @param key
 * @param paramValue
 */
export default function setStorage(key, paramValue) {
    let value = null;
    if (typeof paramValue === 'object' && paramValue != null) {
        value = JSON.stringify(paramValue);
    } else {
        value = paramValue;
    }
    if (window.localStorage) {
        localStorage.setItem(key, value);
    } else {
        this.rest.setCookie(key, value, 1);
    }
}
