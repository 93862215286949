import sAction from 'sAction';

/**
 *
 * @param {*} e
 */
export default function baseOnKeyDown(e) {
    if (e.ctrlKey && e.keyCode === 32) {
        this.toggleSweetSpot();
    }

    if (e.keyCode === 116 ||
        (e.ctrlKey && e.keyCode === 82) ||
        (e.ctrlKey && (e.which || e.keyCode) === 116) ||
        (e.ctrlKey && (e.which || e.keyCode) === 82)) {
        if (this.needSave() === false) {
            e.preventDefault();
            this.route(false);
        } else {
            this.alert('Provedené změny nebudou po odchodu uloženy. Ulože prosím záznam.');
        }
    }

    if (e.keyCode === 9) {
        const isRightPanelShow = !!this.dataGet('rightPanel/show');
        if (!(this.dataGet('conf/view') === 'detail' || isRightPanelShow) || e.target.classList.length) {
            return;
        }

        e.preventDefault();
        const prefix = isRightPanelShow ? 'rightPanel/data/detail' : 'view';

        const fields = sAction.dataGet(`${prefix}/fields`);
        let skip = false;

        fields.forEach((v) => {
            if (v.actEdit) {
                skip = true;
            }
        });

        if (!skip) {
            sAction.detailTabNext({
                name: '',
                prefix: prefix,
                index: -1,
                rowWay: '',
                type: '',
                shift: e.shiftKey,
            }, true );
        }
    }
}
