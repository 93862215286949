/**
 * @param {Object} sAction
 * @param {prefix} prefix
 * @returns {Object}
 */
export default function getQuoteSaveData(sAction, prefix) {
    const quoteData = sAction.dataGet(prefix + '/customData');
    const prodData = quoteData.get('productData').toJS();
    const sums = sAction.calcSums(prefix + '/customData/productData', {
        type: 'total',
        data: prodData,
        cenotvorba: prodData.cenotvorba,
    });
    const duplicateId = quoteData.duplicateId;
    const type = quoteData.type;

    return {productData: prodData, total_tax: sums.total_tax, total_discount: sums.total_discount, total: sums.total, duplicateId, type};
}
