/**
 *
 * @param text
 */
export default function decodeHTMLEntities(text) {
    if (text && typeof(text) === 'string') {
        const entities = [
            ['#039', '\''],
            ['quot', '"'],
        ];

        for (let i = 0, max = entities.length; i < max; ++i) {
            text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);
        }
    }
    return text;
}
