/**
 *
 * @param formData
 * @param callback
 */
export default function sendFiles(formData, callback = undefined) {
    const data = {
        url: 'createFiles',
        data: formData,
    };
    this.filesXhr(data, (returnData) => {
        // this.response is a Blob, because we set responseType above
        try {
            if (callback != undefined) {
                callback(returnData);
            }
        } catch (e) {

        }
    });
}
