import React from 'react';
import PropTypes from 'prop-types';
import Enum from '../Enum/Enum';
import Select from '../../formElements/Select';
import sAction from 'sAction';

export default class Bool extends Enum {
    render() {
        const optionsToSelect = [
            {value: '1', label: sAction.translate('LBL_STATUS_ACTIVE_LIST')},
            {value: '0', label: sAction.translate('LBL_STATUS_INACTIVE_LIST')},
        ];

        const forceOpen = this.props.def.get('forceOpen') || this.props.listField;

        return (
            <Select
                options={optionsToSelect}
                defaultValue={this.props.value}
                onKeyDown={(e) => this.onKeyDown(e, null, false)}
                open={forceOpen === true ? forceOpen : false}
                autoFocus={forceOpen !== true ? true : forceOpen}
                onChange={(e) => this.save(e)}
            />
        );
    }
}

Bool.propTypes = {
    value: PropTypes.bool,
    def: PropTypes.object,
    listField: PropTypes.bool,
    index: PropTypes.number,
};
