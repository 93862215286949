import detailDefault from '../../detailDefault';

// eslint-disable-next-line camelcase
export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
        const priceListType = sAction.dataGet(`${data.prefix}/fields/price_list_type/value`);
        this.handleProductCategoryVisible(sAction, data.prefix, priceListType);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'price_list_type':
                this.handleProductCategoryVisible(sAction, data.prefix, data.value);
                break;
        }
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} priceListType
     */
    handleProductCategoryVisible(sAction, prefix, priceListType) {
        let visible = false;
        if (priceListType === 'group') {
            visible = true;
        }
        sAction.dsClear();
        sAction.dsAdd('set', `${prefix}/fields/category_discount/def/visible`, visible);
        sAction.dsAdd('set', `${prefix}/fields/category_discount_type/def/visible`, visible);
        sAction.dsAdd('set', `${prefix}/fields/productcategories_acm_price_lists_1_name/def/visible`, visible);
        sAction.dsAdd('set', `${prefix}/fields/productcategories_acm_price_lists_1_name/def/required`, visible);
        sAction.dsAdd('set', `${prefix}/customData/customLines/def/visible`, !visible);
        sAction.dsProcess();
    }

    /**
     *
     * @param {*} sAction
     * @param {*} data
     */
    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    const fields = {product_list_price: 'list_price', product_code: 'mft_part_num'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, (returnData) => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();
                    });
                    sAction.unLoad();
                }
                break;
        }
    }
}
