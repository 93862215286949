import detailDefault from '../../detailDefault';

/* eslint camelcase: "off" */
export default class detailacm_invoices extends detailDefault {
    load(sAction, data) {
        if (data.prefix === 'rightPanel/data/detail') {
            sAction.dataSet(data.prefix + '/fields/cena_dph_celkem/def/readonly', true);
            sAction.dataSet(data.prefix + '/fields/cena_bez_dph_celkem/def/readonly', true);
            sAction.dataSet(data.prefix + '/fields/cena_s_dph_celkem/def/readonly', true);
        }
        sAction.dataSet(data.prefix + '/fields/name/def/visible', false);

        this.setRequiredFields(sAction, data);

        this.lockReadOnly(sAction, data);
    }

    update(sAction, data) {
        if (data.field === 'datum_uhrazeni' && data.value) {
            sAction.dataSet(data.prefix + '/fields/stav_uhrazeni/value', 'uhrazeno');
            sAction.dataSet(data.prefix + '/changes/fields/stav_uhrazeni', 'uhrazeno');
        } else if (data.field === 'stav_uhrazeni') {
            this.setRequiredFields(sAction, data, data.value);
        } else if (data.field === 'currency_id') {
            sAction.load();
            sAction.quickSearch({
                module: 'acm_bankAccounts',
                fields: ['default_account'],
                getFields: ['id', 'name', 'currency_id'],
                value: 1,
            }, (bankAccounts) => {
                const acc = bankAccounts.find((record) => record.currency_id === data.value);
                if (acc) {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/acm_bankaccounts_acm_invoices_name/value', acc.name);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/acm_bankaccounts_acm_invoices_name', acc.name);
                    sAction.dsAdd('set', data.prefix + '/fields/acm_bankaccounts_acm_invoicesacm_bankaccounts_ida/value', acc.id);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/acm_bankaccounts_acm_invoicesacm_bankaccounts_ida', acc.id);
                    sAction.dsProcess();
                }
                sAction.unLoad();
            });
        } else if (data.field === 'acm_invoices_accountsaccounts_ida') {
            if (!data.value.id) {
                return;
            }

            sAction.load();

            const bankAccounts = new Promise((resolve) => {
                sAction.quickSearch({
                    module: 'acm_bankAccounts',
                    fields: ['default_account'],
                    getFields: ['id', 'name', 'currency_id'],
                    value: 1,
                }, (bankAccounts) => {
                    resolve(bankAccounts.reduce((acc, record) => {
                        acc[record.currency_id] = record;
                        return acc;
                    }, {}));
                });
            });

            sAction.quickSearch({
                module: 'Accounts',
                fields: ['id'],
                getFields: ['hourly_rate', 'currency_id'],
                value: data.value.id,
                limit: 1,
            }, ([returnData]) => {
                sAction.dsClear();

                const lines = sAction.dataGet(data.prefix + '/customData/customLines/lines');
                const updatedLines = [];
                if (lines.size !== 0) {
                    for (const [index, line] of lines.entries()) {
                        if (line.get('deleted')) {
                            continue;
                        }

                        if (line.get('mj') === 'hod.') {
                            const lineData = {
                                ...line.toJS(),
                                cena_bez_dph_kus: returnData.hourly_rate,
                                sazba_dph: Number(returnData.currency_id) === -99 ? '21' : '0',
                            };
                            sAction.dsAdd('set', `${data.prefix}/customData/customLines/lines/${index}`, lineData);
                            updatedLines.push(index);
                        }
                    }
                }

                if (returnData.hourly_rate !== 0 && updatedLines.length === 0) {
                    const lineData = {
                        cena_bez_dph_kus: returnData.hourly_rate,
                        mnozstvi: 1,
                        mj: 'hod.',
                        sazba_dph: Number(returnData.currency_id) === -99 ? '21' : '0',
                    };

                    sAction.dsAdd('set', data.prefix + '/customData/customLines/lines/0', lineData);
                    updatedLines.push(0);
                }

                sAction.dsAdd('set', data.prefix + '/fields/currency_id/value', returnData.currency_id);
                sAction.dsAdd('set', data.prefix + '/changes/fields/currency_id', returnData.currency_id);
                sAction.dsAdd('set', data.prefix + '/fields/currency_id/def/readonly', true);
                sAction.dsAdd('set', data.prefix + '/changes/forceChange', true);
                sAction.dsProcess();

                for (const index of updatedLines) {
                    sAction.onLineFieldUpdate(data.prefix, index, 'sazba_dph');
                    sAction.onLineFieldUpdate(data.prefix, index, 'cena_bez_dph_kus');
                }

                if (!returnData.currency_id) {
                    sAction.unLoad();
                    return;
                }

                bankAccounts.then((bankAccounts) => {
                    const acc = bankAccounts[returnData.currency_id];

                    if (acc) {
                        sAction.dsClear();
                        sAction.dsAdd('set', data.prefix + '/fields/acm_bankaccounts_acm_invoices_name/value', acc.name);
                        sAction.dsAdd('set', data.prefix + '/changes/fields/acm_bankaccounts_acm_invoices_name', acc.name);
                        sAction.dsAdd('set', data.prefix + '/fields/acm_bankaccounts_acm_invoicesacm_bankaccounts_ida/value', acc.id);
                        sAction.dsAdd('set', data.prefix + '/changes/fields/acm_bankaccounts_acm_invoicesacm_bankaccounts_ida', acc.id);
                        sAction.dsProcess();
                    }

                    sAction.unLoad();
                });
            });
        }
    }

    /**
     *
     * @param {sAction} sAction
     * @param {object} data
     * @param {string} newState
     */
    setRequiredFields(sAction, data, newState = null) {
        const state = newState ?? sAction.dataGet(data.prefix+'/fields/stav_uhrazeni/value');
        sAction.dsClear();
        // required fields
        sAction.dsAdd('set', data.prefix+'/fields/acm_bankaccounts_acm_invoices_name/def/required', true);
        sAction.dsAdd('set', data.prefix+'/fields/datum_uhrazeni/def/required', state === 'uhrazeno');
        // readonly fields
        sAction.dsAdd('set', data.prefix+'/fields/datum_uhrazeni/def/readonly', state === 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/name/def/readonly', state !== 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/datum_vystaveni/def/readonly', state !== 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/currency_id/def/readonly', state !== 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/acm_invoices_accounts_name/def/readonly', state !== 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/acm_templateinvoicing_acm_invoices_name/def/readonly', state !== 'vystavena');
        sAction.dsAdd('set', data.prefix+'/fields/acm_bankaccounts_acm_invoices_name/def/readonly', state !== 'vystavena');

        sAction.dsProcess();
    }

    /**
     * @param {any} sAction
     * @param {any} data
     */
    newSubRecord(sAction, data) {
        const currency = sAction.dataGet(data.prefix+'/fields/currency_id/value');
        if (sAction.detailPredefinedFields == null) {
            sAction.detailPredefinedFields = [];
        }
        sAction.detailPredefinedFields.push({
            'type': 'field',
            'name': 'currency_id',
            'value': currency,
        });
    }

    /**
     * @param {any} sAction
     * @param {any} data
     * @return {boolean}
     */
    beforeSave(sAction, data) {
        if (data.prefix === 'view') {
            if ((!sAction.dataGet('view/customData/customLines') ||
                sAction.dataGet('view/customData/customLines').has('lines') === false)
            ) {
                return this.noRowsErrorMsg(sAction);
            }
            const lines = sAction.dataGet('view/customData/customLines/lines', null, true);
            let linLen = 0;
            if (lines !== undefined) {
                linLen = Object.keys(lines).length;

                lines.forEachObject((data) => {
                    if (parseInt(data.deleted) === 1) {
                        linLen--;
                    }
                });
            }

            if (lines !== undefined) {
                if (Object.keys(lines).length === 0 || linLen === 0) {
                    return this.noRowsErrorMsg(sAction);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }

        return true;
    }

    /**
     * noRowsErrorMsg
     * @param {any} sAction
     * @return {boolean}
     */
    noRowsErrorMsg(sAction) {
        sAction.alert(sAction.translate('LBL_NO_ROWS_MSG', 'acm_invoices'));
        return false;
    }

    /**
     * lockReadOnly if stav_uhrazeni is paid or sent
     * @param {any} sAction
     * @param {any} data
     */
    lockReadOnly(sAction, data) {
        const state = sAction.dataGet(data.prefix + '/fields/stav_uhrazeni/value');
        const lockedStates = ['odeslana', 'uhrazeno'];
        const allowedFields = [
            'stav_uhrazeni',
            'datum_uhrazeni',
            'assigned_user_id',
            'assigned_user_name',
            'text_pred',
            'objedn_cislo',
        ];

        if (lockedStates.includes(state)) {
            const fields = sAction.dataGet(data.prefix + '/fields');

            sAction.dsClear();

            fields.forEach((field) => {
                if (!allowedFields.includes(field.name)) {
                    sAction.dsAdd('set', `${data.prefix}/fields/${field.name}/def/readonly`, true);
                }
            });

            sAction.dsAdd('set', `${data.prefix}/customData/customLines/def/readonly`, true);
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {any} sAction
     * @param {any} data
     */
    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    const fields = {cena_bez_dph_kus: 'list_price'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, (returnData) => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();
                        sAction.unLoad();
                    });
                }
                break;
        }
    }
}
