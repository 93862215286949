import {Record} from 'immutable';

/**
 * Fetches data for listview editing
 * @param {string} way store path of listview
 * @param {object} props data to pass to backend
 */
export default function openDefaultListViewEdit(way, props = null) {
    const data = this.dataGet(way);
    const module = data.modul;
    const listViewType = data.type || 'list';
    const parent = (data.type === 'subpanel')? this.dataGet(way + '/../../../module') : null;

    this.load();
    let url = `listViewEditLayout/${module}/${listViewType}`;
    if (data.type === 'subpanel') {
        url += `/${parent}`;
    }
    this.rest.fetchData(url, 'GET', props).then((ret) => {
        const defsData = ret.data;
        this.dsClear();
        const id = this.getPopupId();
        this.dsAdd('set', 'conf/popup' + id + '/show', true);
        this.dsAdd('set', 'conf/popup' + id + '/content', 'defaultListViewEdit');
        this.dsAdd('set', 'conf/popup' + id + '/data/way', way);
        this.dsAdd('set', 'conf/popup' + id + '/data/columns', {}, 'reportWindowColumns');

        const fields = {
            fields: [],
            relate: [],
        };
        const active = {};
        const hidden = {};
        defsData.fields.forEachObject((field, key) => {
            if (field === null) {
                return;
            }
            if (field.type !== 'relate' && field.type !== 'link') {
                fields.fields.push(field);
            } else if (field.searchable === true) {
                fields.relate.push(field);
            }

            if (field.type === 'link') {
                return;
            }
            field['show'] = true;
            field['fieldModule'] = module;
            if (field['default'] === true && defsData.active.length === 0) {
                active[key] = field;
            } else {
                hidden[key] = field;
            }
        });

        defsData.active.forEach((field) => {
            if (hidden[field.field] === undefined) {
                return;
            }
            const fieldDef = hidden[field.field];
            fieldDef['width'] = field.width;
            active[field.field] = fieldDef;
            delete hidden[field.field];
        });

        hidden.forEachObject((def, name) => {
            this.dsAdd(
                'add',
                'conf/popup' + id + '/data/columns/hidden',
                {name: name, def: def},
                'reportWindowColumn',
            );
        });
        active.forEachObject((def, name) => {
            this.dsAdd(
                'add',
                'conf/popup' + id + '/data/columns/active',
                {name: name, def: def},
                'reportWindowColumn',
            );
        });

        this.dsAdd('set', `conf/popup${id}/data/order`, new Record(defsData.order ?? {
            field: '',
            sort: 'ASC',
            module: module,
        })());
        this.dsAdd('set', 'conf/popup' + id + '/data/header', this.translate('LBL_LISTVIEW_EDIT'));
        this.dsAdd('set', 'conf/popup' + id + '/data/module', module);
        this.dsAdd('set', 'conf/popup' + id + '/data/fields', fields.fields);
        this.dsAdd('set', 'conf/popup' + id + '/data/relate', fields.relate);
        this.dsAdd('set', 'conf/popup' + id + '/data/listview_type', listViewType);
        if (listViewType === 'subpanel') {
            this.dsAdd('set', 'conf/popup' + id + '/data/parent', parent);
            this.dsAdd('set', `conf/popup${id}/data/translations`, defsData.translations);
        }
        this.dsAdd('set', 'conf/popup' + id + '/data/activeRelate', [
            {main: true, module: module, selected: true},
        ]);
        this.dsProcess();
        this.unLoad();
    }).catch((error) => {
        this.unLoad();
        this.error(error.text ?? error);
    });
}
