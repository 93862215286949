/**
 *
 * @param string
 */
export default function normalizeString(string) {
    return string.split('').map(function(letter) {
        const i = this.accents.indexOf(letter);
        return (i !== -1) ? this.out[i] : letter;
    }.bind({
        accents: 'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğěščřžýáíéúůĚŠČŘŽÝÁÍÉÚŮ',
        out: 'AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGggescrzyaieuuESCRZYAIEUU',
    }),
    ).join('');
}
