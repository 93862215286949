/**
 *
 * @param way
 * @param index
 * @param $module
 */
export default function customDataAddRow(way, index, $module) {
    const changesWay = way.replace('/customData', '/changes/customData');
    const recordsWay = way + '/records';
    const row = this.dataGet(recordsWay + '/' + index);
    this.dsClear();
    this.dsAdd('delete', recordsWay, index);
    this.dsAdd('set', changesWay + '/records/' + index, {delete: row.get('id')});
    this.dsAdd('set', changesWay + '/module', $module);
    this.dsProcess();
}
