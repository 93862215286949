/**
 *
 * @param params
 */
export default function hrefSpecialModule(params) {
    if (params.blank) {
        window.open('#'+params.relModule, '_blank');
    } else {
        this.href('#'+params.relModule);
    }
}
