import {DataConstructors} from '../../store/DataConstructors';
import {List, Map} from 'immutable';

/**
 *
 * @param action
 */
export default function prepareListStructure(action) {
    const list = new DataConstructors['ListView']({
        modul: action.page,
        rows: List(),
        records: List(),
        orderBy: action.orderBy,
        asc: action.asc,
        filter: List(action.filter),
        defaultFilter: Map(action.defaultFilter),
        actFiltering: action.actFiltering,
        fieldFunction: new Map({
            parameters: null,
        }),
        colors: new Map(),
        listViewTree: new DataConstructors.listViewTree(),
        buttons: List(action.buttons),
        selected: null,
        selectedActive: null,
        offset: null,
        limit: action.limit,
        rowCount: null,
        page: action.actPagination,
        type: null,
        savedSearch: null,
        actSavedSearch: action.savedSearch,
        prefix: null,
        category: null,
        customData: new Map(),
    });

    return list;
}
