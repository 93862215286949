/**
 *
 * @param row
 * @param line
 * @param value
 */
export default function cellToEdit(row, line, value) {
    this.dsClear();
    this.dsAdd('set', `view/rows/${row}/records/${line}/actEdit`, value);
    this.dsProcess();
}
