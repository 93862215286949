/**
 * @param {object} data
 * @param {string} way
 * @param {string} prefix
 * @this sAction
 */
export default function toogleSubpanel(data, way, prefix) {
    const open = data.def.get('open');
    if (open === true) {
        this.dataSet(way+'/def/open', false);
        this.saveSubpanelOrder(prefix);
        const count = this.dataGet(`${way}/rows/rowTotalCount`);
        if (!count) {
            // pokud nekdo klikne na aktualizovat, vymaze se pocet zaznamu,
            // pri zavreni je tedy potreba zjistit pocet, pokud jej nezname
            this.listViewCount(`${way}/rows`);
        }
    } else if (open === false || open == null) {
        // this.dataSet(way+"/def/open",true);
        this.dataSet(way+'/def/load', true);
        const parentData = this.dataGet(prefix);
        const rel = data.def.get('rel');
        const subData = {
            parentId: parentData.id,
            parentModule: rel.get('module'),
            module: data.module,
            relName: rel.get('relName'),
            prefix: prefix,
            way: way,
            def: data.def,
        };
        this.reloadSubpanel(subData);
    }
}
