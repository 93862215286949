/**
 * @param {object} paramData
 * @this sAction
 * @button DetailView
 */
export default function detailNewRecord(paramData) {
    const modul = paramData.module;

    const way = paramData.way ?? paramData.prefix;
    const viewData = this.dataGet(way);

    if (viewData.type === 'popup') {
        if (this.getNewPopupId() === 5) {
            // Otevřeli jste příliš mnoho popup oken. Již nelze otevřít další.
            this.error(this.translate('LBL_TOO_MANY_POPUPS'));

            return;
        }
        const callback = this.dataGet(way + '/../callback');
        this.popupDetail({
            module: modul,
            record: '',
            saveCallback: (ret) => {
                const item = {
                    id: ret.record,
                    name: ret.name,
                    popupHidden: ret.popupHidden,
                };
                callback?.(item);
                this.unLoad();
            },
            exitCallback: () => {},
        });
    } else {
        if (paramData.module !== this.getModuleName()) {
            this.closeRightPanel();
        }
        this.dataSet(paramData.prefix + '/shouldCallHooks/shouldCallLoadHook', false);
        window.location.hash = '#detail/' + modul;
    }
}
