import React, {useState} from 'react';
import MaterialTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {makeStyles} from 'tss-react/mui';
import propTypes from 'prop-types';
import sAction from 'sAction';

const TabContainer= (props)=>{
    const [value, setValue] = useState(0);

    // styling MUIv4 component (v5 tss)
    const useStyles = makeStyles()(() => {
        return {
            iconLabelWrapper2: {
                flexDirection: 'row',
            },
        };
    });

    const {classes} = useStyles();
    /**
     *
     * @param {*} event
     * @param {*} newTabIndex
     */
    const handleChange = (event, newTabIndex) => {
        props.onChange(event, newTabIndex);
        setValue(newTabIndex);
    };
    const activeTab = sAction.dataGet(props.prefix + '/activeSubpanelTab' );
    const tabs = [];
    props.tabs.forEach((item, index) => {
        if (!item) {
            return;
        }
        const errorClass = props?.evErrorTabs?.includes(index) ? ' evTabContainerError' : '';
        const className = item ? 'acmTabContainerButton' : 'hidden';
        tabs.push(<Tab
            key={index}
            style={{display: 'flex', flexDirection: 'row'}}
            classes={{
                wrapper: classes.iconLabelWrapper2,
                labelContainer: classes.labelContainer,
            }}
            label={props.isTabsObject ? sAction.translate(item.label) : item}
            data-index={index}
            icon={item?.icon ?
                <span
                    style={activeTab === index ? {color: props?.tabs[value]?.color} : {}}
                    className={`icon-${item?.icon}`}
                /> : null
            }
            className={className + errorClass} />);
    });

    /**
     * @returns {Bool}
     */
    const subpanelsState = () => {
        const subpanels = sAction.dataGet(props.prefix + '/subpanels');
        let open = 0;
        let close = 0;
        subpanels.forEach((subpanel) => {
            subpanel.def.get('open') ? open = open+1 : close = close+1;
        });
        return open > close ? false : true;
    };

    return (
        <MaterialTabs
            TabIndicatorProps={{style: {background: props?.tabs[value]?.color}}}
            onChange={handleChange}
            value={props.value ?? value}
            className={props.isTabsObject ? 'acmTabContainer acmSubpanelTabContainer':'acmTabContainer' }
        >
            {tabs}
            {props.subpanels ?
                <div className={`icon-subpanelClose subpanelsControlAll
                ${subpanelsState() ? 'subpanelsControlAll-close' : null }`}
                onClick={() => sAction.subpanelsChangeState(subpanelsState(), props.prefix)}>
                </div> :
                null}
        </MaterialTabs>
    );
};

TabContainer.propTypes = {
    isTabsObject: propTypes.any,
    tabs: propTypes.any,
    onChange: propTypes.any,
    subpanels: propTypes.any,
    path: propTypes.string,
    evErrorTabs: propTypes.array,
    prefix: propTypes.string,
    value: propTypes.number,
};

export default TabContainer;
