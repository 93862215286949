/**
 *
 * @param value
 */
export default function fromBase64(value) {
    const returnValue = decodeURIComponent(escape(atob(value)));
    try {
        return JSON.parse(returnValue);
    } catch (e) {
        return returnValue;
    }
}
