/**
 * Pokud zmenim slevu nebo kam aplikovat slevu potrebuju prepocitat slevu
 * @param {string} way pr. "view/customData/productData/groups/0"
 * @param {string|number} newValue
 * @param {string} discountType pole na ktere budeme aplikovat slevu price_after_add_discount/discount_amount
 * @param {array} lines radky skupiny nabidky
 * @this sAction
 */
export default function changeGroupDiscount(way, newValue, discountType, lines) {
    this.dsClear();
    lines.forEach((line, lineIndex) => {
        this.dsAdd('set', `${way}/lines/${lineIndex}/${discountType}`, newValue);

        if (discountType === 'price_after_add_discount') {
            let priceAfterDiscount;
            // let percent_discount = newValue;
            // prepocitat slevu z cenika
            if (parseInt(line.discount_select)) {
                priceAfterDiscount = line.list_price * ((100 - newValue) / 100);
            } else {
                priceAfterDiscount = line.list_price - newValue;
                // percent_discount = 100 - (priceAfterDiscount * 100 / line.list_price)
            }
            this.dsAdd('set', `${way}/lines/${lineIndex}/discount_amount`, 0);
            this.dsAdd('set', `${way}/lines/${lineIndex}/price_after_discount`, priceAfterDiscount);
        } else if (discountType === 'discount_amount') {
            let addVal;
            if (parseInt(line.discount_select)) {
                const discount = (this.parseNumber(line.price_after_discount) / 100) * newValue;
                const tmpTotalPrice = this.parseNumber(line.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - line.list_price) / line.list_price);
            } else {
                addVal = line.list_price - (line.price_after_discount - newValue);
            }
            this.dsAdd('set', `${way}/lines/${lineIndex}/price_after_add_discount`, addVal);
        }
    });
    this.dsProcess();
}
