import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import TabSelect from '../formElements/TabSelect';
import PropTypes from 'prop-types';

export default class ImportRelateHeaderSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            module: this.props.relateDef?.module,
        };
        this.tabRef = React.createRef();

        this.setOptions();
    }

    /**
     *
     */
    setOptions() {
        sAction.load();
        const relateDef = this.props.relateDef;
        sAction.rest.get(`defs/${relateDef?.module}`, (returnData) => {
            sAction.unLoad();
            const options = [];
            returnData.forEachObject((def, value) => {
                if (!['relate', 'link'].includes(def.type)) {
                    options.push({
                        value: value,
                        label: sAction.translate(def.vname, relateDef?.module),
                    });
                }
            });
            this.setState({options: options, module: this.props.relateDef?.module});
            this.tabRef.current.onOpen(null);
        });
    }

    /**
     *
     * @param {*} item
     */
    onChange(item) {
        sAction.dsClear();
        sAction.dsAdd('set',
            `${this.props.way}/${this.props.index}/relatedField`,
            {value: item.value, label: item.label, module: this.props.relateDef?.module},
        );
        sAction.dsProcess();
    }

    render() {
        const fieldLabel = this.props.mapping?.relatedField?.['label'] ?? '';
        const relatedFieldName = this.props.mapping?.relatedField?.value;
        const relatedFieldNameString = relatedFieldName ? ` (${relatedFieldName})` : '';
        return (
            <TabSelect
                containerClassName={this.props.containerClassName}
                search={true}
                open={this.state.isLoaded}
                numOfTabSelect={this.props.index}
                options={{Pole: (this.state.options || [])}}
                onChange={(e) => this.onChange(e)}
                container={'importView'}
                label={fieldLabel + relatedFieldNameString}
                clearLabel={true}
                labelStyle={this.props.labelStyle}
                defaultValue={''}
                containerStyle={{
                    maxWidth: '100%',
                    height: '50%',
                    display: 'inline-flex',
                    whiteSpace: 'break-spaces',
                }}
                change={this.change}
                ref={this.tabRef}
            />
        );
    }
}

ImportRelateHeaderSelect.propTypes = {
    labelStyle: PropTypes.any,
    index: PropTypes.any,
    containerClassName: PropTypes.any,
    mapping: PropTypes.any,
    relateDef: PropTypes.any,
    way: PropTypes.string,
};
