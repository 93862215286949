/**
 * @param {object} params
 * @this sAction
 * @button DetailView/Quotes
 * @render src/components/popup/detailApi/CloseQuotePopup.js
 */
export default function quoteClose(params) {
    this.popup('closeQuote', {
        params: params,
        header: this.translate('LBL_CLOSE_QUOTE_TITLE', 'Quotes'),
    });
}
