/**
 *
 * @param params
 */
export default function addToProspectListsCampaignsParticipants(params) {
    const relateData = {
        module: 'acm_CampaignsPartipants',
        defaultFilter: {
            participants: {
                operandType: 'relate',
                parentId: params.id,
                module: 'acm_CampaignsAction',
                relationship: ['acm_campaignsaction_acm_campaignspartipants'],
                name: 'acm_campaignsaction_acm_campaignspartipants_name',
                type: 'eq',
            },
        },
    };
    this.openRelatePopup(relateData, (returnData) => {
        this.popupHide();
        const params2 = {
            prefix: params.prefix,
            module: 'acm_CampaignsPartipants',
            selected: returnData.id,
        };
        this.addToProspectLists(params2);
    });
}
