import PropTypes from 'prop-types';
import React from 'react';
import ListItem from '@mui/material/ListItem';

export default class WaveEffect extends React.PureComponent {
    render() {
        return (
            <ListItem button className={this.props.className != null ? 'waveEffect '+this.props.className : 'waveEffect'}>
                {this.props.children}
            </ListItem>
        );
    }
}

WaveEffect.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
};
