/**
 *
 * @param login
 * @param _callback
 */
export default function createPasswordResetToken(login, _callback) {
    this.load();
    this.rest.post('requestResetPassword', {userName: login}, (data) => {
        this.unLoad();
        _callback(data);
    });
}
