/**
 *
 * @param id
 */
export default function homeTabDelete(id) {
    // Opravdu chcete tuto stránku vymazat?
    this.confrim(this.translate('LBL_PAGE_DELETE_CONFIRM', 'Home'), () => {
        this.dataArrayDelete([{way: 'view/tabs', index: id}]);
        const redirect = id > 0 ? id - 1 : 0;
        this.dataSet('view/activeId', redirect);
        this.popupHide();

        this.homeLayoutSave();
    });
}
