import listCustom from './custom/listView';
import detailCustom from './custom/detailView';
import confCustom from './custom/conf';
import calendarCustom from './custom/calendar';

export var customReducers = [
    listCustom,
    detailCustom,
    confCustom,
    calendarCustom,
];
