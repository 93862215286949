/**
 *
 * @param {*} filter
 * @param {*} where
 * @param {*} _callback
 */
export default function searchPlaces(filter, where, _callback) {
    this.rest.fetchMS(`maps/v1/findAddress/${where}`, 'POST', {filter: filter}).then((response) => {
        if (response.status) {
            _callback(response?.data ?? []);
            return;
        }
        this.error(response.message);
        console.error('FindAddress failed, response: ', response);
        _callback([]);
    });
}
