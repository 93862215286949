/*

ROOT REDUCER
pole které drží pohromade všechny ostatní reducery(tabulky) taže představa může být že to je velká DB a reducery jednotlivé tabulky

*/
import {createStore} from 'redux';
import {combineReducers} from 'redux';
import appReducer from './appReducer';

const rootReducer = combineReducers({
    appReducer,
});
let devtools = {};
// eslint-disable-next-line no-undef
if (DEVMODE) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__ &&
window.__REDUX_DEVTOOLS_EXTENSION__({appReducer, trace: true});
}
const store = createStore(
    rootReducer,
    devtools,
);
export {
    store,
};
