/**
 *
 * @param way
 * @param id
 */
export default function homeWidgetDelete(way, id) {
    this.confrim('Opravdu chcete smazat tento widget?', () => {
        this.dataDelete(way, id);
        this.popupHide();
        this.homeLayoutSave();
    });
}
