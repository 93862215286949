import React from 'react';
import PropTypes from 'prop-types';
import PureComponent from '../pure';
import sAction from 'sAction';
import Tag from './Tag';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class Tags extends PureComponent {
    /**
     *
     * @param {object} record
     * @param {string} key
     * @param {string} way
     */
    deleteTag(record, key, way) {
        sAction.deleteTagRelate(record, key, way);

        return;
    }

    /**
     *
     * @param {string} way
     * @param {object} record
     * @param {string} module
     */
    addTagRelate(way, record, module) {
        sAction.openAddTagRelatePopup(way, record, module);
    }

    /**
     *
     * @param {object} tag
     * @param {object} record
     * @param {string} module
     */
    findTagRelates(tag, record, module) {
        sAction.findTagRelatesOpenPopup(tag, record, module);
    }

    /**
     *
     * @param {object} tag
     * @param {string} key
     * @param {string} way
     */
    onRemove(tag, key, way) {
        sAction.deleteTagRelate(tag.get('relateId'), key, way);
    }

    render() {
        const tags = this.props.tags;
        const way = this.props.way;
        const record = this.props.record;
        const module = this.props.module;

        const renderData = [];

        if (tags) {
            tags.forEach((tag, key) => {
                renderData.push(
                    <div key={key} className={'tagContainer'}>
                        <Tag
                            keyId={key}
                            tag={tag}
                            onClick={() => this.findTagRelates(tag, record, module)}
                        />
                        <span
                            className="icon icon-cancelCircle"
                            onClick={(e) => this.onRemove(tag, key, way, e)}
                        />
                    </div>,
                );
            });
        }

        return (
            <React.Fragment>
                <span className="tags">
                    <TooltipWrapper label={'LBL_TAG_CREATE_NEW'}>
                        <span
                            onClick={() => this.addTagRelate(way, record, module)}
                            className="tagadd"
                        >
                            +
                        </span>
                    </TooltipWrapper>
                    {renderData}
                </span>
            </React.Fragment>
        );
    }
}

Tags.propTypes = {
    tags: PropTypes.any,
    way: PropTypes.any,
    record: PropTypes.any,
    module: PropTypes.any,
};
