/**
 * Načte historii vyhledávání pro daný modul do storu
 *
 * @param {string}  module      modul ke kterému bude historie načtena
 * @param {int}     count       počet záznamů, které se mají načíst
 * @param {bool}    append      mají-li se načíst záznamy jako pokračování již uložených
 * @param {func}    callback    zavolá se po načtení dat
 */
export default function loadSearchHistory(module, count=20, append=false, callback=()=>{}) {
    let offset = 0;
    if (append) {
        offset = this.getStorage('searchHistory/' + module).length ?? 0;
    }
    this.rest.fetchData('searchHistory', 'POST', {module, count, offset}).then((res) => {
        const data = res['data'];
        if (append) {
            data.unshift(...this.getStorage('searchHistory/' + module));
        }
        this.setStorage('searchHistory/' + module, data);
        callback();
    }).catch((error) => {
        this.error(error['text'] ?? error);
    });
}
