import sAction from 'sAction';

/**
 * @param {string} module
 * @param {string[]} selectedGroupingOpt
 */
export default function useResetDetailView(module, selectedGroupingOpt) {
    sAction.confirm(sAction.translate('LBL_EV_RESET_VIEW_POPUP_TEXT'), () => {
        sAction.rest.fetchData('editView/resetEditView', 'POST', {
            module: module,
            options: selectedGroupingOpt,
        }).then(() => {
            sAction.route();
        }).catch(({text}) => {
            sAction.error(sAction.translate(text || 'LBL_UNKNOWN_ERROR'));
        });
    });
}
