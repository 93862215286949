export default function HomeTabChangeName(way, colsNum) {
    const data = this.dataGet(way);
    const oldColsNum = data.cols.size;

    // pokud je novy pocet sloupcu vetsi nez predchozi, pridame nove sloupce
    if (colsNum > oldColsNum) {
        this.dsClear();
        for (let i = oldColsNum; i < colsNum; i++) {
            this.dsAdd('add', way + '/cols', {id: i + 1}, 'HomeColumns');
        }
        this.dsProcess();

    // pokud je novy pocet sloupcu mensi nez predchozi, sloupce odebereme
    // a preskupime data
    } else if (colsNum < oldColsNum) {
        for (let i = oldColsNum; i > colsNum; i--) {
            this.dsClear();

            // preskupeni existujicich radku
            data.cols.get(i - 1).rows.forEach((row, index) => {
                //   console.log(row.toJS());
                const way2 = way + '/cols/' + (colsNum - 1) + '/rows';
                //   console.log(way2);
                this.dsAdd(
                    'add',
                    way2,
                    {id: row.id, name: row.name, def: row.def, data: row.data},
                    'HomeColumnsRows',
                );
            });
            this.dsProcess();

            this.dataDelete(way + '/cols', i - 1);
        }
    }
}
