import React from 'react';
import PureComponent from '../pure';

import Button from '../formElements/Button';
import InputText from '../formElements/InputText';
import RadioGroup from '../formElements/RadioGroup';

import sAction from 'sAction';

/**
 * @parent  src\components\home\Home.js
 *          way={"home/tabs/" + value + "/cols"}
 *
 * zobrazuje se na HP, kdyz neexistuji zadne zalozky/stranky
 */
export default class HomeTabAdd extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: '1',
        };
        this.nameRef = React.createRef();
    }

    updateVal = (name, value) => {
        const pom = {};
        pom[name] = value;
        this.setState(pom);
    };

    savePopup = () => {
        const name = this.nameRef.current.value;
        const value = this.state.value;

        sAction.homeTabAdd(name, value);
        sAction.homeLayoutSave();
    };

    render() {
        return (
            <div className="gridContainer flex3">
                <div className="gridColumn">
                    <div className="homeCard">
                        <div className="homeCardHeader">
                            <div className="homeCardHeaderContent">
                                {sAction.translate('LBL_ADD_PAGE', 'Home')}
                            </div>
                        </div>

                        <div className="homeCardContent addPageCard">
                            <InputText
                                autoFocus
                                margin="dense"
                                inputRef={this.nameRef}
                                id="name"
                                label={sAction.translate('LBL_DASHLET_OPT_TITLE', 'Home')} // Název
                                type="text"
                                defaultValue={this.props.name}
                                onKeyUp={(ev) => {
                                    if (ev.key === 'Enter') {
                                        this.savePopup();
                                        ev.preventDefault();
                                    }
                                }}
                            />

                            <div style={{marginTop: '25px'}}>
                                <p>{sAction.translate('LBL_NUM_COLUMNS', 'Home')}</p>
                                {/* Počet sloupců*/}
                                <RadioGroup
                                    value={this.state.value}
                                    options={{
                                        1: '1',
                                        2: '2',
                                        3: '3',
                                    }}
                                    onChange={(e) => this.updateVal('value', e.target.value)}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        flexDirection: 'row',
                                    }}
                                />
                            </div>
                            <br />
                            <br />

                            <div><Button onClick={this.savePopup} className="stillHover">
                                {sAction.translate('LBL_ADD_PAGE', 'Home')}
                                {/* Přidat stránku*/}
                            </Button></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
