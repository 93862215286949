import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Subpanel from 'ROOT/src/components/subpanel/subpanel';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Loader from 'ROOT/src/components/loader';
import TabContainer from 'ROOT/src/components/formElements/TabContainer';

export default function DetailSubpanelsContainer(props) {
    const [tabs, setTabs] = useState([]);
    // const [tabSubpanels, setTabSubpanels] = useState([props.subpanels]);
    /**
     *
     * @param {*} value
     */
    const tabChange = (value) => {
        sAction.dsClear();
        sAction.dsAdd( 'set', 'view/activeSubpanelTab', value );
        sAction.dsProcess();
    };
    /**
     *
     * @param {*} tab
     * @param {*} subpanels
     * @return {*}
     */
    const filter = (tab, subpanels) =>
        subpanels.filter((subpanel) =>
            tab.subpanels.find((item) => item.module === subpanel.module),
        );

    const formattedSubpanels = [];
    props.subpanels.forEach((tab) => {
        formattedSubpanels.push(sAction.translate(tab.module.toUpperCase(), props.module));
    });

    useEffect(() => {
        if (tabs.length === 0 ) {
            props.subpanelsTabs.map((item)=>{
                setTabs((prevState) => [...prevState, item]);
            });
        }
    }, [props.subpanelsTabs]);
    useEffect(() => {
        return () => {
            setTabs([]);
        };
    }, [props.module]);

    // useEffect(() => {
    //     const subpanels=props.subpanels;
    //
    //     const tmp =[];
    //
    //     // filters the subpanels of module for current tab
    //     subpanels.map((subpanel) => {
    //         props.subpanelsTabs.get(props.activeSubpanelTab)?.subpanels.find((item) =>item===subpanel)?
    //             tmp.push(subpanel): null;
    //     });
    //
    //     setTabSubpanels(tmp);
    // }, [props.activeSubpanelTab, props.subpanels]);

    useEffect(() => {
        if (!props.shouldFetchSubpanels) {
            return;
        }
        sAction.fetchSubpanels(props.prefix, props.module, props.id);
    }, [props.shouldFetchSubpanels]);

    if (props.shouldFetchSubpanels === true) {
        return (
            <div id='subpanels' className='subpanelsContainer' data-way={`${props.way}`}>
                <Loader />
            </div>
        );
    }

    /**
 *  Preusporiadava subpanelov a groups
 * @param {[]} buttons
 * @param {number} startIndex
 * @param {number} endIndex
 * @return {Array} Novo usporiadane polozky v array
 */
    const reorder = (buttons, startIndex, endIndex) => {
        const result = Array.from(buttons);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return [...result];
    };

    /**
     *
     * @param {*} result
     */
    const onDragEnd = (result) => {
        const {source, destination} = result;
        const reordered =reorder([...props.subpanels], source.index, destination.index);
        // setSubpanels(reordered);

        sAction.dsClear();
        sAction.dsAdd('set', `${props.prefix}/subpanels`, reordered);
        props.subpanelsTabs.map((tab, index) =>{
            sAction.dsAdd('set', `${props.prefix}/subpanelsTabs/${index}/subpanels`, filter(tab, reordered) );
        } );

        sAction.dsProcess();
        sAction.saveSubpanelOrder(props.prefix);
    };

    /**
     * Subpanels tab need one place for data, so we take index of module and compose way to subpanel data for Subpanel
     * @type {{}}
     */
    const dataIndexBuffer = {};
    props.subpanels.forEach((item, index) => {
        dataIndexBuffer[item.rel_name] = index;
    });

    // select which subpanel with tabs display
    // after changing order in
    const tabLength = props.subpanelsTabs.get(0)?.subpanels?.length ?? props.subpanelsTabs.get(0)?.subpanels?.size;
    const tabSelect = tabLength ?
        (
            <div >
                <TabContainer
                    onChange={(e, value) => tabChange(value)}
                    value={props.activeSubpanelTab}
                    tabs={ tabs}
                    isTabsObject={true}
                    subpanels={true}
                    prefix={props.prefix}
                />
            </div>
        ) : null;

    return (
        <div>
            {tabSelect}
            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            id="subpanels"
                            className="subpanelsContainer"
                            data-way={props.way}>

                            {props.subpanelsTabs.get(props.activeSubpanelTab)?.subpanels.map((subpanel, index) => {
                                const subpanelDataIndex = +dataIndexBuffer[subpanel.get('rel_name')];
                                const subpanelData = props.subpanels.get(subpanelDataIndex);

                                return (
                                    <Draggable
                                        key={index+'draggablesubpanel'}
                                        draggableId={`${index}`}
                                        index={index}
                                        isDragDisabled = {props.activeSubpanelTab!==0}>
                                        {(providedDraggable) => (
                                            <div
                                                ref={providedDraggable.innerRef}
                                                {...providedDraggable.draggableProps}

                                            >
                                                <Subpanel
                                                    key={index}
                                                    index={index}
                                                    drag={providedDraggable.dragHandleProps}
                                                    activeSubpanelTab={props.activeSubpanelTab}
                                                    data={subpanelData}
                                                    prefix={props.prefix}
                                                    iconColor={sAction.dataGet(
                                                        // eslint-disable-next-line max-len
                                                        `${props.prefix}/subpanelsTabs/${props.activeSubpanelTab}/subpanels/${index}/def/color`)}
                                                    // way - subpanels operations for opening list etc.
                                                    subpanelTabWay={
                                                        `${props.prefix}/subpanelsTabs/${props.activeSubpanelTab}/subpanels/${index}`
                                                    }
                                                    way={`${props.prefix}/subpanels/${subpanelDataIndex}`}
                                                    subpanelDataIndex={subpanelDataIndex}
                                                    acl={props.acl}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

DetailSubpanelsContainer.propTypes = {
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    activeSubpanelTab: PropTypes.number,
    prefix: PropTypes.string,
    way: PropTypes.string,
    subpanels: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            module: PropTypes.string,
            def: ImmutablePropTypes.map,
            rows: ImmutablePropTypes.record,
        }),
    ),
    shouldFetchSubpanels: PropTypes.bool.isRequired,
    module: PropTypes.string,
    id: PropTypes.string,
    subpanelsTabs: PropTypes.any,
};
