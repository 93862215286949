import React, {useEffect} from 'react';
import TickBox from '../../formElements/TickBox';
import sAction from 'sAction';
import DetailCard from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailCard';
import {List} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export default function BoundModuleDetail(props) {
    /**
     * @param {*} value
     */
    const handleCheck = (value) => {
        sAction.dataSet(`${props.prefix}/customData/checked`, value);
    };

    useEffect(() => {
        sAction.custom('load', {prefix: props.prefix, module: props.module});
    }, []);

    return (<div className={'detailInvComp'}>
        <div className="detailCard">
            <div className="header">
                <TickBox
                    className="blueTick"
                    checked={props.customData.get('checked')}
                    onChange={(value) => handleCheck(value)}
                />
                <div className="title">{sAction.translate('LBL_NEW_FORM_TITLE_CL', props.module)}</div>
            </div>
            {props.customData.get('checked') && (<div className="detailView">
                <DetailCard
                    isBoundModule={true}
                    prefix={props.prefix}
                    module={props.module}
                    tabs={new List([props.tab])}
                    fields={props.data.fields}
                    acl={props.data.acl}
                    activeTab={0}
                    readonly={props.data.readonly}
                    id={props.id}
                    attemptedSave={props.data.attemptedSave}
                    index={props.index}
                />
            </div>
            )}
        </div>
    </div>
    );
}

BoundModuleDetail.propTypes = {
    index: PropTypes.number.isRequired,
    module: PropTypes.string,
    prefix: PropTypes.string,
    tab: ImmutablePropTypes.recordOf({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        active: PropTypes.bool,
        panels: ImmutablePropTypes.listOf(
            ImmutablePropTypes.map,
        ),
    }),
    customData: PropTypes.any,
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    id: PropTypes.string,
    attemptedSave: PropTypes.bool.isRequired,
};

BoundModuleDetail.propTypes = {
    acl: ImmutablePropTypes.map,
    attemptedSave: PropTypes.bool,
    customData: PropTypes.any,
    data: ImmutablePropTypes.recordOf({
        acl: ImmutablePropTypes.map,
        activeTab: PropTypes.number,
        activities: PropTypes.any, // No idea for what is that props
        attemptedSave: PropTypes.bool,
        canSave: PropTypes.bool.isRequired,
        changes: ImmutablePropTypes.map,
        customData: PropTypes.any,
        fields: ImmutablePropTypes.map,
        imageActions: ImmutablePropTypes.map,
        isUnderCompetencePrice: PropTypes.bool,
        module: PropTypes.string.isRequired,
        name: PropTypes.string,
        orderLines: ImmutablePropTypes.list,
        readonly: PropTypes.bool.isRequired,
        tableName: PropTypes.string, // I guess it is pointless
        tabs: ImmutablePropTypes.list,
        tags: ImmutablePropTypes.list,
    }),
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    module: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    tab: ImmutablePropTypes.recordOf({}),
};
