/**
 *
 * @param id
 * @param way
 * @param callback
 */
export default function emailGetTemplateAttachments(
    id,
    way,
    callback = undefined,
) {
    if (!id) return;
    // pridani priloh
    const attachments = this.dataGet(way);
    const uniqueAttachments = [];
    if (attachments) {
        attachments.forEach((a) => {
            if (a.id) {
                uniqueAttachments.push(a.id);
            }
        });
    }

    this.rest.post('email/getTemplateAttachments', {parent_id: id}, (data) => {
        this.dsClear();
        if (data != null && typeof data === 'object') {
            const returnData = [];
            data.forEachObject((a) => {
                if (uniqueAttachments.indexOf(a.id) === -1) {
                    const newData = {
                        id: a.id,
                        name: a.filename,
                        type: a.file_mime_type,
                        source: 'sugartemplate',
                        size: a.size,
                    };
                    this.dsAdd('set', way + '/' + newData.id, newData);
                    if (callback) returnData.push(newData);
                }
            });
            if (callback && returnData.length) callback(returnData);
        }
        this.dsAdd('set', 'conf/load', false);
        this.dsProcess();
    }, false);
}
