/**
 *
 * @param filter
 */
export default function getFilterKeyValuePairs(filter) {
    const keyVal = {};
    filter.forEach((obj) => {
        const key = obj.get('field');
        if (obj.get('filters').get('0')) {
            if (obj.field == 'parent_name') {
                const value = {
                    string: obj.get('filters').get('0').get('value'),
                    parent_type: obj.get('filters').get('0').get('parent_type'),
                };
                keyVal[key] = value;
            } else {
                keyVal[key] = obj.get('filters').get('0').get('value');
            }
        }
    });

    return keyVal;
}
