import React from 'react';
import PureComponent from '../../pure';

import InputText from '../../formElements/InputText';

export default class TextField extends PureComponent {
    /**
     *
     * @param e
     */
    waitForSearch(e) {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
        const self = this;
        this.interval = setInterval(() => {
            this.props.onChange(e);
            clearInterval(self.interval);
        }, 300);
    }

    render() {
        const type = this.props.type ? this.props.type : 'text';
        return (
            <InputText
                type={type}
                myRef={this.headerInput}
                label={this.props.label}
                defaultValue={this.props.defaultValue}
                onChange={(e) => this.waitForSearch(e.target.value)}
                freeFormat={true}
            />
        );
    }
}
