/**
 *
 * @param passNew
 * @param passOld
 * @param userID
 * @param _callback
 */
export default function changePasswordRequest(passNew, passOld, userID, _callback) {
    this.load();
    this.rest.post('changePassword', {oldPassword: passOld, newPassword: passNew, userID: userID}, (data) => {
        this.unLoad();
        _callback(data);
    });
}
