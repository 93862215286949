import {DataConstructors} from '../../../DataConstructors';
import {List} from 'immutable';

/**
 *
 * @param cat
 * @param main
 */
export default function createListViewTree(cat, main = false) {
    if (cat == null) {
        return null;
    }
    const listChildren = [];
    const children = cat.children;
    children.forEach((child) => {
        listChildren.push(createListViewTree(child));
    });

    const data = {
        id: cat.id,
        name: cat.name,
        type: cat.type,
        children: List(listChildren),
    };
    return new DataConstructors.listViewTreeCat(data);
}
