import React from 'react';
import PureComponent from 'ROOT/src/components/pure';
import {connect} from 'react-redux';

import Header from 'ROOT/src/components/header/header';
import Menu from 'ROOT/src/components/menu/menu';
import List from 'ROOT/src/components/list/list';
import Home from 'ROOT/src/components/home/home';
import Import from 'ROOT/src/components/Import/Import';
import CalPageContainer from 'ROOT/src/components/calendar/calPageContainer';
// import Login from "ROOT/src/components/login/login";
import RightPanel from 'ROOT/src/components/RightPanel/RightPanel';
import ErrorController from 'ROOT/src/components/Errors/ErrorController';
import ErrorView from 'ROOT/src/components/Errors/ErrorView';
import GlobalSearch from 'ROOT/src/components/globalSearch/globalSearch';
import LeadConvertContainer from 'ROOT/src/components/detailView/Leads/LeadConvertContainer';
import Merge from 'ROOT/src/components/Merge/Merge';
import ReportContainer from 'ROOT/src/components/CustomReport/ReportContainer';
import Loader from 'ROOT/src/components/loader';
import Popper from 'ROOT/src/components/popper/Popper';
import Toast from 'ROOT/src/components/Toast/Toast';
import DetailViewContainer from 'ROOT/src/components/DetailViewNew/DetailViewContainer';
import PropTypes from 'prop-types';
import ErrorBoundary from './ErrorBoundary';
import '../../../less/index.less';
import ShortcutsHelp from 'ROOT/src/components/Shortcuts/ShortcutsHelp';
import NetworkAlert from 'ROOT/src/components/default/NetworkAlert';
import sAction from 'sAction';

const Login = React.lazy(() => import('ROOT/src/components/login/login'));
const Email = React.lazy(() => import('ROOT/src/components/email/Email'));
const Popup = React.lazy(() => import('ROOT/src/components/popup/popup'));
const Admin = React.lazy(() => import('ROOT/src/components/admin/admin'));

import '../../../less/index.less';
import ShortcutContextProvider from 'ROOT/src/components/Shortcuts/ShortcutContextProvider';

class App extends PureComponent {
    render() {
        const store = this.props.store;
        const conf = store.appReducer.conf;
        const hash = window.location.hash;
        const shortcutHelp = sAction.dataGet('conf/shortcutsHelp');
        let recordName = null;
        if (conf.view === 'detail' || conf.view === 'leadConvert') {
            recordName = store.appReducer.view.name;
        }
        const record = store.appReducer.view;
        const routeData = {
            detail: (
                <React.Suspense fallback={<Loader/>}>
                    <DetailViewContainer
                        key="detail"
                        a={this.props.action}
                        data={record}
                        way="view"
                        prefix="view"
                        namespace='main'
                    />
                </React.Suspense>
            ),
            import: <Import way="view" data={store.appReducer.view}/>,
            list: <List key="list" data={store.appReducer.view} way="view" namespace='main'/>,
            home: <Home key="home" data={store.appReducer.view} way="view" />,
            email: <React.Suspense fallback={<Loader/>}><Email key="email" data={store.appReducer.view} way="view" /></React.Suspense>,
            calendar: (
                <CalPageContainer
                    key="calendar"
                    data={store.appReducer.calendar}
                    user={conf.user}
                    language={conf.language}
                />
            ),
            globalSearch: (<GlobalSearch data={store.appReducer.view} way="view" />),
            leadConvert: (<LeadConvertContainer data={store.appReducer.view} way="view" />),
            customReports: <ReportContainer data={store.appReducer.view} way="view" />,
            error: <ErrorView data={store.appReducer.view} />,
            admin: <React.Suspense fallback={<Loader />} ><Admin
                data={store.appReducer.view}
                back_version={conf.back_version}
                front_version={conf.front_version}
                way="view"
            /></React.Suspense>,
            merge: <Merge data={store.appReducer.view} way="view" />,
        };
        let page = null;
        if (conf.view === 'login') {
            page =
          <React.Suspense fallback={<Loader />}>
              <Login key="login" version={conf.front_version} redirect={conf.redirect} system={conf.system}/>
          </React.Suspense>;
        } else {
            let content = null;
            if (conf.view) {
                content = routeData[conf.view];
            } else {
                content = routeData['home'];
            }

            let pageClass = '';
            const alertMessageOpen = conf.alertMessage.get('show');
            if (!store.appReducer.menu.open) {
                pageClass += ' min';
            }
            if (alertMessageOpen) {
                pageClass += ' alertMessageActive';
            }
            page = (
                <React.Fragment>
                    <Header
                        key="header"
                        menu={conf.menuOpen}
                        alertMessage={conf.alertMessage}
                        user={conf.user}
                        systemSettings={conf.system}
                        not={store.appReducer.notifications}
                        hash={hash}
                        recordName={recordName}
                    />
                    <Menu key="menu" data={store.appReducer.menu} alertMessage={conf.alertMessage.get('show')} />
                    <div
                        key="viewContainer"
                        id="page"
                        className={pageClass}
                    >
                        {content}
                    </div>
                    {store.appReducer.rightPanel.show && (
                        <RightPanel data={store.appReducer.rightPanel} namespace='rightPanel' />
                    )}
                </React.Fragment>
            );
        }
        let Container = React.Fragment;
        // eslint-disable-next-line no-undef
        if (DEVMODE) {
            Container = ErrorController;
        }

        // popupy
        const popups = [];
        for (let i = 2; i <= 5; i++) {
            if (conf['popup' + i].show) {
                popups.push(<React.Suspense key={'popup' + i} fallback={<Loader/>}><Popup
                    key={'popup' + i}
                    way={'conf/popup' + i}
                    data={conf['popup' + i]}
                    namespace={'popup' + i}
                /></React.Suspense>);
            }
        }

        const networkWarningStack = (
            <div className="networkWarningStack">
                {conf.offline.status && conf.offline.counter > 2 && <NetworkAlert type="offline" />}
                {conf.loggedOut && <NetworkAlert type="loggedOut" />}
            </div>
        );

        return (
            <ErrorBoundary>
                <ShortcutContextProvider>
                    <React.Suspense fallback={<div>Loading ... </div>}>
                        <Container>
                            <div className="acmMaterial">
                                {page}
                                {conf.popup.show && (
                                    <React.Suspense fallback={<Loader/>}><Popup
                                        reportWindow={store.appReducer.reportWindow}
                                        data={conf.popup}
                                        way={'conf/popup'}
                                        calProps={{}}
                                        namespace='popup'
                                    /></React.Suspense>
                                )}
                                {popups}
                                {shortcutHelp ? <ShortcutsHelp /> : null}
                                {conf.popper.size > 0 &&
                <Popper data={conf.popper} />
                                }
                                {conf.toast.size > 0 &&
                <Toast data={conf.toast} />
                                }
                                {conf.load && (
                                    <Loader fullscreen />
                                )}
                                {(conf.offline || conf.loggedOut) ? networkWarningStack : null}
                            </div>
                            <div className="menuHoverLabel"></div>
                        </Container>
                    </React.Suspense>
                </ShortcutContextProvider>
            </ErrorBoundary>
        );
    }
}

/**
 *
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    return {
        store: state,
    };
};

export default connect(mapStateToProps)(App);

// DM as I have no idea what in props could be I assigned it as any. Sorry for that
App.propTypes = {
    store: PropTypes.any,
    action: PropTypes.any,
};
