import React, {useEffect, useRef, useState} from 'react';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import PropTypes from 'prop-types';

import sAction from 'sAction';
import './index.less';

/**
 * @param {oject} error
 * @returns {JSX.Element}
 */
const renderError = (error) => {
    let message = '';
    console.warn(error);
    switch (error.name) {
        case 'InvalidPDFException':
            message = sAction.translate('LBL_INVALID_PDF', 'Documents');
            break;
        case 'MissingPDFException':
            message = sAction.translate('LBL_MISSING_PDF', 'Documents');
            break;
        case 'UnexpectedResponseException':
            message = sAction.translate('LBL_UNEXPECTED_RESPONSE', 'Documents');
            break;
        default:
            message = sAction.translate('LBL_PDF_ON_REMOTE_SERVER', 'Documents');
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};

function RightPanelPDF(props) {
    const containerRef = useRef(null);
    const [key, setKey] = useState(Math.random());
    // copilot
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setKey(Math.random()); // change key to force re-render
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup function to disconnect the ResizeObserver when the component unmounts
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        // workerUrl is required to load the PDF(needs to be in sync with the version of pdfjs)
        // NOTE: worker version needs to match the version of pdfjs-dist in package.json
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div style={{height: '100%', width: '100%'}} ref={containerRef}>
                <Viewer
                    renderError={renderError}
                    fileUrl={props.data.get('url')}
                    key={key}
                />
            </div>
        </Worker>
    );
}

RightPanelPDF.propTypes = {
    data: PropTypes.object,
};

export default RightPanelPDF;
