/**
 *
 */
export default function dsProcess() {
    // vezme frontu ke zpracování a předá dispatch funkci ke zpracování
    this.dataDispatch(this.dataStack);
    if (this.dsProcessCallback != undefined) {
        this.dsProcessCallback();
    }
    this.dsClear();
}
