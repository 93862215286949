import React from 'react';
import FilterBasic from './filterBasic';
import classNames from 'classnames';

import TabContainer from '../../formElements/TabContainer';
import FilterButtons from './filterButtons';

import sAction from 'sAction';
import InputText from '../../formElements/InputText';
import Select from '../../formElements/Select';

export default class FilterNumber extends FilterBasic {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0, // filter/calc
        };

        this.customOperator = React.createRef();
    }

    /**
     *
     */
    addCustomFilterLineToView() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd(
                'add',
                prefix + '/filter',
                {field: data.get('name'), operator: 'and', actCustom: true},
                'filterGroup',
            );
            sAction.dsAdd(
                'add',
                prefix + '/filter/' + index + '/filters',
                {type: null, value: ''},
                'filterParameter',
            );
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd(
                'add',
                way + '/filters',
                {type: null, value: ''},
                'filterParameter',
            );
            sAction.dsProcess();
        }
    }
    /**
     *
     * @param {str} key
     */
    deleteCustomFilterLineFromView(key) {
        const way = this.props.way;
        sAction.dataDelete(way + '/filters', key);
    }

    /**
     *
     */
    customOption() {
        this.props.parent.advanced();
        const data = this.props.data;
        const prefix = this.props.prefix;
        const way = this.props.way;
        const index = this.props.index;
        if (prefix === way) {
            sAction.dsClear();
            sAction.dsAdd(
                'add',
                prefix + '/filter',
                {field: data.get('name'), operator: 'and', actCustom: true},
                'filterGroup',
            );
            sAction.dsAdd(
                'add',
                prefix + '/filter/' + index + '/filters',
                {type: null, value: ''},
                'filterParameter',
            );
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {str} field
     */
    addCustomFilter(field) {
        const prefix = this.props.prefix;
        const filters = [];
        const lines = this.getLines();
        for (const line of lines) {
            const select = line.select;
            if (!select) {
                sAction.error(sAction.translate('LBL_SELECT_FILTER_TYPE'));
                return;
            }
            const text = line.input;
            const filter = {
                field: field,
                type: select,
                value: text,
            };
            filters.push(filter);
        }
        const operator = this.customOperator.current.value;
        const data = {
            field,
            filters,
            operator,
            prefix,
            reload: true,
        };
        sAction.addFilter(data);
    }

    render() {
        const data = this.props.data;
        const actFilter = this.props.actFilter;
        let custom = null;
        if (actFilter == null || !actFilter.actCustom) {
            custom = (
                <div onClick={() => this.customOption()} className={classNames(['filterButton', {filterButtonDisabled: actFilter}])}>
                    <div className="icon-pencil filterButtonIcon" />
                    {sAction.translate('LBL_CUSTOM_FILTER')}
                </div>
            );
        } else {
            const customLines = [];
            if (actFilter != null) {
                actFilter.filters.forEach((line, key) => {
                    customLines.push(
                        <div className="filterLine" key={key}>
                            <Select
                                key={actFilter.field + '_sekect'}
                                defaultValue={line.type}
                                options={sAction.getNumberFilterOptions()}
                                onChange={(e) =>
                                    this.updateLines(key, e.target.value, 'select')
                                }
                            />
                            <InputText
                                key={actFilter.field + '_input'}
                                type="text"
                                defaultValue={line.value}
                                onChange={(e) =>
                                    this.updateLines(key, e.target.value, 'input')
                                }
                            />
                            {customLines.length !== 0 && (
                                <div
                                    className="customFilterDeleteRow iconCancel"
                                    onClick={() => this.deleteCustomFilterLineFromView(key)}
                                />
                            )}
                        </div>,
                    );
                });
            }
            custom = (
                <div className="customFilter">
                    <div className="filterButton">
                        <div className="icon-pencil filterButtonIcon" />
                        {sAction.translate('LBL_CUSTOM_FILTER')}
                    </div>
                    <div className="CustomFilterLinesContent">
                        <div className="CustomFilterLines">
                            <div key="customLines">{customLines}</div>
                            <div
                                className="filterLine customFilterAddLineButton"
                                onClick={() => this.addCustomFilterLineToView()}
                            >
                +
                            </div>
                        </div>
                        <div className="customFilterLinesOperator">
                            <Select
                                key={actFilter.field + '_groupSelect'}
                                defaultValue={actFilter.operator}
                                myRef={this.customOperator}
                                options={
                                    [
                                        {value: 'and', label: sAction.translate('LBL_AND')},
                                        {value: 'or', label: sAction.translate('LBL_OR')},
                                    ]
                                }
                            />

                        </div>
                    </div>

                    <div
                        tabIndex="2"
                        onClick={() => this.addCustomFilter(data.get('name'))}
                        className="filterCustomFilterButton"
                    >
                        {sAction.translate('LBL_LIST_FILTER')}
                    </div>
                </div>
            );
        }
        let removeFilterButton = null;
        if (actFilter != null) {
            removeFilterButton = (
                <div
                    onClick={() => this.removeFilter(data.get('name'))}
                    className="filterButton cancelFilterButton"
                >
                    {sAction.translate('LBL_DELETE_FILTER')}
                </div>
            );
        }

        return (
            <div className="filterContent filterBox">
                <TabContainer
                    className="listViewFilter"
                    onChange={(e, value) => this.setState({activeTab: value})}
                    value={this.state.activeTab}
                    tabs={[
                        sAction.translate('LBL_FILTER_HEADER_FILTER'),
                        sAction.translate('LBL_FILTER_HEADER_CALC'),
                    ]}
                />
                {this.state.activeTab === 0 && (
                    <div className="listViewFilterCalcContainer">
                        <FilterButtons
                            buttonsDefs={[
                                {
                                    function: () => this.order(data, 0),
                                    label: 'LBL_ORDER_MIN',
                                    icon: 'icon-arrowDownLight',
                                },
                                {
                                    function: () => this.order(data, 1),
                                    label: 'LBL_ORDER_MAX',
                                    icon: 'icon-arrowUpLight',
                                },
                                {
                                    function: () => this.addFilter(data.get('name'), 'nnull', null),
                                    label: 'LBL_IS_NOT_ZERO',
                                    icon: 'icon-plusCircle',
                                },
                                {
                                    function: () => this.addFilter(data.get('name'), 'null', null),
                                    label: 'LBL_IS_ZERO',
                                    icon: 'icon-circle',
                                },
                            ]}
                        />
                        {custom}
                        {removeFilterButton}
                    </div>
                )}
                {this.state.activeTab === 1 && (
                    <div className="listViewFilterCalcContainer">
                        <FilterButtons
                            buttonsDefs={[
                                {
                                    function: () => {
                                        this.addFieldFunction('SUM');
                                    },
                                    label: 'LBL_SUM',
                                    icon: 'icon-sum',
                                },
                                {
                                    function: () => {
                                        this.addFieldFunction('COUNT');
                                    },
                                    label: 'LBL_COUNT',
                                    icon: 'icon-plus',
                                },
                                {
                                    function: () => {
                                        this.addFieldFunction('AVG');
                                    },
                                    label: 'LBL_AVG',
                                    icon: 'icon-diametral',
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        );
    }
}
