import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';

class calExtEvent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const eventData = this.props.event;
        return (
            <div
                className="fc-event fc-external"
                title={eventData.title}
                data-calendar={sAction.toJSON(eventData)}
                key={eventData.id}
                id={eventData.id}
            >
                <a className={'calendarEventsLink'} href={eventData.oLink} target={'_blank'} rel="noreferrer"> {eventData.title}</a>
        -
                <a className={'calendarEventsLink'} href={eventData.aLink} target={'_blank'} rel="noreferrer"> {eventData.aName}</a><br/>
                {eventData.address.street && (
                    <div className={'calExtEventAddressRow'}>
                        {eventData.address.street}
                    </div>
                )}
                {eventData.address.city && (
                    <div className={'calExtEventAddressRow'}>
                        {eventData.address.city}
                    </div>
                )}
                {eventData.address.postalCode && (
                    <div className={'calExtEventAddressRow'}>
                        {eventData.address.postalCode}
                    </div>
                )}
                {eventData.orderLines[0] && (
                    <div className={'calExtEventAddressRow'}>
                        <hr className={'hrExternalEvents'}/>
                        {eventData.orderLines[0].olName}
                    </div>
                )}
            </div>
        );
    }
}

export default calExtEvent;
