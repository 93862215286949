import React, {PureComponent} from 'react';
import sAction from 'sAction';

import Select from '../formElements/Select';
import Button from '../formElements/Button';
import Relate from '../formElements/Relate';

export default class EmailRelateField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            parent_id: '',
            parent_type: '',
            parent_name: '',
            error1: false, // select field
            error2: false, // input field
        };

        const menu = sAction.dataGet('menu/groups');
        this.optionsToSelect = [];
        menu.forEach((menuItem) => {
            if (['LBL_TABGROUP_ACTIVITIES', 'LBL_TABGROUP_REPORTING'].includes(menuItem.name)) {
                return;
            }
            menuItem.buttons.forEach((button) => {
                const module = {value: button.name, label: sAction.app_strings['moduleList'][button.name] || button.name};
                this.optionsToSelect.push(module);
            });
        });

        const otherModules = ['Tasks', 'Calls', 'Meetings'];
        otherModules.forEach((module, i) => {
            this.optionsToSelect.push({value: module, label: sAction.app_strings['moduleList'][module] ?? module});
        });

        this.optionsToSelect.sort(this.sortByLabel);
    }

    /**
     *
     * @param a
     * @param b
     */
    sortByLabel( a, b ) {
        const alabel = a && typeof a.label === 'string' ? a.label : '';
        return alabel.localeCompare(b.label);
    }

    openPopupList = () => {
        const source = this.props.way ? this.props : this.state;
        const data = {
            module: source.parent_type,
            selectedActive: false,
        };

        if (!data.module) {
            sAction.error(sAction.translate('LBL_ERROR_SELECT_MODULE', 'Emails'));
            return;
        }

        sAction.openRelatePopup(data, (returnData) => {
            const {way} = this.props;
            if (way) {
                sAction.dsClear();
                sAction.dsAdd('set', way + '/parent_id', returnData.id);
                sAction.dsAdd('set', way + '/parent_name', returnData.name);
                sAction.dsAdd('set', way + '/updateEmailText', 'relate');
                sAction.dsProcess();
            } else {
                this.props.onChange({...returnData, type: data.module});
                this.setState({
                    parent_id: returnData.id,
                    parent_name: returnData.name,
                });
            }

            this.setState({error2: false});
            sAction.popupHide();
        });
    };

    handleChange = (e) => {
        if (this.props.way) {
            sAction.dataSet(this.props.way + '/' + e.target.name, e.target.value);
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
        this.setState({error1: false});
    };

    update = (data) => {
        const {way} = this.props;
        if (way) {
            sAction.dsClear();
            sAction.dsAdd('set', way + '/parent_id', data.id);
            sAction.dsAdd('set', way + '/parent_name', data.name);
            sAction.dsAdd('set', way + '/updateEmailText', 'relate');
            sAction.dsProcess();
        } else {
            this.props.onChange({...data, type: this.state.parent_type});
            this.setState({parent_id: data.id, parent_name: data.name});
        }

        const source = this.props.way ? this.props : this.state;
        this.setState({error1: !source.parent_type, error2: !data.id});
    };

    render() {
        const source = this.props.way ? this.props : this.state;
        const {parent_id, parent_type, parent_name} = source;

        return (
            <div className="email__relate">
                <div className="email__select1">
                    <Select
                        options={this.optionsToSelect}
                        // label="Vztahující se k"
                        label={sAction.translate('LBL_EMAIL_RELATE', 'Emails')}
                        name="parent_type"
                        value={parent_type}
                        onChange={this.handleChange}
                        error={this.state.error1}
                    />
                </div>
                <div className="email__select2">
                    <Relate
                        key={parent_id + parent_name}
                        newRecord={false}
                        data={{
                            value: parent_name,
                            // label: "Subjekt"
                            label: sAction.translate('LBL_EMAIL_OBJECT', 'Emails'),
                        }}
                        buttons={[]}
                        module={parent_type}
                        callback={this.update}
                        onBlur={this.onBlur}
                        error={this.state.error2}
                        containerClassName={'relateField'}
                    />
                </div>
                <div className="email__compose__field-row__button">
                    <Button onClick={this.openPopupList} className="stillHover">
                        {/* Vybrat*/}
                        {sAction.translate('LBL_EMAIL_SELECT')}
                    </Button>
                </div>
            </div>
        );
    }
}
