/**
 *
 * @param data
 * @param prefix
 * @param way
 * @param index
 */
export default function removeLineFromTab(data, prefix, way, index) {
    this.dsClear();
    data.forEach((field) => {
        if (field != 'empty_row') {
            this.dsAdd('add', prefix+'/hiddenFields', field);
        }
    });
    const wayWithoutPrefix = way.replace('/rows/'+index, '/rows');
    this.dsAdd('delete', wayWithoutPrefix, index);
    this.dsProcess();
}
