import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';
import DropdownAnimation from '../animation/DropdownAnimation';
import sAction from 'sAction';
import Loader from '../loader';
import classNames from 'classnames';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class NotificationCenter extends PureComponent {
    constructor() {
        super();
        this.list = React.createRef();
        this.doUpdate = true;
    }

    scroll = (e) => {
        const data = this.props.data;

        if (data.list.size >= 10) {
            const target = e.target;
            const scroll = target.scrollTop;
            let scrollHeight = target.scrollHeight;
            const height = target.clientHeight;
            if (scrollHeight > height) {
                scrollHeight -= height;
            }
            if (
                scroll >= scrollHeight - 100 &&
        this.doUpdate === true &&
        data.all === false
            ) {
                this.doUpdate = false;
                const paramData = {
                    limit: data.limit,
                    offset: data.offset,
                };

                sAction.notificationMore(paramData);
            }
        }
    };

    deleteAll = () => {
        sAction.notificationClear(this.props.data);
    };

    returnEmpty = () => {
        const label = sAction.translate('LBL_NOTIFICATIONS_EMPTY');

        return (

            <div className="notificationCenterEmpty" key={'zero'}>
                {label}
            </div>
        );
    };

    render() {
        this.doUpdate = true;
        const data = this.props.data;

        let renderData = null;
        let iconCustomClass = '';
        if (data !== null && data.open === true) {
            iconCustomClass = 'open';
            const listData = [];
            if (data.load === true) {
                listData.push(
                    <Loader key={'zero'} />,
                );
            } else if (data.list.size !== 0) {
                data.list.forEach((record, key) => {
                    listData.push(
                        <NotificationItem key={key} index={key} data={record} />,
                    );
                });
                listData.push(
                    <div className="notificationClearButton" onClick={this.deleteAll} >
                        <span className={classNames('icon-resetWidths', 'notificationClearIcon')} />
                    </div>,
                );
                if (data.loadMore === true) {
                    listData.push(
                        <Loader key={'zero'} />,
                    );
                }
            } else if (data.list.size === 0) {
                listData.push(this.returnEmpty());
            }
            renderData = (
                <DropdownAnimation className="notificationCenterContainer" open={data.open}>
                    {/*  <div className="notTriangle" /> */}
                    <div className="notificationCenterContent" onScroll={this.scroll}>
                        {/*  <div className="notificationCenterHeader">
              <div>
                {sAction.translate(
                  "LBL_NOTIFICATION_CENTER",
                  "acm_coripoNotify"
                )}
              </div>
            </div> */}
                        {listData}
                    </div>
                </DropdownAnimation>
            );
        }

        let notice = data.notice;
        if (notice > 9) {
            notice = '9+';
        }

        return (
            <div className="notificationButtonContainer">
                <TooltipWrapper label={'LBL_NOTIFICATION_CENTER'}>
                    <div
                        className={classNames('icon-notify', 'notificationButton', 'userHeaderButton', iconCustomClass)}
                        onClick={(e) => sAction.openNotCenter(e, data)}
                        id='NotificationButton'
                    />
                </TooltipWrapper>
                {data.notice != null && data.notice !== 0 && (
                    <div
                        className={classNames('notificationButton', 'notificationAlertContainer')}
                        onClick={(e) => sAction.openNotCenter(e, data)}
                    >
                        <span className=" notificationAlertNumber">{notice}</span>
                    </div>
                )}
                {renderData}
            </div>
        );
    }
}

NotificationCenter.propTypes = {
    data: PropTypes.shape({
        all: PropTypes.bool,
        limit: PropTypes.any,
        list: PropTypes.any,
        load: PropTypes.bool,
        loadMore: PropTypes.bool,
        notice: PropTypes.number,
        offset: PropTypes.any,
        open: PropTypes.bool,
    }),
};
