import moment from 'moment';

/**
 *
 * @param date
 */
export default function dateFormat(date) {
    if (date) {
        if (date === '0000-00-00') {
            return '';
        }
        const format = this.getDateFormat('moment') + (date.includes(' ') ? ' ' + this.getTimeFormat('moment') : '');
        return moment(date).format(format);
    }
    return '';
}
