import detailDefault from '../../detailDefault';

export default class detailDocumentRevisions extends detailDefault {
    load(sAction, data) {
        if (!data.prefix.includes('popup')) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/filename/def/readonly', true);
            sAction.dsProcess();
        }
    }
}
