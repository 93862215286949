import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import MaterialPopper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default class Popper extends PureComponent {
    /**
     *
     * @param {Number} index
     */
    close(index) {
        sAction.dataDelete('conf/popper', index);
    }
    render() {
        const data = this.props.data;
        const poppersRender = [];
        data.forEach((popper, index) => {
            const anchorOrigin = popper.get('anchorOrigin') !== undefined ? popper.get('anchorOrigin').toJS() : null;
            const transformOrigin = popper.get('transformOrigin') !== undefined ? popper.get('transformOrigin').toJS() : null;
            const className = popper.get('className') ? popper.get('className') : '';
            const selector = popper.get('selector');
            const el = document.querySelector(selector);
            let header = popper.get('header') ?? '';
            header = header.replace(':', '');
            if (el != null) {
                poppersRender.push(
                    <MaterialPopper
                        className={'acmPopover ' + className}
                        anchorEl={el}
                        {...anchorOrigin && {anchorOrigin: anchorOrigin}}
                        {...transformOrigin && {transformOrigin: transformOrigin}}
                        open={data.get('open') ?? true}
                        key={index}
                        onEscapeKeyDown={() => this.close(index)}
                    >
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={() => {
                                this.close(index);
                            }}>
                            <div>
                                <div className="popperHeader">
                                    <label dangerouslySetInnerHTML={{__html: header}} />
                                </div>

                                <div className="popperContent">
                                    {popper.get('content')}
                                </div>
                            </div>
                        </ClickAwayListener>
                    </MaterialPopper>,
                );
            }
        });
        return (
            poppersRender
        );
    }
}

Popper.propTypes = {
    data: PropTypes.shape({
        forEach: PropTypes.func,
        get: PropTypes.func,
    }),
};
