/**
 *
 * @param ind
 */
export default function getUrlParam(ind = 1) {
    let url = window.location.hash;
    url = url.replace('#', '');
    url = url.split('/');
    return url[ind];
}
