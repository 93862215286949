/**
 *
 * @param url
 */
export default function href(url) {
    const actUrl = window.location.hash;

    if (url == actUrl) {
        // this.reload();
        location.reload();
        window.scrollTo(0, 0);
    } else {
        window.location.href = url;
    }
}
