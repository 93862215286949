/**
 *
 * @param paramData
 */
export default function notificationMore(paramData) {
    paramData.offset += paramData.limit;
    const language = this.dataGet('conf/language');
    paramData['lang'] = language;
    this.dsClear();
    this.dsAdd('set', 'notifications/loadMore', true);
    this.dsProcess();
    this.rest.fetchData('getNotifications', 'POST', paramData )
        .then(({data}) =>{
            this.dsClear();
            data.forEach((record) => {
                this.dsAdd('add', 'notifications/list', record, 'notificationsRecord');
            });
            if (data.length < paramData.limit) {
                this.dsAdd('set', 'notifications/all', true);
            }
            this.dsAdd('set', 'notifications/offset', paramData.offset);
            this.dsAdd('set', 'notifications/loadMore', false);
            this.dsProcess();
        })
        .catch((res) => {
            console.warn(res);
        });
}
