/**
 *
 * @param paramData
 */
export default function importFile(paramData) {
    const prefix = paramData.prefix;
    const data = this.dataGet(prefix).toJS();
    if (!data.import) {
        this.error(this.translate('LBL_IMPORT_UPLOAD_CSV_FILE'), this.translate('LBL_IMPORT'));

        return false;
    }
    console.log(data?.mapping.fileMapping);

    if (!data?.mapping.fileMapping ||
        !Object.values(data.mapping.fileMapping)
            .some((val) => {
                return val.value !== false;
            })
    ) {
        this.error(this.translate('LBL_IMPORT_NO_MAPPED_FIELDS'), this.translate('LBL_IMPORT'));

        return false;
    }

    const mapping = data.mapping.fileMapping;
    const additionalMapping = data.mapping.additionalData;
    if (!this.validateImportMapping(prefix, mapping, additionalMapping)) {
        this.error(this.translate('LBL_IMPORT_MAPPING_DUPLICATES'), this.translate('LBL_IMPORT'));

        return false;
    }

    if (!validateRelateFields(this, prefix, mapping)) {
        this.error(this.translate('LBL_IMPORT_INVALID_RELATE_FIELDS'), this.translate('LBL_IMPORT'));

        return false;
    }

    /*
    Pokud jsme dosli az sem, nejsou namapovane zadnu duplicitni sloupce
     */
    const mappingValues = Object.values(data.mapping.fileMapping).filter((val) => val != null);

    const fields = data.fields.fields;
    let required = this.checkRequiredAreMapped(mappingValues, additionalMapping, fields);
    required = required.map((x) => this.translate(x, this.getModuleName()).replace(':', ''));
    const requiredNames = required.join(', ');

    if (required.length) {
        const confirmMessage = this.translate('LBL_IMPORT_REQURIED_FIELDS_MISSING_CONFIRM').replace('{%requiredNames%}', requiredNames);
        this.confirm(confirmMessage, () => {
            this.popupHide();
            startImport(this, prefix);
        });
    } else {
        startImport(this, prefix);
    }
}

/**
 *
 * @param sAction
 * @param prefix
 */
function startImport(sAction, prefix) {
    sAction.confirm(sAction.translate('LBL_IMPORT_CONFIRM_IMPORT'), () => {
        sAction.popupHide();
        sAction.uploadCacheFile(sAction.temporaryData.file, (data) => {
            const fileId = data['ids'][0]['file']['id'];
            sAction.importFileMapping({prefix: prefix, fileId: fileId});
            sAction.popupHide();
        });
    });
}

/**
 *
 * @param sAction
 * @param prefix
 * @param mapping
 */
function validateRelateFields(sAction, prefix, mapping) {
    sAction.dsClear();
    let isFieldValid = true;

    mapping.forEachObject((value, key) => {
        if (value.value && value.type === 'relate' && !value.relatedField?.value) {
            sAction.dsAdd('set', `${prefix}/mapping/duplicates/` + key, true);
            isFieldValid = false;
        } else {
            sAction.dsAdd('set', `${prefix}/mapping/duplicates/` + key, false);
        }
    });

    sAction.dsProcess();

    return isFieldValid;
}
