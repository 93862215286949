/**
 * reloads list with searched terms in search data added to filter
 * @param {Array} searchData searched terms
 * @param {string} prefix search prefix
 * @param {function} callback called after function finishes
 */
export default function listGlobalSearch(searchData, prefix, callback = null) {
    let action = 'REMOVEFROMFILTER';
    const actFilter = {
        field: '*',
        operator: 'and',
        filters: [],
        fieldModule: null,
        relationship: null,
    };

    if (searchData && searchData.length !== 0) {
        searchData.forEach((val) => {
            if (val?.val != '' && val?.val != null && val?.val != undefined) {
                actFilter.filters.push({
                    'field': '*',
                    'type': 'cont',
                    'value': val.val,
                });
            }
        });

        action = 'ADDTOFILTER';
    }

    this.dataSetCustom({
        type: action,
        listViewPrefix: prefix,
        filter: actFilter,
    });

    this.reloadList(0, 1, prefix);

    if (callback !== null) {
        callback();
    }
}
