import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default class TickBox extends React.PureComponent {
    handleChange = (e) => {
        const {onChange} = this.props;
        if (onChange != null) {
            onChange(e.target.checked);
        }
    };

    render() {
        const {...rest} = this.props;

        let className = 'acmTickBox';
        if (this.props.disabled) {
            className += ' disabled';
        }

        return (
            <Checkbox
                className={className}
                {...rest}
                id={this.props.id}
                // checked={checked}
                onChange={this.handleChange}
            />
        );
    }
}

TickBox.propTypes = {
    disabled: PropTypes.any,
    id: PropTypes.any,
    onChange: PropTypes.func,
};
