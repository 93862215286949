import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import sAction from 'sAction';
import moment from 'moment/moment';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default function ActivitiesTimelineItem(props) {
    const [showLink, setShowLink] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);
    const ref = React.createRef();

    React.useLayoutEffect(() => {
        if (ref?.current?.clientHeight < ref?.current?.scrollHeight) {
            setShowLink(true);
        } else {
            if (!showMore) {
                setShowLink(false);
            }
        }
    }, [ref]);

    /**
     *
     */
    const onClickMore = () => {
        setShowMore(!showMore);
    };

    const index = props.index;
    const record = props.record;
    const date = moment(record.get('date_start')).format('DD. MM. YYYY HH:mm');
    const module = record.get('module');
    const diffs = props.format.get(module);
    const createdBy = record.get('created_by');
    const dom = props.activityModuleDoms[module];
    const status = sAction.app_strings[dom][record.get('status')];

    let description = record.get('description') ?? null;
    if (description) {
        description = description?.replace(/(?:\r\n|\r|\n)/g, '<br>');
        description = (
            <div style={{width: '100%'}}>
                <div
                    ref={ref}
                    className={showMore ? 'content' : 'content lessContent'}
                    dangerouslySetInnerHTML={{__html: description}}
                />
                {showLink &&
                    // if description is long enough for displaying button
                    ( showMore ?
                        (
                            // display button for show less
                            <span className={'ActivitiesTimelineItemToogleDisplay'} onClick={onClickMore}>
                                <span className={'icon-circleUp'}/>
                                {' ' + sAction.translate('LBL_SHOW_LESS')}
                            </span>
                        ) : (
                            // display button for show more
                            <span className={'ActivitiesTimelineItemToogleDisplay'} onClick={onClickMore}>
                                <span className={'icon-circleDown'}/>
                                {' ' + sAction.translate('LBL_SHOW_MORE')}
                            </span>
                        )
                    )
                }
            </div>
        );
    }

    const colors = sAction.getActivityColors(module);
    const headerColor = sAction.convertToPastelHSL(colors.eventBackgroundColor, 1, 'dark');
    const iconColors = {
        backgroundColor: colors.eventBackgroundColor,
        color: headerColor,
    };
    const itemStyle = {
        backgroundColor: colors.backgroundColor,
    };
    return (
        <div
            className={'activityItem activity' + diffs.get('activityClass')}
            key={index}
        >
            <TooltipWrapper label={'LBL_' + module.toUpperCase()} module={module}>
                <span style={iconColors} className="icon">
                    <span style={iconColors} className={'icon-' + diffs.get('iconClass')}/>
                </span>
            </TooltipWrapper>
            <div style={itemStyle} className="activityContent">
                <span style={itemStyle} className="arrow"/>
                <a
                    className="header"
                    style={{color: headerColor}}
                    href={'#detail/' + diffs.get('link') + '/' + record.get('id')}
                    target="_blank" rel="noreferrer"
                >
                    {record.get('name')}
                </a>
                <div className="subHeader"/>
                <div className="dateAndStatus">
                    <div className="date">{date}</div>
                    <div className="status">{status}</div>
                    <div className="createdBy">{createdBy}</div>
                </div>
                {description}
            </div>
            {/* Icon in popup for item*/}
            <TooltipWrapper label={'LBL_' + module.toUpperCase()} module={module}>
                <span style={iconColors} className="icon right">
                    <span style={iconColors} className={'icon-' + diffs.get('iconClass')}/>
                </span>
            </TooltipWrapper>
        </div>
    );
}

ActivitiesTimelineItem.propTypes = {// TODO
    record: ImmutablePropTypes.mapContains({
        date_entered: PropTypes.string,
        name: PropTypes.string,
        module: PropTypes.string,
        created_by: PropTypes.string,
        assigned_user_name: PropTypes.string,
        date_start: PropTypes.string,
        status: PropTypes.string,
        id: PropTypes.string,
        description: PropTypes.string,
        assigned_user_id: PropTypes.string,
    }).isRequired,
    activityModuleDoms: PropTypes.objectOf(PropTypes.string).isRequired,
    index: PropTypes.number.isRequired,
    format: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.mapContains({
            activityClass: PropTypes.string,
            iconClass: PropTypes.string,
            link: PropTypes.string,
        }),
    ),
};
