/**
 *
 * @param way
 * @param value
 * @param type
 */
export default function saveCellParentField(way, value, type) {
    this.dsClear();
    console.log(way, value, type);
    this.dsAdd('set', way + '/value', value.name);
    this.dsAdd('set', way + '/module', type);
    this.dsAdd('set', way + '/id', value.id);
    // this.dsAdd('set', way + '/actEdit', false);
    this.dsProcess();
}
