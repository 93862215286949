/**
 * @param {object} paramData
 * @this sAction
 * @button DetailView/Calls
 * @button DetailView/Meetings
 * @button DetailView/Activities
 */
export default function actCloseCreateNew(paramData) {
    if (paramData.canSave === true) {
        let closeStatus = 'Held';
        if (paramData.module === 'Tasks') {
            closeStatus = 'Completed';
        }

        this.dataSet(paramData.prefix+'/fields/status/value', closeStatus);
        this.dataSet(paramData.prefix+'/changes/fields/status', closeStatus);
    }

    const self = this;
    this.saveRecordBase(paramData, (data) => {
        if (data.state === true) {
            this.dataSet(paramData.prefix+'/changes', {fields: [], files: []});

            sessionStorage.setItem('actCloseCreateNewPredfinedFields', '1');
            this.detailPredefinedFields = [];

            if (paramData.newModule === 'Opportunities') {
                this.detailPredefinedFields.push({name: 'sales_stage', value: 'Prospecting', type: 'field'});
            }

            const ignoreFields = [
                'id',
                'created_by_name',
                'date_entered',
                'date_modified',
                'date_start',
                'duration_hours',
                'status',
            ];

            const fields = this.dataGet(paramData.prefix+'/fields').toJS();

            fields.forEachObject( (field, fname) => {
                if (ignoreFields.indexOf(fname) >= 0) {
                    return;
                }
                if (!field.def) {
                    return;
                }
                if (field.def?.forbidDuplicate === true) { // if you delete this, handle extarnal IDs copying, like msgraph_id_c:
                    return;
                }
                if (field.def.type === 'parent') {
                    if (paramData.newModule === 'Opportunities') {
                        if (field.def.parent_type_value === 'Accounts') {
                            self.detailPredefinedFields.push({
                                type: 'relate_simple',
                                name: 'account_id',
                                fieldName: 'account_name',
                                value: {name: field.value, id: field.def.id_value},
                            });
                        }
                    } else {
                        this.detailPredefinedFields.push({
                            name: 'parent_name',
                            id_name: 'parent_id',
                            parentName: field.value,
                            parentId: field.def.id_value,
                            parentModule: field.def.parent_type_value,
                            type: 'parent',
                        });
                    }
                } else {
                    this.detailPredefinedFields.push({name: fname, value: field.value, type: 'field'});
                }
            });

            // prenaseni pozvanych pres customData
            if (['Calls', 'Meetings'].includes(paramData.newModule)) {
                const customData = this.dataGet(`${paramData.prefix}/customData`).toJS();
                const customPredefined = {};
                customData.forEachObject((value, key) => {
                    customPredefined[key] = value;
                });
                this.detailPredefinedFields.push({type: 'customData', value: customPredefined});
            }

            if (paramData.type === 'rightPanel') {
                this.closeRightPanel();
            }

            if (paramData.type === 'popup') {
                this.unLoad();
                this.popupDetail({
                    module: paramData.newModule,
                    record: '',
                    saveCallback: () => {
                        if (paramData.newModule === 'Opportunities') {
                            this.reloadSubpanelByWay('view', paramData.newModule);
                        } else {
                            const rightPanel = this.dataGet('rightPanel/data');
                            this.reloadSubpanelByWay('view', 'Activities');
                            this.reloadSubpanelByWay('view', 'History');
                            if (rightPanel) {
                                this.reloadSubpanelByWay('rightPanel/data/detail', 'Activities');
                                this.reloadSubpanelByWay('rightPanel/data/detail', 'History');
                            }
                        }
                        this.popupHide();
                    },
                    exitCallback: () => {

                    },
                });
            } else {
                if (window.location.hash === `#detail/${paramData.newModule}`) {
                    window.location.hash = '#detail/' + paramData.newModule;
                    this.route();
                } else {
                    window.location.hash = '#detail/' + paramData.newModule;
                }
            }

            /*
            if(this.getModuleName() != paramData.module){
              this.closeRightPanel();
              this.unLoad();
            }else{
              window.location.hash = "#list/" + msg.module;
            }
            */
        } else {
            self.error(data.msg);
        }
    });
}
