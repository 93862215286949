import React from 'react';
import PropTypes from 'prop-types';
import Enum from '../Enum/Enum';
import Select from '../../formElements/Select';
import sAction from 'sAction';

// eslint-disable-next-line camelcase
export default class Currency_id extends Enum {
    /**
     *
     * @param {KeyboardEvent} e
     * @param {*} type
     * @param {boolean} save
     */
    onKeyDown(e, type = null, save = true) {
        if (e.key === 'Enter') {
            e.workAsTab = true;
        }
        super.onKeyDown(e, type, save);
    }

    render() {
        const optionsToSelect = [];
        sAction.currencies.forEachObject((value, key) => {
            if (value['translate']) {
                optionsToSelect.push({
                    value: key,
                    label: value['translate'],
                });
            } else {
                optionsToSelect.push({
                    value: key,
                    label: value['name'] + ' (' + value['symbol'] + ')',
                });
            }
        });
        const forceOpen = this.props.def.get('forceOpen') || this.props.listField;

        return (
            <Select
                key="input"
                options={optionsToSelect}
                onKeyDown={(e) => this.onKeyDown(e, null, false)}
                open={forceOpen === true ? forceOpen : false}
                autoFocus={forceOpen !== true ? true : forceOpen}
                defaultValue={this.props.value}
                onChange={(e) => this.save(e)}
            />
        );
    }
}

// eslint-disable-next-line camelcase
Currency_id.propTypes = {
    def: PropTypes.object,
    listField: PropTypes.bool,
    value: PropTypes.string,
};
