/**
 *
 * @param data
 */
export default function mapImportData(data) {
    const mapping = {};
    mapping.value = data.value;
    mapping.inputValue = data.type === 'relate' ? data.value.name : data.value;
    mapping.field = data.name;
    mapping.label = sessionStorage.getItem('additionalImportDataLabel');
    sessionStorage.removeItem('additionalImportDataLabel');
    mapping.visible = false;
    this.dsClear();
    this.dsAdd('set', data.way, mapping);
    this.dsProcess();
}
