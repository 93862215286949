import React, {useEffect, Suspense, useState} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

/**
 * @param {*} selector
 * @param {*} help
 * @param {*} header
 */
function showInfo(selector, help, header) {
    sAction.popper({
        selector: selector,
        content: help,
        header: header,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    });
}

export default function ReportViewField(props) {
    /**
     * @param {Event} e
     * @param {object} data
     */
    function show(e, data) {
        e.stopPropagation();
        const prefix = props.prefix;
        const type = sAction.dataGet(prefix + '/type');
        if (type !== 'rightPanel') {
            sAction.rightPanelDetail(data.module, data.id);
        }
    }
    /**
     * @param {*} value
     */
    const onBlur = (value) =>{
        if (props.def.get('type') !== 'bool') {
            setActEdit(!actEdit);
        }
        props.onChange(props.name, value);
    };
    const [Field, setField] = useState(React.lazy(() => import(`../DetailViewFields/ValuesFields/Varchar/Varchar.js`)));
    const [actEdit, setActEdit] = useState(false);
    useEffect(() => {
        if (actEdit) {
            setField(React.lazy(() => import(`../DetailViewFields/InputFields/${type}/${type}.js`)));
        } else {
            setField(React.lazy(() => import(`../DetailViewFields/ValuesFields/${type}/${type}.js`)));
        }
    }, [actEdit]);

    const type = props.def.get('type')?.charAt(0).toUpperCase() + props.def.get('type')?.slice(1);

    const help = props.def.get('help') ?? false;
    const style = props.group ? {width: '100%'} : {width: `${100 / (props.panelColumns || 2) * (props.fieldColumns || 1)}%`};
    if (props.def.get('visible') === false) {
        return '';
    }

    return props.def.get('displayIfNew') === false && props.id === null ? null : (
        <div
            className={`detailViwRow ${props.customClass??''}`}
            style={style}
        >
            <div className="DetailviewLabel" style={{width: `${40 / props.fieldColumns}%`}}>
                <label dangerouslySetInnerHTML={{__html: props.labelValue}} />
                {props.def.get('required') && <span className="required">* </span>}
                {help && (<div id={props.name + '_help'}
                    className="icon-info detailviewInfoIcon"
                    onClick={() => showInfo('#' + props.name + '_help', help, props.labelValue)}/>
                )}
            </div>
            <div className={props.edit ? 'DetailViewValue pointerClass' : 'DetailViewValue'}
                style={{width: `${100 - (40 / props.fieldColumns)}%`}}>
                <div className=''>
                    <Suspense fallback={' '}>
                        <Field
                            edit={() => {
                                sAction.fieldToEdit({
                                    way: props.way,
                                    name: props.name,
                                    prefix: props.prefix,
                                    type: props.def.get('type'),
                                });
                                setActEdit(!actEdit);
                            }}
                            onBlur={onBlur}
                            show={(e, data) => show(e, data)}
                            {...props} />
                    </Suspense>
                    {props.def.get('isInvalid') ? (
                        <span className="errorMsg">
                            {sAction.translate('LBL_REQUIRED_FIELD_EMPTY', 'Calendar')}
                        </span>
                    ) : null}
                </div>
                <input type="hidden" id={props.name + '_hidden'} value={props.value == null ? '' : props.value}/>
            </div>
        </div>
    );
}

ReportViewField.propTypes = {
    prefix: PropTypes.any, // napr: view
    module: PropTypes.any,
    edit: PropTypes.any,
    way: PropTypes.any, // napr: view/field/${field}
    percent: PropTypes.any, // TODO netusim co je, mozna pujde smazat
    key: PropTypes.any,
    rowWay: PropTypes.any,
    id: PropTypes.any,
    readonly: PropTypes.any,
    acl: PropTypes.any,
    mode: PropTypes.any,
    attemptedSave: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    actEdit: PropTypes.any,
    customStyle: PropTypes.any,
    customClass: PropTypes.any,
    def: PropTypes.any,
    events: PropTypes.any,
    selectForEdit: PropTypes.any,
    group: PropTypes.bool,
    onChange: PropTypes.func,

    extraStyle: PropTypes.any,
    labelValue: PropTypes.any,
    panelColumns: PropTypes.number,
    fieldColumns: PropTypes.number,
};
